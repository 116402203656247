import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getByIds } from '../../services/services.service'
import { ApprovazionePresenze } from '../../models/ApprovazionePresenze';

const microservice = "unitaoperative";
const entity = "presenze";

interface ApprovazionePresenzeState {
  statusValidPresenze: StatusEnum,
  validPresenze: ApprovazionePresenze[],
  error: string | null
}

const initialState: ApprovazionePresenzeState = {
  statusValidPresenze: StatusEnum.Succeeded,
  validPresenze: [],
  error: null
}

// "cerca/presenze/approvazionerichiesta/{soloDaApprovare}/{dataInizio}/{dataFine}"
// "cerca/presenze/approvazionerichiesta/{soloDaApprovare}"
// "cerca/presenze/approvazionerichiesta"
export const fetchByIds = createAsyncThunk(microservice + '/fetchByIds', async (obj: Record<string, boolean | string | null>) => {
  const ids: (string | boolean)[] = ['approvazionerichiesta']
  if (obj) {
    if (obj['soloDaApprovare'] !== null && obj['soloDaApprovare'] !== undefined)
      ids.push(obj['soloDaApprovare'])
    else
      ids.push(true)

    if ((obj['inizioData'] !== null && obj['inizioData'] !== undefined) || (obj['fineData'] !== null && obj['fineData'] !== undefined)) {
      if (obj['inizioData'] !== null && obj['inizioData'] !== undefined)
        ids.push(obj['inizioData'])
      else
        ids.push('1900-01-01T00:00:00')
      if (obj['fineData'] !== null && obj['fineData'] !== undefined)
        ids.push(obj['fineData'])
      else
        ids.push('9999-12-31T00:00:00')
    }

  }

  const response = await getByIds(microservice, entity, ids, ['/fetchByIds']);

  return response.data as ApprovazionePresenze[];
});

export const approvazionePresenzeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ApprovazionePresenzeState) => {
      return initialState;
    },
    resetError: (state: ApprovazionePresenzeState) => {
      state.error = initialState.error;
      state.statusValidPresenze = initialState.statusValidPresenze;
    },

  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchByIds.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchByIds.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }

      state.validPresenze = []
    })
    builder.addCase(fetchByIds.fulfilled, (state, { payload }: PayloadAction<ApprovazionePresenze[]>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = payload;

    })


  }
});

export const { reset, resetError, } = approvazionePresenzeSlice.actions;
export default approvazionePresenzeSlice.reducer;