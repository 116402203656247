import Axios from 'axios';
import { portaleBEUrl } from "../utils/utilconst";
import { allDeleted, allValid, find, insert, update } from "../utils/serviceConst";
import Funz from '../models/Funzione';
import { SimpleResponse } from '../models/Utils';

const requestControllers: Record<string, {
  controller: AbortController | null,
  aborted: boolean,
}
> = {};

const requestControllersHandler = (abortController: AbortController, key: string): AbortController | null => {
  if (requestControllers[key]?.controller && !requestControllers[key]?.aborted) {
    requestControllers[key].controller?.abort();
    requestControllers[key].aborted = true;
    delete requestControllers[key];
  }

  requestControllers[key] = {
    controller: null,
    aborted: false
  };
  requestControllers[key].controller =
    requestControllers[key] && requestControllers[key].controller
      ? requestControllers[key].controller
      : abortController;
  requestControllers[key].aborted = false;

  return requestControllers[key].controller;
}

export function loadMenu(idAppSoft: string, idAppSoftProf: string) {
  return Axios.get<Funz[]>(portaleBEUrl + "/applicazionisoftwarefunzioni/abilitazioni", {
    headers: {
      "idAppSoft": idAppSoft,
      "idAppSoftProf": idAppSoftProf
    }
  });
}

export function getByIdGenericPortaleSaaS(microservice: string, entity: string, ids: any[], dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + find + "/" + entity;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<Object | Object[]>(call, { signal: abortController?.signal });
}

export function getByIdPortaleSaaS(microservice: string, entity: string, ids: any[], dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + find + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id));
  return Axios.get<Object>(call, { signal: abortController?.signal });
}

export function getAllByIdPortaleSaaS(microservice: string, entity: string, ids: any[]) {
  let call = portaleBEUrl + "/" + microservice + find + "/" + entity + "/all";
  ids.forEach(id => call += ("/" + id));
  return Axios.get<Object>(call);
}

/* ADMIN */
export function createPortaleSaaS(obj: Object, microservice: string, entity: string, dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  return Axios.post<Object>(portaleBEUrl + "/" + microservice + insert + "/" + entity, obj, { signal: abortController?.signal });
}

export function updatePortaleSaaS(obj: Object, microservice: string, entity: string, path?: string[], dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + update + "/" + entity;
  path?.forEach(id => call += ("/" + id));

  return Axios.post<Object>(call, obj, { signal: abortController?.signal });
}

/*FILTERED*/
export function getAllValidByIdsPortaleSaaS(microservice: string, entity: string, ids: any[], dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + find + "/" + entity + allValid;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<Object[]>(call, { signal: abortController?.signal });
}

export function getAllDeletedByIdsPortaleSaaS(microservice: string, entity: string, ids: any[], dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + find + "/" + entity + allDeleted;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<Object[]>(call, { signal: abortController?.signal });
}

/** Generic operation */
export function executeOpByIdsPortaleSaaS(microservice: string, entity: string, method: 'get' | 'post' | 'put' | 'delete', operation: string, ids: any[], obj?: Object, dataModify?: string[]) {
  const key = microservice + '/' + entity + '/' + dataModify?.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = portaleBEUrl + "/" + microservice + operation + "/" + entity;
  ids.forEach(id => call += ("/" + id));

  const signal = { signal: abortController?.signal };

  switch (method) {
    case 'delete':
      return Axios.delete<SimpleResponse>(call, signal);
    case 'put':
      return Axios.put<SimpleResponse>(call, signal);
    case 'post':
      return Axios.post<Object>(call, obj, signal);
    default:
      return Axios.get<Object[] | Object>(call, signal);
  }
}