import { validateInputNumberMin, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type TurnoKeys = keyof Turno;

export const elementIdProps: TurnoKeys[] = ["idTurno"];
export const elementRenderProps: TurnoKeys[] = ["descrizioneBreve"];

export const allFields: Fields[] = [
  {
    field: "descrizioneBreve", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, true), keyTradValidation: "size1to10ParamValidation",
    show: true, titleKey: "descriptionShortParam"
  },
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "ore", type: "numeric", required: false,
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterThanZeroValidation",
    show: true, titleKey: "hoursParam"
  },
  {
    field: "richiestaApprovazione", type: "boolean", required: false, show: true, titleKey: "approvalRequest",
  },
  {
    field: "turnoOrdinario", type: "boolean", required: false, show: true, titleKey: "ordertTurn",
  },

]
export interface Turno {
  idTurno: number,
  descrizioneBreve: string,
  descrizione: string,
  ore?: number,
  richiestaApprovazione: boolean,
  turnoOrdinario: boolean,
  version: number
}