
import { validateInputNumberMin, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type DipartimentiSediKeys = keyof DipartimentiSedi;

export const elementIdProps: DipartimentiSediKeys[] = ['idStruttura', 'idDipartimento', 'idDipartimentoSede'];
export const elementRenderProps: DipartimentiSediKeys[] = ['descrizione'];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', required: true, show: true, titleKey: "descriptionParam",
    validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "indirizzo", type: 'string', required: false, show: true, titleKey: "addressParam"
  },
  {
    field: "latitudine", type: 'string', required: true, show: true, titleKey: "latitudeParam",
    validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "longitudine", type: 'string', required: true, show: true, titleKey: "longitudeParam",
    validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "distanzaAmmessaMetri", type: 'numeric', required: true, show: true, titleKey: "coverageParam",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, false), keyTradValidation: "greaterOrEqualThanZeroValidation"
  },
  {
    field: "mappa", required: false, show: true, titleKey: "positionParam", editable: "never"
  }
];

export interface DipartimentiSedi {
  idStruttura: number,
  idDipartimento: number,
  idDipartimentoSede: number,

  descrizione: string,
  indirizzo?: string,
  latitudine: string,
  longitudine: string,
  distanzaAmmessaMetri: number,

  version: number,
};
