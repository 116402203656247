import { Paper, Box, makeStyles, createStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PDFObject from 'pdfobject';
import clsx from 'clsx';

import { UploadedFile } from '../AnagraficaSoggettiDocumentiW';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: 30,
      textAlign: 'center'
    },
    inputBox: {
      paddingLeft: '20%'
    },
    pdfContainer: {
      height: '100%',
      width: '100%',
    }
  }),
);

interface AnteprimaDocumentiProps {
  file: UploadedFile,
  disableTitle?: boolean,
  isDownloadable?: boolean
}

const AnteprimaDocumenti = ({ file, disableTitle, isDownloadable }: AnteprimaDocumentiProps) => {
  const classes = useStyles();

  const [fileBase64, setFileBase64] = useState<string | null>(null);
  useEffect(() => {
    setFileBase64(file?.content);
  }, [file?.content]);

  useEffect(() => {
    return () => {
      setFileBase64(null)
    }
  }, []);

  PDFObject.embed(fileBase64 ?? '', '#pdf-anteprima')

  return (
    <Paper elevation={2}>
      <Box p={2} height='85vh'>
        <div id='pdf-anteprima' className={clsx(classes.pdfContainer)} />
      </Box>
    </Paper>
  )
}

export default AnteprimaDocumenti;