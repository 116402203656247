import React from 'react'

import { createStyles, makeStyles, Modal, Theme, Grid, IconButton, Paper, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { PDFViewer } from 'react-pdf-renderer-v2';

interface AnteprimaModalProps {
  file: JSX.Element
  open: boolean
  handleClose: () => void
  anagrafica?: {
    nome: string,
    cognome: string,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'hidden',
    },
    paper: {
      height: '90vh'
    }
  }),
);

export const AnteprimaModal = (props: AnteprimaModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      className={clsx(classes.container)}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={clsx(classes.paper)}>
        <Grid container>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Box display='flex' justifyContent='right'>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {/* <PDFDownloadLink
          document={props.file}
          fileName={'Scheda Dipendente ' + props?.anagrafica?.cognome + ' ' + props?.anagrafica?.nome}
        >
          {
            ({ blob, url, loading, error }) =>
              loading ? t('fileLoadingLabel') : t('download')
          }
        </PDFDownloadLink> */}
        <PDFViewer
          height={'100%'}
          width={'100%'}
          showToolbar={true}
        >
          {props.file}
        </PDFViewer>
      </Paper>
    </Modal>
  )
}
