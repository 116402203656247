import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { User } from '../../models/User';
import { getByIdGenericPortaleSaaS } from '../../services/funzionalita.service';

const microservice = "utenti";
const entity = "utenti";
interface UtentiPsState {
  statusValidUtenti: StatusEnum,
  validUtenti: User[],
  utente: User | null,
  error: string | null
}

const initialState: UtentiPsState = {
  statusValidUtenti: StatusEnum.Succeeded,
  validUtenti: [],
  utente: null,
  error: null
}

export const fetchByToken = createAsyncThunk(entity + '/fetchByToken', async (token: string) => {
  const response = await getByIdGenericPortaleSaaS(microservice, entity, ['token', token]);
  return response.data as User[];
});

export const utentiPsSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;;
    },
    resetError: (state: UtentiPsState) => {
      state.error = initialState.error;
      state.statusValidUtenti = initialState.statusValidUtenti;
    }

  },
  extraReducers: builder => {
    builder.addCase(fetchByToken.pending, (state) => {
      state.statusValidUtenti = StatusEnum.Loading;
    })
    builder.addCase(fetchByToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUtenti = StatusEnum.Failed;
      state.validUtenti = [];
    })
    builder.addCase(fetchByToken.fulfilled, (state, { payload }: PayloadAction<User[]>) => {
      state.statusValidUtenti = StatusEnum.Succeeded;
      state.validUtenti = payload ?? [];
    })
  }
});

export const { reset, resetError } = utentiPsSlice.actions;
export default utentiPsSlice.reducer;