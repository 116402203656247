import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { caricamentoDocumentiPath } from "../../../utils/utilconst";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {

  resetError as resetErrorAnagraficaSoggettiDocumenti,
  fetchbyidUploadPdfCheckFile,
  insertMassivo
} from "../../../store/slices/anagraficaSoggettiDocumentiSlice";
import {
  fetchAllValid,
  resetError as resetErrorTipiDocuemnti
} from '../../../store/slices/tipoDocumentoSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiDocumenti, allFieldsCaricamentoDocumenti, AnagraficaSoggettiDocumentiKeys } from '../../../models/AnagraficaSoggettiDocumenti';
import { Fields } from "../../../models/Fields";
import { componentTabsPath, documentoTabPath } from "../../../utils/innerFuncPaths";
import {
  Redirect,
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import Button from "@material-ui/core/Button";
import { Box, createStyles, Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import clsx from "clsx";
import AnteprimaDocumenti from "./AnagraficaSoggettiComponents/AnteprimaDocumenti";
import { AnteprimaModal } from "./AnagraficaSoggettiComponents/AnteprimaModal";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { openDialogConfirm } from "../../../store/slices/dialogsSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    legendTopOffset: {
      // marginTop: -75,
    }
  }),
);

export interface UploadedFile {
  content: string;
  name: string;
  mediaType?: string;
  extension?: string;
}

const CaricamentoDocumentiW = () => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  }

  const abilitazione = useAppSelector(state => getAbilitazione(state, caricamentoDocumentiPath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiDocumenti.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorTipiDocuemnti());
    dispatch(resetErrorAnagraficaSoggettiDocumenti());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  const documentoLookup = useAppSelector(state => state.tipoDocumento.lookup);
  const idTipoDocumento = "idTipoDocumento";

  const title = t('');

  useEffect(() => {
    dispatch(fetchAllValid());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsCaricamentoDocumenti);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiDocumenti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiDocumenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiDocumentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type && f.type === "file") {
          obj.title = t('documents');
          obj.emptyValue = t('noFile');
          obj.render = (rowData: AnagraficaSoggettiDocumenti) => {
            return <Button
              onClick={() => {
                handleOpen();
                setUploadedFile({
                  content: rowData.contenutoDocumento.startsWith('data:application/pdf;base64,') ? rowData.contenutoDocumento : 'data:application/pdf;base64,' + rowData.contenutoDocumento,
                  name: rowData.nomeDocumento
                });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          };
        }
        // /*+++*/
        switch (f.field) {
          case idTipoDocumento:
            obj.lookup = documentoLookup;
            break;
        }

        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, documentoLookup, t]);

  const statusValidAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.statusValidAnagraficaSoggettiDocumenti);

  const validAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.validAnagraficaSoggettiDocumenti);
  const [documentiSelezionati, setDocumentiSelezionati] = useState<Array<AnagraficaSoggettiDocumenti>>([]);

  const [data, setData] = useState<Array<AnagraficaSoggettiDocumenti>>([]);

  useEffect(() => {
    setData(validAnagraficaSoggettiDocumenti)
  }, [validAnagraficaSoggettiDocumenti])

  const formLookups = useMemo(() => {
    return {
      idTipoDocumento: documentoLookup,
    }
  }, [documentoLookup]);

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const formCallback = useCallback((anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti, field: string, optionalArgs: OptionalArgs<AnagraficaSoggettiDocumenti>) => {
    const { setInternalObj, file } = optionalArgs;
    const _file = file as File

    if (field === 'contenutoDocumento') {
      setUploadedFile({
        content: anagraficaSoggettiDocumenti[field],
        name: anagraficaSoggettiDocumenti['nomeDocumento']
      });

      if (_file) {
        setInternalObj(state => {
          let ext: string | string[] = _file['type']?.split('/');
          if (ext)
            ext = ext[ext.length - 1];

          return {
            ...state,
            estensioneDocumento: ['pdf', 'jpg', 'jpeg', 'png'].includes(ext as string) ? (ext as string) : '',
            mediaType: _file['type'],
            nomeDocumento: _file['name']
          }
        })
      } else {
        setInternalObj(state => {
          return {
            ...state,
            estensioneDocumento: '',
            mediaType: '',
            nomeDocumento: '',
          }
        })
      }
    }
  }, []);

  const onSelectionChange = (rows: AnagraficaSoggettiDocumenti[]) => {
    setDocumentiSelezionati(rows)
  }

  const onSubmit = () => {
    dispatch(insertMassivo(documentiSelezionati)).then(() => {
      history.push(caricamentoDocumentiPath);
      dispatch(openDialogConfirm({
        title: ' ',
        text: 'Documenti inviati corretamente',
        showButtons: false
      }));
      setUploadedFile(null);
    });
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={caricamentoDocumentiPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={caricamentoDocumentiPath}
          tabsUri={caricamentoDocumentiPath}
          tabsView={false}
          buttonTitle={""}
          info1={t("Caricamento Documenti")}
          tabs={[
            {
              label: t("subjectRegistryTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <Grid container spacing={2}>
                  <Grid item xs xl>
                    <GeneralForm
                      readOnly={false}
                      language={i18n.language}
                      componentPath={caricamentoDocumentiPath + componentTabsPath + documentoTabPath}
                      action={fetchbyidUploadPdfCheckFile}
                      status={statusValidAnagraficaSoggettiDocumenti}
                      error={errorBE}
                      update={false}
                      fields={allFieldsState}
                      translate={t}
                      lookups={formLookups}
                      checksCallback={formCallback}
                      buttonLabel="elabora"
                    />
                  </Grid>
                  <Grid item xs={5} xl={5} className={clsx(classes.legendTopOffset)}>
                    {
                      uploadedFile &&
                      <AnteprimaDocumenti file={uploadedFile} />
                    }
                  </Grid>
                </Grid>
              ),
              componentElse: <Redirect to={caricamentoDocumentiPath} />,
            },
          ]}
        />
      </Route>
      <Route path={caricamentoDocumentiPath + componentTabsPath + documentoTabPath}>
        {
          data.length > 0
            ? <Paper>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchExtendedByIdAndDate}
                physicalDel={undefined}
                update={undefined}
                insert={undefined}
                insertCallback={undefined}
                updateCallback={undefined}
                detailCallback={undefined}
                statusValid={statusValidAnagraficaSoggettiDocumenti}
                errorBE={errorBE}
                logoUri={logoUri}
                nullableTextFields={true}
                localizedDatePicker={true}
                resetErrorCallback={resetError}
                extraOptions={{
                  maxBodyHeight: 460,
                  selection: true,
                }}
                onSelectionChange={onSelectionChange}
              />
              <Box style={{
                width: 350,
                padding: 25,
                marginRight: 'auto',
                marginLeft: 'auto',
              }} >
                <Button
                  variant='contained'
                  color="primary"
                  onClick={onSubmit}
                  fullWidth
                  style={{
                    padding: 10,
                  }}
                >
                  {t('Invia')}
                </Button>
              </Box>
            </Paper>
            : <Redirect to={caricamentoDocumentiPath} />
        }

        {
          uploadedFile &&
          <AnteprimaModal
            open={isOpen}
            handleClose={handleClose}
            file={uploadedFile}
          />
        }
      </Route>
    </Switch>
  )
}
export default CaricamentoDocumentiW;


