import { Fields } from "./Fields";
import { AnagraficaSoggetto } from "./AnagraficaSoggetti";
import { AnagraficaSoggettiSchedaSanitaria } from "./AnagraficaSoggettiSchedaSanitaria";

export type ScadenzeVisiteKeys = keyof ScadenzeVisite;

export const elementIdProps: ScadenzeVisiteKeys[] = ["idAnagraficaSoggetto"];
export const elementRenderProps: ScadenzeVisiteKeys[] = ["nome", "cognome"];

export const allFields: Fields[] = [
  {
    field: "matricola", type: "string", required: false, removable: false, show: true, titleKey: "numberParam", readonly: true,
  },
  {
    field: "codiceFiscale", type: "string", required: false, removable: false, show: true, titleKey: "fiscalCodeParam", group: "",
  },
  {
    field: "cognome", type: "string", required: false, removable: false, show: true, titleKey: "lastNameParam", group: "",
  },
  {
    field: "nome", type: "string", required: false, removable: false, show: true, titleKey: "nameParam", group: "",
  },
  {
    field: "strutturaDescrizione", type: "string", required: false, removable: false, show: true, titleKey: "structureParam",
  },
  {
    field: "dipartimentoDescrizione", type: "string", required: false, removable: false, show: true, titleKey: "departmentParam",
  },
  {
    field: "unitaOperativaNome", type: "string", required: false, removable: false, show: true, titleKey: "operationalUnitParam",
  },
  {
    field: "codiceVisitaDescrizione", titleKey: "descriptionVisit", required: false, show: true,
  },
  {
    field: "dataVisita", titleKey: "lastvisitDateParam", type: "date", required: false, show: true,
  },
  {
    field: "idoneitaDescrizione", titleKey: "eligibilityParam", required: false, show: true,
  },
  {
    field: "dataControlloSuccessivo", titleKey: "nextcheckDateParam", type: "date", required: false, show: true,
  },
]

export interface ScadenzeVisite extends AnagraficaSoggetto, AnagraficaSoggettiSchedaSanitaria { }