import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type IdoneitaKeys = keyof Idoneita;

export const elementIdProps: IdoneitaKeys[] = ["idIdoneita"];
export const elementRenderProps: IdoneitaKeys[] = ["descrizione"];

export const allFields: Fields[] = [
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
        show: true, titleKey: "descriptionParam"
    }
]
export interface Idoneita {
    idIdoneita: number,
    descrizione: string,
    version: number
}