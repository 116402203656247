import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { defaultValue, filterGroup, Group, renderData } from './utils/utils';

interface SchedaProfessionaleSectionProps {
  schedaProfessionale: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    matricola: ['numberParam', 'string'],
    tipoContratto: ['typeOfContractParam', 'string'],
    dataAssunzione: ['assumptionDateParam', 'date'],
    dataPresaServizio: ['dateOfServiceParam', 'date'],
    dataAnzianitaServizio: ['dateOfServiceSeniorityParam', 'date'],
    dataFinePeriodoProva: ['trialPeriodEndDateParam', 'date'],
    dataFineContratto: ['assumptionDateEndParam', 'date'],
    orarioSettimanale: ['weeklyHoursParam', 'string'],
    specializzazione: ['specializationParam', 'string'],
    motivoDescrizione: ['reasonParamExit', 'string'],
    dataUscita: ['dateLabelexit', 'date'],
  },
  tipoIncarico: {
    tipoIncaricoDescrizione: ['jobTitleParam', 'string'],
    dataInizioIncarico: ['jobStartDateParam', 'date'],
    dataFineIncarico: ['jobEndDateParam', 'date'],
  },
  partTime: {
    partTimeDataInizio: ['partTimeStartDateParam', 'date'],
    partTime: ['partTimeParam', 'string'],
    partTimeTipo: ['partTimeTypeParam', 'string'],
    partTimeOrizzontale: ['partTimeHParam', 'string'],
    partTimeVerticale: ['partTimeVParam', 'string'],
    partTimeMotivazione: ['partTimeMotiveParam', 'string'],
  },
  legge104: {
    legge104DataInizioVariazione: ['law104StartDateParam', 'date'],
    legge104Numero: ['law104NumberParam', 'string'],
  },
  legge151: {
    legge15101DataInizio: ['law15101StartDateParam', 'date'],
    articoloLeggeLegge15101Descrizione: ['lawArticle15101Param', 'string'],
  }
}

const SchedaProfessionaleSection = (props: SchedaProfessionaleSectionProps) => {
  const { schedaProfessionale } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});
  const [tipoIncarico, setTipoIncarico] = useState({});
  const [partTime, setPartTime] = useState({});
  const [legge104, setLegge104] = useState({});
  const [legge151, setLegge151] = useState({});

  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, schedaProfessionale));
    setTipoIncarico(filterGroup(groups.tipoIncarico, schedaProfessionale));
    setPartTime(filterGroup(groups.partTime, schedaProfessionale));
    setLegge104(filterGroup(groups.legge104, schedaProfessionale));
    setLegge151(filterGroup(groups.legge151, schedaProfessionale));
  }, [schedaProfessionale]);

  return (
    <View style={styles.container} wrap={false}>

      {/* No group */}
      <View style={[styles.view, styles.viewWithGroup]}>
        <Text style={styles.groupTitleSchedaProfessionale}>{t('professionalCardParam')}</Text>
        {
          Object.entries(noGroup).map(([attr, value], index) => {
            const [key, dataType] = attr.split(',') as [string, FieldType];
            return (
              <View
                key={'no-group-' + index}
                style={styles.flexBox}
              >

                <Text style={styles.col1}>{t(key)}</Text>
                <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
              </View>
            )
          })
        }
      </View>

      {/* Tipo incarico */}
      {
        Object.values(tipoIncarico).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('jobType')}</Text>
          {
            Object.entries(tipoIncarico).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'tipo-incarico-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Part time */}
      {
        Object.values(partTime).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('partTimeTabLabel')}</Text>
          {
            Object.entries(partTime).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'part-time-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)} </Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Legge 104 */}
      {
        Object.values(legge104).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('law104TabLabel')}</Text>
          {
            Object.entries(legge104).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'legge104-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Legge 151 */}
      {
        Object.values(legge151).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={[styles.groupTitle, { width: 90 }]}>{t('law151TabLabel')}</Text>
          {
            Object.entries(legge151).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'legge151-' + index}
                  style={styles.flexBox}
                >
                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }
    </View>
  )
};

export default SchedaProfessionaleSection;