import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import App from './app/App';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';

function PreApp() {


  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <ThemeProvider theme={createTheme()}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </>
  );
}
export default PreApp;
