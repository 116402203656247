import { validateInputTextMinMaxLen, } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type TipiIncaricoKeys = keyof TipiIncarico;

export const elementIdProps: TipiIncaricoKeys[] = ["idTipoIncarico"];
export const elementRenderProps: TipiIncaricoKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "idTipoIncarico", required: false, show: false, titleKey: "jobType",

  },
  {
    field: "descrizioneBreve", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionShortParam"
  },
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
]

export interface TipiIncarico {
  idTipoIncarico: number,
  descrizioneBreve: string,
  descrizione: string,
  version: number,
}