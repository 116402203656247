import { View } from "react-pdf-renderer-v2";
import { AnagraficaSoggettiFormazione } from "../../../../../../models/AnagraficaSoggettiFormazione";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import styles from '../styles/shared';

interface TableFormazioneProps {
  formazione: AnagraficaSoggettiFormazione[]
}

const TableFormazione = ({ formazione }: TableFormazioneProps) => (


  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow formazione={formazione} />
  </View>
);

export default TableFormazione;