import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { UnitaOperativa, elementIdProps, LookupUnitaOperativaElem, UnitaOperativaKeys } from '../../models/UnitaOperative'
import { cancelById, create, definitiveDeleteById, restoreById, upd, getAllValidByIds, getAllDeletedByIds } from '../../services/services.service'
import { lookupServiceByIds } from '../../services/services.service';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "unitaoperative";
const entity = "unitaoperative";
interface UnitaOperativeState {
  statusValidUnitaOperativeFiltered: StatusEnum,
  validUnitaOperativeFiltered: UnitaOperativa[],
  statusDeletedUnitaOperativeFiltered: StatusEnum,
  deletedUnitaOperativeFiltered: UnitaOperativa[],
  unitaOperativa: UnitaOperativa | null,
  lookup: Lookup,
  error: string | null
}

const initialState: UnitaOperativeState = {
  statusValidUnitaOperativeFiltered: StatusEnum.Succeeded,
  validUnitaOperativeFiltered: [],
  statusDeletedUnitaOperativeFiltered: StatusEnum.Succeeded,
  deletedUnitaOperativeFiltered: [],
  unitaOperativa: null,
  lookup: {},
  error: null
}

export const fetchAllValidByIdStrutturaAndIdDipartimento = createAsyncThunk(microservice + 'filtered/fetchAllValidByIdStrutturaAndIdDipartimento', async (obj: { idStruttura: number, idDipartimento: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idStruttura, obj.idDipartimento], [microservice + 'filtered/fetchAllValidByIdStrutturaAndIdDipartimento']);
  return response.data as UnitaOperativa[];
});

export const fetchAllDeletedByIdStrutturaAndIdDipartimento = createAsyncThunk(microservice + 'filtered/fetchAllDeletedByIdStrutturaAndIdDipartimento', async (obj: { idStruttura: number, idDipartimento: number }) => {
  const response = await getAllDeletedByIds(microservice, entity, [obj.idStruttura, obj.idDipartimento], [microservice + 'filtered/fetchAllDeletedByIdStrutturaAndIdDipartimento']);
  return response.data as UnitaOperativa[];
});

export const lookupFiltered = createAsyncThunk(microservice + 'filtered/lookupFiltered', async (obj: { idStruttura: number, idDipartimento: number }) => {
  let idArr = [obj.idStruttura, obj.idDipartimento];
  const response = await lookupServiceByIds(microservice, entity, idArr);
  return response.data as LookupUnitaOperativaElem[];
});

export const insertFiltered = createAsyncThunk(microservice + 'filtered/insertFiltered', async (UnitaOperativa: UnitaOperativa) => {
  const response = await create(UnitaOperativa, microservice, entity);
  return response.data as UnitaOperativa;
});

export const updateFiltered = createAsyncThunk(microservice + 'filtered/updateFiltered', async (UnitaOperativa: UnitaOperativa) => {
  const response = await upd(UnitaOperativa, microservice, entity);
  return response.data as UnitaOperativa;
});

export const logicalDelFiltered = createAsyncThunk(microservice + 'filtered/logicalDelFiltered', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restoreFiltered = createAsyncThunk(microservice + 'filtered/restoreFiltered', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDelFiltered = createAsyncThunk(microservice + 'filtered/physicalDelFiltered', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const unitaOperativeFilteredSlice = createSlice({
  name: 'unitaOperativeFiltered',
  initialState,
  reducers: {
    cleanLookup: (state) => {
      state.lookup = {};
    },
    reset: (state: UnitaOperativeState) => {
      return initialState;
    },
    resetError: (state: UnitaOperativeState) => {
      state.error = initialState.error;
      state.statusValidUnitaOperativeFiltered = initialState.statusValidUnitaOperativeFiltered;
      state.statusDeletedUnitaOperativeFiltered = initialState.statusDeletedUnitaOperativeFiltered;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllValidByIdStrutturaAndIdDipartimento.pending, (state) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdStrutturaAndIdDipartimento.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativeFiltered = StatusEnum.Failed;
      state.validUnitaOperativeFiltered = [];
    })
    builder.addCase(fetchAllValidByIdStrutturaAndIdDipartimento.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa[]>) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.validUnitaOperativeFiltered = payload ?? [];
      state.lookup = createLookup(payload, "idUnitaOperativa", ["nome"]);
    })
    builder.addCase(fetchAllDeletedByIdStrutturaAndIdDipartimento.pending, (state) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByIdStrutturaAndIdDipartimento.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Failed;
      state.deletedUnitaOperativeFiltered = [];
    })
    builder.addCase(fetchAllDeletedByIdStrutturaAndIdDipartimento.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa[]>) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.deletedUnitaOperativeFiltered = payload ?? [];
    })
    builder.addCase(lookupFiltered.pending, (state) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(lookupFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativeFiltered = StatusEnum.Failed;
      state.lookup = {};
    })
    builder.addCase(lookupFiltered.fulfilled, (state, { payload }: PayloadAction<LookupUnitaOperativaElem[]>) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, "idUnitaOperativa", ["nome"]);
    })
    builder.addCase(insertFiltered.pending, (state) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(insertFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativeFiltered = StatusEnum.Failed;
      state.unitaOperativa = null;
    })
    builder.addCase(insertFiltered.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa>) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.unitaOperativa = payload;
      state.validUnitaOperativeFiltered = state.validUnitaOperativeFiltered.concat([payload]);
    })
    builder.addCase(updateFiltered.pending, (state) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(updateFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativeFiltered = StatusEnum.Failed;
      state.unitaOperativa = null;
    })
    builder.addCase(updateFiltered.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa>) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.validUnitaOperativeFiltered = state.validUnitaOperativeFiltered.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.unitaOperativa = payload;
    })
    builder.addCase(logicalDelFiltered.pending, (state) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(logicalDelFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativeFiltered = StatusEnum.Failed;
    })
    builder.addCase(logicalDelFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUnitaOperativeFiltered = StatusEnum.Succeeded;
      const deleted = state.validUnitaOperativeFiltered.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativaKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedUnitaOperativeFiltered = state.deletedUnitaOperativeFiltered.concat([deleted]);
      }
      state.validUnitaOperativeFiltered = state.validUnitaOperativeFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })
    builder.addCase(restoreFiltered.pending, (state) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(restoreFiltered.rejected, (state, action) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restoreFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Succeeded;
      const valid = state.deletedUnitaOperativeFiltered.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativaKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validUnitaOperativeFiltered = state.validUnitaOperativeFiltered.concat([valid]);
      }
      state.deletedUnitaOperativeFiltered = state.deletedUnitaOperativeFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })
    builder.addCase(physicalDelFiltered.pending, (state) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Loading;
    })
    builder.addCase(physicalDelFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Failed;
    })
    builder.addCase(physicalDelFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativeFiltered = StatusEnum.Succeeded;
      state.deletedUnitaOperativeFiltered = state.deletedUnitaOperativeFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError, cleanLookup } = unitaOperativeFilteredSlice.actions;
export default unitaOperativeFilteredSlice.reducer;