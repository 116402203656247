import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { Formazione, elementIdProps, FormazioneKeys } from '../../models/Formazione';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "formazione";
const entity = "formazione";

interface FormazioneState {
  statusValidFormazione: StatusEnum,
  validFormazione: Formazione[],
  statusDeletedFormazione: StatusEnum,
  deletedFormazione: Formazione[],
  statusAllFormazione: StatusEnum,
  allFormazione: Formazione[],
  formazione: Formazione | null,
  lookup: Lookup,
  error: string | null
}

const initialState: FormazioneState = {
  statusValidFormazione: StatusEnum.Succeeded,
  validFormazione: [],
  statusDeletedFormazione: StatusEnum.Succeeded,
  deletedFormazione: [],
  statusAllFormazione: StatusEnum.Succeeded,
  allFormazione: [],
  formazione: null,
  lookup: {},
  error: null
}

// get cerca/formazione/id/{idFormazione}
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Formazione;
});

// get cerca/formazione/all/completa
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as Formazione[];
});

// get cerca/formazione/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Formazione[];
});

// get cerca/formazione/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Formazione[];
});

// post inserisci/formazione
export const insert = createAsyncThunk(microservice + '/insert', async (formazione: Formazione) => {
  const _formazione = {
    ...formazione,
    controlloScadenza: formazione.controlloScadenze ?? false,
  }
  const response = await create(_formazione, microservice, entity);

  return response.data as Formazione;
});

// put modifica/formazione
export const update = createAsyncThunk(microservice + '/update', async (formazione: Formazione) => {
  const response = await upd(formazione, microservice, entity);
  return response.data as Formazione;
});

// put elimina/formazione/id/{idFormazione}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/formazione/id/{idFormazione}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/formazione/id/{idFormazione}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const formazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetError: (state: FormazioneState) => {
      state.error = initialState.error;
      state.statusValidFormazione = initialState.statusValidFormazione;
      state.statusDeletedFormazione = initialState.statusDeletedFormazione;
      state.statusAllFormazione = initialState.statusAllFormazione;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidFormazione = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFormazione = StatusEnum.Failed;
      }
      state.formazione = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Formazione>) => {
      state.statusValidFormazione = StatusEnum.Succeeded;
      state.formazione = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllFormazione = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllFormazione = StatusEnum.Failed;
      }
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Formazione[]>) => {
      state.statusAllFormazione = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idFormazione', ['descrizione']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidFormazione = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFormazione = StatusEnum.Failed;
      }
      state.validFormazione = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Formazione[]>) => {
      state.statusValidFormazione = StatusEnum.Succeeded;
      state.validFormazione = payload ?? [];
      state.lookup = createLookup(payload, 'idFormazione', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedFormazione = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFormazione = StatusEnum.Failed;
      }
      state.deletedFormazione = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Formazione[]>) => {
      state.statusDeletedFormazione = StatusEnum.Succeeded;
      state.deletedFormazione = payload ?? [];
      state.lookup = createLookup(payload, 'idFormazione', ['descrizione']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidFormazione = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFormazione = StatusEnum.Failed;
      }
      state.formazione = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Formazione>) => {
      state.statusValidFormazione = StatusEnum.Succeeded;
      state.formazione = payload;
      state.validFormazione = state.validFormazione.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidFormazione = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFormazione = StatusEnum.Failed;
      }
      state.formazione = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Formazione>) => {
      state.statusValidFormazione = StatusEnum.Succeeded;
      state.validFormazione = state.validFormazione.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.formazione = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidFormazione = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFormazione = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidFormazione = StatusEnum.Succeeded;
      const deleted = state.validFormazione.find(el => payload.ids.every(idObj => el[idObj.name as FormazioneKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedFormazione = state.deletedFormazione.concat([deleted]);
      }
      state.validFormazione = state.validFormazione.filter(el => payload.ids.some(idObj => el[idObj.name as FormazioneKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedFormazione = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFormazione = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedFormazione = StatusEnum.Succeeded;
      const valid = state.deletedFormazione.find(el => payload.ids.every(idObj => el[idObj.name as FormazioneKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validFormazione = state.validFormazione.concat([valid]);
      }
      state.deletedFormazione = state.deletedFormazione.filter(el => payload.ids.some(idObj => el[idObj.name as FormazioneKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedFormazione = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFormazione = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedFormazione = StatusEnum.Succeeded;
      state.deletedFormazione = state.deletedFormazione.filter(el => payload.ids.some(idObj => el[idObj.name as FormazioneKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = formazioneSlice.actions;
export default formazioneSlice.reducer;