import { Column } from "@material-table/core";
import { TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, CentroDiCosto, allFields, CentroDiCostoKeys } from "../../../models/CentriDiCosto";
import { Fields } from "../../../models/Fields";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  fetchAllDeleted,
  fetchAllValid,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetCentriDiCosto,
  resetError as resetErrorCentriDiCosto
} from "../../../store/slices/centriDiCostoSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { centriDiCostoPath } from "../../../utils/utilconst";
import { sortingStringCI } from "../../../utils/utilfunctions";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";

const CentriDiCostoW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, centriDiCostoPath));
  const errorBE = useAppSelector(state => state.centriDiCosto.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorCentriDiCosto())
  }, [dispatch])

  const title = t('costCentersTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<CentroDiCosto>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<CentroDiCosto> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as CentroDiCostoKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            }

            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);
        /*+++*/
        if (f.field === "livello") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }
                }
                inputProps={{ "min": 1 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        }
        /*+++*/
        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, t]);


  const validCentriDiCosto = useAppSelector(state => state.centriDiCosto.validCentriDiCosto);
  const deletedCentriDiCosto = useAppSelector(state => state.centriDiCosto.deletedCentriDiCosto);

  const statusValidCentriDiCosto = useAppSelector(state => state.centriDiCosto.statusValidCentriDiCosto);
  const statusDeletedCentriDiCosto = useAppSelector(state => state.centriDiCosto.statusDeletedCentriDiCosto);

  const [data, setData] = useState<Array<CentroDiCosto>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validCentriDiCosto) : setData(deletedCentriDiCosto);
  }, [validCentriDiCosto, deletedCentriDiCosto, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetCentriDiCosto());
    };
  }, [dispatch]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      columnsButton={true}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValid}
      fetchAllDeleted={fetchAllDeleted}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidCentriDiCosto}
      statusDeleted={statusDeletedCentriDiCosto}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default CentriDiCostoW;