import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';

import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { defaultValue, filterGroup, Group, renderData } from './utils/utils';

interface TurnoAbilitatoSectionProps {
  turnoAbilitato: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    dataTurniAbilitati: ['validitystartDateParam', 'date'],
    combinazioneTurni: ['shiftCombinationParam', 'string'],
    motivoVariazioneTurniAbilitati: ['variationReasonParam', 'string'],
    idAnagraficaSoggettiSchedaSanitaria: ['personalDataOfHealthCardSubjectsParam', 'string'],
    riposoSabato: ['offSaturdayParam', 'boolean'],
    riposoDomenicaFestivi: ['offHolidayParam', 'boolean'],
    dataInizioTurnoFisso: ['fixedTurnStartParam', 'date'],
    turnoFisso: ['fixedTurnParam', 'string'],
  }
}

const TurnoAbilitatoSection = (props: TurnoAbilitatoSectionProps) => {
  const { turnoAbilitato } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});
  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, turnoAbilitato))
  }, [turnoAbilitato]);

  return (
    <View wrap={false}>

      {/* No group */}
      {
        Object.values(noGroup).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitlemax}>{t('enabledShiftParam')}</Text>
          {
            Object.entries(noGroup).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'no-group-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }
    </View>
  )
};

export default TurnoAbilitatoSection;