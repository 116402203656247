import { Box, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchById, setLingua } from '../../../store/slices/guidaSlice';
import { sessionProfil } from '../../../utils/utilconst';
import { Lookup } from '../../../models/Utils';
import i18n from '../../../i18n';

interface InfoModel {
    title: string,
    info: string,
}

interface HelpButtonProps {
    topics: Lookup;
    size?: 'small' | 'medium' | 'large';
    importance?: 'primary' | 'secondary';
    className?: string;
}

const HelpButton = ({ topics, size = 'medium', importance, className, }: HelpButtonProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState<InfoModel | null>(null);
    const guidaAppSoftware = useAppSelector(state => state.guida.guidaAppSoftware);
    const guidaLang = useAppSelector(state => state.guida.lingua);

    const [functionAppSoftwareHelp, setFunctionAppSoftwareHelp] = useState<InfoModel | null>(null);
    const idAppSoftware = useMemo(() => {
        const sessionProfile = sessionStorage.getItem(sessionProfil)
        return sessionProfile ? JSON.parse(sessionProfile)[0] : null
    }, []);

    const idAppSoftwareFunzione = Number(Object.keys(topics)[0]);

    useEffect(() => {
        const newfuncAppSoftwareHelp: InfoModel = {
            title: topics[idAppSoftwareFunzione],
            info: '',
        };

        if (guidaAppSoftware?.idApplicazioneSoftwareFunzione === idAppSoftwareFunzione) {
            newfuncAppSoftwareHelp.title = topics[idAppSoftwareFunzione];
            newfuncAppSoftwareHelp.info = guidaAppSoftware['testo'];
        }

        setFunctionAppSoftwareHelp(newfuncAppSoftwareHelp);
    }, [guidaAppSoftware, idAppSoftwareFunzione, topics]);

    useEffect(() => {
        setSelectedValue(functionAppSoftwareHelp);
    }, [functionAppSoftwareHelp, topics]);

    const handleClickOpen = () => {
        setOpen(true);
        const currentLang = i18n.language;

        if (idAppSoftwareFunzione !== guidaAppSoftware?.idApplicazioneSoftwareFunzione || currentLang !== guidaLang) {
            dispatch(setLingua(currentLang));
            dispatch(fetchById({
                idApplicazioneSoftware: guidaAppSoftware?.idApplicazioneSoftware ?? idAppSoftware,
                idApplicazioneSoftwareFunzione: idAppSoftwareFunzione,
            }));
        }
    }

    const handleClose = (value: InfoModel) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div className={className} onClick={(e) => e.stopPropagation()}>  {/* prevents parent to call its events */}
            <IconButton color={importance ?? 'inherit'}
                onClick={handleClickOpen}>
                <HelpOutlineRoundedIcon
                    fontSize={size}
                    titleAccess={t('helpTitle')}
                    htmlColor={!importance ? undefined : '#999999'}
                />
            </IconButton>
            <InfoDialog data={selectedValue} open={open} onClose={handleClose} />
        </div>
    )
}

interface InfoDialogBoxProps {
    open: boolean;
    data: InfoModel | null;
    onClose: (value: InfoModel) => void;
}

const InfoDialog = ({ open, data, onClose }: InfoDialogBoxProps) => {
    const handleClose = () => data && onClose(data);
    const { t } = useTranslation();

    return <>
        <Dialog key={data?.title} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth='lg'>
            <DialogTitle id="simple-dialog-title">{data?.title ?? t('noInfo')}</DialogTitle>
            <DialogContent>
                <Box px={2} pb={2}>
                    <Typography>
                        {htmlParser(data && data.info ? data.info : t('helpUnavailable'))}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    </>;
}

export default HelpButton;