export const componentInsertPath = '/inserisci';
export const componentTabsPath = '/dettaglio';

export const turniTabPath = "/turno";
export const strutturaTabPath = "/struttura";
export const oreTurniTabPath = "/oreturni";
export const dipartimentiTabPath = "/dipartimenti";
export const festivitaTabPath = "/festivita";
export const struttureSediTabPath = "/strutturesedi";

export const dipartimentiSediTabPath = "/departimentisedi";

export const unitaOperativeTabPath = "/unitaoperative";
export const contestazioniTabPath = "/contestazione";
export const formazioneTabPath = "/formazione";
export const qualificaTabPath = "/qualifica";
export const schedaSanitariaTabPath = "/schedaSanitaria";
export const schedaProfessionaleTabPath = "/schedaProfessionale";
export const turnoAbilitatoTabPath = "/turnoAbilitato";
export const anagraficaSoggettoTabPath = "/anagraficaSoggetto";
export const documentoTabPath = "/documenti";
export const dittaTabPath = "/ditte";

export const unitaOperativaTabPath = "/unitaoperativa";
export const shiftEmployeesNumTabPath = "/turninumeroaddetti";
export const unitaoperativaCodiceVisitePath = "/unitaoperativaCodiceVisite";
export const unitaOpertativaFormazionePath = "/unitaOperativaFormazione";
export const unitaOperativeSediPath = "/unitaOperativeSedi";

export const anagraficaTabPath = "/anagrafica";