import { validateCAP, validateDate, validateEmail, validateInputTextMinMaxLen, validateCellularNumber, validateTelephoneNumber, validateInputNumberMin } from "../utils/utilfunctions";
import { AnagraficaSoggettiContestazione } from "./AnagraficaSoggettiContestazioni";
import { AnagraficaSoggettiFormazione } from "./AnagraficaSoggettiFormazione";
import { AnagraficaSoggettiQualifica } from "./AnagraficaSoggettiQualifica";
import { AnagraficaSoggettiSchedaSanitaria } from "./AnagraficaSoggettiSchedaSanitaria";
import { AnagraficaSoggettiSchedaProfessionale } from "./AnagraficaSoggettiSchedeProfessionali";
import { AnagraficaSoggettiTurnoAbilitato } from "./AnagraficaSoggettiTurniAbilitati";
import { AnagraficaSoggettiUnitaOperativa } from "./AnagraficaSoggettiUnitaOperativa";
import { AnagraficaSoggettiDitta } from "./AnagraficaSoggettiDitte";
import { Fields } from "./Fields";
import { AnagraficaSoggettiDocumenti } from "./AnagraficaSoggettiDocumenti";

export type AnagraficaSoggettoCurrentDataKeys = keyof AnagraficaSoggettoCurrentData;

export const elementIdProps: AnagraficaSoggettoCurrentDataKeys[] = ["idAnagraficaSoggetto"];
export const elementRenderProps: AnagraficaSoggettoCurrentDataKeys[] = ["nome", "cognome"];

export const allFieldsAnagraficaSoggetti: Fields[] = [
  /******************************************** ANAGRAFICA SOGGETTO ********************************************/
  {
    field: "fotoSegnaletica", type: 'image', required: false, show: true, titleKey: "profilePictureParam", group: "", colsNum: 2,
  },

  {
    field: "matricola", type: "string", required: false, show: true, titleKey: "numberParam", showOn: "table",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "never"
  },
  {
    field: "qualificaDescrizioneBreve", type: "string", required: false, show: true, titleKey: "qualificationParam", showOn: "table",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "never"
  },
  {
    field: "cognome", type: "string", required: true, show: true, titleKey: "lastNameParam", group: "",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 50, false, helperText, true), keyTradValidation: "size1to50ParamValidation"
  },

  {
    field: "nome", type: "string", required: true, show: true, titleKey: "nameParam", group: "",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 50, false, helperText, true), keyTradValidation: "size1to50ParamValidation"
  },
  {
    field: "codiceFiscale", type: "string", required: true, show: true, titleKey: "fiscalCodeParam", group: "",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 16, 16, false, helperText, true), keyTradValidation: "size16to16ParamValidation"
  },
  /** */
  // {
  //     field: "unitaOperativaNome", required:false, show:true, titleKey: "operationalUnitParam",
  //     validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation"
  // },

  // {
  //     field: "qualificaDescrizione", required:false, show:true, titleKey: "qualificationParam",
  //     validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation"
  // },
  /** */
  {
    field: "nascitaData", type: "date", required: false, show: false, titleKey: "dateOfBirthParam", group: "",
    validate: (x: string, helperText: string) => validateDate(x, 1940, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "nascitaCitta", type: "string", required: false, show: false, titleKey: "cityOfBirthParam", group: "",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
  },

  {
    field: "nascitaProvincia", type: "string", required: false, show: false, titleKey: "districtOfBirthParam", group: "",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 2, 2, false, helperText, false), keyTradValidation: "size2to2ParamValidation"
  },

  {
    field: "sesso", required: false, show: false, titleKey: "genderParam", group: "", lookupField: true,
  },

  /********************************** RESIDENZA **********************************/
  {
    field: "residenzaIndirizzo", type: "string", required: false, show: false, titleKey: "residenceAddressParam", group: "residence",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation"
  },

  {
    field: "residenzaCitta", type: "string", required: false, show: false, titleKey: "residenceCityParam", group: "residence",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
  },

  {
    field: "residenzaProvincia", type: "string", required: false, show: false, titleKey: "residenceDistrictParam", group: "residence",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 2, 2, false, helperText, false), keyTradValidation: "size2to2ParamValidation"
  },

  {
    field: "residenzaCap", type: "string", required: false, show: false, titleKey: "capParam", group: "residence",
    validate: (x: string, helperText: string) => validateCAP(x, helperText, false), keyTradValidation: "CapValidation"
  },
  /********************************** DOMICILIO **********************************/
  {
    field: "domicilioIndirizzo", type: "string", required: false, show: false, titleKey: "domicileAddressParam", group: "domicile",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation"
  },

  {
    field: "domicilioCitta", type: "string", required: false, show: false, titleKey: "domicileCityParam", group: "domicile",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
  },

  {
    field: "domicilioProvincia", type: "string", required: false, show: false, titleKey: "domicileDistrictParam", group: "domicile",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 2, 2, false, helperText, false), keyTradValidation: "size2to2ParamValidation"
  },

  {
    field: "domicilioCap", type: "string", required: false, show: false, titleKey: "domicileCapParam", group: "domicile",
    validate: (x: string, helperText: string) => validateCAP(x, helperText, false), keyTradValidation: "CapValidation"
  },
  /********************************** RECAPITI **********************************/
  {
    field: "telefonoAbitazione", type: "string", required: false, show: false, titleKey: "telephoneParam", group: "addresses",
    validate: (x: string, helperText: string) => validateTelephoneNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation"
  },

  {
    field: "telefonoCellulare", type: "string", required: false, show: false, titleKey: "mobilePhoneParam", group: "addresses",
    validate: (x: string, helperText: string) => validateCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation"
  },

  {
    field: "email", type: "string", required: true, show: true, titleKey: "emailParam", group: "addresses",
    validate: (x: string, helperText: string) => validateEmail(x, helperText, true), keyTradValidation: "emailParamValidation"
  },

  {
    field: "emailPersonale", type: "string", required: false, show: false, titleKey: "personalEmailParam", group: "addresses",
    validate: (x: string, helperText: string) => validateEmail(x, helperText, false), keyTradValidation: "emailParamValidation"
  },
  /********************************** ALBO **********************************/
  {
    field: "alboIscrizione", type: "string", required: false, show: false, titleKey: "alboParam", hasHelp: true, group: "albo",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
  },

  {
    field: "alboIscrizioneNumero", type: "string", required: false, show: false, titleKey: "alboNumberParam", hasHelp: true, group: "albo",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 10, false, helperText, false), keyTradValidation: "size3to10ParamValidation"
  },

  {
    field: "alboIscrizioneData", type: "date", required: false, show: false, titleKey: "alboDateParam", hasHelp: true, group: "albo",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "idTurnoIniziale", required: false, show: false, titleKey: "firstShiftParam", group: "albo",
  },
]

// /******************************************** SCHEDA PROFESSIONALE ********************************************/
export const allFieldsDatiProfessionali: Fields[] = [
  {
    field: "tipoContratto", type: "string", required: false, show: false, titleKey: "typeOfContractParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "matricola", type: "string", required: false, show: false, titleKey: "numberParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "dataAssunzione", type: "date", required: false, show: false, titleKey: "assumptionDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "motivoDescrizione", type: "string", required: false, show: false, titleKey: "reasonParamExit", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },
  {
    field: "dataUscita", type: "date", required: false, removable: false, show: true, titleKey: "dateLabelexit", sort: 'desc',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioValidita",
    // validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, false), keyTradValidation2: "smallerEqualThanJobStartDateValidation"
  },
  {
    field: "legge15101DataInizio", type: "date", required: false, show: false, titleKey: "law15101StartDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "articoloLeggeLegge15101Descrizione", type: "string", required: false, show: false, titleKey: "lawArticle15101DescriptionParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation", editable: "onUpdate"
  },

  {
    field: "partTime", type: "string", required: false, show: false, titleKey: "partTimeParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "partTimeDataInizio", type: "date", required: false, show: false, titleKey: "partTimeStartDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "partTimeTipo", required: false, show: false, titleKey: "partTimeTypeParam", readonly: true,
    editable: "onUpdate", lookupField: true,
  },

  {
    field: "partTimeOrizzontale", type: "string", required: false, show: false, titleKey: "partTimeHParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation", editable: "onUpdate"
  },

  {
    field: "partTimeVerticale", type: "string", required: false, show: false, titleKey: "partTimeVParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation", editable: "onUpdate"
  },

  {
    field: "partTimeMotivazione", type: "string", required: false, show: false, titleKey: "partTimeMotiveParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation", editable: "onUpdate"
  },

  {
    field: "legge104DataInizioVariazione", type: "date", required: false, show: false, titleKey: "law104StartDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "legge104Numero", type: "numeric", required: false, show: false, titleKey: "law104NumberParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "specializzazione", type: "string", required: false, show: false, titleKey: "specializationParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "dataInizioIncarico", type: "date", required: false, show: false, titleKey: "jobStartDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "dataFineIncarico", type: "date", required: false, show: false, titleKey: "jobEndDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "orarioSettimanale", type: "numeric", required: false, show: false, titleKey: "weeklyHoursParam", readonly: true,
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterOrEqualThanZeroValidation", editable: "onUpdate"
  },

  {
    field: "riposoSabato", type: "boolean", required: false, show: false, titleKey: "offSaturdayParam", readonly: true,
    editable: "onUpdate"
  },

  {
    field: "riposoDomenicaFestivi", type: "boolean", required: false, show: false, titleKey: "offHolidayParam", readonly: true,
    editable: "onUpdate"
  },
]

// /******************************************** SCHEDA SANITARIA ********************************************/
export const allFieldsSchedaSanitaria: Fields[] = [
  {
    field: "dataVisita", type: "date", required: false, show: false, titleKey: "visitDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },

  {
    field: "codiceVisitaDescrizione", type: "string", required: false, show: false, titleKey: "visitCodeParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "idoneitaDescrizione", type: "string", required: false, show: false, titleKey: "eligibilityParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "prescrizione", type: "string", required: false, show: false, titleKey: "prescriptionParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "dataControlloSuccessivo", type: "date", required: false, show: false, titleKey: "nextcheckDateParam", readonly: true,
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation", editable: "onUpdate"
  },
]

// /******************************************** QUALIFICA ********************************************/
export const allFieldsQualifica: Fields[] = [
  {
    field: "qualificaDescrizioneBreve", type: "string", required: false, show: true, titleKey: "qualificationParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },
]

// /******************************************** UNITA OPERATIVA ********************************************/
export const allFieldsUnitaOperativa: Fields[] = [
  {
    field: "unitaOperativaNome", type: "string", required: false, show: true, titleKey: "operationalUnitParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },
]

// /******************************************** TURNI ABILITATI ********************************************/
export const allFieldsTurniAbilitati: Fields[] = [
  {
    field: "combinazioneTurniDescrizioneBreve", type: 'string', required: true, show: true, titleKey: "shiftCombinationParam", showOn: 'table'
  },

  {
    field: "idCombinazioneTurni", required: true, show: true, titleKey: "shiftCombinationParam", autocomplete: true, showOn: 'form'
  },

  {
    field: "motivoVariazione", type: "string", required: false, show: false, titleKey: "variationReasonParam", readonly: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation", editable: "onUpdate"
  },

  {
    field: "dataInizioTurnoFisso", type: 'date', required: false, show: true, titleKey: "fixedTurnStartParam",
  },

  {
    field: "turnoFisso", type: 'string', required: false, show: true, titleKey: "fixedTurnParam",
  },

  {
    field: "minutiElasticita", type: "numeric", required: false, show: false, titleKey: "minuteBoundParam", defaultValue: '0'
  },

  {
    field: "recuperoRichiesto", type: "boolean", required: false, show: false, titleKey: "recoveryRequiredParam",
  },

  {
    field: "timbraturaSoloPresenza", type: "boolean", required: false, show: false, titleKey: "stampForPresenceParam",
  },
]

export interface AnagraficaSoggetto {
  /**
   * Anagrafica
   */
  idAnagraficaSoggetto: number,
  idStruttura: number,
  codiceFiscale: string,
  codiceFiscaleValido: boolean,
  cognome: string,
  nome: string,
  nascitaData?: string,
  nascitaCitta?: string,
  nascitaProvincia?: string,
  sesso?: string,
  fotoSegnaletica?: string,

  // residenza
  residenzaIndirizzo?: string,
  residenzaCitta?: string,
  residenzaProvincia?: string,
  residenzaCap?: string,

  // domicilio
  domicilioIndirizzo?: string,
  domicilioCitta?: string,
  domicilioProvincia?: string,
  domicilioCap?: string,

  // recapiti
  telefonoAbitazione?: string,
  telefonoCellulare?: string,
  email: string,
  emailPersonale: string,

  // albo
  alboIscrizione?: string,
  alboIscrizioneNumero?: string,
  alboIscrizioneData?: string,
  idTurnoIniziale?: number,
}
export interface AnagraficaSoggettoCurrentData extends AnagraficaSoggetto {
  /**
   * AnagraficaSoggettiSchedeProfessionali
   */
  dataAnzianitaServizio: string,
  dataFinePeriodoProva: string,
  dataInizioValidita: string,
  tipoContratto?: string,
  matricola?: string,
  dataAssunzione?: string,
  dataPresaServizio?: string,
  idMotivo?: number,
  motivoDescrizione?: string,
  dataUscita: string,
  specializzazione?: string,
  orarioSettimanale: number,

  // tipoIncarico
  idTipoIncarico: number,
  tipoIncaricoDescrizione: string,
  dataInizioIncarico?: string,
  dataFineIncarico?: string,

  // partTime
  partTime?: string,
  partTimeDataInizio?: string,
  partTimeTipo: string,
  partTimeOrizzontale: string,
  partTimeVerticale: string,
  partTimeMotivazione: string,

  // legge104
  legge104Numero: number,
  legge104DataInizioVariazione?: string,

  // legge151
  idArticoloLeggeLegge15101?: number,
  articoloLeggeLegge15101Descrizione?: string,
  legge15101DataInizio?: string,

  /**
   * AnagraficaSoggettiSchedaSanitaria
   */
  idAnagraficaSoggettiSchedaSanitaria: number,
  dataVisita?: string,
  idCodiceVisita?: number,
  codiceVisitaDescrizione?: string,
  idIdoneita?: number,
  idoneitaDescrizione?: string,
  prescrizione?: string,
  dataControlloSuccessivo?: string,

  /**
   * AnagraficaSoggettiQualifica
   */
  dataNuovaQualifica: string,
  idQualifica: number,
  qualificaDescrizione?: string,
  qualificaDescrizioneBreve?: string,

  /**
   * AnagraficaSoggettiUnitaOperativa
   */
  idStrutturaUnitaOperativa: number,
  strutturaDescrizione: string,
  idDipartimentoUnitaOperativa: number,
  dipartimentoDescrizione: string,
  idUnitaOperativa: number,
  unitaOperativaNome: string,
  idQualificaIncarico: number,
  qualificaIncaricoDescrizione: string;
  qualificaIncaricoDescrizioneBreve: string;
  dataAssegnazione: string,
  motivoVariazioneUnitaOperativa: string,

  /**
   * AnagraficaSoggettiTurniAbilitati
   */
  idAnagraficaSoggettiSchedaSanitariaTurniAbilitati: number,
  idCombinazioneTurni?: number;
  combinazioneTurniDescrizione?: string;
  combinazioneTurniDescrizioneBreve?: string;
  combinazioneTurni?: string,
  dataTurniAbilitati: string,
  numeroOrdine: number;
  dataInizioTurnoFisso: string;
  turnoFisso: string;
  riposoSabato: boolean;
  riposoDomenicaFestivi: boolean;
  richiestaPianificazione: boolean;
  motivoVariazioneTurniAbilitati: string,
  minutiElasticita?: number;
  recuperoRichiesto?: boolean;
  timbraturaSoloPresenza?: boolean;

  version: number
}

export interface AnagraficaSoggettoExtended extends AnagraficaSoggettoCurrentData {
  anagraficaSoggettiContestazioni: AnagraficaSoggettiContestazione[],
  anagraficaSoggettiContestazioniEliminati: AnagraficaSoggettiContestazione[],
  anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione[],
  anagraficaSoggettiFormazioneEliminati: AnagraficaSoggettiFormazione[],
  anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica[],
  anagraficaSoggettiQualificaEliminati: AnagraficaSoggettiQualifica[],
  anagraficaSoggettiSchedaSanitaria: AnagraficaSoggettiSchedaSanitaria[],
  anagraficaSoggettiSchedaSanitariaEliminati: AnagraficaSoggettiSchedaSanitaria[],
  anagraficaSoggettiSchedeProfessionali: AnagraficaSoggettiSchedaProfessionale[],
  anagraficaSoggettiTurniAbilitati: AnagraficaSoggettiTurnoAbilitato[],
  anagraficaSoggettiUnitaOperative: AnagraficaSoggettiUnitaOperativa[],
  anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti[],
  anagraficaSoggettiDocumentiEliminati: AnagraficaSoggettiDocumenti[],
  ditteAnagraficaSoggetti: AnagraficaSoggettiDitta[],
  version: number,

}

export interface LookupAnagraficaSoggettoElem {
  idAnagraficaSoggetto: number;
  nome: string;
  cognome: string;
  idStruttura?: number;
  idDipartimento?: number;
  idUnitaOperativa?: number;
  unitaOperativaNome?: string; // di appartenenza
}

export interface FiltroAvanzatoAnagraficaSoggetto {
  idQualifica: number[];
  idFormazione: number[];
}