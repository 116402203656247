import { Box } from "@material-ui/core";
import { RiepilogoPianificazioneDTO } from "../core/components/crudWrappers/PianificazioniW";
import { Colors } from "../utils/utildata";
import { Fields } from "./Fields";

export type PianificazioneKeys = keyof Pianificazione;
export type PianificazioneQualificaKeys = keyof PianificazioneQualifica;

export const elementIdProps: PianificazioneKeys[] = ['idStruttura', 'idDipartimento', 'idUnitaOperativa', 'idAnagraficaSoggetto', 'pianificazioneData', 'anno', 'mese', 'reperibilita'];
export const elementRenderProps: PianificazioneKeys[] = ['idAnagraficaSoggetto', 'pianificazioneData'];

export const allFieldsAnagraficaSoggetto: Fields[] = [
    {
        field: "nominativo", type: "string", titleKey: "registryTitle", required: true, show: true,
        render: (rowData: RiepilogoPianificazioneDTO) => {
            const color = rowData['colore'];
            return <Box
                bgcolor={Colors[color] /* + alpha */}
                minHeight={'32px'}
                pt={'6px'}
                fontWeight={'bold'}
                color={color === 'Rosso' ? '#fff' : '#000'}
            >
                {rowData.nominativo}
            </Box>
        },
    },
    {
        field: "oreTotale", type: "numeric", titleKey: "hoursParam", required: true, show: true,
        render: (rowData: RiepilogoPianificazioneDTO) => {
            return rowData.oreTotale + ':' + (rowData.minutiTotale && rowData.minutiTotale < 10 ? '0' + rowData.minutiTotale : (rowData.minutiTotale ?? '00'))
        }
    },
    {
        field: "numeroRiposi", type: "numeric", titleKey: "RestParam", required: true, show: true,
    },
];

export const allFieldsQualifica: Fields[] = [
    {
        field: "qualificaDescrizioneBreve", type: "string", titleKey: "qualificationParam", required: true, show: true, defaultGroupOrder: 0,
    },
    {
        field: "turnoDescrizione", type: "string", titleKey: "shiftParam", required: true, show: true, sort: 'asc',
    },

];

export const allFieldsStatoPianificazione: Fields[] = [
    {
        field: 'oreLavorativeMese', required: false, show: true, type: 'numeric', titleKey: 'workingHoursPerMonthParam', colsNum: 4, readonly: true,
    },
    {
        field: 'numeroRiposiMese', required: false, show: true, type: 'numeric', titleKey: 'restingHoursPerMonthParam', colsNum: 4, readonly: true,
    },
    {
        field: 'stato', required: false, show: true, type: 'string', titleKey: 'statusParam', colsNum: 4, readonly: true,
    },
];

export const allFieldsNuovoSoggetto: Fields[] = [
    {
        field: 'idAnagraficaSoggetto', required: true, show: true, autocomplete: true, titleKey: 'subjectRegistryParam', colsNum: 12, minLen: 3
    },
    {
        field: 'idQualifica', required: true, show: true, type: 'numeric', lookupField: true, titleKey: 'qualificationParam', colsNum: 12,
    },
];

export const allFieldsNuovoTurno: Fields[] = [
    {
        field: 'idTurno', required: true, show: true, type: 'numeric', lookupField: true, titleKey: 'shiftParam', colsNum: 5,
    },
    {
        field: 'idTurnoAssenza', required: false, show: true, type: 'numeric', lookupField: true, titleKey: 'absenceShiftParam', colsNum: 5,
    },
    {
        field: 'ore', required: false, show: true, type: 'numeric', readonly: true, titleKey: 'hoursParam', colsNum: 2,
    },
];

export interface NuovoTurno {
    idTurno?: number,
    idTurnoAssenza?: number,
    ore?: number
}

export interface NuovoSoggetto {
    idStruttura: number;
    idAnagraficaSoggetto: number;
    idQualifica: number;
}

export interface UnitaOperativePianificazione {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    anno: number;
    mese: number;
    oreLavorativeMese: number;
    numeroRiposiMese: number;
    stato: string;
    version: number;
    pianificazioni: Pianificazione[];
    pianificazioniQualifiche: PianificazioneQualifica[];
    pianificazioniAnagraficaSoggetti: PianificazioniAnagraficaSoggetti[];
}

export interface Pianificazione {
    idStruttura: number;
    strutturaNome: string;
    idDipartimento: number;
    dipartimentoNome: string;
    idUnitaOperativa: number;
    unitaOperativaNome: string;
    anno: number;
    mese: number;
    idAnagraficaSoggetto: number;
    cognome: string;
    nome: string;
    idQualifica: number;
    qualificaDescrizione: string;
    qualificaDescrizioneBreve: string;
    pianificazioneData: string;
    reperibilita: boolean;
    tipoInserimento: string;
    idTurno: number;
    turnoDescrizione: string;
    turnoDescrizioneBreve: string;
    idTurnoAssenza: number;
    turnoAssenzaDescrizione: string;
    turnoAssenzaDescrizioneBreve: string;
    turnoFisso: boolean;
    modificaTurnoFisso: boolean;
    oreTurno: number;
    minutiTurno: number,
    nota: string;
    progressivo: number;
    giornoFestivo: boolean;
    version: number;
}

export interface PianificazioneQualifica {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    anno: number;
    mese: number;
    pianificazioneData: string;
    idQualifica: number;
    qualificaDescrizione: string;
    qualificaDescrizioneBreve: string;
    idTurno: number;
    turnoDescrizioneBreve: string;
    turnoDescrizione: string;
    numeroAddettiUnitaOperativa: number;
    numeroLegaleUnitaOperativa: number;
    numeroReperibilitaRichieste: number;
    totaleNumeroAddetti: number;
    totaleNumeroReperibilita: number;
    totaleOreTurno: number;
    totaleMinutiTurno: number,
    coloreValidazione: 'Verde' | 'Giallo' | 'Rosso' | 'Arancione';
}

export interface PianificazioniAnagraficaSoggetti {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    anno: number;
    mese: number;
    idAnagraficaSoggetto: number;
    cognome: string;
    nome: string;
    idQualifica: number;
    qualificaDescrizione: string;
    qualificaDescrizioneBreve: string;
    totaleRiposi: number;
    totaleOre: number;
    totaleMinuti: number,
    totaleOreAssenza: number;
    totaleMinutiAssenza: number,
    coloreValidazioneRiposi: string;
    coloreValidazioneOre: string;
}
