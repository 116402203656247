import React, { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { Fields } from "../../../../../models/Fields";
import { AnagraficaSoggettoCurrentData, AnagraficaSoggettoCurrentDataKeys } from "../../../../../models/AnagraficaSoggetti";
import { ScadenzaContratti, allFields } from "../../../../../models/ScadenzaContratti";


import ReadOnlyMaterialTable from "../../../tables/ReadOnly/ReadOnlyMaterialTable";

import { lookupNoAbilitazione as fetchStrutturaLookup } from '../../../../../store/slices/struttureSlice';
import { lookupNoAbilitazione as fetchDipartimentoLookup } from '../../../../../store/slices/dipartimentiSlice';
import { lookupNoAbilitazione as fetchUnitaOperativaLookup } from '../../../../../store/slices/unitaOperativeSlice';

import { fetchAll as fetchTurniLookup } from '../../../../../store/slices/turniSlice';
import { fetchAll as fetchQualificheLookup } from '../../../../../store/slices/qualificheSlice';
import { fetchConteggioPerQualificaCompleto } from "../../../../../store/slices/presenzeSlice";
import { fetchAll as fetchAllScadenzeContratti } from "../../../../../store/slices/scadenzaContrattiSlice";
import { addDays } from "date-fns";
import { getDateYYYYMMDD_BackEnd } from "../../../../../utils/utilfunctions";

interface ScadenzaContrattiProps {
  validData: ScadenzaContratti[];
}

const ScadenzaContrattiW = (props: ScadenzaContrattiProps) => {
  const { validData } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const title = t('expirationContractTitle');

  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperative.lookup);
  const lookupTurniDesc = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualificheDescBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettoCurrentDataKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder)
          obj.defaultGroupOrder = f.defaultGroupOrder;

        // lookups
        if (f.lookupField)
          switch (f.field) {
            case 'idStruttura':
              obj.lookup = lookupStrutture;
              break;
            case 'idDipartimento':
              obj.lookup = lookupDipartimenti;
              break;
            case 'idUnitaOperativa':
              obj.lookup = lookupUnitaOperative;
              break;
            case 'idTurno':
              obj.lookup = lookupTurniDesc;
              break;
            case 'idQualifica':
              obj.lookup = lookupQualificheDescBreve;
              break;
          }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, lookupDipartimenti, lookupQualificheDescBreve, lookupStrutture, lookupTurniDesc, lookupUnitaOperative, t]);

  /**
   * fetch lookups
   */
  useEffect(() => {
    dispatch(fetchStrutturaLookup());
    dispatch(fetchDipartimentoLookup());
    dispatch(fetchUnitaOperativaLookup());
    dispatch(fetchTurniLookup());
    dispatch(fetchQualificheLookup());
  }, [dispatch]);

  const fixedProps = useMemo(() => {
    return {
      dataRiferimento: getDateYYYYMMDD_BackEnd(addDays(new Date(), 30)),
    }
  }, []);

  useEffect(() => {
    dispatch(fetchConteggioPerQualificaCompleto());
    dispatch(fetchAllScadenzeContratti(fixedProps));
  }, [dispatch, fixedProps]);

  const validAnagraficaSoggettiFinePeriodoProva = useMemo(() => validData, [validData]);
  const statusValidConteggioQualifica = useAppSelector(state => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);
  const errorBE = useAppSelector(state => state.presenze.error);

  const [data, setData] = useState<ScadenzaContratti[]>([]);

  useEffect(() => {
    setData(state => validAnagraficaSoggettiFinePeriodoProva);
  }, [lookupDipartimenti, lookupStrutture, lookupUnitaOperative, validAnagraficaSoggettiFinePeriodoProva]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <ReadOnlyMaterialTable
      title={title}
      columns={columns}
      data={data}
      fetchAllValid={fetchAllScadenzeContratti}
      statusValid={statusValidConteggioQualifica}
      errorBE={errorBE}
      fixedProps={fixedProps}
      logoUri={logoUri}
      columnsButton={true}
      extraOptions={{
        paging: true,
        search: true,
        draggable: false,
        maxBodyHeight: 1000,
        minBodyHeight: 500,
      }}
      localizedDatePicker={true}
    />
  )
}
export default ScadenzaContrattiW;