import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getById, getByIds, restoreById, upd } from '../../services/services.service'
import { StruttureFestivita, elementIdProps, StruttureFestivitaKeys } from '../../models/StruttureFestivita';

const microservice = "strutture";
const entity = "strutturefestivita";

interface StruttureFestivitaState {
  statusValidStruttureFestivita: StatusEnum,
  validStruttureFestivita: StruttureFestivita[],
  statusDeletedStruttureFestivita: StatusEnum,
  deletedStruttureFestivita: StruttureFestivita[],
  statusAllStruttureFestivita: StatusEnum,
  allStruttureFestivita: StruttureFestivita[],
  statusStruttureFestivita: StatusEnum,
  struttureFestivita: StruttureFestivita | null,
  lookup: Lookup,
  error: string | null
}

const initialState: StruttureFestivitaState = {
  statusValidStruttureFestivita: StatusEnum.Succeeded,
  validStruttureFestivita: [],
  statusDeletedStruttureFestivita: StatusEnum.Succeeded,
  deletedStruttureFestivita: [],
  statusAllStruttureFestivita: StatusEnum.Succeeded,
  allStruttureFestivita: [],
  statusStruttureFestivita: StatusEnum.Succeeded,
  struttureFestivita: null,
  lookup: {},
  error: null
}

// get cerca/strutturefestivita/id/{idStruttura}/{mese}/{giorno}
export const fetchByIdStrutturaMeseGiorno = createAsyncThunk(microservice + '/fetchByIdStrutturaMeseGiorno', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as StruttureFestivita;
});

// get cerca/strutturefestivita/id/{idStruttura}
export const fetchByIdStruttura = createAsyncThunk(microservice + '/fetchByIdStruttura', async (idStruttura: number) => {
  const response = await getByIds(microservice, entity, ['id', idStruttura], ['/fetchByIdStruttura']);
  return response.data as StruttureFestivita[];
});

// get cerca/strutturefestivita/festivita/{idStruttura}
export const fetchFestivitaAll = createAsyncThunk(microservice + '/findFestivitaAll', async (ids: Ids<string>[]) => {
  const id = ['festivita', ids[0].id];

  const response = await getByIds(microservice, entity, id, ['/findFestivitaAll']);
  return response.data as StruttureFestivita[];
});

// post inserisci/strutturefestivita
export const insert = createAsyncThunk(microservice + '/insert', async (codiceVisita: StruttureFestivita) => {
  const response = await create(codiceVisita, microservice, entity);
  return response.data as StruttureFestivita;
});

// put modifica/strutturefestivita
export const update = createAsyncThunk(microservice + '/update', async (codiceVisita: StruttureFestivita) => {
  const response = await upd(codiceVisita, microservice, entity);
  return response.data as StruttureFestivita;
});

// put elimina/struttureturniore/id/{idStruttura}/{mese}/{giorno}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/struttureturniore/id/{idStruttura}/{mese}/{giorno}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/strutturefestivita/id/{idStruttura}/{mese}/{giorno}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const struttureFestivitaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {

    reset: (state: StruttureFestivitaState) => {
      return initialState;
    },
    resetError: (state: StruttureFestivitaState) => {
      state.error = initialState.error;
      state.statusValidStruttureFestivita = initialState.statusValidStruttureFestivita;
      state.statusDeletedStruttureFestivita = initialState.statusDeletedStruttureFestivita;
      state.statusAllStruttureFestivita = initialState.statusAllStruttureFestivita;
      state.statusStruttureFestivita = initialState.statusStruttureFestivita;
    }
  },
  extraReducers: builder => {
    // fetchByIdStrutturaMeseGiorno
    builder.addCase(fetchByIdStrutturaMeseGiorno.pending, (state) => {
      state.statusStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdStrutturaMeseGiorno.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusStruttureFestivita = StatusEnum.Failed;
      }
      state.struttureFestivita = null;
    })
    builder.addCase(fetchByIdStrutturaMeseGiorno.fulfilled, (state, { payload }: PayloadAction<StruttureFestivita>) => {
      state.statusStruttureFestivita = StatusEnum.Succeeded;
      state.struttureFestivita = payload;
    })

    // fetchByIdStruttura
    builder.addCase(fetchByIdStruttura.pending, (state) => {
      state.statusAllStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdStruttura.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllStruttureFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(fetchByIdStruttura.fulfilled, (state, { payload }: PayloadAction<StruttureFestivita[]>) => {
      state.statusAllStruttureFestivita = StatusEnum.Succeeded;
      state.allStruttureFestivita = payload;
    })

    // findFestivitaAll
    builder.addCase(fetchFestivitaAll.pending, (state) => {
      state.statusAllStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchFestivitaAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllStruttureFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(fetchFestivitaAll.fulfilled, (state, { payload }: PayloadAction<StruttureFestivita[]>) => {
      state.statusAllStruttureFestivita = StatusEnum.Succeeded;
      state.allStruttureFestivita = payload;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidStruttureFestivita = StatusEnum.Failed;
      }
      state.struttureFestivita = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<StruttureFestivita>) => {
      state.statusValidStruttureFestivita = StatusEnum.Succeeded;
      state.struttureFestivita = payload;
      state.validStruttureFestivita = state.validStruttureFestivita.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidStruttureFestivita = StatusEnum.Failed;
      }
      state.struttureFestivita = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<StruttureFestivita>) => {
      state.statusValidStruttureFestivita = StatusEnum.Succeeded;
      state.validStruttureFestivita = state.validStruttureFestivita.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.struttureFestivita = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidStruttureFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidStruttureFestivita = StatusEnum.Succeeded;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedStruttureFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedStruttureFestivita = StatusEnum.Succeeded;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedStruttureFestivita = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedStruttureFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedStruttureFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedStruttureFestivita = StatusEnum.Succeeded;
      state.deletedStruttureFestivita = state.deletedStruttureFestivita.filter(el => payload.ids.some(idObj => el[idObj.name as StruttureFestivitaKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = struttureFestivitaSlice.actions;
export default struttureFestivitaSlice.reducer;