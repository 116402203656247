import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getAll, getById, } from '../../services/services.service'
import { PresenzeMancanti } from '../../models/PresenzeMancanti';

const microservice = "unitaoperative";
const entity = "presenzemancanti";

interface PresenzeMancantiState {
  statusValidPresenzeMancanti: StatusEnum,
  validPresenzeMancanti: PresenzeMancanti[],
  presenzeMancanti: PresenzeMancanti | null,
  error: string | null
}

const initialState: PresenzeMancantiState = {
  statusValidPresenzeMancanti: StatusEnum.Succeeded,
  validPresenzeMancanti: [],
  presenzeMancanti: null,
  error: null
}

// cerca/presenzemancanti/id/{idAnagraficaSoggetto}/{presenzaData}/{idTurno}
export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: {
  idAnagraficaSoggetto: number | string,
  presenzaData: string,
  idTurno: number,
}) => {
  const ids: Array<string | number> = Object.values(obj);

  const response = await getById(microservice, entity, ids);
  return response.data as PresenzeMancanti;
});

//@GetMapping("cerca/presenzemancanti/all")
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as PresenzeMancanti[];
});

export const presenzeMancantiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetError: (state: PresenzeMancantiState) => {
      state.error = initialState.error;
      state.statusValidPresenzeMancanti = initialState.statusValidPresenzeMancanti;
    },
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidPresenzeMancanti = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenzeMancanti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenzeMancanti = StatusEnum.Failed;
      }
      state.presenzeMancanti = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<PresenzeMancanti>) => {
      state.statusValidPresenzeMancanti = StatusEnum.Succeeded;
      state.presenzeMancanti = payload;
    })

    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidPresenzeMancanti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidPresenzeMancanti = StatusEnum.Failed;
      state.validPresenzeMancanti = [];
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<PresenzeMancanti[]>) => {
      state.statusValidPresenzeMancanti = StatusEnum.Succeeded;
      state.validPresenzeMancanti = payload;
    })
  }
});

export const { reset, resetError, } = presenzeMancantiSlice.actions;
export default presenzeMancantiSlice.reducer;