import React from 'react';

import { Document, StyleSheet, Page, Font } from 'react-pdf-renderer-v2';

import { Riepiloghi } from '../../AnagraficaSoggettiW';

import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';

import AnagraficaSoggettoSection from './AnagraficaSoggettoSection';
import SchedaProfessionaleSection from './SchedaProfessionaleSection';
import QualificaSection from './QualificaSection';
import UnitaOperativaSection from './UnitaOperativaSection';
import TurnoAbilitatoSection from './TurnoAbilitatoSection';
import SchedaSanitariaSection from './SchedaSanitariaSection';
import FormazioneSection from './FormazioneSection';

Font.registerHyphenationCallback(word => [word]);

interface GeneratePDFProps {
  riepiloghi: Riepiloghi,
  logo?: string
}

export const GeneratePDF = (props: GeneratePDFProps) => {
  const { riepiloghi, logo } = props;

  return (
    <Document title='Riepilogo'>
      <Page style={styles.page} size={'A4'} >
        {logo && <HeaderSection anagraficaSoggetto={riepiloghi?.anagraficaSoggetto} logo={logo} />}

        <AnagraficaSoggettoSection anagraficaSoggetto={riepiloghi?.anagraficaSoggetto} />
        <SchedaProfessionaleSection schedaProfessionale={riepiloghi?.anagraficaSoggetto} />
        <QualificaSection qualifica={riepiloghi?.anagraficaSoggetto} />
        <UnitaOperativaSection unitaOperativa={riepiloghi?.anagraficaSoggetto} />
        <TurnoAbilitatoSection turnoAbilitato={riepiloghi?.anagraficaSoggetto} />
        <SchedaSanitariaSection schedaSanitaria={riepiloghi?.anagraficaSoggetto} />
        <FormazioneSection formazione={riepiloghi?.anagraficaSoggetto.anagraficaSoggettiFormazione} />

        <FooterSection />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingVertical: 30,
    paddingHorizontal: 60,
    lineHeight: 1.5,
  }
});
