import { Fields } from "./Fields";
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

export const elementIdProps = [];
export const elementRenderProps = [];

export const allDotazioneNumericaFields: Fields[] = [
    {
        field: "idDipartimentoUnitaOperativa", titleKey: "departmentParam", required: false, show: false,
    },
    {
        field: "dipartimentoDescrizione", titleKey: "departmentParam", required: false, show: true,
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true, lookupField: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true,
    },

    {
        field: "totale", titleKey: "totalParam", type: 'numeric', required: false, show: true,
    },
];

export const allDotazioneNominativaFields: Fields[] = [
    {
        field: "matricola", titleKey: "numberParam", required: false, show: true, lookupField: true,
    },

    {
        field: "cognome", titleKey: "lastNameParam", required: false, show: true, lookupField: true,
    },

    {
        field: "nome", titleKey: "nameParam", required: false, show: true,
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true,
    },

    {
        field: "lungaAssenza", titleKey: "longAbsenceParam", required: false, show: true, defaultValue: <RemoveIcon />,
        render: (rowData) => rowData.combinazioneTurni && rowData.combinazioneTurni.toLowerCase() === 'la' ? <CheckIcon /> : <RemoveIcon />
    },

    {
        field: "combinazioneTurni", titleKey: "shiftCombinationParam", required: false, show: true,
    },

    {
        field: "tipoContratto", titleKey: "typeOfContractParam", required: false, show: true,
    },

    {
        field: "dataInizioAssegnazione", titleKey: "assignmentDateParam", type: "date", required: false, show: true,
    },
];