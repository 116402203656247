import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnagraficaSoggettoExtended, LookupAnagraficaSoggettoElem } from '../../models/AnagraficaSoggetti'
import { Ids, Lookup, LookupObject, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAllExtendedByIds, lookupServiceFiltered, upd, lookupServiceByName, getByIds } from '../../services/services.service';
import { AnagraficaSoggettiUnitaOperativa, AnagraficaSoggettiUnitaOperativaKeys } from '../../models/AnagraficaSoggettiUnitaOperativa';
import { createLookup, createLookupObject } from '../../utils/utilfunctions';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettiunitaoperative";

interface AnagraficaSoggettiUnitaOperativeState {
  statusValidAnagraficaSoggettiUnitaOperative: StatusEnum,
  validAnagraficaSoggettiUnitaOperative: AnagraficaSoggettiUnitaOperativa[],
  statusValidAnagraficaSoggettiExtendedUnitaOperative: StatusEnum,
  validAnagraficaSoggettiExtendedUnitaOperative: AnagraficaSoggettoExtended[],
  anagraficaSoggettiUnitaOperativa: null | AnagraficaSoggettiUnitaOperativa,
  anagraficheEsterne: LookupAnagraficaSoggettoElem[],
  lookup: Lookup,
  lookupObject: LookupObject,
  lookupEsterno: Lookup,
  lookupEsternoNomeCognome: LookupObject,
  error: string | null
}

const initialState: AnagraficaSoggettiUnitaOperativeState = {
  statusValidAnagraficaSoggettiUnitaOperative: StatusEnum.Succeeded,
  validAnagraficaSoggettiUnitaOperative: [],
  statusValidAnagraficaSoggettiExtendedUnitaOperative: StatusEnum.Succeeded,
  validAnagraficaSoggettiExtendedUnitaOperative: [],
  anagraficaSoggettiUnitaOperativa: null,
  anagraficheEsterne: [],
  lookup: {},
  lookupObject: {},
  lookupEsterno: {},
  lookupEsternoNomeCognome: {},
  error: null
}

/**
 * idStruttura, idDipartimento, idUnitaOperativa vengono utilizzati per l'esclusione dei soggetti all'interno dell'unita operativa con id <idUnitaOperativa>
 */
export const lookupByNameAndIDs = createAsyncThunk(entity + '/lookupByNameAndIDs', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number, dataRif: string, token: string }) => {
  const ids = ["sottostringa", obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa, obj.token, obj.dataRif];
  const response = await lookupServiceByName(microservice, entity, ids);
  return response.data as LookupAnagraficaSoggettoElem[];
});

export const lookupByName = createAsyncThunk(entity + '/lookupByName', async (obj: { token: string }) => {
  const ids = ["nominativo", obj.token];
  const response = await lookupServiceByName(microservice, entity, ids);
  return response.data as LookupAnagraficaSoggettoElem[];
});

export const fetchAllExtendedByUnitaOperativa = createAsyncThunk(entity + '/fetchAllExtendedByUnitaOperativa', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let filterArr = [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa];
  const response = await getAllExtendedByIds(microservice, entity, filterArr);
  return response.data as AnagraficaSoggettoExtended[];
});

export const fetchByStrutturaDipartimentoBetweenDate = createAsyncThunk(entity + '/fetchByStrutturaDipartimentoBetweenDate', async (obj: { idStruttura: number, idDipartimento: number, dataFrom: string, dataTo: string }) => {
  let filterArr = Object.values(obj);
  filterArr.unshift("findByIdStruAndIdDipBetweenDate");

  const response = await getByIds(microservice, entity, filterArr, ['/fetchByStrutturaDipartimentoBetweenDate']);
  return response.data as AnagraficaSoggettiUnitaOperativa[];
});

export const lookupByUnitaOperativa = createAsyncThunk(entity + '/lookupByUnitaOperative', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let filterArr = [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa];
  const response = await lookupServiceFiltered(microservice, entity, 'unitaoperativa', filterArr);
  return response.data as LookupAnagraficaSoggettoElem[];
});

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiUnitaOperative: AnagraficaSoggettiUnitaOperativa) => {
  const response = await create(anagraficaSoggettiUnitaOperative, microservice, entity);
  return response.data as AnagraficaSoggettiUnitaOperativa;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiUnitaOperative: AnagraficaSoggettiUnitaOperativa) => {
  const response = await upd(anagraficaSoggettiUnitaOperative, microservice, entity);
  return response.data as AnagraficaSoggettiUnitaOperativa;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<AnagraficaSoggettiUnitaOperativaKeys>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiUnitaOperativeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookupEsterno: (state: AnagraficaSoggettiUnitaOperativeState) => {
      return {
        ...state,
        lookupEsterno: {},
        lookupEsternoNomeCognome: {},
        anagraficheEsterne: [],
      }
    },
    reset: () => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiUnitaOperativeState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiUnitaOperative = initialState.statusValidAnagraficaSoggettiUnitaOperative;
      state.statusValidAnagraficaSoggettiExtendedUnitaOperative = initialState.statusValidAnagraficaSoggettiExtendedUnitaOperative;
    }
  },
  extraReducers: builder => {
    // lookupByNameAndIDs
    builder.addCase(lookupByNameAndIDs.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(lookupByNameAndIDs.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
      state.lookupEsterno = {};
      state.lookupEsternoNomeCognome = {};
    })
    builder.addCase(lookupByNameAndIDs.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.anagraficheEsterne = payload;
      state.lookupEsterno = createLookup(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
      state.lookupEsternoNomeCognome = createLookupObject(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // lookupByName
    builder.addCase(lookupByName.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(lookupByName.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
      state.lookupEsterno = {};
      state.lookupEsternoNomeCognome = {};
    })
    builder.addCase(lookupByName.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.lookupEsterno = createLookup(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
      state.lookupEsternoNomeCognome = createLookupObject(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
      state.anagraficaSoggettiUnitaOperativa = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiUnitaOperativa>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.anagraficaSoggettiUnitaOperativa = payload;
    })

    //update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
      state.anagraficaSoggettiUnitaOperativa = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiUnitaOperativa>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.anagraficaSoggettiUnitaOperativa = payload;
    })

    // physicalDelete
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<AnagraficaSoggettiUnitaOperativaKeys>[] }>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
    })

    // lookupByUnitaOperativa
    builder.addCase(lookupByUnitaOperativa.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(lookupByUnitaOperativa.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
      state.lookup = {};
      state.lookupObject = {};
    })
    builder.addCase(lookupByUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['cognome', 'nome']);
      state.lookupObject = createLookupObject(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // fetchAllExtendedByUnitaOperativa
    builder.addCase(fetchAllExtendedByUnitaOperativa.pending, (state) => {
      state.statusValidAnagraficaSoggettiExtendedUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(fetchAllExtendedByUnitaOperativa.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiExtendedUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiExtendedUnitaOperative = StatusEnum.Failed;
      }
    })
    builder.addCase(fetchAllExtendedByUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoExtended[]>) => {
      state.statusValidAnagraficaSoggettiExtendedUnitaOperative = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiExtendedUnitaOperative = payload ?? [];
    })

    // fetchByStrutturaDipartimentoBetweenDate
    builder.addCase(fetchByStrutturaDipartimentoBetweenDate.pending, (state) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
    })
    builder.addCase(fetchByStrutturaDipartimentoBetweenDate.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Failed;
      }
    })
    builder.addCase(fetchByStrutturaDipartimentoBetweenDate.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiUnitaOperativa[]>) => {
      state.statusValidAnagraficaSoggettiUnitaOperative = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiUnitaOperative = payload ?? [];
    })
  }
});

export const { cleanLookupEsterno, reset, resetError } = anagraficaSoggettiUnitaOperativeSlice.actions;
export default anagraficaSoggettiUnitaOperativeSlice.reducer;