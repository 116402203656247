import React from 'react';

interface MapsProps {
  map: {
    lat: number,
    lng: number
  }
}

const Maps = (props: MapsProps) => {
  const { map } = props;

  const gMapsApiKey = React.useMemo(() => process.env.REACT_APP_GMAPS_API_KEY, []);
  const [src, setSrc] = React.useState<string>('');
  const mapRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (mapRef.current && gMapsApiKey) {
      const mapMode = 'place';
      const url = "https://www.google.com/maps/embed/v1/" + mapMode + "?";

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('key', gMapsApiKey);
      urlSearchParams.set('q', [map.lat, map.lng].join(' '));
      urlSearchParams.set('center', [map.lat, map.lng].join(','));
      urlSearchParams.set('zoom', '16');

      setSrc(url + urlSearchParams.toString());
    }
  }, [gMapsApiKey, map.lat, map.lng]);

  return (
    <iframe
      ref={mapRef}
      title='maps'
      width="100%"
      height="100%"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      src={src}
      allowFullScreen
    />
  )
}

export default Maps