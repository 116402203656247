import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { Motivo, elementIdProps, MotivoKeys } from '../../models/Motivi';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "motivi";
const entity = "motivi";

interface MotiviState {
  statusValidMotivi: StatusEnum,
  validMotivi: Motivo[],
  statusDeletedMotivi: StatusEnum,
  deletedMotivi: Motivo[],
  statusAllMotivi: StatusEnum,
  allMotivi: Motivo[],
  motivo: Motivo | null,
  lookup: Lookup,
  error: string | null
}

const initialState: MotiviState = {
  statusValidMotivi: StatusEnum.Succeeded,
  validMotivi: [],
  statusDeletedMotivi: StatusEnum.Succeeded,
  deletedMotivi: [],
  statusAllMotivi: StatusEnum.Succeeded,
  allMotivi: [],
  motivo: null,
  lookup: {},
  error: null
}

// get cerca/motivi/id/{idMotivo}
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Motivo;
});

// get cerca/motivi/all/completa
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as Motivo[];
});

// get cerca/motivi/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Motivo[];
});

// get cerca/motivi/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Motivo[];
});

// post inserisci/motivi
export const insert = createAsyncThunk(microservice + '/insert', async (motivo: Motivo) => {
  const response = await create(motivo, microservice, entity);
  return response.data as Motivo;
});

// put modifica/motivi
export const update = createAsyncThunk(microservice + '/update', async (motivo: Motivo) => {
  const response = await upd(motivo, microservice, entity);
  return response.data as Motivo;
});

// put elimina/motivi/id/{idMotivo}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/motivi/id/{idMotivo}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/motivi/id/{idMotivo}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const motiviSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: MotiviState) => {
      return initialState;
    },
    resetError: (state: MotiviState) => {
      state.error = initialState.error;
      state.statusValidMotivi = initialState.statusValidMotivi;
      state.statusDeletedMotivi = initialState.statusDeletedMotivi;
      state.statusAllMotivi = initialState.statusAllMotivi;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidMotivi = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidMotivi = StatusEnum.Failed;
      }
      state.motivo = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Motivo>) => {
      state.statusValidMotivi = StatusEnum.Succeeded;
      state.motivo = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllMotivi = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllMotivi = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Motivo[]>) => {
      state.statusAllMotivi = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idMotivo', ['descrizione']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidMotivi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidMotivi = StatusEnum.Failed;
      }
      state.validMotivi = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Motivo[]>) => {
      state.statusValidMotivi = StatusEnum.Succeeded;
      state.validMotivi = payload ?? [];
      state.lookup = createLookup(payload, 'idMotivo', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedMotivi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedMotivi = StatusEnum.Failed;
      }
      state.deletedMotivi = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Motivo[]>) => {
      state.statusDeletedMotivi = StatusEnum.Succeeded;
      state.deletedMotivi = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidMotivi = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidMotivi = StatusEnum.Failed;
      }
      state.motivo = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Motivo>) => {
      state.statusValidMotivi = StatusEnum.Succeeded;
      state.motivo = payload;
      state.validMotivi = state.validMotivi.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidMotivi = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidMotivi = StatusEnum.Failed;
      }
      state.motivo = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Motivo>) => {
      state.statusValidMotivi = StatusEnum.Succeeded;
      state.validMotivi = state.validMotivi.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.motivo = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidMotivi = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidMotivi = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidMotivi = StatusEnum.Succeeded;
      const deleted = state.validMotivi.find(el => payload.ids.every(idObj => el[idObj.name as MotivoKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedMotivi = state.deletedMotivi.concat([deleted]);
      }
      state.validMotivi = state.validMotivi.filter(el => payload.ids.some(idObj => el[idObj.name as MotivoKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedMotivi = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedMotivi = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedMotivi = StatusEnum.Succeeded;
      const valid = state.deletedMotivi.find(el => payload.ids.every(idObj => el[idObj.name as MotivoKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validMotivi = state.validMotivi.concat([valid]);
      }
      state.deletedMotivi = state.deletedMotivi.filter(el => payload.ids.some(idObj => el[idObj.name as MotivoKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedMotivi = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedMotivi = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedMotivi = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedMotivi = StatusEnum.Succeeded;
      state.deletedMotivi = state.deletedMotivi.filter(el => payload.ids.some(idObj => el[idObj.name as MotivoKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = motiviSlice.actions;
export default motiviSlice.reducer;