import { validateDateXGreaterThanY } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export const elementIdProps = [];
export const elementRenderProps = [];

export const allMedicoNumericaFields: Fields[] = [
    {
        field: "idCodiceVisita", required: false, show: false, titleKey: "visitCodeParam",
    },
    {
        field: "codiceVisitaDescrizione", titleKey: "visitCodeParam", required: false, show: true,
    },
    {
        field: "idDipartimentoUnitaOperativa", titleKey: "departmentParam", required: false, show: false,
    },
    {
        field: "dipartimentoDescrizione", titleKey: "departmentParam", required: false, show: true,
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true,
    },

    {
        field: "idoneitaDescrizione", titleKey: "eligibilityParam", required: false, show: true,
    },

    {
        field: "lungaAssenza", titleKey: "longAbsenceParam", type: 'numeric', required: false, show: true,
    },

    {
        field: "totale", titleKey: "totalParam", type: 'numeric', required: false, show: true,
    },
    {
        field: "totaleScadute", titleKey: "expired", type: 'string', required: false, show: true,
    }
];

export const allMedicoNominativaFields: Fields[] = [
    {
        field: "matricola", titleKey: "numberParam", required: false, show: true,
    },
    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "cognome", titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "idoneitaDescrizione", titleKey: "resultParamVisit", required: false, show: true,
    },
    {
        field: "codiceVisitaDescrizione", titleKey: "descriptionVisit", required: false, show: true,
    },
    {
        field: "dataVisita", titleKey: "lastvisitDateParam", type: "date", required: false, show: true,
    },
    {
        field: "dataControlloSuccessivo", titleKey: "nextcheckDateParam", type: "date", required: false, show: true,
    },
    {
        field: "totaleScadute", titleKey: "", required: false, show: true,
        render: (rowData) => {
            if (rowData.dataControlloSuccessivo === undefined || validateDateXGreaterThanY(Date(), rowData.dataControlloSuccessivo, true, null, true) ? 1 : 0) {
                return <div style={{ height: 25, width: 25, borderRadius: 50, borderColor: '#555555', borderStyle: 'solid', borderWidth: 1, backgroundColor: 'red' }}></div>
            }
            return <div style={{ height: 25, width: 25, borderRadius: 50, borderColor: '#555555', borderStyle: 'solid', borderWidth: 1, backgroundColor: 'green' }}></div>
        }
    },
    {
        field: "idDipartimentoUnitaOperativa", titleKey: "departmentParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true,
    },
];