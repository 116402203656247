import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, Dipartimento, allFields, DipartimentoKeys } from "../../../models/Dipartimenti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeletedByIdStruttura,
  fetchAllValidByIdStruttura,
  insertFiltered,
  logicalDelFiltered,
  physicalDelFiltered,
  restoreFiltered,
  updateFiltered,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  fetchAllValid as fetchCentriDiCosto,
  reset as resetCentriDiCosto,
  resetError as resetErrorCentriDiCosto
} from "../../../store/slices/centriDiCostoSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { dipartimentiPath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields } from "../../../models/Fields";

interface DipartimentiDiStrutturaWProps {
  idStruttura: number
}

const DipartimentiDiStrutturaW = ({ idStruttura }: DipartimentiDiStrutturaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, dipartimentiPath));
  const errorBE = useAppSelector(state => state.dipartimentiFiltered.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorCentriDiCosto());
    dispatch(resetErrorDipartimentiFiltered());
  }, [dispatch]);

  const fixedProps = useMemo(() => { return { "idStruttura": idStruttura } }, [idStruttura]);

  useEffect(() => {
    dispatch(fetchCentriDiCosto());
  }, [dispatch]);

  const lookup = useAppSelector(state => state.centriDiCosto.lookup);

  const title = t('departmentsTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Dipartimento>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Dipartimento> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as DipartimentoKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            }

            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.field === "idCentroDiCosto") obj.lookup = lookup;
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, lookup, t]);

  const validDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.validDipartimentiFiltered);
  const deletedDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.deletedDipartimentiFiltered);

  const statusValidDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.statusValidDipartimentiFiltered);
  const statusDeletedDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.statusDeletedDipartimentiFiltered);

  const [data, setData] = useState<Array<Dipartimento>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validDipartimentiFiltered) : setData(deletedDipartimentiFiltered);
  }, [validDipartimentiFiltered, deletedDipartimentiFiltered, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetDipartimentiFiltered());
      dispatch(resetCentriDiCosto());
    };
  }, [dispatch]);

  return <CrudMaterialTable
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchAllValidByIdStruttura}
    fetchAllDeleted={fetchAllDeletedByIdStruttura}
    insert={insertFiltered}
    logicalDel={logicalDelFiltered}
    physicalDel={physicalDelFiltered}
    restore={restoreFiltered}
    update={updateFiltered}
    dataValid={toggleValidDeleted}
    setToggleValidDeleted={setToggleValidDeleted}
    statusValid={statusValidDipartimentiFiltered}
    statusDeleted={statusDeletedDipartimentiFiltered}
    errorBE={errorBE}
    fixedProps={fixedProps}
    logoUri={logoUri}
    resetErrorCallback={resetError}
    extraOptions={{
      maxBodyHeight: 460,
    }}
  />
}
export default DipartimentiDiStrutturaW;