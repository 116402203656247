import React from 'react'
import { Box, Button, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { IFiltroAvanzatoField } from './FiltroAvanzatoFields.model'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sortAscObjectsBy } from '../../../../../utils/utilfunctions';

export interface FiltroAvanzatoProps {
  fields: IFiltroAvanzatoField[]
  defaultValues: Record<string, any>
  filtroMostrato: boolean
  disabled: boolean
}

const FiltroAvanzato = (props: FiltroAvanzatoProps) => {
  const { fields, defaultValues, filtroMostrato, disabled } = props;

  const { t } = useTranslation();

  const { control, reset } = useForm(defaultValues);

  const _reset = React.useCallback(() => {
    reset();
    fields[0].callback('none', null, true); // this will trigger the api call
  }, [fields, reset]);

  // Facoltativo: resetta il form quando il filtro è nascosto
  // React.useEffect(() => {
  //   if (!filtroMostrato) _reset();
  // }, [_reset, filtroMostrato]);

  return (
    <Box borderTop={'1px solid #cecece'} pt={2} mt={2}>
      <Typography>
        {t('advanceFilter')}
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {
            fields.map((field, index) => {
              return (
                <Grid key={'controller-' + field.name + '-' + index} item xs={12} sm={12} md={6} lg={3}>
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      interface AutocompleteData {
                        description: string,
                        value: string | number
                      }

                      const lookup: AutocompleteData[] = Object.entries(field.lookup)
                        .filter(entry => {
                          let found = (value as AutocompleteData[])?.some(selected => {
                            return selected.value === entry[0]
                          });

                          return !found;
                        })
                        .map(entry => {
                          return {
                            value: entry[0],
                            description: entry[1],
                          }
                        });

                      return <FormControl fullWidth>
                        <Autocomplete
                          id={"filtro-avanzato-" + field.name}
                          multiple
                          fullWidth
                          disabled={!filtroMostrato || disabled}
                          options={sortAscObjectsBy(lookup, ['description'])}
                          getOptionLabel={(option) => option?.description}
                          value={value ?? []}
                          onChange={(event: any, newValue: AutocompleteData[] | null) => {
                            onChange({
                              ...event,
                              target: {
                                ...event.target,
                                value: newValue
                              }
                            });

                            field.callback(field.name, newValue?.filter(i => null != i).map(i => i.value))
                          }}
                          renderInput={(params) => {
                            return <TextField
                              {...params}
                              label={t(field.label)}
                              variant="outlined"
                            />
                          }}
                        />
                      </FormControl>
                    }}
                  />
                </Grid>
              )
            })
          }
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              disabled={!filtroMostrato || disabled}
              variant='contained'
              onClick={() => _reset()}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default FiltroAvanzato;