import { validateInputNumberMinMax, validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type StruttureFestivitaKeys = keyof StruttureFestivita;

export const elementIdProps: StruttureFestivitaKeys[] = ['idStruttura', 'mese', 'giorno'];
export const elementRenderProps: StruttureFestivitaKeys[] = ['descrizione'];

export const allFields: Fields[] = [
  {
    field: "mese", type: 'string', required: true, lookupField: true, editable: 'onAdd',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "monthParam"
  },
  {
    field: "giorno", type: "string", required: true, minVal: 1, editable: 'onAdd',
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 1, 31, false, helperText, true), keyTradValidation: "monthSelect",
    show: true, titleKey: "dateParam.0"
  },
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 250, false, helperText, true), keyTradValidation: "size3to250ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
]
export interface StruttureFestivita {
  idStruttura: number;
  mese: number;
  giorno: number;
  descrizione: string;
  version: number;
}