import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Switch as MuiSwitch,
  Checkbox
} from '@material-ui/core';
import clsx from 'clsx';
import { DeleteRounded as DeleteRoundedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { OrderedListOfTurni } from '../Calendar';
// import { Lookup } from '../../../../../models/Utils';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../../store/hooks';
import {
  // createLookup,
  getDateYYYYMMDD
} from '../../../../../../utils/utilfunctions';
import { deleteTurno, modificaReperibilita, modificaTurnoFisso as modificaTurnoFissoAct, refreshSoggettoEQualifica } from '../../../../../../store/slices/pianificazioniSlice';
import { Pianificazione } from '../../../../../../models/Pianificazioni';
import { RiepilogoPianificazioneDTO } from '../../../PianificazioniW';
import { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    hideShowOnHover: {
      '&:hover svg': {
        visibility: 'visible',
      }
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
);

interface TableTurniProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  currentSelectedDate: Date;
  preparedData: OrderedListOfTurni[][];
  selectedDateIndex: number;
  handleClose: () => void;
}

interface TurnoFisso {
  turnoFisso: boolean,
  modificaTurnoFisso: boolean,
}

function elimina(dispatch: any, idAnagraficaSoggetto: number, idQualifica: number, data: string, idTurno?: number, idTurnoAssenza?: number) {
  const selectedTurno: Partial<Pianificazione> = {
    idAnagraficaSoggetto: idAnagraficaSoggetto,
    idQualifica: idQualifica,
    pianificazioneData: data,
    idTurno: idTurno,
    idTurnoAssenza: idTurnoAssenza,
  }

  dispatch(deleteTurno(selectedTurno as Pianificazione));
};

function aggiornaTurnoFisso(dispatch: any, turnoFisso: TurnoFisso, idAnagraficaSoggetto: number, idQualifica: number, data: string, idTurno?: number, idTurnoAssenza?: number) {
  const selectedTurno: Partial<Pianificazione> = {
    idAnagraficaSoggetto: idAnagraficaSoggetto,
    idQualifica: idQualifica,
    pianificazioneData: data,
    idTurno: idTurno,
    idTurnoAssenza: idTurnoAssenza,
    turnoFisso: turnoFisso.turnoFisso,
    modificaTurnoFisso: turnoFisso.modificaTurnoFisso
  }

  dispatch(modificaTurnoFissoAct(selectedTurno as Pianificazione));
}

function aggiornaReperibilita(dispatch: any, idAnagraficaSoggetto: number, idQualifica: number, data: string, valore: boolean, idTurno?: number, idTurnoAssenza?: number) {
  const selectedTurno: Partial<Pianificazione> = {
    idAnagraficaSoggetto: idAnagraficaSoggetto,
    idQualifica: idQualifica,
    pianificazioneData: data,
    idTurno: idTurno,
    idTurnoAssenza: idTurnoAssenza,
  }

  dispatch(modificaReperibilita({ pianificazione: selectedTurno as Pianificazione, valore: valore }));
}

const TableTurni = ({ preparedData, selectedDateIndex, currentSelectedAS, currentSelectedDate, handleClose }: TableTurniProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const validPianificazioni = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validUnitaOperativaPianificazioni = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);

  const currentTurni = useMemo(() => {
    return validPianificazioni
      ?.filter(elem => elem.pianificazioneData.startsWith(getDateYYYYMMDD(currentSelectedDate)) && elem.idAnagraficaSoggetto === currentSelectedAS.idAnagraficaSoggetto)
      ?? [];
  }, [currentSelectedAS.idAnagraficaSoggetto, currentSelectedDate, validPianificazioni]);

  const [reperibilitaCheckBoxValues, setReperibilitaCheckBoxValues] = useState<boolean[]>([]);
  useEffect(() => {
    setReperibilitaCheckBoxValues(state => {
      return currentTurni.map(({ reperibilita }) => reperibilita);
    });
  }, [currentTurni]);

  const [switchState, setSwitchState] = useState<TurnoFisso[]>(
    currentTurni.map(({ turnoFisso, modificaTurnoFisso }) => {
      return {
        turnoFisso: turnoFisso ?? false,
        modificaTurnoFisso: modificaTurnoFisso ?? false
      };
    })
  );
  useEffect(() => {
    setSwitchState(_ => {
      return currentTurni.map(({ turnoFisso, modificaTurnoFisso }) => {
        return {
          turnoFisso: turnoFisso ?? false,
          modificaTurnoFisso: modificaTurnoFisso ?? false
        };
      });
    });
  }, [currentTurni]);

  const [turnoFissoConfirm, setTurnoFissoConfirm] = useState<boolean>(false);

  const handleTurnoFissoChange = (index: number, value: boolean) => {
    const ok = window.confirm('Sei sicuro di volerlo impostare come turno fisso?');

    if (ok) {
      setSwitchState(state => {
        const newVal = [...state];
        state.forEach((_, idx) => {
          newVal[idx] = {
            turnoFisso: idx === index ? value : false,
            modificaTurnoFisso: idx === index ? true : false,
          };
        });
        return newVal;
      });
      setTurnoFissoConfirm(ok);
      setMakeChangesInPianificazioni(true);
    }
  };

  useEffect(() => {
    if (turnoFissoConfirm) {
      preparedData[selectedDateIndex].forEach((elem, idx) => {
        aggiornaTurnoFisso(
          dispatch,
          { turnoFisso: switchState[idx]?.turnoFisso, modificaTurnoFisso: switchState[idx]?.modificaTurnoFisso },
          currentSelectedAS.idAnagraficaSoggetto,
          currentSelectedAS.idQualifica,
          // controllare la data
          getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
          elem?.turno.id,
          elem?.turno.id,
        )
      });

      handleClose();
    }
  }, [currentSelectedAS.idAnagraficaSoggetto, currentSelectedAS.idQualifica, currentSelectedDate, dispatch, handleClose, preparedData, selectedDateIndex, switchState, turnoFissoConfirm]);

  const handleReperibilitaChange = (index: number, value: boolean) => {
    setReperibilitaCheckBoxValues(state => {
      const newVal = [...state];
      newVal[index] = value;
      return newVal;
    });
  };

  useEffect(() => {
    return () => {
      setSwitchState([]);
      setReperibilitaCheckBoxValues([]);
      setTurnoFissoConfirm(false);
    }
  }, []);

  const [makeChangesInPianificazioni, setMakeChangesInPianificazioni] = useState<boolean>(false);

  useEffect(() => {
    if (makeChangesInPianificazioni && validUnitaOperativaPianificazioni) {
      dispatch(refreshSoggettoEQualifica({
        unitaOperativePianificazione: validUnitaOperativaPianificazioni,
        pianificazione: validPianificazioni,
      }));
      setMakeChangesInPianificazioni(false);
    }
  }, [dispatch, makeChangesInPianificazioni, validPianificazioni, validUnitaOperativaPianificazioni]);

  return (
    <TableContainer className={classes.paper}>
      <Table size={preparedData && preparedData[selectedDateIndex]?.length > 0 ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell width={750}>{t('shiftTitle')}</TableCell>
            <TableCell width={20}>{t('availabilityTitle')}</TableCell>
            <TableCell width={20}>{t('hoursTitle')}</TableCell>
            <TableCell width={20}>{t('fixedShiftTitle')}</TableCell>
            <TableCell width={10}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Turni */}
          {
            preparedData && preparedData[selectedDateIndex]?.length > 0
              ? preparedData[selectedDateIndex].map(({ turno, ore, minuti }, index) => {
                const turniFissi = ['M', 'P', 'N'];

                return (
                  <TableRow key={'add-table' + index} className={clsx(classes.hideShowOnHover)}>
                    <TableCell width={750}>{(turno.descrizione ?? turno.descrizioneBreve ?? '')}</TableCell>
                    <TableCell width={20}>
                      {
                        turniFissi.includes(turno.descrizioneBreve?.trim()) &&
                        <Checkbox
                          checked={reperibilitaCheckBoxValues[index] ?? false}
                          onChange={(e) => {
                            handleReperibilitaChange(index, !reperibilitaCheckBoxValues[index]);
                            aggiornaReperibilita(
                              dispatch,
                              currentSelectedAS.idAnagraficaSoggetto,
                              currentSelectedAS.idQualifica,
                              // controllare la data
                              getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
                              e.target.checked,
                              turno.id,
                              turno.id
                            )
                            setMakeChangesInPianificazioni(true);
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell width={20}>{((ore ?? '').toString() + ':' + (minuti < 10 ? '0' + minuti : (minuti ?? '00')))}</TableCell>
                    <TableCell width={20}>
                      {
                        turniFissi.includes(turno.descrizioneBreve?.trim()) &&
                        <MuiSwitch
                          checked={switchState[index]?.turnoFisso ?? false}
                          onChange={() => handleTurnoFissoChange(index, !switchState[index]?.turnoFisso)}
                          disabled={switchState.some(elem => elem.turnoFisso && !elem.modificaTurnoFisso)}
                          name={'MuiSwitch' + selectedDateIndex + '' + index}
                          color='primary'
                        />
                      }
                    </TableCell>
                    <TableCell width={10} align='right'>
                      <IconButton
                        className={clsx(classes.hidden)}
                        onClick={() => {
                          elimina(
                            dispatch,
                            currentSelectedAS.idAnagraficaSoggetto,
                            currentSelectedAS.idQualifica,
                            // controllare la data
                            getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
                            turno.id,
                            turno.id
                          );
                          setMakeChangesInPianificazioni(true);
                        }}>
                        <DeleteRoundedIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
              : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>{t('noDataToShowLabel')}</TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableTurni