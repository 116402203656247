import { validateDate, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiFormazioneKeys = keyof AnagraficaSoggettiFormazione;

export const elementIdProps: AnagraficaSoggettiFormazioneKeys[] = ["idAnagraficaSoggetto", "idAnagraficaSoggettiFormazione"];
export const elementRenderProps: AnagraficaSoggettiFormazioneKeys[] = [];

export const allFields: Fields[] = [
    {
        field: "idFormazione", required: true, show: true, titleKey: "trainingParam", lookupField: true,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    // {
    //     field: "formazioneDescrizione", required: true, show: true, titleKey: "trainingParam",
    //     validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    // },
    {
        field: "dataTitolo", type: "date", required: false, show: true, titleKey: "titleDateParam", sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
    },
    {
        field: "dataScadenza", type: "date", required: false, show: true, titleKey: "expirationDateParam", sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
    }
]
export interface AnagraficaSoggettiFormazione {
    idAnagraficaSoggetto: number,
    idAnagraficaSoggettiFormazione: number,
    idFormazione: number,
    formazioneDescrizione: string,
    dataTitolo?: string,
    dataScadenza: string,
    version: number
}