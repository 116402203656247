import CodiceFiscale from 'codice-fiscale-js';
import * as EmailValidator from 'email-validator';
import imageSize from 'image-size';
import _ from 'lodash';
import { z } from 'zod';
import { Lookup, LookupObject } from '../models/Utils';
import { addDays, differenceInHours, differenceInMinutes, format } from 'date-fns';

const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
const TimeSchema = z.string().refine((val) => timeRegex.test(val));

/**
 * Format a number into {+-}##.####
 * @param value the value to be formatted
 * @returns formatted string value
 */
export function formatCoordinate(value: number): string {
  // Ensure value is a number
  const num = Number(value);

  // Get sign (+ or -)
  const sign = num >= 0 ? '+' : '-';

  // Absolute value to handle formatting
  const absValue = Math.abs(num);

  // Format number with 2 digits before decimal and 4 digits after
  const formatted = absValue.toFixed(4);

  // Extract integer and decimal parts
  const [integerPart, decimalPart] = formatted.split('.');

  // Ensure integer part is 2 digits, pad with zeroes if needed
  const intPartPadded = integerPart.padStart(2, '0');

  // Concatenate with the sign
  return sign + intPartPadded + '.' + decimalPart;
}

/**
 * Sposta l'oggetto identificato da value alla nuova posizione nella lista.
 * @param list la lista da considerare
 * @param id proprietà dell'oggetto che lo identifica
 * @param value valore da cercare
 * @param newPosition nuova posizione dello spostamento
 * @returns nuova array con l'oggetto spostato
 */
export function moveObjectFromListById<T extends Record<string, unknown>>(list: T[] | null | undefined, id: string, value: unknown, newPosition: number): T[] {
  const safeList = list ?? [];

  if (newPosition < 0 || newPosition >= safeList.length || id == null || id.length === 0 || safeList.length === 0)
    return safeList;

  let newList = [...safeList];

  const objectPosition = safeList.findIndex(i => {
    return i[id] === value;
  });

  if (objectPosition === -1) // elemento non trovato
    return safeList;

  newList.splice(objectPosition, 1);
  newList.splice(newPosition, 0, safeList[objectPosition]);

  return newList;
}

/**
 * Sposta l'oggetto identificato da value alla nuova posizione nella lista.
 * @param list la lista da considerare
 * @param oldPosition vecchia posizione dell'elemento
 * @param newPosition nuova posizione dell'elemento'
 * @returns nuova array con l'oggetto spostato
 */
export function moveObjectFromListByIndexes<T extends Record<string, unknown>>(list: T[] | null | undefined, oldPosition: number, newPosition: number): T[] {
  const safeList = list ?? [];

  if (
    (oldPosition < 0 || oldPosition >= safeList.length) ||
    (newPosition < 0 || newPosition >= safeList.length) ||
    safeList.length === 0) {
    return safeList;
  }

  let newList = [...safeList];

  newList.splice(oldPosition, 1);
  newList.splice(newPosition, 0, safeList[oldPosition]);

  return newList;
}

export function isNotNullAndUndefined(arg: any): boolean {
  return arg !== null && arg !== undefined;
}

export function getImageDimensionsFromBase64(base64?: string | null) {
  if (!base64) return null;

  const base64Data = base64.split(',')[1] || base64;
  const buffer = Buffer.from(base64Data, 'base64');
  return imageSize(buffer);
}

export function calculateWidth(oldWidth: number, oldHeight: number, newHeight: number): number {
  let newWidth = 0;

  if (oldHeight > 0)
    newWidth = oldWidth * newHeight / oldHeight;

  return newWidth;
}

export function calculateHeight(oldWidth: number, oldHeight: number, newWidth: number): number {
  let newHeight = 0;

  if (oldWidth > 0)
    newHeight = oldHeight * newWidth / oldWidth;

  return newHeight;
}

/**
 * Ordina un array di oggetti in modo decrescente a seconda del valore di reference
 * @note il parametro reference può essere string oppure string[] per retro-compatibilità
 * @param {T[]} objects - una lista da ordinare
 * @param {string | string[]} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortDescObjectsBy<T>(objects: T[], reference: string | string[]): T[] {
  return sortAscObjectsBy(objects, reference).reverse();
}

/**
 * Ordina un array di oggetti in modo crescente a seconda del valore di reference
 * @note il parametro reference può essere string oppure string[] per retro-compatibilità
 * @param {T[]} objects - una lista da ordinare\
 * @param {string | string[]} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortAscObjectsBy<T>(objects: T[], reference: string | string[]): T[] {
  return _.sortBy(
    objects,
    typeof reference === 'string' ? [reference] : reference
  );
}

/**
 * Calcola la differenza tra due orari salvato in un array [ore, minuti]
 * @param {string} inizio - l'orario di inizio in formato hh:mm
 * @param {string} fine - l'orario di fine in formato hh:mm
 * @returns un'array che contiene la differenza
 */
export function differenzaInOreMinuti(inizio: string, fine?: string): number[] {
  const MINUTES_IN_HOURS = 60;

  if (!fine) {
    return [0, 0]
  }

  const FROM_HOURS = Number(inizio.split(':')[0]);
  const FROM_MINUTES = Number(inizio.split(':')[1]);
  const TO_HOURS = Number(fine.split(':')[0]);
  const TO_MINUTES = Number(fine.split(':')[1]);

  const inizioDate = new Date();
  inizioDate.setHours(FROM_HOURS, FROM_MINUTES);

  const fineDate = new Date();
  fineDate.setHours(TO_HOURS + (TO_HOURS >= FROM_HOURS ? 0 : 24), TO_MINUTES);

  const mezzaNotte = addDays(new Date(), 1);
  mezzaNotte.setHours(0, 0);

  const oreVal = TO_HOURS >= FROM_HOURS && TO_MINUTES >= FROM_MINUTES ? differenceInHours(fineDate, inizioDate) : differenceInHours(mezzaNotte, inizioDate);
  const minutiVal = (TO_HOURS >= FROM_HOURS && TO_MINUTES >= FROM_MINUTES ? differenceInMinutes(fineDate, inizioDate) % MINUTES_IN_HOURS : differenceInMinutes(fineDate, mezzaNotte)) % MINUTES_IN_HOURS;

  return [oreVal, minutiVal]
}

/**
 * Ordina un array di oggetti in modo decrescente a seconda del valore di reference
 * @param {T[]} objects - una lista da ordinare
 * @param {string} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortDescObjectsBy_old<T extends Record<string, any>>(objects: T[], reference: string): T[] {
  const tempObjects = objects ? [...objects] : [];
  return tempObjects.sort((a: T, b: T) => {
    let _a = a[reference];
    let _b = b[reference];

    if (typeof a[reference] === 'string') {
      _a = a[reference].toLowerCase();
      _b = b[reference].toLowerCase();
    }

    return _a > _b
      ? -1
      : _a < _b
        ? 1
        : 0;
  });
}

/**
 * Ordina un array di oggetti in modo crescente a seconda del valore di reference
 * @param {T[]} objects - una lista da ordinare
 * @param {string} reference - l'attributo di ogni oggetto da utilizzare per l'ordinamento
 * @returns una lista ordinata
 */
export function sortAscObjectsBy_old<T extends Record<string, any>>(objects: T[], reference: string): T[] {
  const tempObjects = [...objects];
  return tempObjects.sort((a: T, b: T) => {
    let _a = a[reference];
    let _b = b[reference];

    if (typeof a[reference] === 'string') {
      _a = a[reference].toLowerCase();
      _b = b[reference].toLowerCase()
    }

    return _a < _b
      ? -1
      : _a > _b
        ? 1
        : 0;
  });
}

/**
 *
 * @param {string} url - l'url corrente
 * @param {string} basePath - percorso base della funzione
 * @returns {boolean} Ritorna se l'url è un percorso base della funzione
 */
export function isUrlRoot(url: string, basePath: string): boolean {
  return url === basePath;
}

/**
 * Controlla l'ordinamento (case insensitive) di 2 stringhe ritornando un intero.
 * @param {string} a - Prima stringa
 * @param {string} b - Seconda stringa
 * @returns {int} -1: a viene prima di b\
 *           0: a e b sono simili/uguali\
 *           1: a viene dopo di b
 */
export function sortingStringCI(a: string, b: string): number {
  return a.toLowerCase() < b.toLowerCase()
    ? -1
    : a.toLowerCase() < b.toLowerCase()
      ? 0
      : 1;

}

/**
 * Controlla l'ordinamento (case sensitive) di 2 stringhe ritornando un intero.
 * @param {string} a - Prima stringa
 * @param {string} b - Seconda stringa
 * @returns {int} -1: a viene prima di b\
 *           0: a e b sono simili/uguali\
 *           1: a viene dopo di b
 */
export function sortingStringCS(a: string, b: string): number {
  return a < b
    ? -1
    : a < b
      ? 0
      : 1;

}

export function capitalize(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function isEquals(a: string, b: string, caseSensitive: boolean = false) {
  if (caseSensitive)
    return a === b;
  else
    return a.toLowerCase() === b.toLowerCase();
}

/**
 * Calcola il numero totale dei giorni in un mese
 * @param {number} month - mese da 1 a 12
 * @param {number} year - anno
 * @returns {number} - numero di giorni nel mese
 */
export function daysInMonth(month: number, year: number): number {
  return (month >= 1 && month <= 12) && year > 0
    ? new Date(year, month, 0).getDate()
    : 0
}

export function createLookup<T extends Record<string, any>>(object: T[], idAttribute: string, valueAttribute: string[], flag: string | null = null, separator: string = ' '): Lookup {
  let retval: Lookup = {};

  const isDate = (val: string) => {
    return /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/.test(val);
  }

  if (object) {
    if (flag) {
      object.forEach(elem => {
        if (elem[flag]) {
          let temp: string = "";
          valueAttribute.forEach((e, i, arr) => {
            let val = elem[e];

            if (isDate(val)) {
              val = format(new Date(val), 'dd/MM/yyyy HH:mm:ss')
            }

            if (val)
              temp += val + (i === (arr.length - 1) ? '' : separator);
          });
          retval[elem[idAttribute]] = temp;
        }
      });
    } else {
      object.forEach(elem => {
        let temp = "";
        valueAttribute.forEach((e, i, arr) => {
          let val = elem[e];

          if (isDate(val)) {
            val = format(new Date(val), 'dd/MM/yyyy HH:mm:ss')
          }

          if (val)
            temp += val + (i === (arr.length - 1) ? '' : separator);
        });
        retval[elem[idAttribute]] = temp;
      });
    }
    const temp = Object.entries(retval).sort((a, b) => a[1] < b[1] ? -1 : 1);
    retval = {};
    temp.forEach((elem) => {
      retval[elem[0]] = elem[1];
    });
  }

  return retval;
}

export function createLookupObject<T extends Record<string, any>>(object: T[], idAttribute: string, valueAttribute: string[], flag: string | null = null): LookupObject {
  let retval: LookupObject = {};

  if (object) {
    if (flag) {
      object.forEach(elem => {
        if (elem[flag]) {
          let temp: Record<string, any> = {};
          valueAttribute.forEach(e => {
            temp[e] = elem[e];
          });
          retval[elem[idAttribute]] = temp;
        }
      });
    }
    else {
      object.forEach(elem => {
        let temp: Record<string, any> = {};
        valueAttribute.forEach(e => {
          temp[e] = elem[e];
        });
        retval[elem[idAttribute]] = temp;
      });
    }
  }

  return retval;
}

export function getToday(): string {    // output: yyyy-mm-ddT00:00:00
  return format(new Date(), "yyyy-LL-dd'T00:00:00'");
}

export const getDateDDMMYYYY = (date: Date): string => {
  if (!date || date.toString() === 'Invalid Date') return '';

  return format(date, "dd/LL/yyyy")
}

export const getDateDDMMYYYY_HHMM = (date: Date): string => {
  if (!date || date.toString() === 'Invalid Date') return '';

  return format(date, "dd/LL/yyyy HH:mm")
}

export function getDateYYYYMMDD(date: Date): string {
  if (!date || date.toString() === 'Invalid Date') return '';

  return format(date, "yyyy-LL-dd");
}


export function getDateYYYYMMDD_BackEnd(date: Date): string {
  if (!date || date.toString() === 'Invalid Date') return '';

  return format(date, "yyyy-LL-dd'T00:00:00'");
}

export function setCookie(name: string, val: string, domain: string | undefined, expire: number) {
  const date = new Date();
  const value = val;

  // Expire
  date.setTime(date.getTime() + expire);

  let newCookie = name + "=" + value + "; expires=" + date.toUTCString() + ";";
  if (window.location.protocol === "https:") newCookie = newCookie + " SameSite=Lax; Secure;";
  if (domain) newCookie = newCookie + " domain=" + domain + ";";
  document.cookie = newCookie;
}

export function getCookie(key: string) {
  const found = document.cookie.split(';').find(row => row.trim().startsWith(key + '='));
  return found ? found.split('=')[1] : null;
}

export function deleteCookie(key: string, domain: string | undefined) {
  let str = key + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;";
  if (window.location.protocol === "https:") str = str + " SameSite=Lax; Secure;";
  if (domain) str = str + " domain=" + domain + ";";
  document.cookie = str;
}

export const toBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let str = reader.result as string;
    resolve(str.split(",")[1]);
  }
  reader.onerror = error => reject(error);
});

export const toBase64PDF = (file: Blob) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result as string);
  }
  reader.onerror = error => reject(error);
});


/*********VALIDATIONS***********/

export const validateEmail = (rowDataField: string | undefined | null, helperText: string | null, required: boolean) => {
  if (required && (rowDataField === undefined || rowDataField === null || !EmailValidator.validate(rowDataField))) {
    return helperText ? { isValid: false, helperText: helperText } : false;
  }
  else if (!required && (rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && !EmailValidator.validate(rowDataField))) {
    return helperText ? { isValid: false, helperText: helperText } : false;
  }
  else {
    return true;
  }
}

export const validateInputTextMinMaxLen = (rowDataField: string | undefined | null, minLen: number, maxLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length <= minLen : rowDataField.length < minLen) || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField.length !== 0 &&
    (
      (equal ? rowDataField.length <= minLen : rowDataField.length < minLen) || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
    )
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextMaxLen = (rowDataField: string | undefined | null, maxLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length >= maxLen : rowDataField.length > maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && (equal ? rowDataField.length >= maxLen : rowDataField.length < maxLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextMinLen = (rowDataField: string | undefined | null, minLen: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || (equal ? rowDataField.length <= minLen : rowDataField.length < minLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && (equal ? rowDataField.length <= minLen : rowDataField.length < minLen)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputTextFixedLen = (rowDataField: string | undefined | null, lenVal: number, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || rowDataField.length !== lenVal
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null && rowDataField.length !== 0 && rowDataField.length !== lenVal
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMinMax = (rowDataField: number | undefined | "" | null, minVal: number, maxVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null || rowDataField === "" || (equal ? rowDataField <= minVal : rowDataField < minVal) || (equal ? rowDataField >= maxVal : rowDataField > maxVal)
  )) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal) || (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMax = (rowDataField: number | undefined | "" | null, minVal: number, maxVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null ||
    rowDataField === "" ||
    (
      (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField >= maxVal : rowDataField > maxVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputNumberMin = (rowDataField: number | undefined | "" | null, minVal: number, equal: boolean, helperText: string | null, required: boolean) => {
  if (required && (
    rowDataField === undefined || rowDataField === null ||
    rowDataField === "" ||
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else if (!required && (
    rowDataField !== undefined && rowDataField !== null &&
    rowDataField !== "" &&
    (
      (equal ? rowDataField <= minVal : rowDataField < minVal)
    ))) return helperText ? { isValid: false, helperText: helperText } : false;
  else return true;
}

export const validateInputDate = (start: Date | string | undefined | null, end: Date | string | undefined | null,
  equal: boolean, helperText: string | null, requiredStart: boolean, requiredEnd: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  if (requiredStart) {
    if (start === undefined || start === null || start === "") return notValidRet;
  }
  if (requiredEnd) {
    if (end === undefined || end === null || end === "") return notValidRet;
  }

  if (start === undefined || start === null || start === "") return true;
  if (end === undefined || end === null || end === "") return true;

  let stDate = start as Date;
  let stEnd = end as Date;
  if (equal && (stEnd.getTime() <= stDate.getTime())) return notValidRet;
  else if (!equal && (stEnd.getTime() < stDate.getTime())) return notValidRet;
  else return true;
}

export const validateRequired = (rowDataField: string | number | undefined | null, helperText: string | null) => {
  if (rowDataField === undefined || rowDataField === null || rowDataField === "") {
    return helperText ? { isValid: false, helperText: helperText } : false;
  } else return true;
}

export const validateCodiceFiscale = (codice: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (codice === undefined || codice === null || codice === "") return notValidRet;
  }
  if ((!required && codice && codice.length > 0) || required) {
    if (codice && CodiceFiscale.check(codice)) return true;
    else return notValidRet;
  } else return true;
}

export const validateDate = (date: string | undefined | null, minYear: number | null, maxYear: number | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (date === undefined || date === null || date === "") return notValidRet;
  }
  if (date && (isDateValid(date) || isDateTimeValid(date)) && ((!required && date) || required)) {
    let checkDate = new Date(date);
    let year = checkDate.getFullYear();
    if (
      isNaN(checkDate.valueOf()) ||
      (minYear && year < minYear) ||
      (maxYear && year > maxYear) ||
      checkDate.getMonth() < 0 ||
      checkDate.getMonth() > 11 ||
      checkDate.getDate() < 1 ||
      checkDate.getDate() > 31
    ) return notValidRet;
    else return true;
  } else return true;
}

export const validateTime = (time: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  if (required) {
    if (time === undefined || time === null || time === "") return notValidRet;
  } else {
    if (time === undefined || time === null || time === "") return true;
  }

  try {
    time && TimeSchema.parse(time)
  } catch (_) {
    return notValidRet;
  }

  const timeX_hour = Number(time.split(':')[0]);
  const timeX_minute = Number(time.split(':')[1]);

  if (Number.isNaN(timeX_hour) || Number.isNaN(timeX_minute))
    return true;

  const dateX = new Date(new Date().setUTCHours(timeX_hour, timeX_minute)).toISOString();

  return dateX ? true : false;
}

export const validateDateXGreaterThanY = (dateX: string | undefined | null, dateY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (dateX === undefined || dateX === null || dateX === "") return notValidRet;
  }
  if ((!required && dateX) || required) {
    if (dateY === undefined || dateY === null || dateY === "") return true;
    else {
      if (dateX && dateY && (isDateValid(dateX) || isDateTimeValid(dateX)) && (isDateValid(dateY) || isDateTimeValid(dateY))) {
        let checkDateX = new Date(dateX);
        let checkDateY = new Date(dateY);
        if ((equal && checkDateX.valueOf() === checkDateY.valueOf()) || checkDateX.valueOf() > checkDateY.valueOf()) return true;
        else return notValidRet;
      }
      return notValidRet;
    }
  } else return true;
}

export const validateDateXSmallerThanY = (dateX: string | undefined | null, dateY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (dateX === undefined || dateX === null || dateX === "") return notValidRet;
  }
  if ((!required && dateX) || required) {
    if (dateY === undefined || dateY === null || dateY === "") return true;
    else {
      if (dateX && dateY && (isDateValid(dateX) || isDateTimeValid(dateX)) && (isDateValid(dateY) || isDateTimeValid(dateY))) {
        let checkDateX = new Date(Date.parse(dateX));
        let checkDateY = new Date(Date.parse(dateY));
        if ((equal && checkDateX.valueOf() === checkDateY.valueOf()) || checkDateX.valueOf() < checkDateY.valueOf()) return true;
        else return notValidRet;
      }
      return notValidRet;
    }
  } else return true;
}

export const validateTimeXGreaterThanY = (timeX: string | undefined | null, timeY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  if (timeX == null) {
    if (required) return notValidRet;
    return true;
  }

  if (timeX == null || timeY == null) return true;

  try {
    timeX && timeY && TimeSchema.parse(timeX) && TimeSchema.parse(timeY);
  } catch (_) {
    return notValidRet;
  }

  if (timeX && timeY) {
    const timeX_hour = Number(timeX.split(':')[0]);
    const timeX_minute = Number(timeX.split(':')[1]);

    const timeY_hour = Number(timeY.split(':')[0]);
    const timeY_minute = Number(timeY.split(':')[1]);

    if (Number.isNaN(timeX_hour) || Number.isNaN(timeX_minute) || Number.isNaN(timeY_hour) || Number.isNaN(timeY_minute))
      return true;

    const dateX = new Date(new Date().setUTCHours(timeX_hour, timeX_minute)).toISOString();
    const dateY = new Date(new Date().setUTCHours(timeY_hour, timeY_minute)).toISOString();

    return validateDateXGreaterThanY(dateX, dateY, equal, helperText, required);
  }
}

export const validateTimeXSmallerThanY = (timeX: string | undefined | null, timeY: string | undefined | null, equal: boolean, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;

  if (timeX == null) {
    if (required) return notValidRet;
    return true;
  }

  if (timeX == null || timeY == null) return true;

  try {
    timeX && timeY && TimeSchema.parse(timeX) && TimeSchema.parse(timeY);
  } catch (_) {
    return notValidRet;
  }

  if (timeX && timeY) {
    const timeX_hour = Number(timeX?.split(':')[0]);
    const timeX_minute = Number(timeX?.split(':')[1]);

    const timeY_hour = Number(timeY?.split(':')[0]);
    const timeY_minute = Number(timeY?.split(':')[1]);

    if (Number.isNaN(timeX_hour) || Number.isNaN(timeX_minute) || Number.isNaN(timeY_hour) || Number.isNaN(timeY_minute))
      return true;

    const dateX = new Date(new Date().setUTCHours(timeX_hour, timeX_minute)).toISOString();
    const dateY = new Date(new Date().setUTCHours(timeY_hour, timeY_minute)).toISOString();

    return validateDateXSmallerThanY(dateX, dateY, equal, helperText, required);
  }
}

export const validateCellularNumber = (cellular: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cellular === undefined || cellular === null || cellular === "") return notValidRet;
  }
  if ((!required && cellular) || required) {
    const MNO = /^(\((00|\+)39\)|(00|\+)39)?\s?(313\s?[0-9]|351\s?[3-9]|352\s?[0]|33[013-9]\s?[0-9]|36[0-368]\s?[0-9]|381\s?[0-9]|34[0-9]\s?[0-9]|383\s?[0-9]|32[0234789]\s?[0-9]|355\s?[0-9]|38[089]\s?[0-9]|39[0-37]\s?[0-9])\d{2}\s?\d{4}$/;
    const MVNO = /^(\((00|\+)39\)|(00|\+)39)?(331\s?1|350\s?[0-9]|351\s?0|370\s?[137]|371\s?[01]|373\s?[0-9]|375\s?5|389\s?[0-9]|384\s?[0-9]|377\s?[1-5789])\d{2}\s?\d{4}$/;
    if (cellular && !MNO.test(cellular) && !MVNO.test(cellular)) return notValidRet;
    else return true;
  } else return true;
}

export const validateTelephoneNumber = (cellular: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cellular === undefined || cellular === null || cellular === "") return notValidRet;
  }
  if ((!required && cellular) || required) {
    const pattern = /^(0039|\+39)?\s?0[0-9]{7,10}$/;
    if (cellular && !pattern.test(cellular)) return notValidRet;
    else return true;
  } else return true;
}

export const validateTelephoneCellularNumber = (tcnumber: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (tcnumber === undefined || tcnumber === null || tcnumber === "") return notValidRet;
  }
  if ((!required && tcnumber) || required) {
    if (!validateTelephoneNumber(tcnumber, helperText, required) && !validateCellularNumber(tcnumber, helperText, required)) return notValidRet;
    else return true;
  } else return true;
}

export const validateCAP = (cap: string | undefined | null, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (cap === undefined || cap === null || cap === "") return notValidRet;
  }
  if ((!required && cap) || required) {
    const pattern = /^[0-9]{5}$/;
    if (cap && !pattern.test(cap)) return notValidRet;
    else return true;
  } else return true;
}

export const validateAsDigitsByLength = (value: string | undefined | null, length: number, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (value === undefined || value === null || value === "") return notValidRet;
  }
  if ((!required && value) || required) {
    const pattern = new RegExp("^[0-9]{" + length + "}$");
    if (value && !pattern.test(value)) return notValidRet;
    else return true;
  } else return true;
}

export const validateAsDigitsByInterval = (value: string | undefined | null, minLength: number, maxLength: number, helperText: string | null, required: boolean) => {
  let notValidRet = helperText ? { isValid: false, helperText: helperText } : false;
  if (required) {
    if (value === undefined || value === null || value === "") return notValidRet;
  }
  if ((!required && value) || required) {
    const pattern = new RegExp("^[0-9]{" + minLength + "," + maxLength + "}$");
    if (value && !pattern.test(value)) return notValidRet;
    else return true;
  } else return true;
}

function isDateValid(date: string | null | undefined): boolean {
  const dateValidator = z.string().date();

  const result = dateValidator.safeParse(date);

  return result.success;
}

function isDateTimeValid(date: string | null | undefined): boolean {
  if (typeof date !== 'string') {
    return false;
  }
  const dateTimeValidator = z.string().datetime();
  const result = dateTimeValidator.safeParse(date.endsWith('Z') ? date : date + 'Z');

  return result.success;
}