import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, cancelById, restoreById, getAllValidByIds, getAllDeletedByIds } from '../../services/services.service'
import { AnagraficaSoggettiFormazione } from '../../models/AnagraficaSoggettiFormazione';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettiformazione";

interface AnagraficaSoggettiFormazioneState {
  statusValidAnagraficaSoggettiFormazioni: StatusEnum,
  validAnagraficaSoggettiFormazioni: AnagraficaSoggettiFormazione[],
  statusDeletedAnagraficaSoggettiFormazioni: StatusEnum,
  deletedAnagraficaSoggettiFormazioni: AnagraficaSoggettiFormazione[],
  anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione | null,
  error: string | null
}

const initialState: AnagraficaSoggettiFormazioneState = {
  statusValidAnagraficaSoggettiFormazioni: StatusEnum.Succeeded,
  statusDeletedAnagraficaSoggettiFormazioni: StatusEnum.Succeeded,
  validAnagraficaSoggettiFormazioni: [],
  deletedAnagraficaSoggettiFormazioni: [],
  anagraficaSoggettiFormazione: null,
  error: null
}

// get cerca/anagraficasoggettiformazione/all/valid/{idAnagraficaSoggetto}
export const fetchAllValidById = createAsyncThunk(entity + '/fetchAllValidById', async (obj: { idAnagraficaSoggetto: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idAnagraficaSoggetto], ['/fetchAllValidById']);
  return response.data as AnagraficaSoggettiFormazione[];
});

// get cerca/anagraficasoggettiformazione/all/deleted/{idAnagraficaSoggetto}
export const fetchAllDeletedById = createAsyncThunk(entity + '/fetchAllDeletedById', async (obj: { idAnagraficaSoggetto: number }) => {
  const response = await getAllDeletedByIds(microservice, entity, [obj.idAnagraficaSoggetto], ['/fetchAllDeletedById']);
  return response.data as AnagraficaSoggettiFormazione[];
});

// post inserisci/anagraficasoggettiformazione
export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione) => {
  const response = await create(anagraficaSoggettiFormazione, microservice, entity);
  return response.data as AnagraficaSoggettiFormazione;
});

// put modifica/anagraficasoggettiformazione
export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione) => {
  const response = await upd(anagraficaSoggettiFormazione, microservice, entity);
  return response.data as AnagraficaSoggettiFormazione;
});

// delete delete/anagraficasoggettiformazione/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettiFormazione}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

// put elimina/anagraficasoggettiformazione/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettiFormazione}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/anagraficasoggettiformazione/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettiFormazione}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiFormazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AnagraficaSoggettiFormazioneState) => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiFormazioneState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiFormazioni = initialState.statusValidAnagraficaSoggettiFormazioni;
      state.statusDeletedAnagraficaSoggettiFormazioni = initialState.statusDeletedAnagraficaSoggettiFormazioni;
    }
  },
  extraReducers: builder => {
    // fetchAllValidById
    builder.addCase(fetchAllValidById.pending, (state) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
      state.validAnagraficaSoggettiFormazioni = [];
    })
    builder.addCase(fetchAllValidById.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiFormazione[]>) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiFormazioni = payload;
    })

    // fetchAllDeletedById
    builder.addCase(fetchAllDeletedById.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
      state.anagraficaSoggettiFormazione = null;
    })
    builder.addCase(fetchAllDeletedById.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiFormazione[]>) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
      state.deletedAnagraficaSoggettiFormazioni = payload;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
      state.anagraficaSoggettiFormazione = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiFormazione>) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
      state.anagraficaSoggettiFormazione = payload;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
      state.anagraficaSoggettiFormazione = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiFormazione>) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
      state.anagraficaSoggettiFormazione = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiFormazioni = StatusEnum.Succeeded;
    })
  }
});

export const { reset, resetError } = anagraficaSoggettiFormazioneSlice.actions;
export default anagraficaSoggettiFormazioneSlice.reducer;