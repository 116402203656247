import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from "react-i18next";
import { Pianificazione } from '../../../../../models/Pianificazioni';
import { compareAsc, lastDayOfMonth } from 'date-fns';
import { useAppSelector } from '../../../../../store/hooks';


/** CALENDAR */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hCell: {
      height: 120
    },
    disabled: {
      color: "#cecece"
    },
    bold: {
      fontWeight: "bold"
    },
    error: {
      color: '#f00',
    },
    bgError: {
      backgroundColor: "#900",
      color: "#fff"
    },
    bgErrorDiffMonth: {
      backgroundColor: "#b36767",
      color: "#fff",
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
    },
    textLightGray: {
      color: "#ccc",
    },
    turnoContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: '#cecece',
        borderRadius: 10
      }
    }
  }),
);

export interface PianificazioniDipendente {
  idAnagraficaSoggetto: number,
  nome: string,
  cognome: string,
  pianificazioni: PianificazioneSingoloGiorno[],
}

export interface PianificazioneSingoloGiorno {
  data: string,
  infoTurno: InfoTurno[],
}

export interface InfoTurno {
  idStruttura: number,
  strutturaNome: string,
  idDipartimento: number,
  dipartimentoNome: string,
  idUnitaOperativa: number,
  unitaOperativaNome: string,
  idQualifica: number,
  qualificaDescrizione: string,
  qualificaDescrizioneBreve: string,
  idTurno: number,
  turnoDescrizione: string,
  turnoDescrizioneBreve: string,
  idTurnoAssenza: number,
  turnoAssenzaDescrizione: string,
  turnoAssenzaDescrizioneBreve: string,
  oreTurno: number;
}

interface CalendarProps {
  idAnagraficaSoggetto: number,
  nome: string,
  cognome: string,
  pianificazioni: Pianificazione[],
  anno: number,
  mese: number, // 1-12
}

const CalendarDipendentiPianificazioni = ({ idAnagraficaSoggetto, pianificazioni, nome, cognome, anno, mese }: CalendarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const daysOfTheWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayOfOne: number = new Date(anno, mese - 1, 1).getDay();

  const validPianificazioni = useAppSelector(state => state.pianificazione.validPianificazioni);
  const [pianificazioniState, setPianificazioniState] = useState<Pianificazione[]>([]);
  useEffect(() => {
    setPianificazioniState(validPianificazioni);
  }, [validPianificazioni])

  const [preparedData, setPreparedData] = useState<PianificazioneSingoloGiorno[]>([]);
  useEffect(() => {
    setPreparedData(prepareData(pianificazioniState, anno, mese, dayOfOne));
  }, [anno, dayOfOne, mese, pianificazioniState]);

  const numberOfWeeks = useMemo(() => {
    return Math.ceil(preparedData.length / daysOfTheWeek.length)
  }, [daysOfTheWeek.length, preparedData.length]);

  return (
    <>
      <Paper >
        <Box p={1}>
          <Grid container spacing={1}>
            {/* Header: days of the week & total column */}
            <Grid item xs={12} container spacing={1}>
              {daysOfTheWeek.map((dayOfTheWeek, index) => (
                <Grid key={'dayOfWeek' + index} item xs>
                  <Paper>
                    <Typography variant="h5" align="center">
                      {t(dayOfTheWeek).substring(0, 3)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {
              // calendar table
              new Array(numberOfWeeks)
                .fill(null)
                .map((_, weekNumber) => {
                  // week row
                  return <Grid key={'calendar' + weekNumber} item container xs={12} spacing={2}>
                    {
                      daysOfTheWeek.map((_, indexDayOfTheWeek) => {
                        const dateIndex: number = weekNumber * daysOfTheWeek.length + indexDayOfTheWeek;
                        const currentDate: Date = new Date(preparedData[dateIndex]?.data);
                        const currentDateNumber = currentDate.getDate();


                        // Crea la cella di una giornata dei turni
                        return (
                          <Grid key={'dateCell' + weekNumber.toString() + '-' + indexDayOfTheWeek} item xs>
                            {
                              currentDate.getMonth() === mese - 1 &&
                              <Box
                                p={1}
                                border={1}
                                borderRadius={2}
                                borderColor="gray"
                                className={classes.hCell}
                              >

                                {/* date title number */}
                                <Container className={'titleNumberClassName'}>
                                  <Typography variant="body1" className={classes.bold} align="center">
                                    {currentDateNumber}
                                  </Typography>
                                </Container>

                                <Divider
                                  style={{
                                    marginBottom: 5
                                  }}
                                />

                                { /* Lista turni*/
                                  preparedData && preparedData[dateIndex] && currentDate.getMonth() === mese - 1 &&
                                  preparedData[dateIndex].infoTurno.map((turno, index) => {
                                    return (
                                      <TooltipTurno
                                        key={'tooltip-pianificazione-' + weekNumber.toString() + '-' + indexDayOfTheWeek + index}
                                        turno={turno}
                                      >
                                        <Grid
                                          container
                                          className={classes.turnoContainer}
                                        >
                                          <Grid item xs>
                                            <Typography variant="body2">
                                              {turno.turnoDescrizioneBreve ?? turno.turnoAssenzaDescrizioneBreve}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Typography variant="body2">
                                              {turno.oreTurno}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TooltipTurno>
                                    )
                                  })
                                }
                              </Box>
                            }
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                })
            }
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default CalendarDipendentiPianificazioni;

interface TooltipTurnoProps {
  children: React.ReactElement,
  turno: InfoTurno,
}

const TooltipTurno = (props: TooltipTurnoProps) => {
  const { turno, children } = props;

  return (
    <Tooltip
      title={<span style={{ fontSize: 13 }}>
        Struttura : {turno.strutturaNome} <br />
        Dipartimento : {turno.dipartimentoNome} <br />
        Unita Operativa : {turno.unitaOperativaNome} <br />
        Qualifica : {turno.qualificaDescrizione} <br />
        {
          turno.turnoDescrizione ?
            <>
              Turni: {turno.turnoDescrizione} <br />
            </>
            :
            <>
              Assenza: {turno.turnoAssenzaDescrizione} <br />
            </>
        }
      </span>}
    >
      {children}
    </Tooltip>
  )
}

function prepareData(pianificazione: Pianificazione[], anno: number, mese: number, offset: number): PianificazioneSingoloGiorno[] {
  const retval: (PianificazioneSingoloGiorno | null)[] = [];
  const temp: (PianificazioneSingoloGiorno | null)[] = [];

  pianificazione.forEach(elem => {
    const infoTurno: InfoTurno = {
      idStruttura: elem.idStruttura,
      idDipartimento: elem.idDipartimento,
      idUnitaOperativa: elem.idUnitaOperativa,
      idQualifica: elem.idQualifica,
      idTurno: elem.idTurno,
      idTurnoAssenza: elem.idTurnoAssenza,
      oreTurno: elem.oreTurno,
      strutturaNome: elem.strutturaNome,
      dipartimentoNome: elem.dipartimentoNome,
      unitaOperativaNome: elem.unitaOperativaNome,
      qualificaDescrizione: elem.qualificaDescrizione,
      qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
      turnoDescrizione: elem.turnoDescrizione,
      turnoDescrizioneBreve: elem.turnoDescrizioneBreve,
      turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione,
      turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve,
    }

    const index = temp.findIndex(e => (e && e.data === elem.pianificazioneData));
    if (index >= 0) {
      temp[index]?.infoTurno.push(infoTurno)
    } else {
      temp.push({
        infoTurno: [infoTurno],
        data: elem.pianificazioneData,
      })
    }
  })

  // sort pianificazioni
  temp.sort((a, b) => a && b ? compareAsc(new Date(a.data), new Date(b.data)) : 0)

  // aggiungere date mancanti
  const lastDay = lastDayOfMonth(new Date(anno, mese - 1, 1));
  let tempCounter = 0
  for (let i = tempCounter; i < lastDay?.getDate(); i++) {
    const _data = temp[tempCounter]?.data;

    if (_data && (new Date(_data)).getDate() !== (i + 1)) {
      retval.push({
        infoTurno: [],
        data: anno + '-' + (mese < 10 ? ('0' + mese) : mese) + '-' + ((i + 1) < 10 ? ('0' + (i + 1)) : (i + 1)) + 'T00:00:00'
      })
    } else {
      retval.push(temp[tempCounter])
      tempCounter++;
    }
  }

  // riempie i primi offset posizioni con oggetti vuoti
  new Array(offset).fill({ "": undefined }).forEach(_ => {
    retval?.unshift(null);
  });

  return retval.filter(e => e !== null) as PianificazioneSingoloGiorno[];
}