import {
    validateDate,
    validateDateXGreaterThanY,
    validateDateXSmallerThanY,
    validateEmail,
    validateInputTextMinMaxLen,
    validateTelephoneCellularNumber,
} from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type DittaKeys = keyof Ditta;

export const elementIdProps: DittaKeys[] = ["idDitta"];
export const elementRenderProps: DittaKeys[] = ["denominazione"];

export const allFields: Fields[] = [
    {
        field: "denominazione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
        show: true, titleKey: "denominationParam"
    },
    {
        field: "referente", type: "string", required: false,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
        show: false, titleKey: "referentParam"
    },
    {
        field: "indirizzo", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
        show: true, titleKey: "addressParam"
    },
    {
        field: "civico", type: "string", required: false,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, false), keyTradValidation: "size1to10ParamValidation",
        show: true, titleKey: "streetNumberParam"
    },
    {
        field: "telefono", type: "string", required: true,
        validate: (x: string, helperText: string) => validateTelephoneCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
        show: true, titleKey: "telephoneParam"
    },
    {
        field: "email", type: "string", required: false,
        validate: (x: string, helperText: string) => validateEmail(x, helperText, false), keyTradValidation: "emailParamValidation",
        show: true, titleKey: "emailParam"
    },
    {
        field: "numeroDelibera", type: "string", required: false,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
        show: false, titleKey: "deliberatesNumberParam"
    },
    {
        field: "dataInizio", type: "date", required: false, show: true, titleKey: "startDateParam",
        validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
        field2Validation: "dataFine",
        validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, false), keyTradValidation2: "smallerEqualThanJobStartDateValidation"
    },
    {
        field: "dataFine", type: "date", required: false, show: true, titleKey: "endDateParam",
        validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
        field2Validation: "dataInizio",
        validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, false), keyTradValidation2: "greaterEqualThanJobEndDateeValidation"
    }
]

export interface Ditta {
    idDitta: number,
    denominazione: string,
    indirizzo?: string,
    civico?: string,
    telefono?: string,
    referente?: string,
    email?: string,
    numeroDelibera?: string,
    dataInizio: string,
    dataFine: string,
    version: number
}