import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { Turno, elementIdProps, TurnoKeys } from '../../models/Turni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "turni";
const entity = "turni";

interface TurniState {
  statusValidTurni: StatusEnum,
  validTurni: Turno[],
  statusDeletedTurni: StatusEnum,
  deletedTurni: Turno[],
  statusAllTurni: StatusEnum,
  allTurni: Turno[],
  turno: Turno | null,
  lookupDescrizione: Lookup,
  lookupDescrizioneBreve: Lookup,
  error: string | null
}

const initialState: TurniState = {
  statusValidTurni: StatusEnum.Succeeded,
  validTurni: [],
  statusDeletedTurni: StatusEnum.Succeeded,
  deletedTurni: [],
  statusAllTurni: StatusEnum.Succeeded,
  allTurni: [],
  turno: null,
  lookupDescrizione: {},
  lookupDescrizioneBreve: {},
  error: null
}

// get cerca/turni/id/{idTurno}
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Turno;
});

// get cerca/turni/all/completa
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as Turno[];
});

// get cerca/turni/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Turno[];
});

// get cerca/turni/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Turno[];
});

// post inserisci/turni
export const insert = createAsyncThunk(microservice + '/insert', async (turno: Turno) => {
  const response = await create(turno, microservice, entity);
  return response.data as Turno;
});

// put modifica/turni
export const update = createAsyncThunk(microservice + '/update', async (turno: Turno) => {
  const response = await upd(turno, microservice, entity);
  return response.data as Turno;
});

// put elimina/turni/id/{idTurno}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/turni/id/{idTurno}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/turni/id/{idTurno}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const turniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: TurniState) => {
      return initialState;
    },
    resetError: (state: TurniState) => {
      state.error = initialState.error;
      state.statusValidTurni = initialState.statusValidTurni;
      state.statusDeletedTurni = initialState.statusDeletedTurni;
      state.statusAllTurni = initialState.statusAllTurni;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTurni = StatusEnum.Failed;
      }
      state.turno = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Turno>) => {
      state.statusValidTurni = StatusEnum.Succeeded;
      state.turno = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllTurni = StatusEnum.Failed;
      }
      state.lookupDescrizione = {};
      state.lookupDescrizioneBreve = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Turno[]>) => {
      state.statusAllTurni = StatusEnum.Succeeded;
      state.lookupDescrizione = createLookup(payload, 'idTurno', ['descrizione']);
      state.lookupDescrizioneBreve = createLookup(payload, 'idTurno', ['descrizioneBreve']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTurni = StatusEnum.Failed;
      }
      state.validTurni = [];
      state.lookupDescrizione = {};
      state.lookupDescrizioneBreve = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Turno[]>) => {
      state.statusValidTurni = StatusEnum.Succeeded;
      state.validTurni = payload ?? [];
      state.lookupDescrizione = createLookup(payload, 'idTurno', ['descrizione']);
      state.lookupDescrizioneBreve = createLookup(payload, 'idTurno', ['descrizioneBreve']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedTurni = StatusEnum.Failed;
      }
      state.deletedTurni = [];
      state.lookupDescrizione = {};
      state.lookupDescrizioneBreve = {};
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Turno[]>) => {
      state.statusDeletedTurni = StatusEnum.Succeeded;
      state.deletedTurni = payload ?? [];
      state.lookupDescrizione = createLookup(payload, 'idTurno', ['descrizione']);
      state.lookupDescrizioneBreve = createLookup(payload, 'idTurno', ['descrizioneBreve']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidTurni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTurni = StatusEnum.Failed;
      }
      state.turno = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Turno>) => {
      state.statusValidTurni = StatusEnum.Succeeded;
      state.turno = payload;
      state.validTurni = state.validTurni.concat([payload]);
    })

    // insert
    builder.addCase(update.pending, (state) => {
      state.statusValidTurni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTurni = StatusEnum.Failed;
      }
      state.turno = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Turno>) => {
      state.statusValidTurni = StatusEnum.Succeeded;
      state.validTurni = state.validTurni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.turno = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidTurni = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidTurni = StatusEnum.Succeeded;
      const deleted = state.validTurni.find(el => payload.ids.every(idObj => el[idObj.name as TurnoKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedTurni = state.deletedTurni.concat([deleted]);
      }
      state.validTurni = state.validTurni.filter(el => payload.ids.some(idObj => el[idObj.name as TurnoKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedTurni = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedTurni = StatusEnum.Succeeded;
      const valid = state.deletedTurni.find(el => payload.ids.every(idObj => el[idObj.name as TurnoKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validTurni = state.validTurni.concat([valid]);
      }
      state.deletedTurni = state.deletedTurni.filter(el => payload.ids.some(idObj => el[idObj.name as TurnoKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedTurni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedTurni = StatusEnum.Succeeded;
      state.deletedTurni = state.deletedTurni.filter(el => payload.ids.some(idObj => el[idObj.name as TurnoKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = turniSlice.actions;
export default turniSlice.reducer;