import { validateInputNumberMinMax, validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type FestivitaKeys = keyof Festivita;

export const elementIdProps: FestivitaKeys[] = ['mese', 'giorno'];
export const elementRenderProps: FestivitaKeys[] = ['mese', 'giorno'];

export const allFields: Fields[] = [
  {
    field: "mese", type: 'string', required: true, lookupField: true, editable: 'onAdd',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "monthParam"
  },
  {
    field: "giorno", type: "string", required: true, minVal: 1, editable: 'onAdd',
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 1, 31, false, helperText, true), keyTradValidation: "monthSelect",
    show: true, titleKey: "dateParam.0"
  },
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
]
export interface Festivita {
  mese: number;
  giorno: number;
  descrizione: string;
  version: number;
}