import { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import {
  allDotazioneNumericaFields,
  allDotazioneNominativaFields
} from "../../../models/DotazioneOrganica";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dotazioneOrganicaPath, notFoundPath } from "../../../utils/utilconst";
import Paper from "@material-ui/core/Paper";
import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  FormControlLabel,
  FormControl,
  Switch as MuiSwitch
} from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Switch, Route, Redirect } from "react-router-dom";
import { getDateDDMMYYYY } from '../../../utils/utilfunctions';
import { Fields } from "../../../models/Fields";
import { PDFExtraData } from '../../../models/Utils';
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import { AnagraficaSoggettoCurrentData, AnagraficaSoggettoCurrentDataKeys } from "../../../models/AnagraficaSoggetti";
import { fetchAllValidByIdStrutturaInForza, reset as resetAnagraficaSoggetti } from '../../../store/slices/anagraficaSoggettiSlice';
import { lookup as fetchLookupStrutture, reset as resetStrutture } from '../../../store/slices/struttureSlice';
import { lookupFiltered as fetchLookupDipartimenti, reset as resetDipartimentiFiltered } from '../../../store/slices/dipartimentiFilteredSlice';
import TabPanel from "../tabs/TabPanel/TabPanel";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    legendTopOffset: {
      marginTop: -75,
    }
  }),
);

interface DotazioneNumericaModel {
  idDipartimentoUnitaOperativa: number;
  unitaOperativaNome: string;
  dipartimentoDescrizione: string;
  qualificaDescrizioneBreve: string;
  totale: number;
}

enum TabIndex {
  DOTAZIONE_ORGANICA_NUMERICA,
  DOTAZIONE_ORGANICA_NOMINATIVA
}

const DotazioneOrganicaW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dateSelectName = useMemo(() => t("dateTitle"), [t]);
  const dateSelectLabel = useMemo(() => t("dateSelect"), [t]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dateSelectName]: null,
  });

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      presenzaData: states[dateSelectName],
    };
  }, [states, strSelectName, dateSelectName]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.presenzaData]);

  const idDipartimentoConst = "idDipartimentoUnitaOperativa";

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex | null>(TabIndex.DOTAZIONE_ORGANICA_NUMERICA);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  /**
   * Lunga Assenza controller
   */
  const [isLA, setIsLA] = useState<boolean>(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLA(state => !state);
  };

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string = '';
    if (d) {
      let month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [dateSelectName]: fieldDate } });
  };

  useEffect(() => {
    dispatch(fetchLookupStrutture());
  }, [dispatch]);

  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.presenzaData) {
      isFixedPropsChanged && dispatch(fetchAllValidByIdStrutturaInForza({ idStruttura: Number(fixedProps.idStruttura), presenzaData: fixedProps.presenzaData.toString() }));
      dispatch(fetchLookupDipartimenti({ idStruttura: Number(fixedProps.idStruttura) }));
    }
  }, [dispatch, fixedProps.idStruttura, fixedProps.presenzaData, isFixedPropsChanged]);

  /**
   * update allfields on tabValue change
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.DOTAZIONE_ORGANICA_NUMERICA:
        setAllFieldsState(allDotazioneNumericaFields);
        break;
      case TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA:
        setAllFieldsState(allDotazioneNominativaFields);
        break;
    }
  }, [tabValue]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allDotazioneNumericaFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2)
              resp2 = f.validate2(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                rowData[f.field2Validation as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate)
              return f.validate(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            return false
          }
        }

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render) {
          obj.render = f.render;
        }

        /*+++*/
        if (tabValue === TabIndex.DOTAZIONE_ORGANICA_NUMERICA)
          switch (f.field) {
            case idDipartimentoConst:
              obj.lookup = lookupDip;
              break;
          }
        /*+++*/
        return obj;
      })
    );
  }, [t, allFieldsState, excludedFieldInTable, tabValue, lookupDip]);

  const errorBE = useAppSelector(state => state.anagraficaSoggetti.error);
  const validAnagraficaSoggetti = useAppSelector((state) => state.anagraficaSoggetti.validAnagraficaSoggetti);
  const statusValidAnagraficaSoggetti = useAppSelector((state) => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);

  const [data, setData] = useState<Array<AnagraficaSoggettoCurrentData | DotazioneNumericaModel>>([]);

  const [currentValidAnagraficaSoggetti, setCurrentValidAnagraficaSoggetti] = useState(validAnagraficaSoggetti);
  useEffect(() => {
    setCurrentValidAnagraficaSoggetti(isLA ? validAnagraficaSoggetti : validAnagraficaSoggetti.filter(e => !e.combinazioneTurni || e.combinazioneTurni.toLowerCase() !== 'la'));
  }, [isLA, validAnagraficaSoggetti]);

  useEffect(() => {
    const newData: Array<DotazioneNumericaModel> = [];

    currentValidAnagraficaSoggetti.forEach(current => {
      let isUpdate = false;

      for (let i = 0; i < newData.length; i++) {
        if (newData[i].idDipartimentoUnitaOperativa === current.idDipartimentoUnitaOperativa &&
          newData[i].qualificaDescrizioneBreve === current.qualificaDescrizioneBreve &&
          newData[i].unitaOperativaNome === current.unitaOperativaNome) {
          newData[i].totale++;
          isUpdate = true;
          break;
        }
      }
      if (!isUpdate)
        newData.push({
          idDipartimentoUnitaOperativa: current.idDipartimentoUnitaOperativa,
          qualificaDescrizioneBreve: current.qualificaDescrizioneBreve ?? '',
          dipartimentoDescrizione: current.dipartimentoDescrizione,
          unitaOperativaNome: current.unitaOperativaNome,
          totale: 1,
        });
    });

    setData(tabValue === TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA
      ? currentValidAnagraficaSoggetti
      : newData);
  }, [currentValidAnagraficaSoggetti, tabValue]);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>(() => {
    const _idStruttura = fixedProps['idStruttura'];
    const _presenzaData = fixedProps['presenzaData'];

    return {
      head: {
        title: [t("structureTitle"), t("dateTitle")],
        value: [
          _idStruttura ? lookupStr[_idStruttura] : null,
          _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
        ]
      }
    }
  });

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    if (tabValue === TabIndex.DOTAZIONE_ORGANICA_NUMERICA) {
      setExportDataExtra(() => {
        const _idStruttura = fixedProps['idStruttura'];
        const _presenzaData = fixedProps['presenzaData'];

        return {
          head: {
            title: [t("structureTitle"), t("dateTitle"), t("includeLongAbsenceTitle")],
            value: [
              _idStruttura ? lookupStr[_idStruttura] : null,
              _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
              t(isLA ? 'yes' : 'no'),
            ]
          },
        }
      });
    } else {
      setExportDataExtra(() => {
        const _idStruttura = fixedProps['idStruttura'];
        const _presenzaData = fixedProps['presenzaData'];

        return {
          head: {
            title: [t("structureTitle"), t("dateTitle"), t("includeLongAbsenceTitle")],
            value: [
              _idStruttura ? lookupStr[_idStruttura] : null,
              _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
              t(isLA ? 'yes' : 'no'),
            ]
          }
        }
      });
    }
  }, [fixedProps, isLA, lookupDip, lookupStr, t, tabValue]);

  useEffect(() => {
    return () => {
      dispatch(resetDipartimentiFiltered());
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetStrutture());
    }
  }, [dispatch])

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 12 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {
              states[strSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    variant="inline"
                    label={dateSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    autoOk={true}
                    value={states[dateSelectName] ? states[dateSelectName] : null}
                    fullWidth
                  // clearable={true}
                  // clearLabel={t('clearLabel')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              states[strSelectName] && states[dateSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={isLA}
                        onChange={handleChange}
                        name="lungaAssenza"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={t("includeLongAbsenceLabel")}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[strSelectName] &&
            states[dateSelectName] ? (
            <>
              <Switch>
                <Route path={dotazioneOrganicaPath} exact>
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("numericalTabLabel")} {...handleTabChange(TabIndex.DOTAZIONE_ORGANICA_NUMERICA)} />
                        <Tab label={t("nominativeTabLabel")} {...handleTabChange(TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA)} />
                      </Tabs>
                    </AppBar>
                    {/* DOTAZIONE ORGANICA NUMERICA */}
                    <TabPanel value={tabValue} index={TabIndex.DOTAZIONE_ORGANICA_NUMERICA}>
                      <ReadOnlyMaterialTable
                        title={t("numericalOrganicEquipmentTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllValidByIdStrutturaInForza}
                        statusValid={statusValidAnagraficaSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={false}
                        localizedDatePicker={true}
                        extraOptions={{
                          maxBodyHeight: 460,
                        }}
                      />
                    </TabPanel>
                    {/* DOTAZIONE ORGANICA NOMINATIVA */}
                    <TabPanel value={tabValue} index={TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA}>
                      <ReadOnlyMaterialTable
                        title={t("nameOrganicEquipmentTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllValidByIdStrutturaInForza}
                        statusValid={statusValidAnagraficaSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={false}
                        localizedDatePicker={true}
                        extraOptions={{
                          maxBodyHeight: 460,
                        }}
                      />
                    </TabPanel>
                  </>
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={dotazioneOrganicaPath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : dateSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={dotazioneOrganicaPath} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};

export default DotazioneOrganicaW;