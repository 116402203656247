import { validateInputNumberMin, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { LookupAnagraficaSoggettoElem } from "./AnagraficaSoggetti";
import { Fields } from "./Fields";

export type AssenzaKeys = keyof Assenza;

export const elementIdProps: AssenzaKeys[] = ["idTurnoAssenza"];
export const elementRenderProps: AssenzaKeys[] = ["descrizioneBreve"];

export const allFields: Fields[] = [
    {
        field: "descrizioneBreve", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, true), keyTradValidation: "size1to10ParamValidation",
        show: true, titleKey: "descriptionShortParam"
    },
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
        show: true, titleKey: "descriptionParam"
    },
    {
        field: "ore", type: "numeric", required: false,
        validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterThanZeroValidation",
        show: true, titleKey: "hoursParam"
    },
    {
        field: "assenzaProgrammata", type: "boolean", required: false, show: true, titleKey: "plannedAbsenceParam"
    },
    {
        field: "lungaAssenza", type: "boolean", required: false, show: true, titleKey: "longAbsenceParam"
    },
    {
        field: "richiestaApprovazione", type: "boolean", required: false, show: true, titleKey: "approvalRequest"
    },
    {
        field: "assenzaParziale", type: "boolean", required: false, show: true, titleKey: "Assenza Parziale"
    },

]
export interface Assenza {
    idStruttura: number;
    strutturaNome: string;
    idDipartimento: number;
    dipartimentoNome: string;
    idUnitaOperativa: number;
    unitaOperativaNome: string;
    idAnagraficaSoggetto: number;
    anagraficaSoggetto: LookupAnagraficaSoggettoElem[];
    idTurnoAssenza: number,
    descrizioneBreve: string,
    descrizione: string,
    ore?: number,
    assenzaProgrammata?: boolean,
    lungaAssenza?: boolean,
    richiestaApprovazione: boolean,
    assenzaParziale: boolean,
    approvazioneRichiesta: boolean;
    approvazioneRichiestaMotivo: string;
    approvazioneConcessaData: string;
    approvazioneNegataData: string;
    version: number
}