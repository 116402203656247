import { useEffect, useState } from "react";
import { StatusEnum } from "../../../../models/Utils";

const useInitDeleted = (
    statusDeleted: StatusEnum,
    dispatchCallbackDeleted: ()=>void ) => {

    const [refDeletedBool, setRefDeletedBool] = useState<boolean>(false);

    useEffect(() => {
        statusDeleted === StatusEnum.Loading && setRefDeletedBool(true);
    }, [
        statusDeleted,
        setRefDeletedBool
    ]);
    useEffect(() => {
        if (refDeletedBool) {
            if (statusDeleted === StatusEnum.Succeeded) {
                dispatchCallbackDeleted();
            } if (statusDeleted === StatusEnum.Succeeded
                || statusDeleted === StatusEnum.Failed) {
                    setRefDeletedBool(false);
            }
        }
    }, [
        refDeletedBool,
        setRefDeletedBool,
        dispatchCallbackDeleted,
        statusDeleted
    ]);
}

export default useInitDeleted;