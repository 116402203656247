import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, UnitaOperativa, allFields, UnitaOperativaKeys } from "../../../models/UnitaOperative";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllDeletedByIdStrutturaAndIdDipartimento, fetchAllValidByIdStrutturaAndIdDipartimento, insertFiltered, logicalDelFiltered, physicalDelFiltered, restoreFiltered, updateFiltered, resetError as resetErrorUnitaOperativeFiltered } from "../../../store/slices/unitaOperativeFilteredSlice";
import { lookupFiltered, resetError as resetErrorDipartimentiFiltered } from '../../../store/slices/dipartimentiFilteredSlice';
import { fetchAllValid as fetchCentriDiCosto, resetError as resetErrorCentroDiCosto } from "../../../store/slices/centriDiCostoSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { unitaOperativePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";

interface UnitaOperativeDiDipartimentoWProps {
  idStruttura: number,
  idDipartimento: number
}

const UnitaOperativeDiDipartimentoW = ({ idStruttura, idDipartimento }: UnitaOperativeDiDipartimentoWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, unitaOperativePath));
  const errorBE = useAppSelector(state => state.unitaOperativeFiltered.error);

  const idStrConst = "idStruttura";
  const idDipConst = "idDipartimento";
  const idCostCenterConst = "idCentroDiCosto";

  const fixedProps = useMemo(() => {
    return {
      [idStrConst]: idStruttura,
      [idDipConst]: idDipartimento
    }
  }, [idStruttura, idDipartimento]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCentriDiCosto());
    dispatch(lookupFiltered({ idStruttura }));
  }, [dispatch, idStruttura]);


  const lookup = useAppSelector(state => state.centriDiCosto.lookup);
  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDipFiltered = useAppSelector(state => state.dipartimentiFiltered.lookup);

  const title = t('operatingUnitTitle');

  const resetError = useCallback(() => {
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorCentroDiCosto());
  }, [dispatch]);


  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<UnitaOperativa>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<UnitaOperativa> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as UnitaOperativaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.field === idCostCenterConst) obj.lookup = lookup;
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, lookup, t]);

  const validUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.validUnitaOperativeFiltered);
  const deletedUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.deletedUnitaOperativeFiltered);

  const statusValidUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.statusValidUnitaOperativeFiltered);
  const statusDeletedUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.statusDeletedUnitaOperativeFiltered);

  const [data, setData] = useState<Array<UnitaOperativa>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validUnitaOperativeFiltered) : setData(deletedUnitaOperativeFiltered);
  }, [validUnitaOperativeFiltered, deletedUnitaOperativeFiltered, toggleValidDeleted]);


  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        if (exportDataExtra.head)
          exportDataExtra.head['Struttura'] = lookupStr[fixedProps[elem]];
        break;
      case 'idDipartimento':
        if (exportDataExtra.head)
          exportDataExtra.head['Dipartimento'] = lookupDipFiltered[fixedProps[elem]];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return <CrudMaterialTable
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchAllValidByIdStrutturaAndIdDipartimento}
    fetchAllDeleted={fetchAllDeletedByIdStrutturaAndIdDipartimento}
    insert={insertFiltered}
    logicalDel={logicalDelFiltered}
    physicalDel={physicalDelFiltered}
    restore={restoreFiltered}
    update={updateFiltered}
    dataValid={toggleValidDeleted}
    setToggleValidDeleted={setToggleValidDeleted}
    statusValid={statusValidUnitaOperativeFiltered}
    statusDeleted={statusDeletedUnitaOperativeFiltered}
    errorBE={errorBE}
    fixedProps={fixedProps}
    exportDataExtra={exportDataExtra}
    logoUri={logoUri}
    nullableTextFields={true}
    resetErrorCallback={resetError}
    extraOptions={{
      maxBodyHeight: 460,
    }}
  />
}
export default UnitaOperativeDiDipartimentoW;