import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { allFields, elementIdProps, elementRenderProps, ModelliTerminaleCausali, ModelliTerminaleCausaliKeys } from "../../../models/ModelliTerminaleCausali";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeletedByIdModelloTerminale,
  fetchAllValidByIdModelloTerminale,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetCausali,
  resetError as resetErrorCausali
} from "../../../store/slices/modelliTerminaleCausaliSlice";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields } from "../../../models/Fields";
import { sortingStringCI } from "../../../utils/utilfunctions";
import { fetchAllValid as fetchAllValidAssenze } from "../../../store/slices/assenzeSlice";

interface ModelliTerminaleCausaliProps {
  idModelloTerminale: number,
  abilitazione: number
}

const ModelliTerminaleCausaliW = (props: ModelliTerminaleCausaliProps) => {
  const { idModelloTerminale, abilitazione } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const errorBE = useAppSelector(state => state.causali.error);

  const lookupAssenze = useAppSelector(state => state.assenze.lookupValidDescrizione);

  const resetError = useCallback(() => {
    dispatch(resetErrorCausali());
  }, [dispatch])

  const title = t('explanationTitle');

  const fixedProps = useMemo(() => {
    return {
      idModelloTerminale
    }
  }, [idModelloTerminale]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ModelliTerminaleCausali>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ModelliTerminaleCausali> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ModelliTerminaleCausaliKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);

        if (f.lookupField) {
          switch (f.field) {
            case 'idTurnoAssenza':
              obj.lookup = lookupAssenze;
              break;
          }
        }

        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, lookupAssenze, t]);

  const validCausali = useAppSelector(state => state.causali.validCausali);
  const deletedCausali = useAppSelector(state => state.causali.deletedCausali);

  const statusValidCausali = useAppSelector(state => state.causali.statusValidCausali);
  const statusDeletedCausali = useAppSelector(state => state.causali.statusDeletedCausali);

  const [data, setData] = useState<ModelliTerminaleCausali[]>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validCausali) : setData(deletedCausali);
  }, [validCausali, deletedCausali, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetCausali());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fixedProps && Object.values(fixedProps).every(e => !!e)) {
      dispatch(fetchAllDeletedByIdModelloTerminale(fixedProps));
      dispatch(fetchAllValidByIdModelloTerminale(fixedProps));
      dispatch(fetchAllValidAssenze());
    }
  }, [dispatch, fixedProps]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValidByIdModelloTerminale}
      fetchAllDeleted={fetchAllDeletedByIdModelloTerminale}
      fixedProps={fixedProps}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidCausali}
      statusDeleted={statusDeletedCausali}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default ModelliTerminaleCausaliW;