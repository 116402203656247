import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { ModelliTerminale, elementIdProps, elementRenderProps, ModelliTerminaleKeys } from '../../models/ModelloTerminale'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "modelliterminale";
const entity = "modelliterminale";

interface ModelloTerminaleState {
  statusValidModelloTerminale: StatusEnum,
  validModelliTerminali: ModelliTerminale[],
  statusDeletedModelloTerminale: StatusEnum,
  deletedModelliTerminali: ModelliTerminale[],
  modelloTerminale: ModelliTerminale | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ModelloTerminaleState = {
  statusValidModelloTerminale: StatusEnum.Succeeded,
  validModelliTerminali: [],
  statusDeletedModelloTerminale: StatusEnum.Succeeded,
  deletedModelliTerminali: [],
  modelloTerminale: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as ModelliTerminale;
});

export const fetchByCodice = createAsyncThunk(entity + '/fetchByCodice', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)
  args.unshift('codice');

  const response = await getById(microservice, entity, args);
  return response.data as ModelliTerminale;
});

export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as ModelliTerminale[];
});

export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as ModelliTerminale[];
});

export const insert = createAsyncThunk(entity + '/insert', async (modelloTerminale: ModelliTerminale) => {
  const response = await create(modelloTerminale, microservice, entity);
  return response.data as ModelliTerminale;
});

export const update = createAsyncThunk(entity + '/update', async (modelloTerminale: ModelliTerminale) => {
  const response = await upd(modelloTerminale, microservice, entity);
  return response.data as ModelliTerminale;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const modelloTerminaleSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ModelloTerminaleState) => {
      return initialState;;
    },
    resetError: (state: ModelloTerminaleState) => {
      state.error = initialState.error;
      state.statusValidModelloTerminale = initialState.statusValidModelloTerminale;
      state.statusDeletedModelloTerminale = initialState.statusDeletedModelloTerminale;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
      state.modelloTerminale = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      state.modelloTerminale = payload;
    })

    // fetchByCodice
    builder.addCase(fetchByCodice.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(fetchByCodice.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
      state.modelloTerminale = null;
    })
    builder.addCase(fetchByCodice.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      state.modelloTerminale = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
      state.validModelliTerminali = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale[]>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      state.validModelliTerminali = payload ?? [];
      state.lookup = createLookup(payload, 'idModelloTerminale', elementRenderProps);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedModelloTerminale = StatusEnum.Failed;
      }
      state.deletedModelliTerminali = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale[]>) => {
      state.statusDeletedModelloTerminale = StatusEnum.Succeeded;
      state.deletedModelliTerminali = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
      state.modelloTerminale = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      state.modelloTerminale = payload;
      state.validModelliTerminali = state.validModelliTerminali.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
      state.modelloTerminale = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ModelliTerminale>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      state.validModelliTerminali = state.validModelliTerminali.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.modelloTerminale = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidModelloTerminale = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidModelloTerminale = StatusEnum.Succeeded;
      const deleted = state.validModelliTerminali.find(el => payload.ids.every(idObj => el[idObj.name as ModelliTerminaleKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedModelliTerminali = state.deletedModelliTerminali.concat([deleted]);
      }
      state.validModelliTerminali = state.validModelliTerminali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedModelloTerminale = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedModelloTerminale = StatusEnum.Succeeded;
      const valid = state.deletedModelliTerminali.find(el => payload.ids.every(idObj => el[idObj.name as ModelliTerminaleKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validModelliTerminali = state.validModelliTerminali.concat([valid]);
      }
      state.deletedModelliTerminali = state.deletedModelliTerminali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedModelloTerminale = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedModelloTerminale = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedModelloTerminale = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedModelloTerminale = StatusEnum.Succeeded;
      state.deletedModelliTerminali = state.deletedModelliTerminali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = modelloTerminaleSlice.actions;
export default modelloTerminaleSlice.reducer;