import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StrutturaTurnoOre } from '../../models/StruttureTurniOre';
import { Ids, StatusEnum } from '../../models/Utils';
import { cancelById, create, definitiveDeleteById, restoreById, upd } from '../../services/services.service';

const microservice = "strutture";
const entity = "struttureturniore";

interface StruttureTurniOreState {
    statusStrutturaTurnoOre: StatusEnum,
    strutturaTurnoOre: StrutturaTurnoOre | null,
    error: string | null
}

const initialState: StruttureTurniOreState = {
    statusStrutturaTurnoOre: StatusEnum.Succeeded,
    strutturaTurnoOre: null,
    error: null
}

export const insert = createAsyncThunk(entity + '/insert', async (strutturaTurnoOre: StrutturaTurnoOre) => {
    const response = await create(strutturaTurnoOre, microservice, entity);
    return response.data as StrutturaTurnoOre;
});

export const update = createAsyncThunk(entity + '/update', async (strutturaTurnoOre: StrutturaTurnoOre) => {
    const response = await upd(strutturaTurnoOre, microservice, entity);
    return response.data as StrutturaTurnoOre;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
    await cancelById(ids, microservice, entity);
    return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
    await restoreById(ids, microservice, entity);
    return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const struttureTurniOreSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        clearStatus: state => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
            state.strutturaTurnoOre = null;
            state.error = null;
        },
        reset: (state: StruttureTurniOreState) => {
            return initialState;
        },
        resetError: (state: StruttureTurniOreState) => {
            state.error = initialState.error;
            state.statusStrutturaTurnoOre = initialState.statusStrutturaTurnoOre
        }
    },
    extraReducers: builder => {
        builder.addCase(insert.pending, (state) => {
            state.statusStrutturaTurnoOre = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusStrutturaTurnoOre = StatusEnum.Failed;
            state.strutturaTurnoOre = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<StrutturaTurnoOre>) => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
            state.strutturaTurnoOre = payload;
        })
        builder.addCase(update.pending, (state) => {
            state.statusStrutturaTurnoOre = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusStrutturaTurnoOre = StatusEnum.Failed;
            state.strutturaTurnoOre = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<StrutturaTurnoOre>) => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
            state.strutturaTurnoOre = payload;
        })
        builder.addCase(logicalDel.pending, (state) => {
            state.statusStrutturaTurnoOre = StatusEnum.Loading;
        })
        builder.addCase(logicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusStrutturaTurnoOre = StatusEnum.Failed;
        })
        builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
        })
        builder.addCase(restore.pending, (state) => {
            state.statusStrutturaTurnoOre = StatusEnum.Loading;
        })
        builder.addCase(restore.rejected, (state, action) => {
            state.statusStrutturaTurnoOre = StatusEnum.Failed;
            state.error = (action.error.message) ? action.error.message : "";
        })
        builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
        })
        builder.addCase(physicalDel.pending, (state) => {
            state.statusStrutturaTurnoOre = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusStrutturaTurnoOre = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusStrutturaTurnoOre = StatusEnum.Succeeded;
        })
    }
});

export const { reset, resetError, clearStatus } = struttureTurniOreSlice.actions;
export default struttureTurniOreSlice.reducer;