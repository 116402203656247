import React from 'react';
import { Image, View, Text, StyleSheet } from 'react-pdf-renderer-v2';
import { calculateWidth, getImageDimensionsFromBase64 } from '../../../../../../utils/utilfunctions';
import { Riepiloghi } from '../../../AnagraficaSoggettiW';

interface HeaderProps {
  logo: string,
  riepiloghi: Riepiloghi,
}

const Header = (props: HeaderProps) => {
  const { logo, riepiloghi } = props;

  const anagrafica = riepiloghi.anagraficaSoggetto;

  const VIEW_HEIGHT = 30;
  const metadata = getImageDimensionsFromBase64(logo);

  return (
    <View
      style={styles.container}
    >
      <View
        style={{
          height: VIEW_HEIGHT,
          width: calculateWidth(metadata?.width ?? 0, metadata?.height ?? 0, VIEW_HEIGHT)
        }}
      >
        <Image
          src={
            (logo?.startsWith('data')
              ? ''
              : 'data:image/' + metadata?.type + ';base64,')
            + logo
          }
        />
      </View>
      <View style={styles.name}>
        <Text style={styles.value}>{anagrafica.nome + ' ' + anagrafica.cognome}</Text>
      </View>
    </View>
  )
}

export default Header;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  value: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 15,
    flex: 3,
    alignSelf: 'flex-end'
  },
  name: {
    flex: 1,
  }
});