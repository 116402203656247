import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type MotivoKeys = keyof Motivo;

export const elementIdProps: MotivoKeys[] = ["idMotivo"];
export const elementRenderProps: MotivoKeys[] = ["descrizione"];

export const allFields: Fields[] = [
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
        show: true, titleKey: "descriptionParam"
    }
]
export interface Motivo {
    idMotivo: number,
    descrizione: string,
    version: number
}