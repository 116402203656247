import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type CodiceVisitaKeys = keyof CodiceVisita;

export const elementIdProps: CodiceVisitaKeys[] = ["idCodiceVisita"];
export const elementRenderProps: CodiceVisitaKeys[] = ["descrizione"];

export const allFields: Fields[] = [
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
        show: true, titleKey: "descriptionParam"
    },
    {
        field: "controlloScadenze", type: "boolean", required: false, show: true, titleKey: "controlloScadenze"
    }
]
export interface CodiceVisita {
    idCodiceVisita: number,
    descrizione: string,
    controlloScadenze: boolean,
    version: number
}