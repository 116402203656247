import { Column } from "@material-table/core";
import { Box, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Fields } from "../../../models/Fields";
import { allFields, elementIdProps, elementRenderProps, AnagraficaSoggettiFormazione, AnagraficaSoggettiFormazioneKeys } from "../../../models/AnagraficaSoggettiFormazione";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagraficaFormazionePath } from "../../../utils/utilconst";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchAllLookupByAbilitazione,
  fetchExtendedByIdAndDate,
  reset as resetAnagraficaSoggetti,
  resetError as resetErrorAnagraficaSoggetti
} from '../../../store/slices/anagraficaSoggettiSlice';
import {
  fetchAll as fetchAllFormazioni,
  fetchAllValid as fetchAllValidFormazioni,
  reset as resetFormazione,
  resetError as resetErrorFormazione
} from '../../../store/slices/formazioneSlice';
import {
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  reset as resetAnagraficaSoggettiFormazione,
  resetError as resetErrorAnagraficaSoggettiFormazione
} from '../../../store/slices/anagraficaSoggettiFormazioneSlice';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm from "../forms/GeneralForm";
import i18n from "../../../i18n";
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";

const FormazioneDipendentiW = () => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiFormazione());
    dispatch(resetErrorFormazione());
  }, [dispatch]);

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagraficaFormazionePath));

  const title = t('trainingTitle');

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);

  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup);
  const lookupFormazione = useAppSelector(state => state.formazione.lookup);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const [obj, setObj] = useState<AnagraficaSoggettiFormazione | null>(null);

  const [states, setStates] = useState<{
    [selectName: string]: string | number | null;
  }>({ [soggettoSelectName]: null });

  const fixedProps = useMemo(() => {
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
    };
  }, [states, soggettoSelectName]);

  useEffect(() => {
    dispatch(fetchAllLookupByAbilitazione());
    dispatch(fetchAllFormazioni()); // la chiamata serve per il lookup
  }, [dispatch]);

  useEffect(() => {
    if (history.location.pathname === anagraficaFormazionePath)
      dispatch(fetchAllFormazioni());
  }, [dispatch, history.location.pathname]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiFormazione>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiFormazione> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiFormazioneKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          if (f.field === 'idFormazione')
            obj.lookup = lookupFormazione;
        }

        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, lookupFormazione, t]);

  const insertCallback = () => {
    dispatch(fetchAllValidFormazioni());
    history.push(anagraficaFormazionePath + componentInsertPath);
  };

  const updateDetailCallback = (anagraficaSoggetto: AnagraficaSoggettiFormazione) => {
    setObj(anagraficaSoggetto);
    history.push(anagraficaFormazionePath + componentTabsPath);
  };

  const validFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.validAnagraficaSoggettiFormazioni);
  const deletedFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.deletedAnagraficaSoggettiFormazioni);

  const statusValidFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusValidAnagraficaSoggettiFormazioni);
  const statusDeletedFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusDeletedAnagraficaSoggettiFormazioni);
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);
  const errorBE = useAppSelector(state => state.anagraficaSoggettiFormazione.error);
  const statusAnagraficaSoggettoEstesa = useAppSelector(state => state.anagraficaSoggetti.statusAnagraficaSoggettoEstesa)
  const [data, setData] = useState<AnagraficaSoggettiFormazione[]>([]);

  useEffect(() => {
    toggleValidDeleted
      ? setData(anagraficaSoggettiEstesa?.anagraficaSoggettiFormazione ?? [])
      : setData(anagraficaSoggettiEstesa?.anagraficaSoggettiFormazioneEliminati ?? [])
  }, [validFormazione, deletedFormazione, toggleValidDeleted, dispatch, anagraficaSoggettiEstesa?.anagraficaSoggettiFormazione, anagraficaSoggettiEstesa?.anagraficaSoggettiFormazioneEliminati]);

  useInitValid(
    statusValidFormazione,
    () => dispatch(fetchExtendedByIdAndDate({
      idAnagraficaSoggetto: fixedProps.idAnagraficaSoggetto as number,
    }))
  );
  useInitDeleted(
    statusDeletedFormazione,
    () => dispatch(fetchExtendedByIdAndDate({
      idAnagraficaSoggetto: fixedProps.idAnagraficaSoggetto as number,
    }))
  );

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetAnagraficaSoggettiFormazione());
      dispatch(resetFormazione());
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <SimpleIdSelects
            selectsArray={[
              {
                name: soggettoSelectName,
                lookup: lookupAnagraficaSoggettoPerAbilitazione,
                label: soggettoSelectLabel,
                disabled: false,
                breakpoints: { xs: 12, md: 6, sm: 12, lg: 4 }
              },
            ]}
            states={states}
            setStates={setStates}
          />
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[soggettoSelectName]
          ? (
            <Switch>
              <Route path={anagraficaFormazionePath} exact>
                <CrudMaterialTable
                  abilitazione={abilitazione}
                  title={title}
                  columns={columns}
                  data={data}
                  elementIdProps={elementIdProps}
                  elementRenderProps={elementRenderProps}
                  fetchAllValid={fetchExtendedByIdAndDate}
                  fetchAllDeleted={fetchExtendedByIdAndDate}
                  insert={insert}
                  logicalDel={logicalDel}
                  physicalDel={physicalDel}
                  restore={restore}
                  update={update}
                  dataValid={toggleValidDeleted}
                  setToggleValidDeleted={setToggleValidDeleted}
                  statusValid={statusAnagraficaSoggettoEstesa}
                  statusDeleted={statusAnagraficaSoggettoEstesa}
                  errorBE={errorBE}
                  logoUri={logoUri}
                  fixedProps={fixedProps}
                  localizedDatePicker={true}
                  insertCallback={insertCallback}
                  updateCallback={updateDetailCallback}
                  resetErrorCallback={resetError}
                  extraOptions={{
                    maxBodyHeight: 460,
                  }}
                />
              </Route>
              <Route path={anagraficaFormazionePath + componentInsertPath} exact>
                <InnerComponentViews
                  abilitazione={abilitazione}
                  mainUri={anagraficaFormazionePath}
                  tabsUri={anagraficaFormazionePath + componentInsertPath}
                  tabsView={false}
                  buttonTitle={title}
                  info1={t("newTrainingTitle")}
                  tabs={[
                    {
                      label: t(''),
                      tabPath: '',
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf:
                        <GeneralForm
                          readOnly={false}
                          language={i18n.language}
                          componentPath={anagraficaFormazionePath}
                          action={insert}
                          fixedProps={fixedProps}
                          status={statusValidFormazione}
                          error={errorBE}
                          update={false}
                          fields={allFieldsState}
                          translate={t}
                          lookups={{
                            idFormazione: lookupFormazione
                          }}
                        />,
                      componentElse: <Redirect to={anagraficaFormazionePath} />,
                    }
                  ]}
                />
              </Route>
              <Route path={anagraficaFormazionePath + componentTabsPath} exact>
                <InnerComponentViews
                  abilitazione={abilitazione}
                  mainUri={anagraficaFormazionePath}
                  tabsUri={anagraficaFormazionePath + componentTabsPath}
                  tabsView={false}
                  buttonTitle={t("attendanceTitle")}
                  info1={t("newAttendanceTitle")}
                  tabs={[
                    {
                      label: t(''),
                      tabPath: '',
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf:
                        <GeneralForm
                          readOnly={false}
                          language={i18n.language}
                          componentPath={anagraficaFormazionePath}
                          action={update}
                          fixedProps={fixedProps}
                          status={statusValidFormazione}
                          error={errorBE}
                          update={true}
                          fields={allFieldsState}
                          translate={t}
                          lookups={{ idFormazione: lookupFormazione }}
                          obj={obj}
                        />,
                      componentElse: <Redirect to={anagraficaFormazionePath} />,
                    }
                  ]}
                />
              </Route>
            </Switch>
          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {soggettoSelectLabel}
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>
    </>
  )
}
export default FormazioneDipendentiW;