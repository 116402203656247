import { Fields } from "./Fields";
import { User } from "./User";

export type ChatKeys = keyof Chat;

export const elementIdProps: ChatKeys[] = ['idApplicazioneSoftware', 'idUtente1'];
export const elementRenderProps: ChatKeys[] = [];

export const allFields: Fields[] = []

export interface Chat {
  idApplicazioneSoftware: number,
  idUtente1: number,
  idUtente2: number,

  organizzazioneNome?: string,
  appSoftwareDescrizione?: string,
  nominativo1?: User,
  nominativo2?: User,

  version: number
}