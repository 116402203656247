import { Fields } from "./Fields";

export const elementIdProps = [];
export const elementRenderProps = [];

export const all104Fields: Fields[] = [
    {
        field: "cognome", type: 'string', titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", type: 'string', titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "qualificaDescrizioneBreve", type: 'string', titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", type: 'string', titleKey: "operationalUnitParam", required: false, show: true,
    },
    {
        field: "legge104Numero", type: 'numeric', titleKey: "law104NumberParam", required: false, show: true,
    },
];

export const allEntratiFields: Fields[] = [
    {
        field: "cognome", type: 'string', titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", type: 'string', titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "qualificaDescrizioneBreve", type: 'string', titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", type: 'string', titleKey: "operationalUnitParam", required: false, show: true,
    },
    {
        field: "dataPresaServizio", type: 'date', titleKey: "serviceTakenParam", required: false, show: true,
    },
];

export const allUscitiFields: Fields[] = [
    {
        field: "cognome", type: 'string', titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", type: 'string', titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "qualificaDescrizioneBreve", type: 'string', titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", type: 'string', titleKey: "operationalUnitParam", required: false, show: true,
    },
    {
        field: "dataFineIncarico", type: 'date', titleKey: "jobEndDateParam", required: false, show: true,
    },
    {
        field: "motivoDescrizione", type: 'string', titleKey: "reasonParam", required: false, show: true,
    },
];