import React from 'react'
import { Riepiloghi } from '../../../AnagraficaSoggettiW'
import { View, Image, StyleSheet, Text } from 'react-pdf-renderer-v2';
import { getDateDDMMYYYY, getImageDimensionsFromBase64 } from '../../../../../../utils/utilfunctions';
import { useTranslation } from 'react-i18next';

const ProfileImageView = View;
const InfoView = View;

// Table
const TRow = View;

interface MainProps {
  riepiloghi: Riepiloghi,
}

const Main = (props: MainProps) => {
  const { riepiloghi } = props;
  const anagrafica = riepiloghi.anagraficaSoggetto;
  const defaultValue = '-';

  const { t } = useTranslation();

  const metadata = getImageDimensionsFromBase64(anagrafica.fotoSegnaletica);

  return (
    <View style={styles.container}>
      <ProfileImageView
        style={{
          // height: 120 // calculated height
          width: 60,
          justifyContent: 'center'
        }}
      >
        {
          metadata &&
          <Image
            src={
              (anagrafica.fotoSegnaletica?.startsWith('data')
                ? ''
                : 'data:image/' + metadata?.type + ';base64,')
              + anagrafica.fotoSegnaletica
            }
          />
        }
      </ProfileImageView>
      <InfoView
        style={styles.infoContainer}
      >
        <TRow debug={false} style={styles.rowLabel}>
          <Text style={styles.label}>{t('numberLabel')}</Text>
          <Text style={styles.value}>{anagrafica.matricola}</Text>
        </TRow>
        <TRow debug={false} style={styles.rowLabel}>
          <Text style={styles.label}>{t('departmentLabel')}</Text>
          <Text style={styles.value}>{anagrafica.unitaOperativaNome}</Text>
        </TRow>
        <TRow debug={false} style={styles.rowLabel}>
          <Text style={styles.label}>{t('qualificationLabel')}</Text>
          <Text style={[styles.value, { textOverflow: 'ellipsis', maxLines: 1 }]}>{anagrafica.qualificaDescrizione}</Text>
        </TRow>
        <TRow debug={false} style={styles.rowLabel}>
          <Text style={styles.label}>{t('hireLabel')}</Text>
          <Text style={styles.value}>{anagrafica.dataAssunzione ? getDateDDMMYYYY(new Date(anagrafica.dataAssunzione)) : defaultValue}</Text>
        </TRow>

      </InfoView>
    </View>
  )
}

export default Main;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10
  },
  infoContainer: {
    flex: 1,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    flex: 1,
    alignSelf: 'baseline',
    fontFamily: 'Helvetica-Oblique',
    fontSize: 5
  },
  value: {
    flex: 2,
    alignSelf: 'flex-start',
    fontFamily: 'Helvetica-Bold',
    fontSize: 6
  },
  rowLabel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
});