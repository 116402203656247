import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type ModelliTerminaleKeys = keyof ModelliTerminale;

export const elementIdProps: ModelliTerminaleKeys[] = ["idModelloTerminale"];
export const elementRenderProps: ModelliTerminaleKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "codice", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "codeParam"
  },
  {
    field: "geolocalizzazione", type: "boolean", required: false, show: true, titleKey: "geocodeParam"
  },
]

export interface ModelliTerminale {
  idModelloTerminale: number;
  codice: string;
  descrizione: string;
  geolocalizzazione: boolean;
  version: number;
}