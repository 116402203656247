import { validateAsDigitsByLength, validateInputTextFixedLen, validateInputTextMinMaxLen, validateTelephoneCellularNumber } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { StruttureFestivita } from "./StruttureFestivita";
import { StrutturaTurnoOre } from "./StruttureTurniOre";

export type StrutturaKeys = keyof Struttura;
export type StrutturaExtendedKeys = keyof StrutturaExtended;

export const elementIdProps: StrutturaKeys[] = ["idStruttura"];
export const elementRenderProps: StrutturaKeys[] = ["nome"];

export const allFields: Fields[] = [
  {
    field: "nome", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 40, false, helperText, true), keyTradValidation: "size3to40ParamValidation",
    show: true, titleKey: "nameParam", colsNum: 6
  },

  {
    field: "indirizzo", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 30, false, helperText, true), keyTradValidation: "size3to30ParamValidation",
    show: true, titleKey: "addressParam", colsNum: 6
  },

  {
    field: "cap", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextFixedLen(x, 5, helperText, true), keyTradValidation: "size5to5ParamValidation",
    show: true, titleKey: "capParam", colsNum: 6
  },

  {
    field: "citta", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
    show: true, titleKey: "cityParam", colsNum: 6
  },

  {
    field: "telefono", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateTelephoneCellularNumber(x, helperText, false), keyTradValidation: "cellTelNumberParamValidation",
    show: true, titleKey: "telephoneParam", colsNum: 6
  },

  {
    field: "codicefiscale", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateAsDigitsByLength(x, 11, helperText, false), keyTradValidation: "size11to11DigitsParamValidation",
    show: true, titleKey: "fiscalCodeParam", colsNum: 6
  },

  {
    field: "partitaiva", type: "string", required: false,
    validate: (x: string | undefined, helperText: string) => validateInputTextFixedLen(x, 11, helperText, false), keyTradValidation: "size11to11ParamValidation",
    show: true, titleKey: "VATParam", colsNum: 6
  },
  {
    field: "orarioSettimanale", type: 'numeric', titleKey: "hoursWeek", required: false, show: true,
  },
  {
    field: "note", type: "string", required: false,
    show: true, titleKey: "noteParam", colsNum: 6
  },

  {
    field: "rigo1", type: "string", required: false,
    show: false, titleKey: "row1Param", colsNum: 6
  },

  {
    field: "rigo2", type: "string", required: false,
    show: false, titleKey: "row2Param", colsNum: 6
  },

  {
    field: "rigo3", type: "string", required: false,
    show: false, titleKey: "row3Param", colsNum: 6
  },

  {
    field: "logo1", type: "image", titleKey: "logo1Param", required: false,
    show: false, colsNum: 6
  },

  {
    field: "logo2", type: "image", titleKey: "logo2Param", required: false,
    show: false, colsNum: 6
  }
]

export interface Struttura {
  idStruttura: number,
  nome: string,
  indirizzo: string,
  cap: string,
  citta: string,
  telefono?: string,
  codicefiscale?: string,
  partitaiva?: string,
  note?: string,
  orarioSettimanale: number,
  rigo1?: string,
  rigo2?: string,
  rigo3?: string,
  logo1?: string,
  logo2?: string,
  version: number
}

export interface LookupStrutturaElem {
  idStruttura: number,
  nome: string
}

export interface StrutturaExtended extends Struttura {
  struttureTurniOre: StrutturaTurnoOre[],
  struttureTurniOreEliminati: StrutturaTurnoOre[],
  struttureFestivita: StruttureFestivita[],
  struttureFestivitaEliminati: StruttureFestivita[],
}