import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getByIds } from '../../services/services.service'
import { ScadenzaContratti } from '../../models/ScadenzaContratti';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettischedeprofessionali";

interface ScadenzaContrattiState {
  statusValidScadenzaContratti: StatusEnum,
  validScadenzaContratti: ScadenzaContratti[],
  scadenzaContratti: ScadenzaContratti | null,
  error: string | null
}

const initialState: ScadenzaContrattiState = {
  statusValidScadenzaContratti: StatusEnum.Succeeded,
  validScadenzaContratti: [],
  scadenzaContratti: null,
  error: null
}

export const fetchAll = createAsyncThunk(entity + 'scadenzacontratti/fetchAll', async (obj: {
  dataRiferimento: string,
}) => {
  const response = await getByIds(microservice, entity, ['scadenzacontratti', obj.dataRiferimento], ['scadenzacontratti/fetchAll']);
  return response.data as ScadenzaContratti[];
});

export const scadenzaContrattiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ScadenzaContrattiState) => {
      return initialState;;
    },
    resetError: (state: ScadenzaContrattiState) => {
      state.error = initialState.error;
      state.statusValidScadenzaContratti = initialState.statusValidScadenzaContratti;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidScadenzaContratti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidScadenzaContratti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidScadenzaContratti = StatusEnum.Failed;
      }
      state.validScadenzaContratti = [];
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ScadenzaContratti[]>) => {
      state.statusValidScadenzaContratti = StatusEnum.Succeeded;
      state.validScadenzaContratti = payload;
    })
  }
});

export const { reset, resetError } = scadenzaContrattiSlice.actions;
export default scadenzaContrattiSlice.reducer;