import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, cancelById, restoreById } from '../../services/services.service'
import { AnagraficaSoggettiSchedaSanitaria, AnagraficaSoggettiSchedaSanitariaKeys } from '../../models/AnagraficaSoggettiSchedaSanitaria';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettischedasanitaria";

interface AnagraficaSoggettiSchedaSanitariaState {
    statusValidAnagraficaSoggettiSchedeSanitarie: StatusEnum,
    validAnagraficaSoggettiSchedeSanitarie: AnagraficaSoggettiSchedaSanitaria[],
    statusDeletedAnagraficaSoggettiSchedeSanitarie: StatusEnum,
    deletedAnagraficaSoggettiSchedeSanitarie: AnagraficaSoggettiSchedaSanitaria[],
    anagraficaSoggettiSchedaSanitaria: AnagraficaSoggettiSchedaSanitaria | null,
    error: string | null
}

const initialState: AnagraficaSoggettiSchedaSanitariaState = {
    statusValidAnagraficaSoggettiSchedeSanitarie: StatusEnum.Succeeded,
    validAnagraficaSoggettiSchedeSanitarie: [],
    statusDeletedAnagraficaSoggettiSchedeSanitarie: StatusEnum.Succeeded,
    deletedAnagraficaSoggettiSchedeSanitarie: [],
    anagraficaSoggettiSchedaSanitaria: null,
    error: null
}

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiContestazione: AnagraficaSoggettiSchedaSanitaria) => {
    const response = await create(anagraficaSoggettiContestazione, microservice, entity);
    return response.data as AnagraficaSoggettiSchedaSanitaria;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiContestazione: AnagraficaSoggettiSchedaSanitaria) => {
    const response = await upd(anagraficaSoggettiContestazione, microservice, entity);
    return response.data as AnagraficaSoggettiSchedaSanitaria;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
    await cancelById(ids, microservice, entity);
    return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
    await restoreById(ids, microservice, entity);
    return { ids };
});

export const anagraficaSoggettiSchedaSanitariaSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        reset: (state: AnagraficaSoggettiSchedaSanitariaState) => {
            return initialState;;
        },
        resetError: (state: AnagraficaSoggettiSchedaSanitariaState) => {
            state.error = initialState.error;
            state.statusValidAnagraficaSoggettiSchedeSanitarie = initialState.statusValidAnagraficaSoggettiSchedeSanitarie;
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = initialState.statusDeletedAnagraficaSoggettiSchedeSanitarie;
        }
    },
    extraReducers: builder => {
        // insert
        builder.addCase(insert.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Failed;
            state.anagraficaSoggettiSchedaSanitaria = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiSchedaSanitaria>) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Succeeded;
            state.anagraficaSoggettiSchedaSanitaria = payload;
        })

        // update
        builder.addCase(update.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Failed;
            state.anagraficaSoggettiSchedaSanitaria = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiSchedaSanitaria>) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Succeeded;
            state.anagraficaSoggettiSchedaSanitaria = payload;
        })

        // physicalDelete
        builder.addCase(physicalDel.pending, (state) => {
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Succeeded;
            state.deletedAnagraficaSoggettiSchedeSanitarie = state.deletedAnagraficaSoggettiSchedeSanitarie.filter(elem => payload.ids.some(idObj => elem[idObj.name as AnagraficaSoggettiSchedaSanitariaKeys] !== idObj.id));
        })

        // logicalDelete
        builder.addCase(logicalDel.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Loading;
        })
        builder.addCase(logicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Failed;
        })
        builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusValidAnagraficaSoggettiSchedeSanitarie = StatusEnum.Succeeded;
        })

        // restore
        builder.addCase(restore.pending, (state) => {
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Loading;
        })
        builder.addCase(restore.rejected, (state, action) => {
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Failed;
            state.error = (action.error.message) ? action.error.message : "";
        })
        builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusDeletedAnagraficaSoggettiSchedeSanitarie = StatusEnum.Succeeded;
        })
    }
});

export const { reset, resetError } = anagraficaSoggettiSchedaSanitariaSlice.actions;
export default anagraficaSoggettiSchedaSanitariaSlice.reducer;