import { StyleSheet} from 'react-pdf-renderer-v2';


const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    flex: 1,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  image: {
    height: 50,
    width: 200,
  },
  name: {
    textAlign: 'right', 
    fontSize: '15', 
    flex: 1,
    fontFamily: 'Helvetica-Bold'
  
   
  },
  title: {
    fontSize: '12',
    textAlign: 'center',
  },
});

export default styles;