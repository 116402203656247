import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, StruttureFestivita, allFields, StruttureFestivitaKeys } from "../../../models/StruttureFestivita";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  insert,
  update,
  logicalDel,
  restore,
  physicalDel,
  reset as resetStrutturaFestivita,
  resetError as resetErrorStruttureFestivita
} from "../../../store/slices/strutturaFestivitaSlice";
import {
  fetchExtendedById as fetchStruttureExtendedById,
  reset as resetStrutture,
  resetError as resetErrorStrutture
} from "../../../store/slices/struttureSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { festivitaPath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields } from "../../../models/Fields";
import { Lookup } from "../../../models/Utils";
import { MonthsList } from "../../../utils/utildata";
import { TextField } from "@material-ui/core";
import { daysInMonth } from "../../../utils/utilfunctions";
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";

interface FestivitaDiStrutturaWProps {
  idStruttura: number,
  struttureFestivitaValidi: StruttureFestivita[],
  struttureFestivitaEliminati: StruttureFestivita[],
}

const FestivitaDiStrutturaW = ({ idStruttura, struttureFestivitaValidi, struttureFestivitaEliminati }: FestivitaDiStrutturaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, festivitaPath));
  const errorBE = useAppSelector(state => state.festivita.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorStrutture());
    dispatch(resetErrorStruttureFestivita());
  }, [dispatch]);

  const fixedProps = useMemo(() => { return { idStruttura } }, [idStruttura]);

  const lookup = useAppSelector(state => state.centriDiCosto.lookup);

  const title = t('holidayTitle');

  const validStruttureFestivita = useMemo(() => {
    return struttureFestivitaValidi;
  }, [struttureFestivitaValidi])

  const deletedStruttureFestivita = useMemo(() => {
    return struttureFestivitaEliminati;
  }, [struttureFestivitaEliminati])

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<StruttureFestivita>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<StruttureFestivita> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as StruttureFestivitaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        // override cell component
        switch (f.field) {
          case 'giorno':
            obj.editComponent = props => {
              const month = props.rowData?.mese;  // get data (mese) of other column
              const maxDays = month ? daysInMonth(month, new Date().getFullYear()) : 0;

              let helperText: string = f.keyTradValidation ? t(f.keyTradValidation) : '';
              switch (maxDays) {
                case 28:
                  helperText = t('between1And28Validation');
                  break;
                case 29:
                  helperText = t('between1And29Validation');
                  break;
                case 30:
                  helperText = t('between1And30Validation');
                  break;
                case 31:
                  helperText = t('between1And31Validation');
                  break;
              }

              // reset value when giorno exceeds maximum date of the selected month
              if (props.value && props.value > maxDays) {
                props.onChange(null);
              }

              return (
                <TextField
                  type="number"
                  value={props.value && props.value <= maxDays ? props.value : ''}
                  onChange={(e) => {
                    (e.target.value === "" || (f.minVal && Number(e.target.value) >= f.minVal && Number(e.target.value) <= maxDays)) && props.onChange(e.target.value);
                  }}
                  inputProps={{
                    min: f.minVal,
                    max: maxDays,
                  }}
                  error={props.error}
                  helperText={props.error ? helperText : ''}
                  disabled={!maxDays}
                />
              )
            }
            break;
        }

        /*+++*/
        if (f.lookupField) {
          switch (f.field) {
            case 'mese':
              const lookup: Lookup = {};
              MonthsList.forEach((month, index) => {
                lookup[index + 1] = t(month);
              });
              obj.lookup = lookup;
              break;
          }
        }
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, lookup, t]);

  const statusValidStruttureFestivita = useAppSelector(state => state.struttureFestivita.statusValidStruttureFestivita);
  const statusDeletedStruttureFestivita = useAppSelector(state => state.struttureFestivita.statusDeletedStruttureFestivita);

  useInitValid(
    statusValidStruttureFestivita,
    () => dispatch(fetchStruttureExtendedById({ idStruttura }))
  );
  useInitDeleted(
    statusDeletedStruttureFestivita,
    () => dispatch(fetchStruttureExtendedById({ idStruttura }))
  );


  const [data, setData] = useState<Array<StruttureFestivita>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validStruttureFestivita) : setData(deletedStruttureFestivita);
  }, [validStruttureFestivita, deletedStruttureFestivita, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetStrutturaFestivita());
      dispatch(resetStrutture());
    };
  }, [dispatch]);

  return <CrudMaterialTable
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchStruttureExtendedById}
    fetchAllDeleted={fetchStruttureExtendedById}
    insert={insert}
    logicalDel={logicalDel}
    physicalDel={physicalDel}
    restore={restore}
    update={update}
    dataValid={toggleValidDeleted}
    setToggleValidDeleted={setToggleValidDeleted}
    statusValid={statusValidStruttureFestivita}
    statusDeleted={statusDeletedStruttureFestivita}
    errorBE={errorBE}
    fixedProps={fixedProps}
    logoUri={logoUri}
    resetErrorCallback={resetError}
    extraOptions={{
      maxBodyHeight: 460,
    }}
  />
}
export default FestivitaDiStrutturaW;