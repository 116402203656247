import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getAllByIds, getById, getByIds, } from '../../services/services.service'
import { Presenza, } from '../../models/Presenze';
import { DipendentiPresenze } from '../../models/DipendentiPresenze';

const microservice = "unitaoperative";
const entity = "presenze";

interface PresenzeState {
  statusValidDipendentiPresenze: StatusEnum,
  validDipendentiPresenze: Presenza[],
  dipendentiPresenza: DipendentiPresenze | null,
  error: string | null
}

const initialState: PresenzeState = {
  statusValidDipendentiPresenze: StatusEnum.Succeeded,
  validDipendentiPresenze: [],
  dipendentiPresenza: null,
  error: null
}

//@GetMapping/cerca/presenze/all/anagraficasoggetto/{idStruttura}/{dataInizio}/{dataFine}
export const fetchStruttuturaPerData = createAsyncThunk(entity + '/fetchAnagraficaSoggettoPerData', async (obj: {

  idStruttura: number,
  dataInizio: string,
  dataFine: string,
}) => {
  const ids: Array<string | number> = Object.values(obj);
  ids.unshift('anagraficasoggetto');

  const response = await getAllByIds(microservice, entity, ids);
  return response.data as Presenza[];
});

export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  idAnagraficaSoggetto: number;
  idPresenza: number,
}) => {
  const args = Object.values(ids)

  const response = await getById(microservice, entity, args);
  return response.data as Presenza;
});

// esterno -- cerca/presenze/nuovapresenza/{idAnagraficaSoggetto}/{presenzaData}
// interno -- cerca/presenze/nuovapresenza/{idAnagraficaSoggetto}/{presenzaData}/{idStruttura}/{idDipartimento}/{idUnitaOperativa}
export const fetchPreviousInfo = createAsyncThunk(entity + '/fetchPreviousInfo', async (obj: {
  idAnagraficaSoggetto: number,
  presenzaData: string,
  idStruttura?: number,
  idDipartimento?: number,
  idUnitaOperativa?: number,
}) => {
  const ids: Array<string | number> = Object.values(obj);

  ids.unshift("nuovapresenza");

  const response = await getByIds(microservice, entity, ids, ['/fetchPreviousInfo']);
  return response.data as Presenza;
});

export const dipendentiPresenzeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: PresenzeState) => {
      return initialState;
    },
    resetError: (state: PresenzeState) => {
      state.error = initialState.error;
      state.statusValidDipendentiPresenze = initialState.statusValidDipendentiPresenze;

    },
    resetPresenzaSingola: (state: PresenzeState) => {
      state.dipendentiPresenza = initialState.dipendentiPresenza;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidDipendentiPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDipendentiPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDipendentiPresenze = StatusEnum.Failed;
      }
      state.dipendentiPresenza = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidDipendentiPresenze = StatusEnum.Succeeded;
      state.dipendentiPresenza = payload;
    })

    //fetchAnagrificaSoggettoPerData
    builder.addCase(fetchStruttuturaPerData.pending, (state) => {
      state.statusValidDipendentiPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchStruttuturaPerData.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDipendentiPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDipendentiPresenze = StatusEnum.Failed;
      }
      state.dipendentiPresenza = null;
    })
    builder.addCase(fetchStruttuturaPerData.fulfilled, (state, { payload }: PayloadAction<Presenza[]>) => {
      state.statusValidDipendentiPresenze = StatusEnum.Succeeded;
      state.validDipendentiPresenze = payload;
    })
  }
});

export const { reset, resetError, resetPresenzaSingola } = dipendentiPresenzeSlice.actions;
export default dipendentiPresenzeSlice.reducer;