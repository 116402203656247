import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, cancelById, restoreById, getByIds } from '../../services/services.service'
import { AnagraficaSoggettiQualifica } from '../../models/AnagraficaSoggettiQualifica';

export type AnagraficaSoggettiQualificaKeys = keyof AnagraficaSoggettiQualifica;

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettiqualifica";

export interface QualificheAbilitate {
  idQualifica: number,
  descrizioneBreve: string,
  descrizione: string,
  selected: boolean,
}

interface AnagraficaSoggettiQualificaState {
  statusValidAnagraficaSoggettiQualifiche: StatusEnum,
  validAnagraficaSoggettiQualifiche: AnagraficaSoggettiQualifica[],
  statusDeletedAnagraficaSoggettiQualifiche: StatusEnum,
  deletedAnagraficaSoggettiQualifiche: AnagraficaSoggettiQualifica[],
  anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica | null,
  qualificheAbilitate: QualificheAbilitate[],
  error: string | null
}

const initialState: AnagraficaSoggettiQualificaState = {
  statusValidAnagraficaSoggettiQualifiche: StatusEnum.Succeeded,
  statusDeletedAnagraficaSoggettiQualifiche: StatusEnum.Succeeded,
  validAnagraficaSoggettiQualifiche: [],
  deletedAnagraficaSoggettiQualifiche: [],
  anagraficaSoggettiQualifica: null,
  qualificheAbilitate: [],
  error: null
}

export const fetchAllValidById = createAsyncThunk(entity + '/fetchAllValidById', async (ids: any[]) => {
  const newIds = ['lookupqualificheabilitate', ...ids]
  const response = await getByIds(microservice, entity, newIds, ['/fetchAllValidById']);
  return response.data as QualificheAbilitate[];
});

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica) => {
  const response = await create(anagraficaSoggettiQualifica, microservice, entity);
  return response.data as AnagraficaSoggettiQualifica;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica) => {
  const response = await upd(anagraficaSoggettiQualifica, microservice, entity);
  return response.data as AnagraficaSoggettiQualifica;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<AnagraficaSoggettiQualificaKeys>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<AnagraficaSoggettiQualificaKeys>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<AnagraficaSoggettiQualificaKeys>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiQualificaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AnagraficaSoggettiQualificaState) => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiQualificaState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiQualifiche = initialState.statusValidAnagraficaSoggettiQualifiche;
      state.statusDeletedAnagraficaSoggettiQualifiche = initialState.statusDeletedAnagraficaSoggettiQualifiche;
    }
  },
  extraReducers: builder => {
    // fetchAllValidById
    builder.addCase(fetchAllValidById.pending, (state) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
      state.qualificheAbilitate = [];
    })
    builder.addCase(fetchAllValidById.fulfilled, (state, { payload }: PayloadAction<QualificheAbilitate[]>) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
      state.qualificheAbilitate = payload;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
      state.anagraficaSoggettiQualifica = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiQualifica>) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
      state.anagraficaSoggettiQualifica = payload;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
      state.anagraficaSoggettiQualifica = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiQualifica>) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
      state.anagraficaSoggettiQualifica = payload;
    })

    // physicalDelete
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<AnagraficaSoggettiQualificaKeys>[] }>) => {
      state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
      state.deletedAnagraficaSoggettiQualifiche = state.deletedAnagraficaSoggettiQualifiche.filter(elem => payload.ids.some(idObj => elem[idObj.name] !== idObj.id));
    })

    // logicalDelete
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<AnagraficaSoggettiQualificaKeys>[] }>) => {
      state.statusValidAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<AnagraficaSoggettiQualificaKeys>[] }>) => {
      state.statusDeletedAnagraficaSoggettiQualifiche = StatusEnum.Succeeded;
    })
  }
});

export const { reset, resetError } = anagraficaSoggettiQualificaSlice.actions;
export default anagraficaSoggettiQualificaSlice.reducer;