import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dipartimento, elementIdProps, elementRenderProps, allFields } from "../../../models/Dipartimenti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllValidByIdStruttura,
  fetchAllDeletedByIdStruttura,
  insertFiltered,
  logicalDelFiltered,
  physicalDelFiltered,
  restoreFiltered,
  updateFiltered,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  lookup as fetchLookupStrutture,
  reset as resetStrutture,
  resetError as resetErrorStrutture
} from "../../../store/slices/struttureSlice";
import {
  fetchAllValid as fetchCentriDiCosto,
  reset as resetCentriDiCosto,
  resetError as resetErrorCentriDiCosto
} from "../../../store/slices/centriDiCostoSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { notFoundPath, dipartimentiPath } from "../../../utils/utilconst";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentInsertPath, componentTabsPath, dipartimentiSediTabPath, strutturaTabPath, unitaOperativeTabPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Box, Paper, Typography } from "@material-ui/core";
import UnitaOperativeDiDipartimentoW from "./UnitaOperativeDiDipartimentoW";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { PDFExtraData } from "../../../models/Utils";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";
import DipartimentiSediW from "./DipartimentiSediW";

const DipartimentiW = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, dipartimentiPath));
  const title = t('departmentsTitle');

  const resetError = useCallback(() => {
    dispatch(resetErrorCentriDiCosto());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorStrutture());
  }, [dispatch]);

  const strSelectName = t("structuresTitle");
  const strSelectLabel = t("structureSelect");
  const [states, setStates] = useState<{ [selectName: string]: number | string | null }>({ [strSelectName]: "" });

  const idStrConst = "idStruttura";
  const idCostCenterConst = "idCentroDiCosto";

  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupCostCenter = useAppSelector(state => state.centriDiCosto.lookup);

  useEffect(() => {
    dispatch(fetchLookupStrutture());
    dispatch(fetchCentriDiCosto());
  }, [dispatch]);

  const fixedProps = useMemo(() => { return { [idStrConst]: states[strSelectName] } }, [states, strSelectName]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Dipartimento>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Dipartimento> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.field === idCostCenterConst) obj.lookup = lookupCostCenter;
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, lookupCostCenter, lookupStr, t]);

  const errorBE = useAppSelector(state => state.dipartimentiFiltered.error);

  const validDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.validDipartimentiFiltered);
  const deletedDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.deletedDipartimentiFiltered);

  const statusValidDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.statusValidDipartimentiFiltered);
  const statusDeletedDipartimentiFiltered = useAppSelector(state => state.dipartimentiFiltered.statusDeletedDipartimentiFiltered);

  const [data, setData] = useState<Array<Dipartimento>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const [obj, setObj] = useState<Dipartimento | null>(null);

  useEffect(() => {
    toggleValidDeleted ? setData(validDipartimentiFiltered) : setData(deletedDipartimentiFiltered);
  }, [validDipartimentiFiltered, deletedDipartimentiFiltered, toggleValidDeleted]);

  const insertCallback = () => {
    history.push(dipartimentiPath + componentInsertPath);
  }

  const updateDetailCallback = (dip: Dipartimento) => {
    setObj(dip);
    history.push(dipartimentiPath + componentTabsPath);
  }

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        const idStrutturaValue = fixedProps[elem];
        if (exportDataExtra.head && idStrutturaValue)
          exportDataExtra.head['Struttura'] = lookupStr[idStrutturaValue];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setObj(null);
      setToggleValidDeleted(true);
      setStates({});
      setAllFieldsState([]);
      dispatch(resetDipartimentiFiltered());
      dispatch(resetStrutture());
      dispatch(resetCentriDiCosto());
    };
  }, [dispatch]);

  return <>
    <Paper elevation={2}>
      <Box p={4}  >
        <SimpleIdSelects selectsArray={[
          {
            name: strSelectName, lookup: lookupStr, label: strSelectLabel,
            disabled: history.location.pathname === (dipartimentiPath + componentTabsPath),
            breakpoints: { xs: 12, sm: 12, md: 6, lg: 3 },

          }
        ]} states={states} setStates={setStates} />
      </Box>
    </Paper>
    <Box marginTop={2}>
      {
        states[strSelectName] ? (
          <Switch>
            <Route path={dipartimentiPath} exact>
              <CrudMaterialTable
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllValidByIdStruttura}
                fetchAllDeleted={fetchAllDeletedByIdStruttura}
                logicalDel={logicalDelFiltered}
                physicalDel={physicalDelFiltered}
                restore={restoreFiltered}
                statusValid={statusValidDipartimentiFiltered}
                statusDeleted={statusDeletedDipartimentiFiltered}
                dataValid={toggleValidDeleted}
                setToggleValidDeleted={setToggleValidDeleted}
                errorBE={errorBE}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                logoUri={logoUri}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                resetErrorCallback={resetError}
                extraOptions={{
                  maxBodyHeight: 460
                }}
              />
            </Route>
            <Route path={dipartimentiPath + componentInsertPath} exact>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={dipartimentiPath}
                tabsUri={dipartimentiPath + componentInsertPath}
                tabsView={false}
                buttonTitle={t('departmentsTitle')}
                info1={t("newDepartmentTitle")}
                info2={lookupStr[states[strSelectName] ?? '']}
                tabs={
                  [
                    {
                      label: t("departmentTitle"), tabPath: "",
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={dipartimentiPath}
                        action={insertFiltered}
                        fixedProps={fixedProps}
                        lookups={{ [idStrConst]: lookupStr, [idCostCenterConst]: lookupCostCenter }}
                        status={statusValidDipartimentiFiltered}
                        error={errorBE}
                        update={false}
                        fields={allFields}
                        translate={t}
                      />,
                      componentElse: <Redirect to={dipartimentiPath} />
                    }
                  ]
                } />
            </Route>
            <Route path={dipartimentiPath + componentTabsPath}>
              {
                obj ? (
                  <InnerComponentViews abilitazione={abilitazione} mainUri={dipartimentiPath} tabsUri={dipartimentiPath + componentTabsPath} tabsView={true}
                    buttonTitle={t('departmentsTitle')} info1={obj.nome}
                    tabs={
                      [
                        {
                          label: t("departmentTitle"),
                          tabPath: strutturaTabPath,
                          abilitazione: Abilitazione.READ_UPDATE,
                          componentIf: <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={dipartimentiPath}
                            action={updateFiltered}
                            fixedProps={fixedProps}
                            lookups={{ [idStrConst]: lookupStr, [idCostCenterConst]: lookupCostCenter }}
                            status={statusValidDipartimentiFiltered}
                            error={errorBE}
                            obj={obj}
                            update={true}
                            fields={allFields}
                            translate={t}
                          />,
                          componentElse: <GeneralForm
                            readOnly={true}
                            language={i18n.language}
                            componentPath={dipartimentiPath}
                            action={updateFiltered}
                            fixedProps={fixedProps}
                            lookups={{ [idStrConst]: lookupStr, [idCostCenterConst]: lookupCostCenter }}
                            status={statusValidDipartimentiFiltered}
                            error={errorBE}
                            obj={obj}
                            update={true}
                            fields={allFields}
                            translate={t}
                          />
                        },
                        {
                          label: t("operationalUnitsTabLabel"),
                          tabPath: unitaOperativeTabPath,
                          abilitazione: Abilitazione.READ,
                          componentIf: <UnitaOperativeDiDipartimentoW idStruttura={obj.idStruttura} idDipartimento={obj.idDipartimento} />,
                          componentElse: <Redirect to={dipartimentiPath} />
                        },
                        {
                          label: t("officesTabLabel"),
                          tabPath: dipartimentiSediTabPath,
                          abilitazione: Abilitazione.READ,
                          componentIf: <DipartimentiSediW
                            idStruttura={obj.idStruttura}
                            idDipartimento={obj.idDipartimento}
                          />,
                          componentElse: <Redirect to={dipartimentiPath} />
                        }
                      ]
                    } />
                ) : <Redirect to={dipartimentiPath} />
              }
            </Route>
            <Route path="*"><Redirect to={notFoundPath} /></Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={dipartimentiPath} exact>
              <Paper elevation={2}>
                <Box p={4}>
                  <Typography align="center" variant="h5">
                    {t("structureSelect")}
                  </Typography>
                </Box>
              </Paper>
            </Route>
            <Route>
              <Redirect to={dipartimentiPath} />
            </Route>
          </Switch>
        )
      }
    </Box>
  </>
}
export default DipartimentiW;