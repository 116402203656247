import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllDeletedByIds, getAllValid, getAllValidByIds, getById, restoreById, upd } from '../../services/services.service'
import { CombinazioniTurni, CombinazioniTurniKeys, elementIdProps } from '../../models/CombinazioniTurni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "turni";
const entity = "combinazioniturni";

interface CombinazioniTurniState {
  statusValidCombinazioniTurni: StatusEnum,
  validCombinazioniTurni: CombinazioniTurni[],
  statusDeletedCombinazioniTurni: StatusEnum,
  deletedCombinazioniTurni: CombinazioniTurni[],
  statusAllCombinazioniTurni: StatusEnum,
  allCombinazioniTurni: CombinazioniTurni[],
  combinazioneTurni: CombinazioniTurni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: CombinazioniTurniState = {
  statusValidCombinazioniTurni: StatusEnum.Succeeded,
  validCombinazioniTurni: [],
  statusDeletedCombinazioniTurni: StatusEnum.Succeeded,
  deletedCombinazioniTurni: [],
  statusAllCombinazioniTurni: StatusEnum.Succeeded,
  allCombinazioniTurni: [],
  combinazioneTurni: null,
  lookup: {},
  error: null
}

// get cerca/combinazioniturni/id/{idCombinazioneTurni}
export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as CombinazioniTurni;
});

// get cerca/combinazioniturni/all/completa
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as CombinazioniTurni[];
});

// get cerca/combinazioniturni/all/valid
export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as CombinazioniTurni[];
});

// get cerca/combinazioniturni/all/deleted
export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as CombinazioniTurni[];
});

// get cerca/combinazioniturni/all/valid/{idTurno}
export const fetchAllValidByIdTurno = createAsyncThunk(entity + '/fetchAllValidByIdTurno', async (obj: { idTurno: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idTurno], ['/fetchAllValidByIdTurno']);
  return response.data as CombinazioniTurni[];
});

// get cerca/combinazioniturni/all/deleted/{idTurno}
export const fetchAllDeletedByIdTurno = createAsyncThunk(entity + '/fetchAllDeletedByIdTurno', async (obj: { idTurno: number }) => {
  const response = await getAllDeletedByIds(microservice, entity, [obj.idTurno], ['/fetchAllDeletedByIdTurno']);
  return response.data as CombinazioniTurni[];
});

// post inserisci/combinazioniturni
export const insert = createAsyncThunk(entity + '/insert', async (combinazioneTurni: CombinazioniTurni) => {
  const response = await create(combinazioneTurni, microservice, entity);
  return response.data as CombinazioniTurni;
});

// put modifica/combinazioniturni
export const update = createAsyncThunk(entity + '/update', async (combinazioneTurni: CombinazioniTurni) => {
  const response = await upd(combinazioneTurni, microservice, entity);
  return response.data as CombinazioniTurni;
});

// put elimina/combinazioniturni/id/{idCombinazioneTurni}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/combinazioniturni/id/{idCombinazioneTurni}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/combinazioniturni/id/{idCombinazioneTurni}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const combinazioniTurniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;;
    },
    resetError: (state: CombinazioniTurniState) => {
      state.error = initialState.error;
      state.statusValidCombinazioniTurni = initialState.statusValidCombinazioniTurni;
      state.statusDeletedCombinazioniTurni = initialState.statusDeletedCombinazioniTurni;
      state.statusAllCombinazioniTurni = initialState.statusAllCombinazioniTurni;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
      state.combinazioneTurni = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      state.combinazioneTurni = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllCombinazioniTurni = StatusEnum.Failed;
      }
      state.allCombinazioniTurni = [];
      state.lookup = {}
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni[]>) => {
      state.statusAllCombinazioniTurni = StatusEnum.Succeeded;
      state.allCombinazioniTurni = payload ?? [];
      state.lookup = createLookup(payload, 'idCombinazioneTurni', ['descrizioneCombinazione']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
      state.validCombinazioniTurni = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni[]>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      state.validCombinazioniTurni = payload ?? [];
      state.lookup = createLookup(payload, 'idCombinazioneTurni', ['descrizioneCombinazione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCombinazioniTurni = StatusEnum.Failed;
      }
      state.deletedCombinazioniTurni = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni[]>) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Succeeded;
      state.deletedCombinazioniTurni = payload ?? [];
    })

    // fetchAllValidByIdTurno
    builder.addCase(fetchAllValidByIdTurno.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdTurno.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
      state.validCombinazioniTurni = [];
    })
    builder.addCase(fetchAllValidByIdTurno.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni[]>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      state.validCombinazioniTurni = payload ?? [];
      state.lookup = createLookup(payload, 'idCombinazioneTurni', ['descrizioneCombinazione']);
    })

    // fetchAllDeletedByIdTurno
    builder.addCase(fetchAllDeletedByIdTurno.pending, (state) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByIdTurno.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCombinazioniTurni = StatusEnum.Failed;
      }
      state.deletedCombinazioniTurni = [];
    })
    builder.addCase(fetchAllDeletedByIdTurno.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni[]>) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Succeeded;
      state.deletedCombinazioniTurni = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
      state.combinazioneTurni = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      state.combinazioneTurni = payload;
      state.validCombinazioniTurni = state.validCombinazioniTurni.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
      state.combinazioneTurni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<CombinazioniTurni>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      state.validCombinazioniTurni = state.validCombinazioniTurni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.combinazioneTurni = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCombinazioniTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidCombinazioniTurni = StatusEnum.Succeeded;
      const deleted = state.validCombinazioniTurni.find(el => payload.ids.every(idObj => el[idObj.name as CombinazioniTurniKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedCombinazioniTurni = state.deletedCombinazioniTurni.concat([deleted]);
      }
      state.validCombinazioniTurni = state.validCombinazioniTurni.filter(el => payload.ids.some(idObj => el[idObj.name as CombinazioniTurniKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCombinazioniTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Succeeded;
      const valid = state.deletedCombinazioniTurni.find(el => payload.ids.every(idObj => el[idObj.name as CombinazioniTurniKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validCombinazioniTurni = state.validCombinazioniTurni.concat([valid]);
      }
      state.deletedCombinazioniTurni = state.deletedCombinazioniTurni.filter(el => payload.ids.some(idObj => el[idObj.name as CombinazioniTurniKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCombinazioniTurni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCombinazioniTurni = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCombinazioniTurni = StatusEnum.Succeeded;
      state.deletedCombinazioniTurni = state.deletedCombinazioniTurni.filter(el => payload.ids.some(idObj => el[idObj.name as CombinazioniTurniKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = combinazioniTurniSlice.actions;
export default combinazioniTurniSlice.reducer;