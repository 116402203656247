
import { LookupAnagraficaSoggettoElem } from "./AnagraficaSoggetti";
import { Fields } from "./Fields";

export type PresenzeMancantiKeys = keyof PresenzeMancanti;

export const elementIdProps: PresenzeMancantiKeys[] = ["idAnagraficaSoggetto"];
export const elementRenderProps: PresenzeMancantiKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "nominativo", required: false, removable: false, show: true, titleKey: "Nominativo", render: (rowData: PresenzeMancanti) => {
      return rowData.nominativo.nome + ' ' + rowData.nominativo.cognome
    },
  },
  {
    field: "presenzaData", type: "date", required: false, removable: false, show: true, titleKey: "presenzaData", group: "",
  },
  {
    field: "idTurno", type: "numeric", required: false, removable: false, show: false, titleKey: "Turno", group: "",
  },
  {
    field: "turnoDescrizione", required: false, removable: false, show: true, titleKey: "turnoDescrizione", group: "", lookupField: true,
  },
  {
    field: "turnoDescrizioneBreve", required: false, removable: false, show: true, titleKey: "turnoDescrizioneBreve", group: "", lookupField: true,
  },
  {
    field: "oreTurno", type: "string", required: false, removable: false, show: true, titleKey: "0re Turno", group: "",
  },
  {
    field: "minutiTurno", type: "string", required: false, removable: false, show: true, titleKey: "Minuti Turno",
  },
  {
    field: "oreTurnoMancanti", type: "string", required: false, removable: false, show: true, titleKey: "Ore Mancanti",
  },
  {
    field: "minutiTurnoMancanti", type: "string", required: false, removable: false, show: true, titleKey: "Minuti Mancanti",
  },
  {
    field: "oraInizioTurnoMancante", type: "string", required: false, removable: false, show: true, titleKey: "Inizio Mancante", readonly: true,
  },
  {
    field: "oraFineTurnoMancante", type: "string", required: true, show: true, titleKey: "Fine Mancante",

  },


]

export interface PresenzeMancanti {

  idAnagraficaSoggetto: number,
  nominativo: LookupAnagraficaSoggettoElem,
  presenzaData: string,
  idTurno: number,
  oreTurno: number,
  minutiTurno: string,
  oraInizioTurno: string,
  oraFineTurno: string,
  oreTurnoMancanti: number,
  minutiTurnoMancanti: string,
  oraInizioTurnoMancante: string,
  oraFineTurnoMancante: string,
  version: number,

}
