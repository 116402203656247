
import { useTranslation } from 'react-i18next';
import { Image, View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import styles from './styles/header';
import { calculateWidth, getImageDimensionsFromBase64 } from '../../../../../utils/utilfunctions';

interface HeaderSectionProps {
  anagraficaSoggetto: AnagraficaSoggettoExtended,
  logo: string
}

const HeaderSection = ({ anagraficaSoggetto, logo }: HeaderSectionProps) => {

  const { t } = useTranslation();

  const LOGO_HEIGHT = 50;
  const metadata = getImageDimensionsFromBase64(logo);
  const width = calculateWidth(metadata?.width ?? 0, metadata?.height ?? 0, LOGO_HEIGHT);

  return (
    <View fixed >
      <View style={styles.header}>
        <View style={styles.imageContainer}>
          <Image
            src={
              (logo?.startsWith('data')
                ? ''
                : 'data:image/' + metadata?.type + ';base64,')
              + logo
            }
            style={{
              height: LOGO_HEIGHT,
              width
            }}
          />
        </View>
        <Text style={styles.name} > {anagraficaSoggetto?.nome + ' ' + anagraficaSoggetto?.cognome}</Text>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}> {t("registryParamCardTitle")}</Text>
      </View>
    </View>

  )
}

export default HeaderSection;