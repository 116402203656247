import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { filterGroup, Group, renderData } from './utils/utils';

interface AnagraficaSoggettoSectionProps {
  anagraficaSoggetto: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    codiceFiscale: ['fiscalCodeParam', 'string'],
    cognome: ['lastNameParam', 'string'],
    nome: ['nameParam', 'string'],
    nascitaData: ['dateOfBirthParam', 'date'],
    nascitaCitta: ['cityOfBirthParam', 'string'],
    nascitaProvincia: ['districtOfBirthParam', 'string'],
    sesso: ['genderParam', 'string'],
  },
  residenza: {
    residenzaIndirizzo: ['residenceAddressParam', 'string'],
    residenzaCitta: ['residenceCityParam', 'string'],
    residenzaProvincia: ['residenceDistrictParam', 'string'],
    residenzaCap: ['capParam', 'string'],
  },
  domicilio: {
    domicilioIndirizzo: ['domicileAddressParam', 'string'],
    domicilioCitta: ['domicileCityParam', 'string'],
    domicilioProvincia: ['domicileDistrictParam', 'string'],
    domicilioCap: ['domicileCapParam', 'string'],
  },
  recapiti: {
    telefonoAbitazione: ['telephoneParam', 'string'],
    telefonoCellulare: ['mobilePhoneParam', 'string'],
    email: ['emailParam', 'string'],
    emailPersonale: ['personalEmailParam', 'string'],
  },
  albo: {
    alboIscrizione: ['alboParam', 'string'],
    alboIscrizioneNumero: ['alboNumberParam', 'string'],
    alboIscrizioneData: ['alboDateParam', 'date'],
  }
}

const AnagraficaSoggettoSection = (props: AnagraficaSoggettoSectionProps) => {
  const { anagraficaSoggetto } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});
  const [residenza, setResidenza] = useState({});
  const [domicilio, setDomicilio] = useState({});
  const [recapiti, setRecapiti] = useState({});
  const [albo, setAlbo] = useState({});

  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, anagraficaSoggetto));
    setResidenza(filterGroup(groups.residenza, anagraficaSoggetto));
    setDomicilio(filterGroup(groups.domicilio, anagraficaSoggetto));
    setRecapiti(filterGroup(groups.recapiti, anagraficaSoggetto));
    setAlbo(filterGroup(groups.albo, anagraficaSoggetto));
  }, [anagraficaSoggetto]);

  return (
    <View style={styles.container} wrap={false}>

      {/* No group */}
      {
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('registryParam')}</Text>
          {
            Object.entries(noGroup).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'anagrafica-soggetto-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Residenza */}
      {
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('residence')}</Text>
          {
            Object.entries(residenza).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'residenza-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Domicilio */}
      {
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('domicile')}</Text>
          {
            Object.entries(domicilio).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'domicilio-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Recapiti */}
      {
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('addresses')}</Text>
          {
            Object.entries(recapiti).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'recapiti-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }

      {/* Albo */}
      {
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitle}>{t('albo')}</Text>
          {
            Object.entries(albo).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'albo-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }
    </View>
  )
};

export default AnagraficaSoggettoSection;