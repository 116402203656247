import { Box, Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import SimpleTabs, { TabElem } from "../tabs/SimpleTabs";

interface TabsWProps {
  tabs: TabElem[];
  abilitazione: number;
  mainUri: string;
  tabsUri: string;
  buttonTitle: string;
  info1: string;
  info2?: string;
  tabsView: boolean;
  backButtonCallback?: () => void
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const InnerComponentViews = ({ tabs, abilitazione, mainUri, tabsUri, buttonTitle, info1, info2, tabsView, backButtonCallback }: TabsWProps) => {
  const classes = useStyles();
  const history = useHistory();

  const tabRoute = useCallback((tabPath: string) => { history.push(tabsUri + tabPath) }, [history, tabsUri]);

  return <>
    <Paper className={classes.root} elevation={2}>
      <Box p={6}>
        <Grid container alignItems="center" spacing={2}>
          {
            buttonTitle !== "" &&
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  backButtonCallback && backButtonCallback();
                  history.push(mainUri);
                }}
              >
                {"< " + buttonTitle}
              </Button>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography align="center" variant="h4">{info1}{info2 && " - " + info2}</Typography>
          </Grid>
        </Grid>

        {tabsView && <SimpleTabs tabs={tabs} tabRoute={tabRoute} />}
        <Box marginTop={2}>
          <Switch>
            {tabs.map(tab => {
              if (abilitazione >= tab.abilitazione) {
                return <Route key={tab.label} path={tabsUri + tab.tabPath}>{tab.componentIf}</Route>;
              } else {
                return <Route key={tab.label} path={tabsUri + tab.tabPath}>{tab.componentElse}</Route>;
              }
            })}
          </Switch>
        </Box>
      </Box>
    </Paper>
  </>
}
export default InnerComponentViews;