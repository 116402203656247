import { Fields } from "./Fields";
import { AnagraficaSoggetto } from "./AnagraficaSoggetti";
import { AnagraficaSoggettiSchedaProfessionale } from "./AnagraficaSoggettiSchedeProfessionali";

export type ScadenzaContrattiKeys = keyof ScadenzaContratti;

export const elementIdProps: ScadenzaContrattiKeys[] = ["idAnagraficaSoggetto"];
export const elementRenderProps: ScadenzaContrattiKeys[] = ["nome", "cognome"];

export const allFields: Fields[] = [
  /******************************************** ANAGRAFICA SOGGETTO ********************************************/
  {
    field: "matricola", type: "string", required: false, removable: false, show: true, titleKey: "numberParam", readonly: true,
  },
  {
    field: "codiceFiscale", type: "string", required: false, removable: false, show: true, titleKey: "fiscalCodeParam", group: "",
  },
  {
    field: "cognome", type: "string", required: false, removable: false, show: true, titleKey: "lastNameParam", group: "",
  },
  {
    field: "nome", type: "string", required: false, removable: false, show: true, titleKey: "nameParam", group: "",
  },
  {
    field: "strutturaDescrizione", type: "string", required: false, removable: false, show: true, titleKey: "structureParam",
  },
  {
    field: "dipartimentoDescrizione", type: "string", required: false, removable: false, show: true, titleKey: "departmentParam",
  },
  {
    field: "unitaOperativaNome", type: "string", required: false, removable: false, show: true, titleKey: "operationalUnitParam",
  },
  {
    field: "qualificaIncaricoDescrizioneBreve", type: "string", required: false, removable: false, show: true, titleKey: "qualificationParam", readonly: true,
  },
  {
    field: 'dataAssunzione', type: 'date', required: false, removable: false, show: true, titleKey: "hiredDateParam",
  },
  {
    field: 'dataFineContratto', type: 'date', required: false, removable: false, show: true, titleKey: "assumptionDateEndParam",
  },
  {
    field: "nascitaData", type: "date", required: false, show: false, titleKey: "dateOfBirthParam", group: "",
  },
  {
    field: "nascitaCitta", type: "string", required: false, show: false, titleKey: "cityOfBirthParam", group: "",
  },
  {
    field: "nascitaProvincia", type: "string", required: false, show: false, titleKey: "districtOfBirthParam", group: "",
  },
  {
    field: "sesso", type: "string", required: false, show: false, titleKey: "genderParam", group: "",
  },
  /********************************** RECAPITI **********************************/
  {
    field: "telefonoAbitazione", type: "string", required: false, show: false, titleKey: "telephoneParam", group: "addresses",
  },
  {
    field: "telefonoCellulare", type: "string", required: false, show: false, titleKey: "mobilePhoneParam", group: "addresses",
  },
  {
    field: "email", type: "string", required: false, show: false, titleKey: "emailParam", group: "addresses",
  },
  {
    field: "emailPersonale", type: "string", required: false, show: false, titleKey: "personalEmailParam", group: "addresses",
  },
  /********************************** RESIDENZA **********************************/
  {
    field: "residenzaIndirizzo", type: "string", required: false, show: false, titleKey: "residenceAddressParam", group: "residence",
  },

  {
    field: "residenzaCitta", type: "string", required: false, show: false, titleKey: "residenceCityParam", group: "residence",
  },

  {
    field: "residenzaProvincia", type: "string", required: false, show: false, titleKey: "residenceDistrictParam", group: "residence",
  },

  {
    field: "residenzaCap", type: "string", required: false, show: false, titleKey: "capParam", group: "residence",
  },
  /********************************** DOMICILIO **********************************/
  {
    field: "domicilioIndirizzo", type: "string", required: false, show: false, titleKey: "domicileAddressParam", group: "domicile",
  },

  {
    field: "domicilioCitta", type: "string", required: false, show: false, titleKey: "domicileCityParam", group: "domicile",
  },

  {
    field: "domicilioProvincia", type: "string", required: false, show: false, titleKey: "domicileDistrictParam", group: "domicile",
  },

  {
    field: "domicilioCap", type: "string", required: false, show: false, titleKey: "domicileCapParam", group: "domicile",
  },
  /********************************** ALBO **********************************/
  {
    field: "alboIscrizione", type: "string", required: false, show: false, titleKey: "alboParam", hasHelp: true, group: "albo",
  },

  {
    field: "alboIscrizioneNumero", type: "string", required: false, show: false, titleKey: "alboNumberParam", hasHelp: true, group: "albo",
  },

  {
    field: "alboIscrizioneData", type: "date", required: false, show: false, titleKey: "alboDateParam", hasHelp: true, group: "albo",
  },

  {
    field: "idTurnoIniziale", required: false, show: false, titleKey: "firstShiftParam", group: "albo",
  },
]

export interface ScadenzaContratti extends AnagraficaSoggetto, AnagraficaSoggettiSchedaProfessionale { }