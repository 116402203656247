import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, Festivita, allFields, FestivitaKeys } from "../../../models/Festivita";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeleted,
  fetchAllValid,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetFestivita,
  resetError as resetErrorFestivita,
} from "../../../store/slices/festivitaSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { festivitaPath } from "../../../utils/utilconst";
import { daysInMonth, sortingStringCI } from "../../../utils/utilfunctions";
import { MonthsList } from "../../../utils/utildata";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Lookup } from "../../../models/Utils";
import { TextField } from "@material-ui/core";

const FestivitaW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, festivitaPath));
  const errorBE = useAppSelector(state => state.festivita.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorFestivita());
  }, [dispatch]);

  const title = t('holidayTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Festivita>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Festivita> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as FestivitaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);

        // override cell component
        switch (f.field) {
          case 'giorno':
            obj.editComponent = props => {
              const month = props.rowData?.mese;  // get data (mese) of other column
              const maxDays = month ? daysInMonth(month, new Date().getFullYear()) : 0;

              let helperText: string = f.keyTradValidation ? t(f.keyTradValidation) : '';
              switch (maxDays) {
                case 28:
                  helperText = t('between1And28Validation');
                  break;
                case 29:
                  helperText = t('between1And29Validation');
                  break;
                case 30:
                  helperText = t('between1And30Validation');
                  break;
                case 31:
                  helperText = t('between1And31Validation');
                  break;
              }

              // reset value when giorno exceeds maximum date of the selected month
              if (props.value && props.value > maxDays) {
                props.onChange(null);
              }

              return (
                <TextField
                  type="number"
                  value={props.value && props.value <= maxDays ? props.value : ''}
                  onChange={(e) => {
                    (e.target.value === "" || (f.minVal && Number(e.target.value) >= f.minVal && Number(e.target.value) <= maxDays)) && props.onChange(e.target.value);
                  }}
                  inputProps={{
                    min: f.minVal,
                    max: maxDays,
                  }}
                  error={props.error}
                  helperText={props.error ? helperText : ''}
                  disabled={!maxDays}
                />
              )
            }
            break;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'mese':
              const lookup: Lookup = {};
              MonthsList.forEach((month, index) => {
                lookup[index + 1] = t(month);
              });
              obj.lookup = lookup;
              break;
          }
        }

        return obj;
      }))
  }, [allFieldsState, excludedFieldInTable, t]);

  const validFestivita = useAppSelector(state => state.festivita.validFestivita);
  const deletedFestivita = useAppSelector(state => state.festivita.deletedFestivita);

  const statusValidFestivita = useAppSelector(state => state.festivita.statusValidFestivita);
  const statusDeletedFestivita = useAppSelector(state => state.festivita.statusDeletedFestivita);

  const [data, setData] = useState<Array<Festivita>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validFestivita) : setData(deletedFestivita);
  }, [validFestivita, deletedFestivita, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetFestivita());
    };
  }, [dispatch]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValid}
      fetchAllDeleted={fetchAllDeleted}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidFestivita}
      statusDeleted={statusDeletedFestivita}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default FestivitaW;