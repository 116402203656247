import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { ArticoloLegge, ArticoloLeggeKeys, elementIdProps } from '../../models/ArticoliLegge'
import { cancelById, create, definitiveDeleteById, getAll, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "articolilegge";
const entity = "articolilegge";
interface ArticoliLeggeState {
  statusValidArticoli: StatusEnum,
  validArticoli: ArticoloLegge[],
  statusDeletedArticoli: StatusEnum,
  deletedArticoli: ArticoloLegge[],
  statusAllArticoli: StatusEnum,
  allArticoli: ArticoloLegge[],
  articolo: ArticoloLegge | null,
  lookup: Lookup,
  lookupExtended: Lookup,
  error: string | null
}

const initialState: ArticoliLeggeState = {
  statusValidArticoli: StatusEnum.Succeeded,
  validArticoli: [],
  statusDeletedArticoli: StatusEnum.Succeeded,
  deletedArticoli: [],
  statusAllArticoli: StatusEnum.Succeeded,
  allArticoli: [],
  articolo: null,
  lookup: {},
  lookupExtended: {},
  error: null
}

// get cerca/articolilegge/id/{idArt}
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as ArticoloLegge;
});

// get cerca/articolilegge/all
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as ArticoloLegge[];
});

// get cerca/articolilegge/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as ArticoloLegge[];
});

// get cerca/articolilegge/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as ArticoloLegge[];
});

// post inserisci/articolilegge
export const insert = createAsyncThunk(microservice + '/insert', async (articolo: ArticoloLegge) => {
  const response = await create(articolo, microservice, entity);
  return response.data as ArticoloLegge;
});

// put modifica/articolilegge
export const update = createAsyncThunk(microservice + '/update', async (articolo: ArticoloLegge) => {
  const response = await upd(articolo, microservice, entity);
  return response.data as ArticoloLegge;
});

// put elimina/articolilegge/id/{idArt}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/articolilegge/id/{idArt}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/articolilegge/id/{idArt}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const articoliLeggeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ArticoliLeggeState) => {
      return initialState;;
    },
    resetError: (state: ArticoliLeggeState) => {
      state.error = initialState.error;
      state.statusValidArticoli = initialState.statusValidArticoli;
      state.statusDeletedArticoli = initialState.statusDeletedArticoli;
      state.statusAllArticoli = initialState.statusAllArticoli;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidArticoli = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidArticoli = StatusEnum.Failed;
      }
      state.articolo = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge>) => {
      state.statusValidArticoli = StatusEnum.Succeeded;
      state.articolo = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllArticoli = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllArticoli = StatusEnum.Failed;
      }
      state.lookup = {};
      state.lookupExtended = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge[]>) => {
      state.statusAllArticoli = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idArticoloLegge', ['articolo']);
      state.lookupExtended = createLookup(payload, 'idArticoloLegge', ['articolo', 'descrizione'], null, ' -> ');
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidArticoli = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidArticoli = StatusEnum.Failed;
      }
      state.validArticoli = [];
      state.lookup = {};
      state.lookupExtended = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge[]>) => {
      state.statusValidArticoli = StatusEnum.Succeeded;
      state.validArticoli = payload ?? [];
      state.lookup = createLookup(payload, 'idArticoloLegge', ['articolo']);
      state.lookupExtended = createLookup(payload, 'idArticoloLegge', ['articolo', 'descrizione'], null, ' -> ');
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedArticoli = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedArticoli = StatusEnum.Failed;
      }
      state.deletedArticoli = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge[]>) => {
      state.statusDeletedArticoli = StatusEnum.Succeeded;
      state.deletedArticoli = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidArticoli = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidArticoli = StatusEnum.Failed;
      }
      state.articolo = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge>) => {
      state.statusValidArticoli = StatusEnum.Succeeded;
      state.articolo = payload;
      state.validArticoli = state.validArticoli.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidArticoli = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidArticoli = StatusEnum.Failed;
      }
      state.articolo = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ArticoloLegge>) => {
      state.statusValidArticoli = StatusEnum.Succeeded;
      state.validArticoli = state.validArticoli.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.articolo = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidArticoli = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidArticoli = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidArticoli = StatusEnum.Succeeded;
      const deleted = state.validArticoli.find(el => payload.ids.every(idObj => el[idObj.name as ArticoloLeggeKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedArticoli = state.deletedArticoli.concat([deleted]);
      }
      state.validArticoli = state.validArticoli.filter(el => payload.ids.some(idObj => el[idObj.name as ArticoloLeggeKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedArticoli = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedArticoli = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedArticoli = StatusEnum.Succeeded;
      const valid = state.deletedArticoli.find(el => payload.ids.every(idObj => el[idObj.name as ArticoloLeggeKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validArticoli = state.validArticoli.concat([valid]);
      }
      state.deletedArticoli = state.deletedArticoli.filter(el => payload.ids.some(idObj => el[idObj.name as ArticoloLeggeKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedArticoli = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedArticoli = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedArticoli = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedArticoli = StatusEnum.Succeeded;
      state.deletedArticoli = state.deletedArticoli.filter(el => payload.ids.some(idObj => el[idObj.name as ArticoloLeggeKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = articoliLeggeSlice.actions;
export default articoliLeggeSlice.reducer;