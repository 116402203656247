import React, { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { Fields } from "../../../../../models/Fields";
import { AnagraficaSoggettoCurrentData, AnagraficaSoggettoCurrentDataKeys } from "../../../../../models/AnagraficaSoggetti";
import { ScadenzaFormazione, allFields } from "../../../../../models/ScadenzaFormazione";
import ReadOnlyMaterialTable from "../../../tables/ReadOnly/ReadOnlyMaterialTable";
import { addDays } from "date-fns";
import { fetchScadenzaFormazione } from "../../../../../store/slices/scadenzaFormazioneSlice";
import { getDateYYYYMMDD_BackEnd } from "../../../../../utils/utilfunctions";

interface ScadenzaFormazioneProps {
  validData: ScadenzaFormazione[];
}

const ScadenzaContrattiW = (props: ScadenzaFormazioneProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const title = t('expirationFormationTitle');

  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperative.lookup);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  const [data, setData] = useState<ScadenzaFormazione[]>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettoCurrentDataKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder)
          obj.defaultGroupOrder = f.defaultGroupOrder;

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const fixedProps = useMemo(() => {
    return {
      dataRiferimento: getDateYYYYMMDD_BackEnd(addDays(new Date(), 30)),
    }
  }, []);

  const validAnagraficaSoggettiFineFormazione = useAppSelector(state => state.scadenzaFormazione.validScadenzeFormazione);
  const statusValidScadenzeFormazione = useAppSelector(state => state.scadenzaFormazione.statusValidScadenzeFormazione);
  const errorBE = useAppSelector(state => state.presenze.error);

  useEffect(() => {
    setData(state => validAnagraficaSoggettiFineFormazione);
  }, [lookupDipartimenti, lookupStrutture, lookupUnitaOperative, validAnagraficaSoggettiFineFormazione]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <ReadOnlyMaterialTable
      title={title}
      columns={columns}
      data={data}
      fetchAllValid={fetchScadenzaFormazione}
      statusValid={statusValidScadenzeFormazione}
      errorBE={errorBE}
      fixedProps={fixedProps}
      logoUri={logoUri}
      columnsButton={true}
      extraOptions={{
        paging: true,
        search: true,
        draggable: false,
        maxBodyHeight: 1000,
        minBodyHeight: 500,
      }}
      localizedDatePicker={true}
    />
  )
}
export default ScadenzaContrattiW;