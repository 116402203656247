import { StyleSheet } from 'react-pdf-renderer-v2';

const styles = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  dataOra:{
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    right: 60,
    textAlign: 'right',
    color: 'grey'
  }
});

export default styles;