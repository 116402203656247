import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettiFormazione } from '../../../../../models/AnagraficaSoggettiFormazione';
import { sortDescObjectsBy } from '../../../../../utils/utilfunctions';
import TableFormazione from './formazioneSection/TableFormazione';
import styles from './styles/shared';

interface FormazioneSectionProps {
  formazione: AnagraficaSoggettiFormazione[]
}

const FormazioneSection = (props: FormazioneSectionProps) => {
  const { formazione } = props;
  const { t } = useTranslation();

  return formazione?.length > 0
    ? (
      <View style={[styles.viewWithGroup, styles.view]} wrap={false} >
        <Text style={styles.groupTitle}>{t('trainingParam')}</Text>
        <TableFormazione formazione={sortDescObjectsBy(formazione, 'dataTitolo')} />
      </View >
    )
    : <></>
};

export default FormazioneSection;