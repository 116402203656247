import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { filterGroup, Group, renderData } from './utils/utils';

interface QualificaSectionProps {
  qualifica: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    dataNuovaQualifica: ['validitystartDateParam', 'date'],
    qualificaDescrizione: ['qualificationParam', 'string'],
  },
}

const QualificaSection = (props: QualificaSectionProps) => {
  const { qualifica } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});

  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, qualifica))
  }, [qualifica]);

  return (
    <View wrap={false}>

      {/* No group */}
      <View style={[styles.view, styles.viewWithGroup]}>
        <Text style={styles.groupTitle}>{t('qualificationParam')}</Text>
        {
          Object.entries(noGroup).map(([attr, value], index) => {
            const [key, dataType] = attr.split(',') as [string, FieldType];
            return (
              <View
                key={'no-group-' + index}
                style={styles.flexBox}
              >

                <Text style={styles.col1}>{t(key)}</Text>
                <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
};

export default QualificaSection;