import { useEffect, useState } from "react";
import { StatusEnum } from "../../../../models/Utils";

const useInitValid = (
  statusValid: StatusEnum,
  dispatchCallbackValid: () => void,) => {

  const [refValidBool, setRefValidBool] = useState<boolean>(false);

  useEffect(() => {
    statusValid === StatusEnum.Loading && setRefValidBool(true);
  }, [statusValid, setRefValidBool]);

  useEffect(() => {
    if (refValidBool) {
      if (statusValid === StatusEnum.Succeeded) {
        dispatchCallbackValid();
      } if (statusValid === StatusEnum.Succeeded
        || statusValid === StatusEnum.Failed) {
        setRefValidBool(false);
      }
    }
  }, [refValidBool, setRefValidBool, dispatchCallbackValid, statusValid]);
}

export default useInitValid;