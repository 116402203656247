import { useState, } from 'react';
import {
  Button,
  MenuItem,
  Typography,
  Box,
  Menu
} from '@material-ui/core';
import i18n from '../../../i18n';
import React from 'react';

const LanguageSelector = () => {

  const options = ['Italiano', 'English'].sort(); // ['Italiano', 'English', 'Español', 'Français']
  const langCode: Record<string, string> = {};

  options.forEach(elem => {
    langCode[elem] = elem.slice(0, 2).toLowerCase();
  });

  const [selectedIndex, setSelectedIndex] = useState(options.findIndex(val => {
    const defaultLang = 'Italiano';
    return val === defaultLang;
  }));

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    i18n
      .changeLanguage(langCode[options[index]])
      .then(_ => { })
      .catch(err => {
        console.error(err);
      });
    setSelectedIndex(index);
    setAnchorEl(null)
  };


  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Typography style={{ color: "#fff" }}>
          {options[selectedIndex].slice(0, 3)}

        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((langOption, index) => (
          <MenuItem
            key={langOption}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <Box px={2} >
              <Typography variant='body1' style={{ fontSize: 15 }}>
                {langOption}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageSelector;