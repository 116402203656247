import { createSlice, } from '@reduxjs/toolkit'
import { CombinazioneTurniLookup, ElencoTurniFissiLookup, SessoLookup, StatoPianificazioneMap, StatoPianificazioneTranslation, TipoContrattoLookup, TipoPartTimeLookup, VersoTimbraturaLookup } from '../../utils/utildata';

const entity = "qualifiche";

interface LookupState {
  statoPianificazioneTranslation: { [key: string]: string },
  statoPianificazioneMap: { [key: string]: string },
  combinazioneTurniLookup: { [key: string]: string },
  elencoTurniFissiLookup: { [key: string]: string },
  tipoPartTimeLookup: { [key: string]: string },
  tipoContrattoLookup: { [key: string]: string },
  versoTimbratura: { [key: string]: string },
  sessoLookup: { [key: string]: string },

}

const initialState: LookupState = {
  statoPianificazioneTranslation: StatoPianificazioneTranslation,
  statoPianificazioneMap: StatoPianificazioneMap,
  combinazioneTurniLookup: CombinazioneTurniLookup,
  elencoTurniFissiLookup: ElencoTurniFissiLookup,
  tipoPartTimeLookup: TipoPartTimeLookup,
  tipoContrattoLookup: TipoContrattoLookup,
  versoTimbratura: VersoTimbraturaLookup,
  sessoLookup: SessoLookup,
}

function removeEmpty(obj: Record<string, string>) {
  const temp: Record<string, string> = {};
  Object.keys(obj).forEach((elem) => {
    if (obj[elem]) {
      temp[elem] = obj[elem]
    }
  })

  return temp
}

export const lookupSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: LookupState) => {
      return initialState;
    },
    setStatoPianificazioneTranslation: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.statoPianificazioneTranslation = { ...removeEmpty(action.payload) };
    },
    setStatoPianificazioneMap: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.statoPianificazioneMap = { ...removeEmpty(action.payload) };
    },
    setCombinazioneTurniLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.combinazioneTurniLookup = { ...removeEmpty(action.payload) };
    },
    setElencoTurniFissiLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.combinazioneTurniLookup = { ...removeEmpty(action.payload) };
    },
    setTipoPartTimeLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.tipoPartTimeLookup = { ...removeEmpty(action.payload) };
    },
    setTipoContrattoLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.tipoContrattoLookup = { ...removeEmpty(action.payload) };
    },
    setVersoTimbratura: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.versoTimbratura = { ...removeEmpty(action.payload) };
    },
    setSessoLookup: (state: LookupState, action: { payload: { [key: string]: string } }) => {
      state.sessoLookup = { ...removeEmpty(action.payload) };
    }
  },
});

export const { reset, setStatoPianificazioneTranslation, setStatoPianificazioneMap,
  setCombinazioneTurniLookup, setElencoTurniFissiLookup, setTipoPartTimeLookup, setTipoContrattoLookup, setSessoLookup,
  setVersoTimbratura } = lookupSlice.actions;
export default lookupSlice.reducer;