import { Fields } from "./Fields";

export type PresenzeUOKeys = keyof PresenzeUO;

export const elementIdProps: PresenzeUOKeys[] = [];
export const elementRenderProps: PresenzeUOKeys[] = [];

export const allPresenzeUOFields: Fields[] = [
    {
        field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true,
    },

    {
        field: "cognome", titleKey: "lastNameParam", required: false, show: true, lookupField: true,
    },

    {
        field: "nome", titleKey: "nameParam", required: false, show: true,
    },

    {
        field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitMembershipParam", required: false, show: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
    },

    {
        field: "motivo", titleKey: "reasonParam", required: false, show: true,
    },
];

export const allUOEsternaFields: Fields[] = [
    {
        field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true,
    },

    {
        field: "cognome", titleKey: "lastNameParam", required: false, show: true, lookupField: true,
    },

    {
        field: "nome", titleKey: "nameParam", required: false, show: true,
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitMembershipParam", required: false, show: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
    },

    {
        field: "motivo", titleKey: "reasonParam", required: false, show: true,
    },
];

export const allRiepilogoFields: Fields[] = [
    {
        field: "nominativo", titleKey: "nominativeParam", required: false, removable: false, show: true,
    },

    /**
     * Le colonne dei giorni vengono create dinamicamente.
     */
]

export const allPrestazioneEsterneUOFields: Fields[] = [
    {
        field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true,
    },

    {
        field: "cognome", titleKey: "lastNameParam", required: false, show: true, lookupField: true,
    },

    {
        field: "nome", titleKey: "nameParam", required: false, show: true,
    },

    {
        field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitExternalParam", required: false, show: true,
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '',
    },

    {
        field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
    },

    {
        field: "approvazioneRichiestaMotivo", titleKey: "reasonParam", required: false, show: true,
    },
];

export interface PresenzeUO {
    presenzaData: string;
    cognome: string;
    nome: string;
    turnoDescrizione: string;
    turnoDescrizioneBreve: string;
    turnoAssenzaDescrizione: string;
    turnoAssenzaDescrizioneBreve: string;
    unitaOperativaNome?: string;
    qualificaDescrizione: string;
    qualificaDescrizioneBreve: string;
    oreTurno: number | string;
    approvazioneRichiestaMotivo?: string;
}


