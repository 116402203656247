import { Fields } from "./Fields";
import { User } from "./User";

export type ChatMessaggioKeys = keyof ChatMessaggio;

export const elementIdProps: ChatMessaggioKeys[] = ['idChatMessaggio', 'idApplicazioneSoftware', 'idUtente1', 'idUtente2'];
export const elementRenderProps: ChatMessaggioKeys[] = [];

export const allFields: Fields[] = [];

export interface ChatMessaggio {
  idChatMessaggio: number,
  idApplicazioneSoftware: number,
  idUtente1: number,
  idUtente2: number,

  idUtenteSend: number,
  idUtenteRecv: number,

  testo?: string,
  immagine?: string,
  inserimentoDateTime?: string,

  applicazioneSoftwareDescrizione?: string,
  organizzazioneNome?: string,
  utenteSend?: User,
  utenteRecv?: User,

  version: number
}