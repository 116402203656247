import React from 'react';
import { Document, StyleSheet, Page, Font } from 'react-pdf-renderer-v2';
import { Riepiloghi } from '../../AnagraficaSoggettiW';
import Header from './components/Header';
import Main from './components/Main';
import Logo from './components/Logo';

Font.registerHyphenationCallback(word => [word]);

interface GenerateCardPDFProps {
  logo?: string,
  riepiloghi: Riepiloghi,
}

const GenerateCardPDF = (props: GenerateCardPDFProps) => {
  const { riepiloghi, logo } = props;

  return (
    <Document title='Card'>
      <Page style={styles.page1} size='ID1' orientation='landscape'>
        {logo && <Header logo={logo} riepiloghi={riepiloghi} />}
        <Main riepiloghi={riepiloghi} />
      </Page>
      <Page style={styles.page2} size='ID1' orientation='landscape'>
        {logo && <Logo logo={logo} />}
      </Page>
    </Document>
  );
}

export default GenerateCardPDF;

const styles = StyleSheet.create({
  page1: {
    fontFamily: 'Helvetica',
    padding: 10,
    lineHeight: 1.5,
  },
  page2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});