import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";

import {
  fetchAllValidById as fetchAllValidAnagraficaSoggettoFormazioniById,
  fetchAllDeletedById as fetchAllDeletedAnagraficaSoggettoFormazioniById,
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiFormazione
} from "../../../store/slices/anagraficaSoggettiFormazioneSlice";
import {
  resetError as resetErrorFormazione
} from '../../../store/slices/formazioneSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiFormazione, allFields, AnagraficaSoggettiFormazioneKeys } from '../../../models/AnagraficaSoggettiFormazione';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Route, Switch } from "react-router-dom";

interface AnagraficaSoggettiFormazioneWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione[],
  anagraficaSoggettiFormazioneEliminati: AnagraficaSoggettiFormazione[]
}

const AnagraficaSoggettiFormazioneW = ({ idAnagraficaSoggetto, anagraficaSoggettiFormazione, anagraficaSoggettiFormazioneEliminati }: AnagraficaSoggettiFormazioneWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiFormazione.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorFormazione());
    dispatch(resetErrorAnagraficaSoggettiFormazione());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  const formazioneLookup = useAppSelector(state => state.formazione.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto"
  const idFormazione = "idFormazione";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('trainingTitle');

  useEffect(() => {
    if (idAnagraficaSoggetto) {
      dispatch(fetchAllValidAnagraficaSoggettoFormazioniById({ idAnagraficaSoggetto }));
      dispatch(fetchAllDeletedAnagraficaSoggettoFormazioniById({ idAnagraficaSoggetto }));
    }
  }, [dispatch, idAnagraficaSoggetto]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiFormazione>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiFormazione> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiFormazioneKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idFormazione:
            obj.lookup = formazioneLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, formazioneLookup, t]);

  const statusValidAnagraficaSoggettiFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusValidAnagraficaSoggettiFormazioni);
  const statusDeletedAnagraficaSoggettiFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusDeletedAnagraficaSoggettiFormazioni);

  const [data, setData] = useState<Array<AnagraficaSoggettiFormazione>>([]);

  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiFormazione) : setData(anagraficaSoggettiFormazioneEliminati);
  }, [
    anagraficaSoggettiFormazione,
    anagraficaSoggettiFormazioneEliminati,
    toggleValidDeleted
  ]);

  useInitValid(
    statusValidAnagraficaSoggettiFormazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );
  useInitDeleted(
    statusDeletedAnagraficaSoggettiFormazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idAnagraficaSoggetto':
        if (exportDataExtra.head)
          exportDataExtra.head['Anagrafica Soggetto'] = lookupAnagraficaSoggetto[fixedProps[elem]];
        break;
    }
  });

  return (
    <Switch>
      <Route path='*'>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchExtendedByIdAndDate}
          restore={restore}
          logicalDel={logicalDel}
          physicalDel={physicalDel}
          update={update}
          insert={insert}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusValidAnagraficaSoggettiFormazione}
          statusDeleted={statusDeletedAnagraficaSoggettiFormazione}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            maxBodyHeight: 460,
          }}
        />
      </Route>
    </Switch>
  )
}
export default AnagraficaSoggettiFormazioneW;

