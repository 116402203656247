import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { Fields } from "../../../../../models/Fields";
import { allFields, ApprovazionePresenze, ApprovazionePresenzeKeys } from "../../../../../models/ApprovazionePresenze";

import ReadOnlyMaterialTable from "../../../tables/ReadOnly/ReadOnlyMaterialTable";

import { fetchByIds } from "../../../../../store/slices/approvazionePresenzeSlice";
import { update } from "../../../../../store/slices/presenzeSlice";
import { Box, IconButton } from "@material-ui/core";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { getDateDDMMYYYY_HHMM, getDateYYYYMMDD_BackEnd } from "../../../../../utils/utilfunctions";
import { Presenza } from "../../../../../models/Presenze";

interface PresenzeDaApprovareProps {
  validData: Presenza[];
}

const PresenzeDaApprovareW = (props: PresenzeDaApprovareProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const title = t('pendingAttendanceTitle');

  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperative.lookup);
  const lookupTurniDescBreve = useAppSelector(state => state.turni.lookupDescrizioneBreve);
  const lookupQualificheDescBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);

  const fixedProps = useMemo(() => (
    {
      soloDaApprovare: true,
      inizioData: null,
      fineData: null,
    }
  ), []);

  const accetta = useCallback(
    (presenza: ApprovazionePresenze) => {
      presenza.approvazioneConcessaData = getDateYYYYMMDD_BackEnd(new Date());
      dispatch(update(presenza))
        .then(() => {
          dispatch(fetchByIds(fixedProps));
        })
    }, [dispatch, fixedProps]
  )

  const rifiuta = useCallback(
    (presenza: ApprovazionePresenze) => {
      presenza.approvazioneNegataData = getDateYYYYMMDD_BackEnd(new Date());
      dispatch(update(presenza))
        .then(() => {
          dispatch(fetchByIds(fixedProps));
        })
    }, [dispatch, fixedProps]
  )

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>([
    ...allFields,
    {
      field: "nomeGruppo", titleKey: "operationalUnitParam", required: false, show: true, defaultGroupOrder: 1
    },
  ]);
  const [columns, setColumns] = useState<Array<Column<ApprovazionePresenze>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ApprovazionePresenze> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue,
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ApprovazionePresenzeKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder)
          obj.defaultGroupOrder = f.defaultGroupOrder;

        if (f.field === "approvazione") {
          obj.render = (rowData: ApprovazionePresenze) => {
            if (!rowData.approvazioneConcessaData && !rowData.approvazioneNegataData) {
              return <>
                <Box display='flex'>
                  <Box
                    borderColor={'#0e59a8'}
                    border='1px solid #0e59a8'
                    borderRadius={5}
                    px={1}
                    mr={1}
                    onClick={() => accetta(rowData)}
                  >
                    <IconButton
                      size='small'
                      color='primary'
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>

                  <Box
                    borderColor={'#e83519'}
                    border='1px solid #e83519'
                    borderRadius={5}
                    px={1}
                    onClick={() => rifiuta(rowData)}
                  >
                    <IconButton
                      size='small'
                      color='secondary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </>
            }
            else if (rowData.approvazioneConcessaData) {
              return <>{t('approvalGrantedDate') + ' ' + getDateDDMMYYYY_HHMM(new Date(rowData.approvazioneConcessaData))} </>
            }
            else if (rowData.approvazioneNegataData) {
              return <>{t('approvalDeniedDate') + ' ' + getDateDDMMYYYY_HHMM(new Date(rowData.approvazioneNegataData))} </>
            }
          };
        }

        // lookups
        if (f.lookupField)
          switch (f.field) {

          }

        return obj;
      })
    )
  }, [accetta, allFieldsState, excludedFieldInTable, lookupDipartimenti, lookupQualificheDescBreve, lookupStrutture, lookupTurniDescBreve, lookupUnitaOperative, rifiuta, t]);

  /**
   * fetch lookups
   */
  useEffect(() => {

  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchByIds(fixedProps));
  }, [dispatch, fixedProps]);

  const validPresenzeDaApprovare = useAppSelector(state => state.approvazionePresenze.validPresenze);
  const statusValidPresenzeDaApprovare = useAppSelector(state => state.approvazionePresenze.statusValidPresenze);
  const errorBE = useAppSelector(state => state.approvazionePresenze.error);

  const [data, setData] = useState<Array<ApprovazionePresenze>>([]);
  useEffect(() => {
    setData(state => {
      return validPresenzeDaApprovare
    });
  }, [lookupDipartimenti, lookupStrutture, validPresenzeDaApprovare]);

  useEffect(() => {
    setData(validPresenzeDaApprovare
      .map(elem => {
        return {
          ...elem,
          nomeGruppo: [elem.strutturaNome + ' > ' + elem.dipartimentoNome + ' > ' + elem.unitaOperativaNome]
        }
      })
    )
  }, [validPresenzeDaApprovare]);

  useEffect(() => {
    setAllFieldsState(state => {
      const newState = [...state]
      if (!(newState).find((elem) => elem.field === 'nomeGruppo')) {
        newState.push(
          {
            field: "nomeGruppo", titleKey: "operatingUnitTitle", required: false, show: true, defaultGroupOrder: 0,
          },
        )
      }
      return newState

    })
  }, []);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <ReadOnlyMaterialTable
      title={title}
      columns={columns}
      data={data}
      fetchAllValid={fetchByIds}
      statusValid={statusValidPresenzeDaApprovare}
      fixedProps={fixedProps}
      errorBE={errorBE}
      logoUri={logoUri}
      extraOptions={{
        paging: true,
        search: true,
        draggable: false,
        maxBodyHeight: 1000,
        minBodyHeight: 500,
        defaultExpanded: true,
      }}
    />
  )
}
export default PresenzeDaApprovareW;