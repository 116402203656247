import { Column } from "@material-table/core";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiQualifica
} from "../../../store/slices/anagraficaSoggettiQualificaSlice";
import { elementIdProps, elementRenderProps, AnagraficaSoggettiQualifica, allFields, AnagraficaSoggettiQualificaKeys } from '../../../models/AnagraficaSoggettiQualifica';
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath, qualificaTabPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm from "../forms/GeneralForm";
import i18n from "../../../i18n";
import {
  resetError as resetErrorQualifiche
} from '../../../store/slices/qualificheSlice';
import { Box, Button, Paper } from "@material-ui/core";
import InnerComponentViewsAnagrafiche from "./AnagraficaSoggettiComponents/InnerComponentsViewsAnagrafiche";
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import _ from 'lodash';

interface AnagraficaSoggettiQualificaWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica[],
  anagraficaSoggettiQualificaEliminati: AnagraficaSoggettiQualifica[]
}

const AnagraficaSoggettiQualificaW = ({ idAnagraficaSoggetto, anagraficaSoggettiQualifica, anagraficaSoggettiQualificaEliminati }: AnagraficaSoggettiQualificaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiQualifica.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiQualifica());
    dispatch(resetErrorQualifiche());
  }, [dispatch]);

  const qualificaLookup = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);

  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const idQualifica = "idQualifica";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('qualificationTitle');

  const [obj, setObj] = useState<AnagraficaSoggettiQualifica | null>(null);

  const excludedFieldInTable = useMemo(() => ['idQualifica'], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiQualifica>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiQualifica> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiQualificaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '')
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idQualifica:
            obj.lookup = qualificaLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, qualificaLookup, t]);

  const formLookups = useMemo(() => {
    return {
      idQualifica: qualificaLookup,
    }
  }, [qualificaLookup]);

  const insertCallback = useCallback(() => {
    const temp: Partial<AnagraficaSoggettiQualifica> = {
      idAnagraficaSoggetto: 0,
      idQualifica: 0,
      dataNuovaQualifica: "",
    }

    const lastAnagraficaSoggettiQualifica: Partial<AnagraficaSoggettiQualifica> = {
      ..._.pick(anagraficaSoggettiEstesa, Object.keys(temp)),
      dataNuovaQualifica: undefined
    }

    setObj(lastAnagraficaSoggettiQualifica as AnagraficaSoggettiQualifica);

    history.push(anagrafichePath + componentTabsPath + qualificaTabPath + componentInsertPath);
  }, [anagraficaSoggettiEstesa, history]);

  const updateDetailCallback = (anagraficaSoggettiQualifica: AnagraficaSoggettiQualifica) => {
    setObj(anagraficaSoggettiQualifica);
    history.push(anagrafichePath + componentTabsPath + qualificaTabPath + componentTabsPath)
  };

  const statusValidAnagraficaSoggettiQualifica = useAppSelector(state => state.anagraficaSoggettiQualifica.statusValidAnagraficaSoggettiQualifiche);
  const statusDeletedAnagraficaSoggettiQualifica = useAppSelector(state => state.anagraficaSoggettiQualifica.statusDeletedAnagraficaSoggettiQualifiche);

  const [data, setData] = useState<Array<AnagraficaSoggettiQualifica>>([]);

  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiQualifica) : setData(anagraficaSoggettiQualificaEliminati);
  }, [
    anagraficaSoggettiQualifica,
    anagraficaSoggettiQualificaEliminati,
    toggleValidDeleted,
  ]);

  useInitValid(statusValidAnagraficaSoggettiQualifica, () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto })));
  useInitDeleted(statusDeletedAnagraficaSoggettiQualifica, () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto })));

  useEffect(() => {
    dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  }, [dispatch, idAnagraficaSoggetto]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idAnagraficaSoggetto':
        if (exportDataExtra.head)
          exportDataExtra.head['Anagrafica Soggetto'] = lookupAnagraficaSoggetto[fixedProps[elem]];
        break;
    }
  });

  return (
    <Switch>
      <Route exact path={anagrafichePath + componentTabsPath + qualificaTabPath + componentInsertPath}>
        <InnerComponentViewsAnagrafiche
          abilitazione={abilitazione}
          mainUri={anagrafichePath + componentTabsPath + qualificaTabPath}
          tabsUri={anagrafichePath + componentTabsPath + qualificaTabPath + componentInsertPath}
          tabsView={false}
          buttonTitle={t("cancelLabel")}
          info1={t("update")}
          tabs={[
            {
              label: t("registryTitle"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={anagrafichePath + componentTabsPath + qualificaTabPath}
                  action={insert}
                  fixedProps={fixedProps}
                  status={statusValidAnagraficaSoggettiQualifica}
                  error={errorBE}
                  update={false}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                  obj={obj}
                  buttonLabel="saveLabel"
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path={anagrafichePath + componentTabsPath + qualificaTabPath + componentTabsPath} exact>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={anagrafichePath + componentTabsPath + qualificaTabPath}
          tabsUri={anagrafichePath + componentTabsPath + qualificaTabPath + componentTabsPath}
          tabsView={false}
          buttonTitle={t("cancelLabel")}
          info1={""}
          tabs={[
            {
              label: t("registryTitle"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={anagrafichePath + componentTabsPath + qualificaTabPath}
                  action={update}
                  fixedProps={fixedProps}
                  status={statusValidAnagraficaSoggettiQualifica}
                  error={errorBE}
                  update={true}
                  fields={allFieldsState}
                  translate={t}
                  obj={obj}
                  lookups={formLookups}
                  buttonLabel="confirmLabel"
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path={anagrafichePath + componentTabsPath + qualificaTabPath} exact>
        <Box flex={1} display='flex' justifyContent='center'>
        </Box>
        <Box mt={6} display="flex" justifyContent='right'>
          <Button
            size="large"
            variant="contained"
            onClick={() => insertCallback()}
          >
            {t("update")}
          </Button>
        </Box>
        <GeneralForm
          readOnly={true}
          language={i18n.language}
          componentPath={anagrafichePath + componentTabsPath + qualificaTabPath}
          action={update}
          fixedProps={fixedProps}
          status={statusValidAnagraficaSoggettiQualifica}
          error={errorBE}
          update={false}
          fields={allFields}
          translate={t}
          obj={anagraficaSoggettiEstesa}
          lookups={formLookups}
        />
        <Box mt={6} display="flex">
          <Box flex={1} display='flex' justifyContent='right'>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                setIsArchiveOpen(state => !state);
              }}
            >
              {t("archive")}
            </Button>
          </Box>
        </Box>
        {
          isArchiveOpen &&
          <>
            <Box mt={6}>
              <Paper >
                <CrudMaterialTable
                  abilitazione={abilitazione}
                  title={t(title)}
                  columns={columns}
                  columnsButton={true}
                  data={data}
                  elementIdProps={elementIdProps}
                  elementRenderProps={elementRenderProps}
                  fetchAllValid={fetchExtendedByIdAndDate}
                  restore={restore}
                  logicalDel={logicalDel}
                  physicalDel={physicalDel}
                  dataValid={toggleValidDeleted}
                  setToggleValidDeleted={setToggleValidDeleted}
                  statusValid={statusValidAnagraficaSoggettiQualifica}
                  statusDeleted={statusDeletedAnagraficaSoggettiQualifica}
                  errorBE={errorBE}
                  fixedProps={fixedProps}
                  exportDataExtra={exportDataExtra}
                  logoUri={logoUri}
                  nullableTextFields={true}
                  localizedDatePicker={true}
                  resetErrorCallback={resetError}
                  updateCallback={updateDetailCallback}
                  detailCallback={updateDetailCallback}
                  autofetch={false}
                  extraOptions={{
                    maxBodyHeight: 460,
                  }}
                />
              </Paper>
            </Box>
          </>
        }
      </Route>
    </Switch>
  )
}
export default AnagraficaSoggettiQualificaW;

