import { validateInputNumberMin, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type CentroDiCostoKeys = keyof CentroDiCosto;

export const elementIdProps: CentroDiCostoKeys[] = ["idCentroDiCosto"];
export const elementRenderProps: CentroDiCostoKeys[] = ["codice"];

export const allFields: Fields[] = [
    {
        field: "codice", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 2, 20, false, helperText, true), keyTradValidation: "size3to20ParamValidation",
        show: true, titleKey: "codeParam"
    },
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 150, false, helperText, true), keyTradValidation: "size3to150ParamValidation",
        show: true, titleKey: "descriptionParam"
    },
    {
        field: "livello", type: "numeric", required: false,
        validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterThanZeroValidation",
        show: true, titleKey: "levelParam"
    }
]
export interface CentroDiCosto {
    idCentroDiCosto: number,
    codice: string,
    descrizione: string,
    livello?: number,
    version: number
}