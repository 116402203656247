import { Box } from '@material-ui/core';
import React from 'react'
import { Lookup } from '../../../../models/Utils';
import { moveObjectFromListByIndexes } from '../../../../utils/utilfunctions';
import { Card } from './Card';

interface DndContainerProps<T> {
  data: T[],
  updateFieldCallback: (data: any[]) => void,
  lookup: Lookup,
  fixedItems?: (string | number)[]
}

interface Item {
  id: string | number,
  value: string
}

const DndContainer = (props: DndContainerProps<any>) => {
  const { data, updateFieldCallback, lookup, fixedItems } = props;

  const [componentData, setComponentData] = React.useState<Item[]>([]);

  React.useEffect(() => {
    setComponentData(() => {
      const state: any[] = [];

      const safeData = data ?? [];

      safeData.forEach(i => {
        state.push({
          id: i,
          value: lookup[i]
        })
      });

      return state;
    });
  }, [data, lookup]);

  const moveCard = React.useCallback((dragIndex: number, hoverIndex: number) => {
    const newComponentData = moveObjectFromListByIndexes(componentData as any[], dragIndex, hoverIndex);
    setComponentData(newComponentData);
    updateFieldCallback(newComponentData.map(i => i.id));
  }, [componentData, updateFieldCallback]);

  const removeItem = React.useCallback((id: number) => {
    const newComponentData = componentData.filter((i) => i.id !== id);
    setComponentData(newComponentData);
    updateFieldCallback(newComponentData.map(i => i.id));
  }, [componentData, updateFieldCallback]);

  const renderCard = React.useCallback((card: Item, index: number) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.value}
        moveCard={moveCard}
        removeItemCallback={removeItem}
        fixedItems={fixedItems}
      />
    );
  }, [fixedItems, moveCard, removeItem]);

  return (
    componentData.length > 0
      ? <Box width={'70%'} pl={2}>
        {componentData.map((card, i) => renderCard(card, i))}
      </Box>
      : <></>
  );
}

export default DndContainer