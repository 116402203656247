import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { allFields, TipiDocumenti, elementIdProps, elementRenderProps, TipiDocumentiKeys } from "../../../models/TipiDocumenti";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllValid,
  fetchAllDeleted,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetTipoDocumento,
  resetError as resetErrorTipoDocumento
} from "../../../store/slices/tipoDocumentoSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { tipidocumentiPath } from "../../../utils/utilconst";
import { sortingStringCI } from "../../../utils/utilfunctions";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";

const TipoDocumentoW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, tipidocumentiPath));

  const errorBE = useAppSelector(state => state.tipoDocumento.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorTipoDocumento());
  }, [dispatch])

  const title = t('documentTypes');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<TipiDocumenti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<TipiDocumenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as TipiDocumentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione)

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validTipoDocumento = useAppSelector(state => state.tipoDocumento.validtipoDocumento);
  const deletedTipoDocumento = useAppSelector(state => state.tipoDocumento.deletedtipoDocumento);

  const statusValidtipoDocumento = useAppSelector(state => state.tipoDocumento.statusValidtipoDocumento);
  const statusDeletedtipoDocumento = useAppSelector(state => state.tipoDocumento.statusDeletedtipoDocumento);

  const [data, setData] = useState<Array<TipiDocumenti>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validTipoDocumento) : setData(deletedTipoDocumento)
  }, [validTipoDocumento, deletedTipoDocumento, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetTipoDocumento());
    };
  }, [dispatch]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValid}
      fetchAllDeleted={fetchAllDeleted}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidtipoDocumento}
      statusDeleted={statusDeletedtipoDocumento}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      autofetch={true}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default TipoDocumentoW;