import { Column } from "@material-table/core";
import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, Assenza, allFields, AssenzaKeys } from "../../../models/Assenze";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeleted,
  fetchAllValid,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetAssenza,
  resetError as resetErrorAssenze
} from "../../../store/slices/assenzeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { assenzePath } from "../../../utils/utilconst";
import { sortingStringCI } from "../../../utils/utilfunctions";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";

const AssenzeW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, assenzePath));
  const errorBE = useAppSelector(state => state.assenze.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAssenze());
  }, [dispatch])

  const title = t('absencesTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Assenza>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Assenza> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.validate)
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as AssenzaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            }
            return false;
          }
        if (f.type && f.type !== "image" && f.type !== "file")
          obj.type = f.type;
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);
        if (f.defaultValue)
          obj.emptyValue = f.defaultValue;
        /*+++*/
        if (f.field === "ore") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }}
                inputProps={{ "min": 1 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        }
        /*+++*/
        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, t]);

  const validAssenze = useAppSelector(state => state.assenze.validAssenze);
  const deletedAssenze = useAppSelector(state => state.assenze.deletedAssenze);

  const statusValidAssenze = useAppSelector(state => state.assenze.statusValidAssenze);
  const statusDeletedAssenze = useAppSelector(state => state.assenze.statusDeletedAssenze);

  const [data, setData] = useState<Array<Assenza>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validAssenze.map(elem => {
      return {
        ...elem,
        richiestaApprovazione: elem.richiestaApprovazione ?? false,
        lungaAssenza: elem.lungaAssenza ?? false,
        assenzaProgrammata: elem.assenzaProgrammata ?? false,
        assenzaParziale: elem.assenzaParziale ?? false,

      }
    })) : setData(deletedAssenze.map(elem => {
      return {
        ...elem,
        richiestaApprovazione: elem.richiestaApprovazione ?? false,
        lungaAssenza: elem.lungaAssenza ?? false,
        assenzaProgrammata: elem.assenzaProgrammata ?? false,
        assenzaParziale: elem.assenzaParziale ?? false,

      }
    }));
  }, [validAssenze, deletedAssenze, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetAssenza());
    };
  }, [dispatch]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      columnsButton={true}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValid}
      fetchAllDeleted={fetchAllDeleted}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidAssenze}
      statusDeleted={statusDeletedAssenze}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      extraOptions={{
        maxBodyHeight: 460,
      }}
    />
  )
}
export default AssenzeW;