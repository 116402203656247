import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils';
import { ChatMessaggio, ChatMessaggioKeys, elementIdProps } from '../../models/ChatMessaggio';
import { cancelById, definitiveDeleteById, restoreById, upd } from '../../services/services.service'
import { createPortaleSaaS, executeOpByIdsPortaleSaaS, getAllDeletedByIdsPortaleSaaS, getAllValidByIdsPortaleSaaS, getByIdPortaleSaaS } from '../../services/funzionalita.service';
import { sortAscObjectsBy } from '../../utils/utilfunctions';
import _ from 'lodash';
import { Chat } from '../../models/Chat';
import { update as updateOp } from "../../utils/serviceConst";

const microservice = "chat";
const entity = "chatmessaggio";

interface ChatMessaggioState {
  statusValidChatMessaggioNonLetti: StatusEnum,
  validChatMessaggioNonLetti: { [chat: string]: ChatMessaggio[] },
  statusValidChatMessaggio: StatusEnum,
  validChatMessaggio: ChatMessaggio[],
  statusDeletedChatMessaggio: StatusEnum,
  deletedChatMessaggio: ChatMessaggio[],
  statusAllChatMessaggio: StatusEnum,
  allChatMessaggio: ChatMessaggio[],
  chatMessaggio: ChatMessaggio | null,
  lookup: Lookup,
  lookupExtended: Lookup,
  error: string | null
}

const initialState: ChatMessaggioState = {
  statusValidChatMessaggioNonLetti: StatusEnum.Succeeded,
  validChatMessaggioNonLetti: {},
  statusValidChatMessaggio: StatusEnum.Succeeded,
  validChatMessaggio: [],
  statusDeletedChatMessaggio: StatusEnum.Succeeded,
  deletedChatMessaggio: [],
  statusAllChatMessaggio: StatusEnum.Succeeded,
  allChatMessaggio: [],
  chatMessaggio: null,
  lookup: {},
  lookupExtended: {},
  error: null
}

interface IDChatMessaggio {
  idChatMessaggio?: number,
  idApplicazioneSoftware: number,
  idUtente1?: number,
  idUtente2?: number,
  nomeUtente1?: string,
  nomeUtente2?: string
}

// cerca/chatmessaggio/id/{idApplicazioneSoftware}/{utente2}
export const fetchAllMessaggioByAppSoftwareAndUtente = createAsyncThunk(entity + '/fetchAllMessaggioByAppSoftwareAndUtente', async (id: IDChatMessaggio) => {
  const args = [id.idApplicazioneSoftware, id.nomeUtente2];

  const response = await getByIdPortaleSaaS(microservice, entity, args);
  return response.data as ChatMessaggio[];
});

// get cerca/chatmessaggio/all/valid/nonletti/{idApplicazioneSoftware}/{idChatMessaggioMax}
export const fetchAllValidByAppSoftwareNonLetti = createAsyncThunk(entity + '/fetchAllValidByAppSoftwareNonLetti', async (id: { idApplicazioneSoftware: number, idChatMessaggioMax: number }) => {
  const args = ['nonletti', id.idApplicazioneSoftware, id.idChatMessaggioMax];

  const response = await getAllValidByIdsPortaleSaaS(microservice, entity, args);
  return response.data as ChatMessaggio[];
});

// get cerca/chatmessaggio/all/valid/{idApplicazioneSoftware}/{idUtente1}/{idUtente2}
export const fetchAllValidByAppSoftware = createAsyncThunk(entity + '/fetchAllValidByAppSoftware', async (id: IDChatMessaggio) => {
  const args = [id.idApplicazioneSoftware, id.idUtente1, id.idUtente2];
  const response = await getAllValidByIdsPortaleSaaS(microservice, entity, args, [entity + '/fetchAllValidByAppSoftware']);
  return response.data as ChatMessaggio[];
});

// get cerca/chatmessaggio/all/deleted/{idApplicazioneSoftware}/{idUtente1}/{idUtente2}
export const fetchAllDeletedByAppSoftware = createAsyncThunk(entity + '/fetchAllDeletedByAppSoftware', async (id: IDChatMessaggio) => {
  const args = [id.idApplicazioneSoftware, id.idUtente1, id.idUtente2];

  const response = await getAllDeletedByIdsPortaleSaaS(microservice, entity, args);
  return response.data as ChatMessaggio[];
});

// post inserisci/chatmessaggio
export const insert = createAsyncThunk(entity + '/insert', async (chatMessaggio: ChatMessaggio) => {
  const response = await createPortaleSaaS(chatMessaggio, microservice, entity);
  return response.data as ChatMessaggio;
});

// put modifica/chatmessaggio
export const update = createAsyncThunk(entity + '/update', async (chatMessaggio: ChatMessaggio) => {
  const response = await upd(chatMessaggio, microservice, entity);
  return response.data as ChatMessaggio;
});

// get modifica/chatmessaggio/{idApplicazioneSoftware}/{idUtente1}/{idUtente2}/{listaIdChatMessaggio}
export const updateMessaggiLetti = createAsyncThunk(entity + '/updateMessaggiLetti', async (obj: { chat: Chat, listMessaggi: number[] }) => {
  const chat = obj.chat;
  const listaMesaggi = obj.listMessaggi.join('-');

  const response = await executeOpByIdsPortaleSaaS(microservice, entity, 'get', updateOp, [chat.idApplicazioneSoftware, chat.idUtente1, chat.idUtente2, listaMesaggi]);
  return response.data as ChatMessaggio[];
});


// put elimina/chat/id/{idArt}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/chat/id/{idArt}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/chat/id/{idArt}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const chatMessaggioSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ChatMessaggioState) => {
      return initialState;;
    },
    resetError: (state: ChatMessaggioState) => {
      state.error = initialState.error;
      state.statusValidChatMessaggio = initialState.statusValidChatMessaggio;
      state.statusDeletedChatMessaggio = initialState.statusDeletedChatMessaggio;
      state.statusAllChatMessaggio = initialState.statusAllChatMessaggio;
    },
    removeMessaggiLetti: (state: ChatMessaggioState, action: { payload: string }) => {
      const temp = { ...state.validChatMessaggioNonLetti };
      delete temp[action.payload];
      state.validChatMessaggioNonLetti = { ...temp };
    },
    addValidMessaggi: (state: ChatMessaggioState, action: { payload: ChatMessaggio[] }) => {
      const toBeAdded = action.payload.filter(i => state.validChatMessaggio.findIndex(j => i.idChatMessaggio === j.idChatMessaggio) < 0);
      state.validChatMessaggio = [...state.validChatMessaggio, ...toBeAdded];
    },
    addNewMessaggio: (state: ChatMessaggioState, action: { payload: ChatMessaggio[] }) => {
      state.statusValidChatMessaggioNonLetti = StatusEnum.Succeeded;

      const message = [
        ...Object.values(state.validChatMessaggioNonLetti).flat(),
        ...action.payload
      ];

      const temp = _.groupBy(message, i => {
        return i.idApplicazioneSoftware + '-' + i.idUtente1 + '-' + i.idUtente2;
      });

      const orderedMessaggi: Record<string, ChatMessaggio[]> = {};
      Object.entries(temp).forEach(([chat, messaggi]) => {
        orderedMessaggi[chat] = sortAscObjectsBy(messaggi, 'inserimentoDateTime');
      });

      state.validChatMessaggioNonLetti = orderedMessaggi;
    }
  },
  extraReducers: builder => {
    // fetchAllMessaggioByAppSoftwareAndUtente
    builder.addCase(fetchAllMessaggioByAppSoftwareAndUtente.pending, (state) => {
      state.statusAllChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllMessaggioByAppSoftwareAndUtente.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusAllChatMessaggio = StatusEnum.Failed;
      state.allChatMessaggio = [];
    })
    builder.addCase(fetchAllMessaggioByAppSoftwareAndUtente.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio[]>) => {
      state.statusValidChatMessaggio = StatusEnum.Succeeded;
      state.allChatMessaggio = sortAscObjectsBy(payload, ['inserimentoDateTime']);
    })

    // fetchAllValidByAppSoftware
    builder.addCase(fetchAllValidByAppSoftware.pending, (state) => {
      state.statusValidChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllValidByAppSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChatMessaggio = StatusEnum.Failed;
      state.validChatMessaggio = [];
    })
    builder.addCase(fetchAllValidByAppSoftware.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio[]>) => {
      state.statusValidChatMessaggio = StatusEnum.Succeeded;
      state.validChatMessaggio = sortAscObjectsBy(payload, ['inserimentoDateTime']);
    })

    // fetchAllValidByAppSoftwareNonLetti
    builder.addCase(fetchAllValidByAppSoftwareNonLetti.pending, (state) => {
      state.statusValidChatMessaggioNonLetti = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllValidByAppSoftwareNonLetti.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChatMessaggioNonLetti = StatusEnum.Failed;
      state.validChatMessaggioNonLetti = {};
    })
    builder.addCase(fetchAllValidByAppSoftwareNonLetti.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio[]>) => {
      state.statusValidChatMessaggioNonLetti = StatusEnum.Succeeded;

      const temp = _.groupBy(payload, i => {
        return i.idApplicazioneSoftware + '-' + i.idUtente1 + '-' + i.idUtente2;
      });

      const orderedMessaggi: Record<string, ChatMessaggio[]> = {};
      Object.entries(temp).forEach(([chat, messaggi]) => {
        orderedMessaggi[chat] = sortAscObjectsBy(messaggi, 'inserimentoDateTime');
      });

      state.validChatMessaggioNonLetti = orderedMessaggi;
    })

    // fetchAllDeletedByAppSoftware
    builder.addCase(fetchAllDeletedByAppSoftware.pending, (state) => {
      state.statusDeletedChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllDeletedByAppSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedChatMessaggio = StatusEnum.Failed;
      state.deletedChatMessaggio = [];
    })
    builder.addCase(fetchAllDeletedByAppSoftware.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio[]>) => {
      state.statusDeletedChatMessaggio = StatusEnum.Succeeded;
      state.deletedChatMessaggio = sortAscObjectsBy(payload, ['inserimentoDateTime']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChatMessaggio = StatusEnum.Failed;
      state.chatMessaggio = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio>) => {
      state.statusValidChatMessaggio = StatusEnum.Succeeded;
      state.chatMessaggio = payload;
      state.validChatMessaggio = state.validChatMessaggio.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChatMessaggio = StatusEnum.Failed;
      state.chatMessaggio = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ChatMessaggio>) => {
      state.statusValidChatMessaggio = StatusEnum.Succeeded;
      state.validChatMessaggio = state.validChatMessaggio.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.chatMessaggio = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChatMessaggio = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidChatMessaggio = StatusEnum.Succeeded;
      const deleted = state.validChatMessaggio.find(el => payload.ids.every(idObj => el[idObj.name as ChatMessaggioKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedChatMessaggio = state.deletedChatMessaggio.concat([deleted]);
      }
      state.validChatMessaggio = state.validChatMessaggio.filter(el => payload.ids.some(idObj => el[idObj.name as ChatMessaggioKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedChatMessaggio = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedChatMessaggio = StatusEnum.Succeeded;
      const valid = state.deletedChatMessaggio.find(el => payload.ids.every(idObj => el[idObj.name as ChatMessaggioKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validChatMessaggio = state.validChatMessaggio.concat([valid]);
      }
      state.deletedChatMessaggio = state.deletedChatMessaggio.filter(el => payload.ids.some(idObj => el[idObj.name as ChatMessaggioKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedChatMessaggio = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedChatMessaggio = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedChatMessaggio = StatusEnum.Succeeded;
      state.deletedChatMessaggio = state.deletedChatMessaggio.filter(el => payload.ids.some(idObj => el[idObj.name as ChatMessaggioKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError, removeMessaggiLetti, addValidMessaggi, addNewMessaggio } = chatMessaggioSlice.actions;
export default chatMessaggioSlice.reducer;