import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusEnum } from '../../models/Utils';
import { Guida } from '../../models/Guida';
import { getByIdPortaleSaaS } from '../../services/funzionalita.service';

const microservice = "applicazionisoftwarefunzioni";
const entity = "applicazionisoftwarefunzioniguida";

interface GuidaState {
    statusGuida: StatusEnum;
    validGuidaComponenteField: Guida[];
    guidaAppSoftware: Guida | null;
    lingua: string;
    error: string | null;
}

const initialState: GuidaState = {
    statusGuida: StatusEnum.Succeeded,
    validGuidaComponenteField: [],
    guidaAppSoftware: null,
    lingua: 'it',
    error: null,
}

export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: {
    idApplicazioneSoftware: number,
    idApplicazioneSoftwareFunzione: number,
}) => {
    const args = Object.values(obj);

    const response = await getByIdPortaleSaaS(microservice, entity, args);
    return response.data as Guida;
});

export const guidaSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        setLingua: (state, { payload }) => {
            state.lingua = payload;
        },

        reset: (state: GuidaState) => {
            return initialState;
        },
        resetError: (state: GuidaState) => {
            state.error = initialState.error;
            state.statusGuida = initialState.statusGuida
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchById.pending, (state) => {
            state.statusGuida = StatusEnum.Loading;
        })
        builder.addCase(fetchById.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusGuida = StatusEnum.Failed;
            state.guidaAppSoftware = null;
        })
        builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Guida>) => {
            state.statusGuida = StatusEnum.Succeeded;
            state.guidaAppSoftware = payload;
        })

        // builder.addCase(insert.rejected, (state, action) => {
        //     state.error = (action.error.message) ? action.error.message : "";
        //     state.statusGuida = StatusEnum.Failed;
        //     state.guida = null;
        // })
        // builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Guida>) => {
        //     state.statusGuida = StatusEnum.Succeeded;
        //     state.guida = payload;
        //     state.validGuida = state.validGuida.concat([payload]);
        // })

        // builder.addCase(update.pending, (state) => {
        //     state.statusGuida = StatusEnum.Loading;
        // })
        // builder.addCase(update.rejected, (state, action) => {
        //     state.error = (action.error.message) ? action.error.message : "";
        //     state.statusGuida = StatusEnum.Failed;
        //     state.guida = null;
        // })
        // builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Guida>) => {
        //     state.statusGuida = StatusEnum.Succeeded;
        //     state.validGuida = state.validGuida.map(el => {
        //         if (elementIdProps.every(prop => el[prop] === payload[prop])){
        //             return {...payload, version: payload.version+1};
        //         }else return el;
        //     });
        //     state.guida = payload;
        // })

        // builder.addCase(physicalDel.pending, (state) => {
        //     state.statusDeletedFormazione = StatusEnum.Loading;
        // })
        // builder.addCase(physicalDel.rejected, (state, action) => {
        //     state.error = (action.error.message) ? action.error.message : "";
        //     state.statusDeletedFormazione = StatusEnum.Failed;
        // })
        // builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids:Ids<string>[] }>) => {
        //     state.statusDeletedFormazione = StatusEnum.Succeeded;
        //     state.deletedFormazione = state.deletedFormazione.filter(el => payload.ids.some(idObj => el[idObj.name] !== idObj.id));
        // })
    }
});

export const { reset, resetError } = guidaSlice.actions;
export const { setLingua } = guidaSlice.actions;
export default guidaSlice.reducer;