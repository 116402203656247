import { validateDate, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiContestazioneKeys = keyof AnagraficaSoggettiContestazione;

export const elementIdProps: AnagraficaSoggettiContestazioneKeys[] = ["idAnagraficaSoggetto", "idAnagraficaSoggettiContestazione"];
export const elementRenderProps: AnagraficaSoggettiContestazioneKeys[] = ["contestazione"];

export const allFields: Fields[] = [
    {
        field: "contestazione", type: "string", required: true, show: true, titleKey: "disputeParam",
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation"
    },
    {
        field: "esito", type: "string", required: false, show: true, titleKey: "resultParam",
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
    },
    {
        field: "dataContestazione", type: "date", required: true, show: true, titleKey: "disputeDateParam", sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
    }
]
export interface AnagraficaSoggettiContestazione {
    idAnagraficaSoggetto: number,
    idAnagraficaSoggettiContestazione: number,
    contestazione: string,
    esito?: string,
    dataContestazione: string,
    version: number
}