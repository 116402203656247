import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, cancelById, restoreById, getAllValidByIds, getAllDeletedByIds, postByIds, createWithOption } from '../../services/services.service'
import { AnagraficaSoggettiDocumenti } from '../../models/AnagraficaSoggettiDocumenti';


const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettidocumenti";

interface AnagraficaSoggettiDocumentiState {
  statusValidAnagraficaSoggettiDocumenti: StatusEnum,
  statusValidAnagraficaSoggettiDocumentiDipendenti: StatusEnum,
  validAnagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti[],
  // validAnagraficaSoggettiDocumentiDipendenti: AnagraficaSoggettiDocumenti[],
  statusDeletedAnagraficaSoggettiDocumenti: StatusEnum,
  deletedAnagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti[],
  anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti | null,
  error: string | null
}

const initialState: AnagraficaSoggettiDocumentiState = {
  statusValidAnagraficaSoggettiDocumenti: StatusEnum.Succeeded,
  statusValidAnagraficaSoggettiDocumentiDipendenti: StatusEnum.Succeeded,
  validAnagraficaSoggettiDocumenti: [],
  // validAnagraficaSoggettiDocumentiDipendenti: [],
  statusDeletedAnagraficaSoggettiDocumenti: StatusEnum.Succeeded,
  deletedAnagraficaSoggettiDocumenti: [],
  anagraficaSoggettiDocumenti: null,
  error: null
}

// get cerca/anagraficasoggettidocumento/all/valid/{idAnagraficaSoggetto}
export const fetchAllValidById = createAsyncThunk(entity + '/fetchAllValidById', async (obj: { idAnagraficaSoggetto: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idAnagraficaSoggetto], ['/fetchAllValidById']);
  return response.data as AnagraficaSoggettiDocumenti[];
});

// get cerca/anagraficasoggettidocumento/all/valid/true/{idAnagraficaSoggetto}
export const fetchAllValidByIdDocumentiDipendenti = createAsyncThunk(entity + '/fetchAllValidByIdDocumentiDipendenti', async (obj: { idAnagraficaSoggetto: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idAnagraficaSoggetto, true], ['/fetchAllValidByIdDocumentiDipendenti']);
  return response.data as AnagraficaSoggettiDocumenti[];
});

// get cerca/anagraficasoggettidocumento/all/deleted/{idAnagraficaSoggetto}
export const fetchAllDeletedById = createAsyncThunk(entity + '/fetchAllDeletedById', async (obj: { idAnagraficaSoggetto: number }) => {
  const response = await getAllDeletedByIds(microservice, entity, [obj.idAnagraficaSoggetto], ['/fetchAllDeletedById']);
  return response.data as AnagraficaSoggettiDocumenti[];
});

// post inserisci/anagraficasoggettidocumenti
export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti) => {
  const response = await create(anagraficaSoggettiDocumenti, microservice, entity);
  return response.data as AnagraficaSoggettiDocumenti;
});

// @PostMapping(value = "inserisci/anagraficasoggettidocumenti/massivo")
export const insertMassivo = createAsyncThunk(entity + '/insertMassivo', async (anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti[]) => {
  const response = await createWithOption(anagraficaSoggettiDocumenti, microservice, entity, ['massivo']);
  return response.data as AnagraficaSoggettiDocumenti[];
});

// put modifica/anagraficasoggettidocumenti
export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti) => {
  const response = await upd(anagraficaSoggettiDocumenti, microservice, entity);
  return response.data as AnagraficaSoggettiDocumenti;
});

// delete delete/anagraficasoggettidocumenti/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettidocumenti}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

// put elimina/anagraficasoggettidocumenti/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettidocumenti}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/anagraficasoggettidocumenti/id/{idAnagraficaSoggetto}/{idAnagraficaSoggettidocumenti}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// @PostMapping("cerca/anagraficasoggettidocumenti/uploadPdfCheckFile")
export const fetchbyidUploadPdfCheckFile = createAsyncThunk(entity + '/fetchbyidUploadPdfCheckFile', async (anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti) => {
  anagraficaSoggettiDocumenti.contenutoDocumento = anagraficaSoggettiDocumenti.contenutoDocumento.replace('data:application/pdf;base64,', '');
  const response = await postByIds(microservice, entity, ['uploadPdfCheckFile'], anagraficaSoggettiDocumenti);
  return response.data as AnagraficaSoggettiDocumenti[];
});


export const anagraficaSoggettiDocumentiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AnagraficaSoggettiDocumentiState) => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiDocumentiState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiDocumenti = initialState.statusValidAnagraficaSoggettiDocumenti;
      state.statusDeletedAnagraficaSoggettiDocumenti = initialState.statusDeletedAnagraficaSoggettiDocumenti;
    }
  },
  extraReducers: builder => {
    // fetchAllValidById
    builder.addCase(fetchAllValidById.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggettiDocumenti = [];
    })
    builder.addCase(fetchAllValidById.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti[]>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiDocumenti = payload;
    })


    // fetchAllValidById
    builder.addCase(fetchAllValidByIdDocumentiDipendenti.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumentiDipendenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdDocumentiDipendenti.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumentiDipendenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumentiDipendenti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggettiDocumenti = [];
    })
    builder.addCase(fetchAllValidByIdDocumentiDipendenti.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti[]>) => {
      state.statusValidAnagraficaSoggettiDocumentiDipendenti = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiDocumenti = payload;
    })

    // fetchAllDeletedById
    builder.addCase(fetchAllDeletedById.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.anagraficaSoggettiDocumenti = null;
    })
    builder.addCase(fetchAllDeletedById.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti[]>) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.deletedAnagraficaSoggettiDocumenti = payload;
    })


    // fetchbyidUploadPdfCheckFile
    builder.addCase(fetchbyidUploadPdfCheckFile.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(fetchbyidUploadPdfCheckFile.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggettiDocumenti = [];
    })
    builder.addCase(fetchbyidUploadPdfCheckFile.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti[]>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiDocumenti = payload;
    })


    // insertMassivo
    builder.addCase(insertMassivo.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(insertMassivo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggettiDocumenti = [];
    })
    builder.addCase(insertMassivo.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti[]>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.validAnagraficaSoggettiDocumenti = payload;
    })


    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.anagraficaSoggettiDocumenti = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.anagraficaSoggettiDocumenti = payload;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
      state.anagraficaSoggettiDocumenti = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDocumenti>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
      state.anagraficaSoggettiDocumenti = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiDocumenti = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiDocumenti = StatusEnum.Succeeded;
    })
  }
});

export const { reset, resetError } = anagraficaSoggettiDocumentiSlice.actions;
export default anagraficaSoggettiDocumentiSlice.reducer;

