import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type ArticoloLeggeKeys = keyof ArticoloLegge;

export const elementIdProps: ArticoloLeggeKeys[] = ["idArticoloLegge"];
export const elementRenderProps: ArticoloLeggeKeys[] = ["articolo"];

export const allFields: Fields[] = [
    {
        field: "articolo", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 50, false, helperText, true), keyTradValidation: "size1to50ParamValidation",
        show: true, titleKey: "articleParam"
    },
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, true), keyTradValidation: "size3to255ParamValidation",
        show: true, titleKey: "descriptionParam"
    }
]

export interface ArticoloLegge {
    idArticoloLegge: number,
    articolo: string,
    descrizione: string,
    version: number
}