import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, } from '../../services/services.service'
import { AnagraficaSoggettiDitta } from '../../models/AnagraficaSoggettiDitte';

const microservice = "anagraficasoggetti";
const entity = "ditteanagraficasoggetti";

interface AnagraficaSoggettiDittaState {
    statusValidAnagraficaSoggettiDitte: StatusEnum,
    validAnagraficaSoggettiDitte: AnagraficaSoggettiDitta[],
    anagraficaSoggettiDitta: AnagraficaSoggettiDitta | null,
    error: string | null
}

const initialState: AnagraficaSoggettiDittaState = {
    statusValidAnagraficaSoggettiDitte: StatusEnum.Succeeded,
    validAnagraficaSoggettiDitte: [],
    anagraficaSoggettiDitta: null,
    error: null
}

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiDitta: AnagraficaSoggettiDitta) => {
    const response = await create(anagraficaSoggettiDitta, microservice, entity);
    return response.data as AnagraficaSoggettiDitta;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiDitta: AnagraficaSoggettiDitta) => {
    const response = await upd(anagraficaSoggettiDitta, microservice, entity);
    return response.data as AnagraficaSoggettiDitta;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const anagraficaSoggettiDittaSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        reset: (state: AnagraficaSoggettiDittaState) => {
            return initialState;;
        },
        resetError: (state: AnagraficaSoggettiDittaState) => {
            state.error = initialState.error;
            state.statusValidAnagraficaSoggettiDitte = initialState.statusValidAnagraficaSoggettiDitte;
        }
    },
    extraReducers: builder => {
        // insert
        builder.addCase(insert.pending, (state) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Failed;
            state.anagraficaSoggettiDitta = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDitta>) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Succeeded;
            state.anagraficaSoggettiDitta = payload;
        })

        // update
        builder.addCase(update.pending, (state) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Failed;
            state.anagraficaSoggettiDitta = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiDitta>) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Succeeded;
            state.anagraficaSoggettiDitta = payload;
        })

        // physicalDelete
        builder.addCase(physicalDel.pending, (state) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusValidAnagraficaSoggettiDitte = StatusEnum.Succeeded;
        })
    }
});

export const { reset, resetError } = anagraficaSoggettiDittaSlice.actions;
export default anagraficaSoggettiDittaSlice.reducer;