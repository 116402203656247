import React from 'react';
import { Image, View } from 'react-pdf-renderer-v2';
import { calculateWidth, getImageDimensionsFromBase64 } from '../../../../../../utils/utilfunctions';

interface LogoProps {
  logo: string
}

const Logo = (props: LogoProps) => {
  const { logo } = props;

  const VIEW_HEIGHT = 100;
  const metadata = getImageDimensionsFromBase64(logo);

  return (
    <View
      style={{
        height: VIEW_HEIGHT,
        width: calculateWidth(metadata?.width ?? 0, metadata?.height ?? 0, VIEW_HEIGHT)
      }}
    >
      <Image
        src={
          (logo?.startsWith('data')
            ? ''
            : 'data:image/' + metadata?.type + ';base64,')
          + logo
        }
      />
    </View>
  )
}

export default Logo;