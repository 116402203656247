import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { Ditta, DittaKeys, elementIdProps } from '../../models/Ditte'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "ditte";
const entity = "ditte";
interface DitteState {
  statusValidDitte: StatusEnum,
  validDitte: Ditta[],
  statusDeletedDitte: StatusEnum,
  deletedDitte: Ditta[],
  ditta: Ditta | null,
  lookup: Lookup,
  error: string | null
}

const initialState: DitteState = {
  statusValidDitte: StatusEnum.Succeeded,
  validDitte: [],
  statusDeletedDitte: StatusEnum.Succeeded,
  deletedDitte: [],
  ditta: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Ditta;
});

export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Ditta[];
});

export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Ditta[];
});

export const insert = createAsyncThunk(microservice + '/insert', async (ditta: Ditta) => {
  const response = await create(ditta, microservice, entity);
  return response.data as Ditta;
});

export const update = createAsyncThunk(microservice + '/update', async (ditta: Ditta) => {
  const response = await upd(ditta, microservice, entity);
  return response.data as Ditta;
});

export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const ditteSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: DitteState) => {
      return initialState;;
    },
    resetError: (state: DitteState) => {
      state.error = initialState.error;
      state.statusValidDitte = initialState.statusValidDitte;
      state.statusDeletedDitte = initialState.statusDeletedDitte;

    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidDitte = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitte = StatusEnum.Failed;
      }
      state.ditta = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Ditta>) => {
      state.statusValidDitte = StatusEnum.Succeeded;
      state.ditta = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidDitte = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitte = StatusEnum.Failed;
      }
      state.validDitte = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Ditta[]>) => {
      state.statusValidDitte = StatusEnum.Succeeded;
      state.validDitte = payload ?? [];
      // let newLookup = {}
      // payload.forEach(t => newLookup[t.idDitta] = t.denominazione);
      state.lookup = createLookup(payload, 'idDitta', ['denominazione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedDitte = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedDitte = StatusEnum.Failed;
      }
      state.deletedDitte = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Ditta[]>) => {
      state.statusDeletedDitte = StatusEnum.Succeeded;
      state.deletedDitte = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidDitte = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitte = StatusEnum.Failed;
      }
      state.ditta = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Ditta>) => {
      state.statusValidDitte = StatusEnum.Succeeded;
      state.ditta = payload;
      state.validDitte = state.validDitte.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidDitte = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitte = StatusEnum.Failed;
      }
      state.ditta = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Ditta>) => {
      state.statusValidDitte = StatusEnum.Succeeded;
      state.validDitte = state.validDitte.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.ditta = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidDitte = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitte = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidDitte = StatusEnum.Succeeded;
      const deleted = state.validDitte.find(el => payload.ids.every(idObj => el[idObj.name as DittaKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedDitte = state.deletedDitte.concat([deleted]);
      }
      state.validDitte = state.validDitte.filter(el => payload.ids.some(idObj => el[idObj.name as DittaKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedDitte = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedDitte = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDitte = StatusEnum.Succeeded;
      const valid = state.deletedDitte.find(el => payload.ids.every(idObj => el[idObj.name as DittaKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validDitte = state.validDitte.concat([valid]);
      }
      state.deletedDitte = state.deletedDitte.filter(el => payload.ids.some(idObj => el[idObj.name as DittaKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedDitte = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedDitte = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedDitte = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDitte = StatusEnum.Succeeded;
      state.deletedDitte = state.deletedDitte.filter(el => payload.ids.some(idObj => el[idObj.name as DittaKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = ditteSlice.actions;
export default ditteSlice.reducer;