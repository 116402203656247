import { useEffect, useState } from 'react';
import { getFunzionalita, getHomeUri, getUritoGuida, getUriToTitle } from '../../../store/slices/funzionalitaSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { navigateTo } from '../../../store/slices/routingSlice';
import { useHistory } from 'react-router-dom';

import { closeAppbarStatus, openAppbarStatus } from '../../../store/slices/appbarSlice';

const useNavAppBar = () => {
  const dispatch = useAppDispatch();
  const titleBar = useAppSelector(state => state.routing.title);
  const selectedRoute = useAppSelector(state => state.routing.uri);
  const funzionalita = useAppSelector(getFunzionalita);
  const uritotitle = useAppSelector(getUriToTitle);
  const uritoguida = useAppSelector(getUritoGuida);

  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [variantDraw, setVariantDraw] = useState<"persistent" | "temporary" | "permanent">("persistent");
  const [newOpenedModules, setNewOpenedModules] = useState<boolean[][]>([[false]]);
  const guidaHelp = useAppSelector(state => state.routing.guida);
  const [mobileOpen, setMobileOpen] = useState(false);

  let titleID = 0;
  funzionalita.forEach(func => {
    if (func.descrizione === titleBar) {
      titleID = func.idApplicazioneSoftwareFunzione;
    } else if (func.funzioni) {
      func.funzioni.forEach(elem => {
        if (elem.descrizione === titleBar) {
          titleID = elem.idApplicazioneSoftwareFunzione
        }
      })
    }
  })

  useEffect(() => {
    dispatch(navigateTo({ uri: window.location.pathname, title: uritotitle[window.location.pathname], guida: uritoguida[window.location.pathname] }));
  }, [history, dispatch, uritotitle, uritoguida]);

  const handleClickModulo = (indLiv: number, ind: number) => {
    setNewOpenedModules(s => {
      if (s[indLiv] && s[indLiv][ind]) {
        return [...s.slice(0, indLiv),
        [...s[indLiv].slice(0, ind), !s[indLiv][ind], ...s[indLiv].slice(ind + 1, s[indLiv].length)],
        ...s.slice(indLiv + 1, s.length)
        ];
      } else if (s[indLiv]) {
        return [...s.slice(0, indLiv),
        [...s[indLiv].slice(0, ind), true, ...s[indLiv].slice(ind + 1, s[indLiv].length)],
        ...s.slice(indLiv + 1, s.length)
        ];
      } else {
        let newArr = [];
        newArr[ind] = true;
        return [...s.slice(0, indLiv),
          newArr,
        ...s.slice(indLiv + 1, s.length)
        ];
      }
    })
  };

  const isModuleOpened: (indLiv: number, ind: number) => boolean = (indLiv, ind) => {
    if (!newOpenedModules[indLiv]) return false;
    else if (!newOpenedModules[indLiv][ind]) return false;
    else return newOpenedModules[indLiv][ind];
  };

  const handleListItemClick = (uri: string, idApplicazioneSoftware: number, idApplicazioneSoftwareFunzione: number) => {
    history.push(uri);
    dispatch(navigateTo({ uri: uri, title: uritotitle[uri], guida: uritoguida[uri] }));
  };

  const homeTitle = useAppSelector(state => state.funzionalita.hometitle);
  const homeUri = useAppSelector(getHomeUri);
  const homeGuida = useAppSelector(state => state.funzionalita.homeguida);

  const handleLogoClick = () => {
    if (homeTitle && homeUri) {
      dispatch(navigateTo({ uri: homeUri, title: homeTitle, guida: homeGuida ?? false }));
      history.push(homeUri);
    }
  }

  const handleDrawerOpen = () => {
    if (variantDraw === "persistent") {
      setOpen(false);
      setVariantDraw("temporary")
    }
    if (variantDraw === "temporary") {
      setOpen(true);
      setVariantDraw("persistent")
    }
    dispatch(openAppbarStatus());
  };

  const handleDrawerHoverToggleOpen = () => {
    dispatch(closeAppbarStatus());
    setOpen(!open);
    setMobileOpen(!mobileOpen);

  };

  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen)
    dispatch(openAppbarStatus());
  }

  const handleMobileClose = () => {
    dispatch(closeAppbarStatus());
    setMobileOpen(!mobileOpen)
  }

  return {
    open, mobileOpen, selectedRoute, titleID, titleBar, funzionalita, guidaHelp, variantDraw,
    handleDrawerOpen, handleDrawerHoverToggleOpen, handleMobileOpen, handleMobileClose, handleClickModulo,
    isModuleOpened, handleListItemClick, handleLogoClick,
  };
}
export default useNavAppBar;