import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Lookup, StatusEnum } from '../../models/Utils'
import { ApplicazioneSoftware } from '../../models/ApplicazioniSoftware'
import { getByIdPortaleSaaS } from '../../services/funzionalita.service';

const microservice = "appsoftware";
const entity = "appsoftware";

interface AppSoftwareState {
  statusAppSoftware: StatusEnum,
  appSoftware: ApplicazioneSoftware | null,
  lookup: Lookup,
  translation: { [lang: string]: { [codice: string]: any } },
  error: string | null
}

const initialState: AppSoftwareState = {
  statusAppSoftware: StatusEnum.Succeeded,
  appSoftware: null,
  lookup: {},
  translation: {},
  error: null
}

// @GetMapping("cerca/appsoftware/id/{idApplicazioneSoftware}")
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (obj: { idApplicazioneSoftware: number }) => {
  const args = [obj.idApplicazioneSoftware]

  const response = await getByIdPortaleSaaS(microservice, entity, args);
  return response.data as ApplicazioneSoftware;
});

export const applicazioneSoftwareSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanTranslation: (state: AppSoftwareState) => {
      state.translation = {};
    },
    reset: (state: AppSoftwareState) => {
      return initialState;;
    },
    resetError: (state: AppSoftwareState) => {
      state.error = initialState.error;
      state.statusAppSoftware = initialState.statusAppSoftware;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusAppSoftware = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusAppSoftware = StatusEnum.Failed;
      state.appSoftware = null;
      state.translation = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ApplicazioneSoftware>) => {
      state.statusAppSoftware = StatusEnum.Succeeded;
      state.appSoftware = payload;
      const temp: Record<string, any> = {}
      payload.applicazioniSoftwareDizionarioDati.forEach(elem => {
        if (!temp[elem.lingua]) {
          temp[elem.lingua] = {};
        }
        temp[elem.lingua][elem.codice] = elem.valore.startsWith('[') ? JSON.parse(elem.valore) : elem.valore;
      })
      state.translation = { ...temp }
    })
  }
});

export const { reset, resetError } = applicazioneSoftwareSlice.actions;
export default applicazioneSoftwareSlice.reducer;