import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type FormazioneKeys = keyof Formazione;

export const elementIdProps: FormazioneKeys[] = ["idFormazione"];
export const elementRenderProps: FormazioneKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "controlloScadenze", type: "boolean", required: false, show: true, titleKey: "controlloScadenze"
  }
]
export interface Formazione {
  idFormazione: number,
  descrizione: string,
  controlloScadenze: boolean,
  version: number
}