import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, allFields, StruttureSedi, StruttureSediKeys } from "../../../models/StruttureSedi";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllValid, fetchAllDeleted, insert, update, logicalDel, restore, physicalDel, reset as resetStruttureSedi } from "../../../store/slices/struttureSediSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { strutturePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields, FormEventHandlerKeys, Handler } from "../../../models/Fields";
import { PDFExtraData } from "../../../models/Utils";
import { Button, Typography, TextField, Grid } from "@material-ui/core";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import GTModal from "../modal/GTModal";
import Maps from "../maps/Maps";
import { componentInsertPath, componentTabsPath, struttureSediTabPath } from "../../../utils/innerFuncPaths";
import { Route, Switch, useHistory } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import i18n from "../../../i18n";
import CoordinateListModal from "./UnitaOperativeSediSub/CoordinateListModal";
import { setMultipleState } from "../../../store/slices/temporaryStatesSlice";
import { ComponentStateSetter } from "../../../utils/data.types";

interface StruttureSediWProps {
  idStruttura: number
}

const StruttureSediW = ({ idStruttura }: StruttureSediWProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, strutturePath));
  const errorBE = useAppSelector(state => state.unitaOperativeSedi.error);

  const [map, setMap] = useState<any>(null);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const handleOpenMap = () => setOpenMap(true);

  const fixedProps = useMemo(() => {
    return {
      idStruttura
    };
  }, [idStruttura]);

  useEffect(() => {
    dispatch(fetchAllValid(fixedProps));
    dispatch(fetchAllDeleted(fixedProps));
  }, [dispatch, fixedProps]);

  const [obj, setObj] = useState<StruttureSedi | null>(null);

  const title = t('officesParam');

  const mainUri = strutturePath + componentTabsPath + struttureSediTabPath;
  // const geocodeApi = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + process.env.REACT_APP_GMAPS_API_KEY + '&address=';
  const geoCodeApi = process.env.REACT_APP_TOMTOM_FREE_FORM_API + '/search/2/geocode/'; // {query}.{ext}

  const searchParams = useMemo(() => {
    const tomtomKey = process.env.REACT_APP_TOMTOM_API_KEY;

    const searchParams: Record<string, string> = {
      key: tomtomKey ?? '',
      view: 'Unified',
      limit: '5'
    };

    return tomtomKey ? new URLSearchParams(searchParams) : null;
  }, []);

  const lookup = useAppSelector(state => state.unitaOperativeSedi.lookup)
  const lookupStr = useAppSelector(state => state.strutture.lookup);

  const mapField = 'mappa';
  const latitudeField = 'latitudine';
  const longitudineField = 'longitudine';

  const excludedFieldInTable = useMemo(() => [], []);
  const [data, setData] = useState<Array<StruttureSedi>>([]);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields.filter(e => ![latitudeField, longitudineField].includes(e.field)));
  const [columns, setColumns] = useState<Array<Column<StruttureSedi>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<StruttureSedi> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as StruttureSediKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.field === mapField) {
          obj.emptyValue = undefined;
          obj.render = (rowData: StruttureSedi) => {
            return <Button
              onClick={() => {
                handleOpenMap();
                setMap({ lat: Number(rowData.latitudine), lng: Number(rowData.longitudine) });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        return obj;
      }));
  }, [t, allFieldsState, excludedFieldInTable, lookup]);

  const fetchGeoCode = useCallback((address: string, setData: ComponentStateSetter<StruttureSedi>) => {
    const query = new URLSearchParams({ address });

    dispatch(setMultipleState([
      // setObject del generalForm che viene passato allo store in modo tale da accedere al <CoordinateListModal />
      {
        key: 'setData',
        value: setData
      },
      {
        key: 'coordinateModalOpen',
        value: false
      }
    ]));

    // TODO: Aggiornare nel caso di GeoCode di Google Maps
    searchParams && fetch(geoCodeApi + query.get('address') + '.json?' + searchParams.toString())
      .then(resp => resp.json())
      .then(data => {
        // For the complete interface, see https://developer.tomtom.com/geocoding-api/documentation/geocode
        const results = data?.results?.filter((e: { type: string; }) => e.type === 'Street');

        if (results?.length === 1) {
          const latitude = results[0].position.lat;
          const longitude = results[0].position.lon;

          setData(state => {
            return {
              ...state,
              latitudine: latitude,
              longitudine: longitude
            }
          })
        } else if (results?.length > 1) {
          /**
           * Salvare questi stati nello store perchè <CoordianteListModal /> non si aggiorna
           * se lo stato non viene passato per il <GeneralForm />
           */
          dispatch(setMultipleState([
            {
              key: 'coordinateModalOpen',
              value: true
            },
            {
              key: 'coordinateResultList',
              value: results
            }
          ]));
        }
      })
  }, [dispatch, geoCodeApi, searchParams]);

  const convertFieldsForGeneralForm = useCallback(() => {
    return allFields
      .filter(e => e.field !== mapField)
      .map(e => {
        if (e.field !== 'indirizzo')
          return e;

        return {
          ...e,
          customFormFieldRender: function (data: StruttureSedi, field: Fields, handler: Handler, hasError: boolean, helperText: string, setData: ComponentStateSetter<StruttureSedi>) {
            return <>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    required={field.required}
                    label={t(field.titleKey)}
                    multiline={field.multiline}
                    value={data[field.field as StruttureSediKeys] ? data[field.field as StruttureSediKeys] : ""}
                    error={hasError}
                    helperText={helperText}
                    onChange={(e) => field.type && handler[field.type as FormEventHandlerKeys](e as any, field.field, field.validate, field.validate2, field.field2Validation)}
                  />
                </Grid>
                {
                  // TODO: enable when Google Maps Geocode is available
                  process.env.NON_EXISTING_ENV &&
                  <Grid item >
                    <Button variant='outlined' onClick={() => data.indirizzo && fetchGeoCode(data.indirizzo, setData)}>
                      <Typography variant='overline'>{t('getDataLabel')}</Typography>
                    </Button>
                  </Grid>
                }
              </Grid>
              <CoordinateListModal />
            </>
          }
        } as Fields
      });
  }, [fetchGeoCode, t]);

  const insertCallback = useCallback(() => {
    setAllFieldsState(convertFieldsForGeneralForm());
    history.push(mainUri + componentInsertPath);
  }, [convertFieldsForGeneralForm, history, mainUri]);

  const updateCallback = useCallback((sede: StruttureSedi) => {
    setObj(sede);
    setAllFieldsState(convertFieldsForGeneralForm());
    history.push(mainUri + componentTabsPath);
  }, [convertFieldsForGeneralForm, history, mainUri]);

  const backButtonCallback = useCallback(() => {
    setObj(null);
    setAllFieldsState(allFields.filter(e => ![latitudeField, longitudineField].includes(e.field)));
  }, []);

  const newInsert = useCallback((arg: StruttureSedi) => {
    backButtonCallback();
    return insert(arg);
  }, [backButtonCallback]);

  const newUpdate = useCallback((arg: StruttureSedi) => {
    backButtonCallback();
    return update(arg);
  }, [backButtonCallback]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const validStruttureSedi = useAppSelector(state => state.struttureSedi.validStruttureSedi);
  const deletedStruttureSedi = useAppSelector(state => state.struttureSedi.deletedStruttureSedi);

  const statusValidStruttureSediState = useAppSelector(state => state.struttureSedi.statusValidStruttureSedi);
  const statusDeletedStruttureSediState = useAppSelector(state => state.struttureSedi.statusDeletedStruttureSedi);

  useEffect(() => {
    setData(toggleValidDeleted ? validStruttureSedi : deletedStruttureSedi);
  }, [deletedStruttureSedi, toggleValidDeleted, validStruttureSedi]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        if (exportDataExtra.head)
          exportDataExtra.head['Struttura'] = lookupStr[fixedProps[elem]];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetStruttureSedi())
    };
  }, [dispatch]);

  const Map = useMemo(() => {
    return map && map.lat && map.lng
      ? <Maps map={map} />
      : null
  }, [map]);

  return <>
    <Switch>
      <Route path={mainUri} exact>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fixedProps={fixedProps}
          fetchAllValid={fetchAllValid}
          fetchAllDeleted={fetchAllDeleted}
          insertCallback={insertCallback}
          updateCallback={updateCallback}
          logicalDel={logicalDel}
          restore={restore}
          physicalDel={physicalDel}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusValidStruttureSediState}
          statusDeleted={statusDeletedStruttureSediState}
          errorBE={errorBE}
          logoUri={logoUri}
          extraOptions={{
            maxBodyHeight: 460,
          }}
        />
      </Route>
      <Route path={mainUri + componentInsertPath} exact>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={mainUri}
          tabsUri={mainUri + componentInsertPath}
          tabsView={false}
          buttonTitle={t('officesTitle')}
          backButtonCallback={backButtonCallback}
          info1={''}
          tabs={[
            {
              label: t("officesTitle"),
              tabPath: '',
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={mainUri}
                action={newInsert as any}
                fixedProps={fixedProps}
                status={statusValidStruttureSediState}
                error={errorBE}
                obj={obj}
                update={false}
                fields={allFieldsState}
                translate={t}
              />,
              componentElse: <></>
            }
          ]}
        />
      </Route>
      <Route path={mainUri + componentTabsPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={mainUri}
          tabsUri={mainUri + componentTabsPath}
          tabsView={true}
          buttonTitle={t('officesTitle')}
          backButtonCallback={backButtonCallback}
          info1={''}
          tabs={[
            {
              label: t("officesTitle"),
              tabPath: mainUri,
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={mainUri}
                action={newUpdate as any}
                fixedProps={fixedProps}
                status={statusValidStruttureSediState}
                error={errorBE}
                obj={obj}
                update={true}
                fields={allFieldsState}
                translate={t}
              />,
              componentElse: <></>
            }
          ]}
        />
      </Route>
    </Switch>

    {
      openMap &&
      <GTModal
        openState={{
          open: openMap,
          setOpen: setOpenMap
        }}
      >
        {
          Map ??
          <Typography>
            Impossibile rilevare la posizione
          </Typography>
        }
      </GTModal>
    }
  </>
}
export default StruttureSediW;


