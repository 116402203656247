import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { UnitaOperativa, elementIdProps, UnitaOperativaKeys, } from '../../models/UnitaOperative'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, lookupServiceNoAbilitazione, restoreById, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "unitaoperative";
const entity = "unitaoperative";

interface LookupUnitaOperativa {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  nome: string,
}

interface UnitaOperativaState {
  statusValidUnitaOperativa: StatusEnum,
  validUnitaOperativa: UnitaOperativa[],
  statusDeletedUnitaOperativa: StatusEnum,
  deletedUnitaOperativa: UnitaOperativa[],
  unitaOperativa: UnitaOperativa | null,
  lookup: Lookup,
  error: string | null
}

const initialState: UnitaOperativaState = {
  statusValidUnitaOperativa: StatusEnum.Succeeded,
  validUnitaOperativa: [],
  statusDeletedUnitaOperativa: StatusEnum.Succeeded,
  deletedUnitaOperativa: [],
  unitaOperativa: null,
  lookup: {},
  error: null
}

// "cerca/unitaoperative/id/{extended}/{idStruttura}/{idDipartimento}/{idUnitaOperativa}"
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as UnitaOperativa;
});

// "cerca/unitaoperative/all/valid/{idStruttura}/{idDipartimento}"
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as UnitaOperativa[];
});

// "cerca/unitaoperative/all/deleted/{idStruttura}/{idDipartimento}"
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as UnitaOperativa[];
});

// "cerca/unitaoperative/lookup/noabilitazione"
export const lookupNoAbilitazione = createAsyncThunk(microservice + '/lookupNoAbilitazione', async () => {
  const response = await lookupServiceNoAbilitazione(microservice, entity);
  return response.data as LookupUnitaOperativa[];
});

export const lookup = createAsyncThunk(microservice + '/lookup', async () => {
  const response = await getAllValid(microservice, entity, ['/lookup']);
  return response.data as UnitaOperativa[];
});

export const insert = createAsyncThunk(microservice + '/insert', async (UnitaOperativa: UnitaOperativa) => {
  const response = await create(UnitaOperativa, microservice, entity);
  return response.data as UnitaOperativa;
});

export const update = createAsyncThunk(microservice + '/update', async (UnitaOperativa: UnitaOperativa) => {
  const response = await upd(UnitaOperativa, microservice, entity);
  return response.data as UnitaOperativa;
});

export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const unitaOperativeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: UnitaOperativaState) => {
      state.lookup = {};
    },
    reset: (state: UnitaOperativaState) => {
      return initialState;
    },
    resetError: (state: UnitaOperativaState) => {
      state.error = initialState.error;
      state.statusValidUnitaOperativa = initialState.statusValidUnitaOperativa;
      state.statusDeletedUnitaOperativa = initialState.statusDeletedUnitaOperativa;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.unitaOperativa = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.unitaOperativa = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.validUnitaOperativa = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa[]>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.validUnitaOperativa = payload ?? [];
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedUnitaOperativa = StatusEnum.Failed;
      }
      state.deletedUnitaOperativa = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa[]>) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Succeeded;
      state.deletedUnitaOperativa = payload ?? [];
    })

    // lookupNoAbilitazione
    builder.addCase(lookupNoAbilitazione.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(lookupNoAbilitazione.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(lookupNoAbilitazione.fulfilled, (state, { payload }: PayloadAction<LookupUnitaOperativa[]>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idUnitaOperativa', ['nome']);
    })

    // lookup
    builder.addCase(lookup.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(lookup.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(lookup.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa[]>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, "idUnitaOperativa", ["nome"]);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.unitaOperativa = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.unitaOperativa = payload;
      state.validUnitaOperativa = state.validUnitaOperativa.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
      state.unitaOperativa = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativa>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      state.validUnitaOperativa = state.validUnitaOperativa.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.unitaOperativa = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidUnitaOperativa = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUnitaOperativa = StatusEnum.Succeeded;
      const deleted = state.validUnitaOperativa.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativaKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedUnitaOperativa = state.deletedUnitaOperativa.concat([deleted]);
      }
      state.validUnitaOperativa = state.validUnitaOperativa.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedUnitaOperativa = StatusEnum.Loading;
      } else {
        state.statusDeletedUnitaOperativa = StatusEnum.Failed;
        state.error = (action.error.message) ? action.error.message : "";
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Succeeded;
      const valid = state.deletedUnitaOperativa.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativaKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validUnitaOperativa = state.validUnitaOperativa.concat([valid]);
      }
      state.deletedUnitaOperativa = state.deletedUnitaOperativa.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidUnitaOperativa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedUnitaOperativa = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativa = StatusEnum.Succeeded;
      state.deletedUnitaOperativa = state.deletedUnitaOperativa.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = unitaOperativeSlice.actions;
export default unitaOperativeSlice.reducer;