import { validateInputNumberMinMax, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type StrutturaTurnoOreKeys = keyof StrutturaTurnoOre;

export const elementIdProps: StrutturaTurnoOreKeys[] = ["idStruttura", "idTurno"];
export const elementRenderProps: StrutturaTurnoOreKeys[] = [];

export const allFields: Fields[] = [
    {
        field: "idTurno", required: true, show: true, titleKey: "shiftParam", editable: "onAdd",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "ore", type: "numeric", required: true, minVal: 1, maxVal: 24, show: true, titleKey: "hoursParam",
        validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 1, 24, false, helperText, true), keyTradValidation: "between1And24Validation",
    }
]
export interface StrutturaTurnoOre {
    idStruttura: number,
    idTurno: number,
    ore: number,
    version: number
}