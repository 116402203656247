import { validateDate, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiQualificaKeys = keyof AnagraficaSoggettiQualifica;

export const elementIdProps: AnagraficaSoggettiQualificaKeys[] = ["idAnagraficaSoggetto", "idAnagraficaSoggettiQualifica"];
export const elementRenderProps: AnagraficaSoggettiQualificaKeys[] = ['qualificaDescrizione'];

export const allFields: Fields[] = [
    {
        field: "dataNuovaQualifica", type: "date", required: true, show: true, titleKey: "newQualificationVariantDateParam", sort: 'desc', group: 'noGroup', editable: 'onAdd',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },
    {
        field: "idQualifica", required: true, show: false, titleKey: "qualificationParam", lookupField: true,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "qualificaDescrizioneBreve", required: true, show: true, titleKey: "qualificationVariationTitle", editable: 'never',
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

]

export interface AnagraficaSoggettiQualifica {
    idAnagraficaSoggetto: number,
    idAnagraficaSoggettiQualifica: number,
    idQualifica: number,
    qualificaDescrizione: string,
    qualificaDescrizioneBreve: string,
    dataNuovaQualifica: string,
    idAnagraficaSoggettiQualificaPrecedente?: number,
    version: number,
}