import { StyleSheet } from 'react-pdf-renderer-v2';

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
  },
  container: {
    marginTop: 30,
  },
  view: {
    paddingVertical: 10,
    paddingHorizontal: 18,
    marginTop: 30,
  },
  viewWithGroup: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#6e6e6e',
    position: 'relative'
  },
  groupTitle: {
    position: 'absolute',
    top: -5,
    bottom: 0,
    left: 10,
    right: 0,
    paddingHorizontal: 5,
    backgroundColor: '#fff',
    width: 60,
    textAlign: 'center',
    color: '#8e8e8e'
  },
  flexBox: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

  },

  col1: {
    flex: 1
  },
  col2: {
    flex: 3,
    fontFamily: 'Helvetica-Bold'
  },

  // table
  groupFormazione: {
    position: 'absolute',
    top: 5,
    bottom: 0,
    left: 10,
    right: 0,
    paddingHorizontal: 5,
    backgroundColor: '#fff',
    width: 80,
    textAlign: 'center',
    color: '#8e8e8e',
  },

  tableContainer: {
    paddingVertical: 10,
    paddingHorizontal: 3,
    marginTop: 10,


  },
  headerContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    paddingVertical: 10,

    fontStyle: 'bold',
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    alignItems: "flex-start",
    paddingVertical: 5,
  },

  tableColDescriptionTitle: {
    flex: 4,
  },

  tableColDateTitle: {
    flex: 1,
    textAlign: 'left',
  },

  tableColDescription: {
    flex: 4,
    fontFamily: 'Helvetica-Bold',
  },

  tableColDate: {
    flex: 1,
    textAlign: 'left',
    fontFamily: 'Helvetica-Bold'
  },

  groupTitleSchedaProfessionale: {
    position: 'absolute',
    top: -5,
    bottom: 0,
    left: 10,
    right: 0,
    paddingHorizontal: 5,
    backgroundColor: '#fff',
    width: 100,
    textAlign: 'center',
    color: '#8e8e8e'
  },

  groupTitlemax: {
    position: 'absolute',
    top: -5,
    bottom: 0,
    left: 10,
    right: 0,
    paddingHorizontal: 5,
    backgroundColor: '#fff',
    width: 75,
    textAlign: 'center',
    color: '#8e8e8e'
  },
  groupTitleSchedaSanitaria: {
    position: 'absolute',
    top: -5,
    bottom: 0,
    left: 10,
    right: 0,
    paddingHorizontal: 5,
    backgroundColor: '#fff',
    width: 80,
    textAlign: 'center',
    color: '#8e8e8e'
  },




});

export default styles;
