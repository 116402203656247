import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, cancelById, restoreById } from '../../services/services.service'
import { AnagraficaSoggettiContestazione } from '../../models/AnagraficaSoggettiContestazioni';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggetticontestazioni";

interface AnagraficaSoggettiContestazioneState {
  statusValidAnagraficaSoggettiContestazioni: StatusEnum,
  validAnagraficaSoggettiContestazioni: AnagraficaSoggettiContestazione[],
  statusDeletedAnagraficaSoggettiContestazioni: StatusEnum,
  deletedAnagraficaSoggettiContestazioni: AnagraficaSoggettiContestazione[],
  anagraficaSoggettiContestazione: AnagraficaSoggettiContestazione | null,
  error: string | null
}

const initialState: AnagraficaSoggettiContestazioneState = {
  statusValidAnagraficaSoggettiContestazioni: StatusEnum.Succeeded,
  statusDeletedAnagraficaSoggettiContestazioni: StatusEnum.Succeeded,
  validAnagraficaSoggettiContestazioni: [],
  deletedAnagraficaSoggettiContestazioni: [],
  anagraficaSoggettiContestazione: null,
  error: null
}

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiContestazione: AnagraficaSoggettiContestazione) => {
  const response = await create(anagraficaSoggettiContestazione, microservice, entity);
  return response.data as AnagraficaSoggettiContestazione;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiContestazione: AnagraficaSoggettiContestazione) => {
  const response = await upd(anagraficaSoggettiContestazione, microservice, entity);
  return response.data as AnagraficaSoggettiContestazione;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiContestazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AnagraficaSoggettiContestazioneState) => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiContestazioneState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiContestazioni = initialState.statusValidAnagraficaSoggettiContestazioni;
      state.statusDeletedAnagraficaSoggettiContestazioni = initialState.statusDeletedAnagraficaSoggettiContestazioni;
    }
  },
  extraReducers: builder => {
    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Failed;
      state.anagraficaSoggettiContestazione = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiContestazione>) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Succeeded;
      state.anagraficaSoggettiContestazione = payload;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Failed;
      state.anagraficaSoggettiContestazione = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiContestazione>) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Succeeded;
      state.anagraficaSoggettiContestazione = payload;
    })

    // physicalDelete
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Succeeded;
    })

    // logicalDelete
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAnagraficaSoggettiContestazioni = StatusEnum.Succeeded;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAnagraficaSoggettiContestazioni = StatusEnum.Succeeded;
    })
  }
});

export const { reset, resetError } = anagraficaSoggettiContestazioneSlice.actions;
export default anagraficaSoggettiContestazioneSlice.reducer;