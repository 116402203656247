import Axios from "axios";
import { CheckJWTResponse, TokenResponse } from "../models/AuthModels";
import { OrganizationInfo } from '../models/Organizzazione';
import { authURL, portaleBEUrl } from "../utils/utilconst";

export function checkJWT(token: string) {
  return Axios.get<CheckJWTResponse>(authURL + '/isvalid', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export function refreshToken() {
  return Axios.get<TokenResponse>(authURL);
}

export function getOrganizationByID(obj: { IDOrg: number, token: string }) {
  return Axios.get<OrganizationInfo>(portaleBEUrl + '/organizzazioni/cerca/id/' + obj.IDOrg, {
    headers: {
      Authorization: `Bearer ${obj.token}`
    }
  });
}