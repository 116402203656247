import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type QualificaKeys = keyof Qualifica;

export const elementIdProps: QualificaKeys[] = ["idQualifica"];
export const elementRenderProps: QualificaKeys[] = ["descrizioneBreve"];

export const allFields: Fields[] = [
    {
        field: "descrizioneBreve", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, true), keyTradValidation: "size1to10ParamValidation",
        show: true, titleKey: "descriptionShortParam"
    },
    {
        field: "descrizione", type: "string", required: true, group: 'noGroup',
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
        show: true, titleKey: "descriptionParam"
    },
    {
        field: "richiestaPianificazione", type: "boolean", required: false, show: true, titleKey: "planningRequest",
    },
    {
        field: "minutiElasticita", type: "numeric", required: false, show: true, titleKey: "minuteBoundParam", defaultValue: '0'
    },
    {
        field: "recuperoRichiesto", type: "boolean", required: false, show: true, titleKey: "recoveryRequiredParam",
    },
    {
        field: "timbraturaSoloPresenza", type: "boolean", required: false, show: true, titleKey: "stampForPresenceParam",
    },
]
export interface Qualifica {
    idQualifica: number,
    descrizioneBreve: string,
    descrizione: string,
    richiestaPianificazione: boolean,
    minutiElasticita?: number;
    recuperoRichiesto?: boolean;
    timbraturaSoloPresenza?: boolean;
    version: number
}

