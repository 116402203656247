import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, executeGetOpByIds, getAllByIds, getAllDataByIds, getById, getByIds, upd } from '../../services/services.service'
import { Presenza, elementIdProps } from '../../models/Presenze';
import { ConteggioAssenza } from '../../models/ConteggioAssenze';
import { Straordinari } from '../../models/VerificaStraordinari';
import { ConteggioQualifica } from '../../models/ConteggioQualifica';
import { insert as insertOp } from "../../utils/serviceConst";
import { PresenzaForm } from '../../core/components/crudWrappers/PresenzeW';

const microservice = "unitaoperative";
const entity = "presenze";

interface PresenzeState {
  statusValidPresenze: StatusEnum,
  validPresenze: Presenza[],
  presenza: Presenza | null,
  statusConteggioAssenza: StatusEnum,
  conteggioAssenza: ConteggioAssenza[],
  statusConteggioQualifica: StatusEnum,
  conteggioQualifica: ConteggioQualifica[],
  statusValidStraordinari: StatusEnum,
  validStraordinari: Straordinari[],
  // lookup: Lookup,
  error: string | null
}

const initialState: PresenzeState = {
  statusValidPresenze: StatusEnum.Succeeded,
  validPresenze: [],
  presenza: null,
  statusConteggioAssenza: StatusEnum.Succeeded,
  conteggioAssenza: [],
  statusConteggioQualifica: StatusEnum.Succeeded,
  conteggioQualifica: [],
  statusValidStraordinari: StatusEnum.Succeeded,
  validStraordinari: [],
  // lookup: {},
  error: null
}

//@GetMapping("cerca/presenze/all/{idAnagraficaSoggetto}/{dataInizio}/{dataFine}")
export const fetchAnagraficaSoggettoPerData = createAsyncThunk(entity + '/fetchAnagraficaSoggettoPerData', async (obj: {
  idAnagraficaSoggetto: number | string,
  dataInizio: string,
  dataFine: string,
}) => {
  const ids: Array<string | number> = Object.values(obj);

  const response = await getAllByIds(microservice, entity, ids);
  return response.data as Presenza[];
});


// inserisci/presenze/chiusuragiornata/{idStruttura}/{idDipartimento}/{idUnitaoperativa}/{dataPresenza}
export const chiusuraGiornata = createAsyncThunk(entity + '/chiusuraGiornata', async (obj: object) => {
  const idsArray = Object.values(obj);
  idsArray.unshift('chiusuragiornata');

  const response = await executeGetOpByIds(microservice, entity, insertOp, idsArray);
  return response.data as Presenza;
});

export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  idAnagraficaSoggetto: number;
  idPresenza: number,
}) => {
  const args = Object.values(ids)

  const response = await getById(microservice, entity, args);
  return response.data as Presenza;
});

// "cerca/unitaoperativeturninumeroaddetti/id"
export const fetchConteggioPerQualificaCompleto = createAsyncThunk(entity + '/fetchConteggioPerQualifica', async () => {
  const entity = 'unitaoperativeturninumeroaddetti';

  let ids = ['id'];

  const response = await getByIds(microservice, entity, ids, ['/fetchConteggioPerQualifica']);
  return response.data as ConteggioQualifica[];
});

export const fetchConteggioPerQualifica = createAsyncThunk(entity + '/fetchConteggioPerQualifica', async (obj: {
  idStruttura?: number,
  idDipartimento?: number,
  idUnitaOperativa?: number,
  presenzaData: string,
}) => {
  const entity = 'unitaoperativeturninumeroaddetti';

  let ids = obj ? Object.values(obj)?.filter(elem => elem !== undefined && elem !== null) : [];
  ids.unshift('id');

  const response = await getByIds(microservice, entity, ids, ['/fetchConteggioPerQualifica']);
  return response.data as ConteggioQualifica[];
});

// esterno -- cerca/presenze/nuovapresenza/{idAnagraficaSoggetto}/{presenzaData}
// interno -- cerca/presenze/nuovapresenza/{idAnagraficaSoggetto}/{presenzaData}/{idStruttura}/{idDipartimento}/{idUnitaOperativa}
export const fetchPreviousInfo = createAsyncThunk(entity + '/fetchPreviousInfo', async (obj: {
  idAnagraficaSoggetto: number,
  presenzaData: string,
  idStruttura?: number,
  idDipartimento?: number,
  idUnitaOperativa?: number,
}) => {
  const ids: Array<string | number> = Object.values(obj);

  ids.unshift("nuovapresenza");

  const response = await getByIds(microservice, entity, ids, ['/fetchPreviousInfo']);
  return response.data as Presenza;
});

export const fetchAssenzeBetweenDates = createAsyncThunk(entity + '/fetchAssenzeBetweenDates', async (obj: {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  da: string,
  a: string
}) => {
  const ids: Array<string | number> = Object.values(obj);

  ids.unshift("unitaOperativa", "turnoAssenzaBetweenDate");

  const response = await getAllDataByIds(microservice, entity, ids);
  return response.data as ConteggioAssenza[];
});

export const fetchAllBetweenDates = createAsyncThunk(entity + '/fetchAllBetweenDates', async (obj: {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  da: string,
  a: string
}) => {
  const ids: Array<string | number> = Object.values(obj);

  // ids.unshift("unitaOperativa");

  const response = await getAllByIds(microservice, entity, ids);
  return response.data as Presenza[];
});

export const fetchStraordinariBetweenDates = createAsyncThunk(entity + '/fetchStraordinariBetweenDates', async (obj: {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  da: string,
  a: string
}) => {
  const ids: Array<string | number> = Object.values(obj);

  ids.unshift("straordinari");

  const response = await getByIds(microservice, entity, ids, ['/fetchStraordinariBetweenDates']);
  return response.data as Straordinari[];
});

type PresenzaIdsKeys = 'idStruttura' | 'idDipartimento' | 'idUnitaOperativa' | 'dataRiferimento';
export const fetchByDate = createAsyncThunk(entity + '/fetchByDate', async (ids: Record<PresenzaIdsKeys, string | number>) => {
  let formattedIds = []

  for (let idKey in ids) {
    formattedIds.push(ids[idKey as PresenzaIdsKeys])
  }

  const response = await getAllByIds(microservice, entity, formattedIds);
  return response.data as Presenza[];
});

export const fetchBetweenDates = createAsyncThunk(entity + '/fetchBetweenDates', async (ids: Ids<string>[]) => {
  let formattedIds = []

  for (let idKey in ids) {
    formattedIds.push(ids[idKey])
  }

  const response = await getAllDataByIds(microservice, entity, formattedIds);
  return response.data as Presenza[];
});

export const insert = createAsyncThunk(entity + '/insert', async (presenza: PresenzaForm) => {
  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idAnagraficaSoggettoInternaConst = "idAnagraficaSoggetto int";
  const idAnagraficaSoggettoEsternaConst = "idAnagraficaSoggetto est";
  const reperibilitaConst = "reperibilita";
  const excludeFlagConst = "includiInseriti";

  if (presenza[idAnagraficaSoggettoInternaConst]) {
    presenza = { ...presenza, [idAnagraficaSoggettoConst]: presenza[idAnagraficaSoggettoInternaConst] };
  } else if (presenza[idAnagraficaSoggettoEsternaConst]) {
    presenza = { ...presenza, [idAnagraficaSoggettoConst]: presenza[idAnagraficaSoggettoEsternaConst] };
  }

  delete (presenza as Partial<PresenzaForm>)[excludeFlagConst];
  delete (presenza as Partial<PresenzaForm>)[idAnagraficaSoggettoInternaConst];
  delete (presenza as Partial<PresenzaForm>)[idAnagraficaSoggettoEsternaConst];

  presenza[reperibilitaConst] = presenza[reperibilitaConst] ?? false;

  const response = await create(presenza, microservice, entity);
  return response.data as Presenza;
});

export const update = createAsyncThunk(entity + '/update', async (presenza: Presenza) => {
  const response = await upd(presenza, microservice, entity);
  return response.data as Presenza;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const presenzeSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: PresenzeState) => {
      return initialState;
    },
    resetError: (state: PresenzeState) => {
      state.error = initialState.error;
      state.statusValidPresenze = initialState.statusValidPresenze;
      state.statusConteggioAssenza = initialState.statusConteggioAssenza;
      state.statusConteggioQualifica = initialState.statusConteggioQualifica;
      state.statusValidStraordinari = initialState.statusValidStraordinari;
    },
    resetPresenzaSingola: (state: PresenzeState) => {
      state.presenza = initialState.presenza;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.presenza = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.presenza = payload;
    })

    // chiusuraGiornata
    builder.addCase(chiusuraGiornata.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(chiusuraGiornata.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
    })
    builder.addCase(chiusuraGiornata.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
    })

    //fetchAnagrificaSoggettoPerData
    builder.addCase(fetchAnagraficaSoggettoPerData.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchAnagraficaSoggettoPerData.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.statusValidPresenze = StatusEnum.Failed;
        state.validPresenze = [];
      }
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(fetchAnagraficaSoggettoPerData.fulfilled, (state, { payload }: PayloadAction<Presenza[]>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = payload;
    })


    // fetchByDate
    builder.addCase(fetchByDate.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchByDate.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.validPresenze = [];
    })
    builder.addCase(fetchByDate.fulfilled, (state, { payload }: PayloadAction<Presenza[]>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = payload ?? [];
    })

    // fetchAllBetweenDates
    builder.addCase(fetchAllBetweenDates.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchAllBetweenDates.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.validPresenze = [];
    })
    builder.addCase(fetchAllBetweenDates.fulfilled, (state, { payload }: PayloadAction<Presenza[]>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = payload ?? [];
    })

    // fetchPreviousInfo
    builder.addCase(fetchPreviousInfo.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchPreviousInfo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.presenza = null;
    })
    builder.addCase(fetchPreviousInfo.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.presenza = payload;
    })

    // fetchConteggioPerQualifica
    builder.addCase(fetchConteggioPerQualifica.pending, (state) => {
      state.statusConteggioQualifica = StatusEnum.Loading;
    })
    builder.addCase(fetchConteggioPerQualifica.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusConteggioQualifica = StatusEnum.Failed;
      }
      state.conteggioQualifica = [];
    })
    builder.addCase(fetchConteggioPerQualifica.fulfilled, (state, { payload }: PayloadAction<ConteggioQualifica[]>) => {
      state.statusConteggioQualifica = StatusEnum.Succeeded;
      state.conteggioQualifica = payload;
    })

    // fetchAssenzeBetweenDates
    builder.addCase(fetchAssenzeBetweenDates.pending, (state) => {
      state.statusConteggioAssenza = StatusEnum.Loading;
    })
    builder.addCase(fetchAssenzeBetweenDates.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusConteggioAssenza = StatusEnum.Failed;
      }
      state.conteggioAssenza = [];
    })
    builder.addCase(fetchAssenzeBetweenDates.fulfilled, (state, { payload }: PayloadAction<ConteggioAssenza[]>) => {
      state.statusConteggioAssenza = StatusEnum.Succeeded;
      state.conteggioAssenza = payload ?? [];
    })

    // fetchStraordinariBetweenDates
    builder.addCase(fetchStraordinariBetweenDates.pending, (state) => {
      state.statusValidStraordinari = StatusEnum.Loading;
    })
    builder.addCase(fetchStraordinariBetweenDates.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidStraordinari = StatusEnum.Failed;
      }
      state.validStraordinari = [];
    })
    builder.addCase(fetchStraordinariBetweenDates.fulfilled, (state, { payload }: PayloadAction<Straordinari[]>) => {
      state.statusValidStraordinari = StatusEnum.Succeeded;
      state.validStraordinari = payload ?? [];
    })

    // fetchBetweenDates
    builder.addCase(fetchBetweenDates.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(fetchBetweenDates.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.validPresenze = [];
    })
    builder.addCase(fetchBetweenDates.fulfilled, (state, { payload }: PayloadAction<Presenza[]>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.presenza = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.presenza = payload;
      state.validPresenze = state.validPresenze.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
      state.presenza = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Presenza>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      state.validPresenze = state.validPresenze.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.presenza = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidPresenze = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPresenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPresenze = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidPresenze = StatusEnum.Succeeded;
      let deletedIdPresenza = 0;
      payload.ids.forEach(elem => {
        if (elem.name === 'idPresenza') deletedIdPresenza = elem.id;
      });
      state.validPresenze = state.validPresenze.filter(presenza => presenza.idPresenza !== deletedIdPresenza);
    })
  }
});

export const { reset, resetError, resetPresenzaSingola } = presenzeSlice.actions;
export default presenzeSlice.reducer;