import { validateDate, validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiDocumentiKeys = keyof AnagraficaSoggettiDocumenti;

export const elementIdProps: AnagraficaSoggettiDocumentiKeys[] = ["idAnagraficaSoggetto", "idAnagraficaSoggettoDocumento"];
export const elementRenderProps: AnagraficaSoggettiDocumentiKeys[] = ['tipoDocumentoDescrizione', 'nomeDocumento'];

export const allFields: Fields[] = [
  {
    field: "dataDocumento", type: "date", required: false, show: true, titleKey: "documentDateParam", sort: 'desc', readonly: false, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "idTipoDocumento", required: true, show: false, titleKey: "documentTypes", lookupField: true,
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "tipoDocumentoDescrizione", required: false, show: true, titleKey: "documentTypes", showOn: "table",
  },
  {
    field: "nomeDocumento", type: "string", required: true, show: true, titleKey: "documentName", sort: 'desc',
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation"
  },
  {
    field: "contenutoDocumento", type: "file", required: false, show: true, titleKey: "document", sort: 'desc',
  },
]


export const allFieldsCaricamentoDocumenti: Fields[] = [
  {
    field: "idTipoDocumento", required: true, show: false, titleKey: "documentTypes", lookupField: true,
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "tipoDocumentoDescrizione", required: false, show: true, titleKey: "documentTypes", showOn: "table",
  },
  {
    field: "nomeDocumento", type: "string", required: true, show: true, titleKey: "documentName", sort: 'desc',
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation"
  },
  {
    field: "contenutoDocumento", type: "file", required: false, show: true, titleKey: "document", sort: 'desc',
  },
]

export const allFieldsDipendenti: Fields[] = [
  {
    field: "dataDocumento", type: "date", required: false, show: true, titleKey: "documentDateParam", sort: 'desc', readonly: false, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "idTipoDocumento", required: true, show: false, titleKey: "documentTypes", lookupField: true,
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "tipoDocumentoDescrizione", required: false, show: true, titleKey: "documentTypes", showOn: "table",
  },
  {
    field: "nomeDocumento", type: "string", required: true, show: true, titleKey: "documentName", sort: 'desc',
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation"
  },
  {
    field: "contenutoDocumento", type: "file", required: false, show: true, titleKey: "document", sort: 'desc',
  },
]

export interface AnagraficaSoggettiDocumenti {
  idAnagraficaSoggetto: number;
  idAnagraficaSoggettoDocumento: number;
  idTipoDocumento: number;
  tipoDocumentoDescrizione: string,
  nomeDocumento: string;
  dataDocumento: string;
  estensioneDocumento: string;
  mediaType: string;
  contenutoDocumento: string;
  visibileAnagraficaSoggetto: boolean;
  version: number;
}


