
import { Fields } from "./Fields";

export type UnitaOperativaFormazioneKeys = keyof UnitaOperativaFormazione;

export const elementIdProps: UnitaOperativaFormazioneKeys[] = ["idFormazione"];
export const elementRenderProps: UnitaOperativaFormazioneKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idFormazione", required: true, show: true, titleKey: "trainingParam", editable: "onAdd", lookupField: true,
  },
]

export interface UnitaOperativaFormazione {
  idFormazione: number,
  version: number
}


