import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAll, getAllByIds, getAllDeletedByIds, getAllValidByIds, getById, restoreById, upd } from '../../services/services.service'
import { elementIdProps, UnitaOperativeSedi, UnitaOperativeSediKeys } from '../../models/UnitaOperativeSedi';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "unitaoperative";
const entity = "unitaoperativesedi";

interface UnitaOperativeSediState {
  statusValidUnitaOperativaSedi: StatusEnum,
  validUnitaOperativaSedi: UnitaOperativeSedi[],
  statusDeletedUnitaOperativaSedi: StatusEnum,
  deletedUnitaOperativaSedi: UnitaOperativeSedi[],
  statusSingleUnitaOperativaSede: StatusEnum,
  unitaOperativaSede: UnitaOperativeSedi | null,
  lookup: Lookup,
  error: string | null
}

const initialState: UnitaOperativeSediState = {
  statusValidUnitaOperativaSedi: StatusEnum.Succeeded,
  validUnitaOperativaSedi: [],
  statusDeletedUnitaOperativaSedi: StatusEnum.Succeeded,
  deletedUnitaOperativaSedi: [],
  statusSingleUnitaOperativaSede: StatusEnum.Succeeded,
  unitaOperativaSede: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number, idUnitaOperativaSede: number }) => {
  let response = await getById(microservice, entity, Object.values(obj));
  return response.data as UnitaOperativeSedi;
});

export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let response = await getAllValidByIds(microservice, entity, Object.values(obj), ['fetchAllValid'])
  return response.data as UnitaOperativeSedi[];
});

export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let response = await getAllDeletedByIds(microservice, entity, Object.values(obj), ['fetchAllDeleted'])
  return response.data as UnitaOperativeSedi[];
});

export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  let response = await getAll(microservice, entity);
  return response.data as UnitaOperativeSedi[];
});

export const fetchByIdUnitaOperativa = createAsyncThunk(entity + '/fetchByIdUnitaOperativa', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let response = await getAllByIds(microservice, entity, Object.values(obj))
  return response.data as UnitaOperativeSedi[];
});

export const insert = createAsyncThunk(entity + '/insert', async (unitaOperativaSede: UnitaOperativeSedi) => {
  const response = await create(unitaOperativaSede, microservice, entity);
  return response.data as UnitaOperativeSedi;
});

export const update = createAsyncThunk(entity + '/update', async (unitaOperativaSede: UnitaOperativeSedi) => {
  const response = await upd(unitaOperativaSede, microservice, entity);
  return response.data as UnitaOperativeSedi;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDel', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const unitaOperativeSediSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: UnitaOperativeSediState) => {
      return initialState;
    },
    resetError: (state: UnitaOperativeSediState) => {
      state.error = initialState.error;
      state.statusValidUnitaOperativaSedi = initialState.statusValidUnitaOperativaSedi;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusSingleUnitaOperativaSede = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusSingleUnitaOperativaSede = StatusEnum.Failed;
      state.unitaOperativaSede = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi>) => {
      state.statusSingleUnitaOperativaSede = StatusEnum.Succeeded;
      state.unitaOperativaSede = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
      state.validUnitaOperativaSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi[]>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      state.validUnitaOperativaSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idUnitaOperativaSede', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Failed;
      state.deletedUnitaOperativaSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi[]>) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Succeeded;
      state.deletedUnitaOperativaSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idUnitaOperativaSede', ['descrizione']);
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
      state.validUnitaOperativaSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi[]>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      state.validUnitaOperativaSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idUnitaOperativaSede', ['descrizione']);
    })

    // fetchByIdUnitaOperativa
    builder.addCase(fetchByIdUnitaOperativa.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdUnitaOperativa.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
      state.validUnitaOperativaSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchByIdUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi[]>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      state.validUnitaOperativaSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idUnitaOperativaSede', ['descrizione']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
      state.unitaOperativaSede = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      state.unitaOperativaSede = payload;
      state.validUnitaOperativaSedi = state.validUnitaOperativaSedi.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
      state.unitaOperativaSede = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativeSedi>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      state.validUnitaOperativaSedi = state.validUnitaOperativaSedi.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.unitaOperativaSede = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Succeeded;
      state.deletedUnitaOperativaSedi = state.deletedUnitaOperativaSedi.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativeSediKeys] !== idObj.id));
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaSedi = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUnitaOperativaSedi = StatusEnum.Succeeded;
      const deleted = state.validUnitaOperativaSedi.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativeSediKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedUnitaOperativaSedi = state.deletedUnitaOperativaSedi.concat([deleted]);
      }
      state.validUnitaOperativaSedi = state.validUnitaOperativaSedi.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativeSediKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedUnitaOperativaSedi = StatusEnum.Succeeded;
      const valid = state.deletedUnitaOperativaSedi.find(el => payload.ids.every(idObj => el[idObj.name as UnitaOperativeSediKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validUnitaOperativaSedi = state.validUnitaOperativaSedi.concat([valid]);
      }
      state.deletedUnitaOperativaSedi = state.deletedUnitaOperativaSedi.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativeSediKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = unitaOperativeSediSlice.actions;
export default unitaOperativeSediSlice.reducer;