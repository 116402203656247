import {
  Box, Button, Checkbox, createStyles, FormControl,
  FormControlLabel, Grid, IconButton, makeStyles,
  TextField, Theme, Typography
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AsyncThunk } from "@reduxjs/toolkit";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { useHistory } from "react-router-dom";
import { Fields, Handler, Validate, Validate2 } from "../../../models/Fields";
import { Lookup, StatusEnum } from "../../../models/Utils";
import { getDateDDMMYYYY, toBase64, toBase64PDF } from "../../../utils/utilfunctions";
import LoadingSvg from "../svgs/LoadingSvg";
import ClearIcon from '@material-ui/icons/Clear';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import HelpButton from '../buttons/HelpButton';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import itIT from 'date-fns/locale/it';
import enGB from 'date-fns/locale/en-GB';
import clsx from 'clsx';
import { sessionProfil } from "../../../utils/utilconst";
import { ComponentStateSetter, FixedProps } from "../../../utils/data.types";
import DialogConfirm from "./subComponents/DialogConfirm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DndContainer from "./DndContainer/DndContainer";

export interface OptionalArgs<T> {
  setInternalObj: ComponentStateSetter<T>
  isUpdate?: boolean
  setFields: ComponentStateSetter<Fields[]>
  setCalled: ComponentStateSetter<boolean>
  setFormLookups: ComponentStateSetter<Record<string, Lookup> | undefined>
  removeHelperText: (field: string) => void
  file: Object | null
}

export interface DialogProps<T> {
  title?: string,
  body: string,
  condition: (formObject: T) => boolean,
  postDialogConfirmCallback?: (setObject: ComponentStateSetter<T>) => void,
  clearErrorMessage?: () => void,
}

export interface ChecksCallbackReturn {
  type: 'info' | 'error',
  field: string,
  message: string
}

export interface AutocompleteData {
  id: number | string;
  value: string;
}

interface GeneralFormProps<T> {
  componentPath?: string;  // indirizzo di ritorno dopo un'operazione
  action?: (AsyncThunk<T, T, {}>);    // obbligatorio
  localAction?: (object: T, resetForm?: () => void) => void,
  status?: string;                            // obbligatorio
  buttonLabel?: string,
  error?: string | null;                       // obbligatorio
  obj?: T | null;
  readOnly?: boolean;                          // obbligatorio
  update?: boolean;                            // obbligatorio
  fields: Fields[];
  translate: (s: string) => string;
  lookups?: { [key: string]: Lookup };
  autoCompleteSearchAction?: (s: any, minLen?: number, maxlen?: number, minVal?: number, maxVal?: number) => void;
  fixedProps?: FixedProps;
  language?: string;
  groupOrder?: string[],
  checksCallback?: (obj: T | null, field: string, extraArgs: OptionalArgs<T>) => ChecksCallbackReturn | void;
  clearance?: () => void;
  multiplo?: boolean;
  excludedFields?: string[];
  dialogProps?: DialogProps<T>;
  objectCallback?: (obj: T) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2)
    },
    insertButton: {
      marginBottom: theme.spacing(4)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    groupFormTitle: {
      backgroundColor: "#fff",
      transform: "translate(50px,-50%)",
      left: 0,
      top: 0,
      position: "absolute",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem"
    },
    mt_1: {
      marginTop: "0.25rem"
    },
    mt_4: {
      marginTop: "1.5rem"
    },
    mr_8: {
      marginRight: "3.5rem"
    },
    textfieldInputRelative: {
      position: 'relative',
    },
    textfieldInputAbsolute: {
      position: 'absolute',
      right: 40,
      bottom: 0,
    },
    notAllowed: {
      cursor: 'not-allowed'
    },
    noShow: {
      display: 'none'
    }
  }),
);

const GeneralForm = (props: GeneralFormProps<any>) => {
  const { componentPath, action, localAction, status, buttonLabel,
    error, obj, readOnly, update, fields, translate, lookups,
    autoCompleteSearchAction, fixedProps, language, groupOrder,
    checksCallback, clearance, multiplo, excludedFields, dialogProps, objectCallback } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<Record<string, any>>(fixedProps ? { ...fixedProps } : {});
  const [allFields, setAllFields] = useState<Fields[]>(fields);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [called, setCalled] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [requiredKeys, setRequiredKeys] = useState<string[]>([]);
  const [dependentFields, setDependentFields] = useState<{ field: string, dependency?: string, validation?: Validate2 }[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [dateClearFiles, setDateClearFiles] = useState<Record<string, string>>({});
  const [errorUtilMex, setErrorUtilMex] = useState<Record<string, string | null> | null>(null);
  const [helperTextMex, setHelperTextMex] = useState<Record<string, string | null> | null>(null);
  const [file, setFile] = useState<Object | null>(null);
  const [_lookups, setLookups] = useState<Record<string, Lookup> | undefined>(lookups);

  const classes = useStyles() as Record<string, any>;

  useEffect(() => {
    setLookups(lookups);
  }, [lookups]);

  /**
   * initialize default values on insert using obj
   */
  useEffect(() => {
    if (obj && !update /* && Object.keys(object).length === 0 */) {
      setFormData(state => {
        return {
          ...state,
          ...obj
        }
      });
    }
  }, [obj, update]);

  /**
   * initialize default values on insert using fixedProps
   */
  useEffect(() => {
    if (fixedProps) {
      setFormData(prev => {
        return { ...prev, ...fixedProps }
      });
    }
  }, [fixedProps]);

  /**
   * let parent component know about updated object
   */
  useEffect(() => {
    objectCallback && objectCallback(formData);
  }, [formData, objectCallback]);

  /**
   * collect required allFields
   */
  useEffect(() => {
    setRequiredKeys(allFields.filter(fi => {
      return fi.required
        && ((update && fi.editable && ['always', 'onUpdate'].includes(fi.editable))
          || (!update && fi.editable && ['always', 'onAdd'].includes(fi.editable))
          || (!fi.editable));
    }).map(fi => fi.field));

    setDependentFields(allFields.filter(field => field.validate2 && (field.field2Validation !== undefined && field.field2Validation !== null && field.field2Validation !== ''))
      .map(field => {
        return {
          field: field.field,
          dependency: field.field2Validation,
          validation: field.validate2,
        };
      })
    );

    setDateClearFiles(obj => {
      let newObj: Record<string, string> = { ...obj }
      allFields
        .filter(fi => fi.type === "image" || fi.type === "file")
        .forEach(fi => newObj[fi.field] = Math.random().toString(36));
      return newObj;
    });
  }, [allFields, update]);

  /**
   * initialize object based on selected obj on update
   */
  useEffect(() => {
    if (update) {
      if (obj) {
        setFormData(prev => {
          return { ...prev, ...obj }
        });
      } else {
        componentPath && history.push(componentPath);
      }
    }
  }, [update, obj, componentPath, history]);

  const clearAttr = (attr: string) => {
    setFormData(state => {
      return {
        ...state,
        [attr]: null
      }
    });
  }

  const clearImage = (attr: string) => {
    setDateClearFiles(obj => { return { ...obj, [attr]: Math.random().toString(36) } });
    clearAttr(attr);
  }

  const clearFile = (attr: string) => {
    setFormData(state => {
      return { ...state, [attr]: null };
    });

    clearAttr(attr);

    setFile(null);
    setModifiedFieldOrGroup(attr);
    setCalledCallback(false);
  }

  /**
   * disable confirm action when a required field is empty
   */
  useEffect(() => {
    /**
     * disable button when there is an error message
     */
    if (errorUtilMex) setDisabled(true);
    else {
      let newDis = requiredKeys.some(key => formData[key] === undefined || formData[key] === null || formData[key] === "");
      newDis = newDis || Object.values(errors).some(t => t);

      dependentFields.forEach(field => {
        if (field.dependency && formData[field.dependency] && formData[field.field]) {

          let retval = (formData[field.dependency] && field.validation) ? field.validation(formData[field.field], formData[field.dependency], '') : true;

          if (typeof retval === 'boolean')

            newDis = newDis || !retval;
          else
            newDis = newDis || !retval.isValid;
        }
      });

      setDisabled(newDis);
    }
  }, [errors, formData, requiredKeys, errorUtilMex, dependentFields]);

  useEffect(() => {
    const clear = () => {
      setFormData({ ...fixedProps });
      setShowError(false);
    }
    if (called && status === StatusEnum.Succeeded) {
      setCalled(false);
      clear();
      componentPath && history.push(componentPath);
    } else if (called && status === StatusEnum.Failed) {
      setShowError(true);
      setCalled(false);
    }
  }, [called, status, componentPath, history, fixedProps]);

  /**
   * Dialog
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogResultOK, setDialogResultOK] = useState<boolean>(false);

  const submit = useCallback(() => {
    const resetForm = () => {
      setFormData({ ...fixedProps });
      setShowError(false);
    }
    if (!multiplo) {
      let dispObj: Record<string, string | number | boolean | Date> = {};

      Object.keys(formData).forEach(k => {
        let val = formData[k];
        if (typeof formData[k] === "string") {
          val = val.trim();
        }
        if (val !== null && val !== undefined && val !== "") {
          dispObj[k] = val;
        }
      });
      if (action) {
        dispatch(action(dispObj));
        setCalled(true);
      }
      else if (localAction) {
        setCalled(true);
        localAction(dispObj, resetForm);
      }
    }
  }, [action, dispatch, fixedProps, localAction, multiplo, formData]);

  /**
   * Continua con l'invio dei dati se risponde SI al dialog con la condizione vera
   */
  useEffect(() => {
    if (dialogResultOK && isOpen) { /*|| (dialogProps && (!dialogProps.condition || (dialogProps.condition && dialogProps.condition(object))))) { */
      dialogProps?.postDialogConfirmCallback && dialogProps.postDialogConfirmCallback(setFormData);
      setSubmitConfirmed(true);
      setDialogResultOK(false);
      setIsOpen(false);
    }
  }, [dialogProps, dialogResultOK, isOpen, submit]);

  const [submitConfirmed, setSubmitConfirmed] = useState<boolean>(false);
  useEffect(() => {
    if (submitConfirmed) {
      setSubmitConfirmed(false);
      submit();
    }
  }, [submit, submitConfirmed]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (dialogProps && ((dialogProps.condition(formData)) || error?.startsWith('#'))) {
      setIsOpen(true);
    } else {
      submit();

    }
  }

  useEffect(() => {
    if (error?.startsWith('#')) {
      setIsOpen(true);
    }
  }, [error]);

  const [modifiedFieldOrGroup, setModifiedFieldOrGroup] = useState<string>("");
  const [calledCallback, setCalledCallback] = useState<boolean>(false);
  useEffect(() => {
    let check: ChecksCallbackReturn | void = undefined;

    if (!calledCallback) {
      const removeHelperText = (field: string) => {
        setHelperTextMex(state => {
          return {
            ...state,
            [field]: null
          }
        });
      }

      check = checksCallback
        ? checksCallback(
          formData,
          modifiedFieldOrGroup,
          {
            setInternalObj: setFormData,
            isUpdate: update,
            setFields: setAllFields,
            setCalled,
            setFormLookups: setLookups,
            file,
            removeHelperText
          }
        )
        : undefined;

      setCalledCallback(true);
    }

    if (check) {
      switch (check.type) {
        case 'error':
          setErrorUtilMex(state => {
            return {
              ...state,
              [(check as ChecksCallbackReturn).field]: (check as ChecksCallbackReturn).message
            };
          });
          break;
        case 'info':
          setHelperTextMex(state => {
            return {
              ...state,
              [(check as ChecksCallbackReturn).field]: (check as ChecksCallbackReturn).message
            };
          });
          break;
      }
    }
  }, [formData, checksCallback, calledCallback, setCalledCallback, modifiedFieldOrGroup, update, file]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string, validate?: Validate, validate2?: Validate2, field2Validation?: string) => {
    setCalledCallback(false);

    const _errorUtilMex = errorUtilMex ? errorUtilMex[field] : null;
    if (_errorUtilMex && _errorUtilMex.length > 0) {
      setErrorUtilMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    const _helperTextMex = helperTextMex ? helperTextMex[field] : null;
    if (_helperTextMex && _helperTextMex.length > 0) {
      setHelperTextMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    setFormData(prev => { return { ...prev, [field]: event.target.value } });
    setModifiedFieldOrGroup(field);

    if (validate2 && field2Validation && formData[field2Validation])
      setErrors(prev => { return { ...prev, [field]: !validate2(event.target.value, formData[field2Validation], '') } });
    else if (validate)
      setErrors(prev => { return { ...prev, [field]: !validate(event.target.value, '') } });
  }

  const handleSelectChange = (field: string, value?: string | number, validate?: Validate, validate2?: Validate2, field2Validation?: string) => {
    setCalledCallback(false);

    const _errorUtilMex = errorUtilMex ? errorUtilMex[field] : null;
    if (_errorUtilMex && _errorUtilMex.length > 0) {
      setErrorUtilMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    const _helperTextMex = helperTextMex ? helperTextMex[field] : null;
    if (_helperTextMex && _helperTextMex.length > 0) {
      setHelperTextMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    setFormData(prev => { return { ...prev, [field]: value } });
    setModifiedFieldOrGroup(field);

    if (validate2 && field2Validation && formData[field2Validation])
      setErrors(prev => { return { ...prev, [field]: !validate2(value, formData[field2Validation], '') } });
    else if (validate)
      setErrors(prev => { return { ...prev, [field]: !validate(value, '') } });
  };

  const handleDateChange = (d: Date | null, field: string, validate?: Validate, validate2?: Validate2, field2Validation?: string) => {
    setCalledCallback(false);

    const _errorUtilMex = errorUtilMex ? errorUtilMex[field] : null;
    if (_errorUtilMex && _errorUtilMex.length > 0) {
      setErrorUtilMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    const _helperTextMex = helperTextMex ? helperTextMex[field] : null;
    if (_helperTextMex && _helperTextMex.length > 0) {
      setHelperTextMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    let fieldDate: string = '';
    if (d) {
      let month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setFormData(prev => { return { ...prev, [field]: fieldDate } });
    setModifiedFieldOrGroup(field);

    if (validate2 && field2Validation && formData[field2Validation]) {
      setErrors(prev => { return { ...prev, [field]: !validate2(fieldDate, formData[field2Validation], '') } });
    }
    else if (validate) {
      setErrors(prev => { return { ...prev, [field]: !validate(fieldDate, '') } });
    }
  };

  const handleChangeImage = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {

    if (event.target.files?.[0]) {
      let img = await toBase64(event.target.files[0]);
      setFormData(prev => { return { ...prev, [field]: img } });
    } else {
      setFormData(prev => { return { ...prev, [field]: null } });
      event.target.value = '';
    }
    setModifiedFieldOrGroup(field);
    setCalledCallback(false);
  }

  const handleChangePDF = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {

    if (event.target.files?.[0]) {
      let img = await toBase64PDF(event.target.files[0]);
      setFormData(prev => { return { ...prev, [field]: img } });
    } else {
      setFormData(prev => { return { ...prev, [field]: null } });
      event.target.value = '';
    }
    setFile(event.target.files?.[0] ?? null);
    setModifiedFieldOrGroup(field);
    setCalledCallback(false);
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setCalledCallback(false);

    const _errorUtilMex = errorUtilMex ? errorUtilMex[field] : null;
    if (_errorUtilMex && _errorUtilMex.length > 0) {
      setErrorUtilMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    const _helperTextMex = helperTextMex ? helperTextMex[field] : null;
    if (_helperTextMex && _helperTextMex.length > 0) {
      setHelperTextMex((state => {
        return {
          ...state,
          [field]: null
        }
      }));
    }

    setFormData(prev => { return { ...prev, [field]: event.target.checked } });
    setModifiedFieldOrGroup(field);
  }

  const [autoCompleteValue, setAutoCompleteValue] = useState<AutocompleteData | string | null>(null);
  const handleAutoCompleteChange = (newValue: AutocompleteData | null, field: string, allowMultipleDraggable: boolean = false) => {
    setCalledCallback(false);

    setModifiedFieldOrGroup(field);
    setFormData((state) => {
      if (allowMultipleDraggable) {
        const newState = {
          ...state
        }

        if (newState[field] == null) {
          newState[field] = [];
        }

        newState[field] = [...newState[field], newValue?.id ?? ""]

        return newState;
      } else {
        return { ...state, [field]: newValue ? newValue.id : "" };
      }
    });

    if (!allowMultipleDraggable)
      setAutoCompleteValue(newValue);
  };

  useEffect(() => {
    return () => {
      setFormData({});
      setErrors({});
      setCalled(false);
      setShowError(false);
      setRequiredKeys([]);
      setDisabled(true);
      setDateClearFiles({});
      setErrorUtilMex(null);
      clearance && clearance();
    };
  }, [clearance]);

  const getError = (field: string) => {
    if (errors[field]) return true;
    else if (errorUtilMex && (errorUtilMex.field === field)) return true;
    else return false;
  }

  const defaultGroups = {
    default: 'default',
    noGroup: 'noGroup',
  };

  const convertToAutoCompleteDataFormat = useCallback(
    (lookup: Lookup): AutocompleteData[] => {
      const retval: AutocompleteData[] = [];

      if (lookup) {
        Object.keys(lookup).forEach((e) => {
          retval.push({
            id: e,
            value: lookup[e]
          });
        });
      }

      return retval;
    }, []);

  const idAppSoftware = useMemo(() => {
    const _sessionProfil = sessionStorage.getItem(sessionProfil);
    return _sessionProfil ? JSON.parse(_sessionProfil)[0] : null
  }, []);

  const renderFields = (groupName: string, formFields: Fields[]) => {
    return formFields
      .filter(fi => {
        if (readOnly) {
          return fi.readonly !== false;
        }
        return true;
      })
      .map((fi: Fields) => {
        /**
         * readOnly
         */
        if (fi.formHidden)
          return (
            <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} container direction="column-reverse">
            </Grid>
          )
        else if (readOnly || fi.readonly || ((!update && fi.editable && ['onUpdate', 'never'].includes(fi.editable)) || (fi.editable && update && ['onAdd', 'never'].includes(fi.editable)))) {
          if (fi.isLabel)
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} container direction="column-reverse">
                <Typography className={classes.notAllowed}>
                  {formData?.[fi.field] ?? ''}
                </Typography>
              </Grid>
            )
          else if (fi.type === 'boolean') {
            return (
              <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={formData?.[fi.field] ? formData[fi.field] : false}
                      onChange={(e) => handleCheckboxChange(e, fi.field)}
                      inputProps={{
                        disabled: true,
                        className: clsx(classes.notAllowed, classes.noShow),
                      }}
                    />
                  }
                  className={clsx(classes.notAllowed)}
                  label={translate(fi.titleKey)}
                  labelPlacement="end"
                />
              </Grid>
            )
          }
          else if (fi.lookupField || fi.autocomplete)
            return (
              <Grid key={groupName + fi.field} className={clsx(classes.textfieldInputRelative)} item xs={fi.colsNum ? fi.colsNum : 6}>
                <TextField
                  fullWidth
                  multiline={fi.multiline}
                  label={translate(fi.titleKey)}
                  value={formData?.[fi.field] && _lookups?.[fi.field] ? (_lookups[fi.field][formData[fi.field]] || '') : ''}
                  error={getError(fi.field)}
                  helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: (formData?.[fi.field] && _lookups?.[fi.field]) ? true : false,
                  }}
                  inputProps={{
                    className: classes.notAllowed
                  }}
                />
                {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
              </Grid>
            )
          else
            return (
              <Grid key={groupName + fi.field} className={clsx(classes.textfieldInputRelative)} item xs={fi.colsNum ? fi.colsNum : 6}>
                <TextField
                  fullWidth
                  multiline={fi.multiline}
                  label={translate(fi.titleKey)}
                  value={[undefined, null].includes(formData?.[fi.field])
                    ? ''
                    : (fi.type && ['date', 'datetime'].includes(fi.type))
                      ? (getDateDDMMYYYY(new Date(formData[fi.field])) || '')
                      : (formData[fi.field])}
                  error={getError(fi.field)}
                  helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                  InputProps={{
                    readOnly: true
                  }}
                  inputProps={{
                    className: classes.notAllowed
                  }}
                />
                {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
              </Grid>
            )
        }
        /**
         * editable
         */
        else
          if (fi.customFormFieldRender) {
            const handler: Handler = {
              string: handleChange,
              numeric: handleChange,
              lookup: handleChange,
              image: handleChangeImage,
              file: handleChangePDF,
              boolean: handleCheckboxChange,
              time: handleSelectChange,
              date: handleDateChange,
              autocomplete: handleAutoCompleteChange
            }
            return (
              <Grid key={groupName + fi.field} className={clsx(classes.textfieldInputRelative)} item xs={fi.colsNum ? fi.colsNum : 6}>
                {
                  fi.customFormFieldRender(
                    formData,
                    fi,
                    handler,
                    getError(fi.field),
                    (fi.keyTradValidation && errors[fi.field])
                      ? translate(fi.keyTradValidation)
                      : (
                        errorUtilMex && errorUtilMex[fi.field]
                          ? errorUtilMex[fi.field]
                          : helperTextMex && helperTextMex[fi.field]
                            ? helperTextMex[fi.field]
                            : ''
                      ) ?? '',
                    setFormData
                  )
                }
                {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
              </Grid>
            );
          }
          else if (fi.type === 'string')
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <Grid key={groupName + fi.field} className={clsx(classes.textfieldInputRelative)} item xs={fi.colsNum ? fi.colsNum : 6}>
                <TextField
                  fullWidth
                  required={fi.required}
                  label={translate(fi.titleKey)}
                  multiline={fi.multiline}
                  value={formData?.[fi.field] ? formData[fi.field] : ""}
                  error={getError(fi.field)}
                  helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                  onChange={(e) => handleChange(e, fi.field, fi.validate, fi.validate2, fi.field2Validation)}
                />
                {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
              </Grid>
            )
          else if (fi.type === 'time')
            if (readOnly && update && !formData[fi.field]) return <></>
            else {
              const parseDate = new Date();
              parseDate.setHours(formData?.[fi.field]?.split(':')[0]);
              parseDate.setMinutes(formData?.[fi.field]?.split(':')[1]);

              return (
                <MuiPickersUtilsProvider key={groupName + fi.field} utils={DateFnsUtils} locale={(language === "en-GB" || language === "en-US" || language === "en") ? enGB : itIT}>
                  <Grid key={groupName + fi.field} className={clsx(classes.textfieldInputRelative)} item xs={fi.colsNum ? fi.colsNum : 6}>
                    <KeyboardTimePicker
                      fullWidth
                      clearable
                      required={fi.required}
                      label={translate(fi.titleKey)}
                      ampm={false}
                      keyboardIcon={<AccessTimeSharpIcon />}
                      invalidDateMessage={translate('notValidTimeValidation')}
                      placeholder='HH:mm'
                      format='HH:mm'
                      mask='__:__'
                      value={formData?.[fi.field] ? parseDate : null}
                      onChange={(_, value) => (value) && handleSelectChange(fi.field, value, fi.validate, fi.validate2, fi.field2Validation)}
                      InputLabelProps={{
                        shrink: true,

                      }}
                    />
                    {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                  </Grid>
                </MuiPickersUtilsProvider>
              )
            }
          else if (fi.type === 'boolean')
            if (readOnly && update && !formData[fi.field]) return <></>
            else {
              return (
                <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 12}>
                  <FormControlLabel
                    control={<Checkbox
                      color="primary"
                      checked={formData?.[fi.field] ? formData[fi.field] : false}
                      onChange={(e) => handleCheckboxChange(e, fi.field)}
                    />
                    }
                    label={translate(fi.titleKey)}
                    labelPlacement="end"
                  />
                </Grid>
              )
            }
          else if (fi.type === 'numeric' && (!fi.lookupField)) {
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                <TextField
                  type="number"
                  fullWidth
                  required={fi.required}
                  label={translate(fi.titleKey)}
                  value={formData?.[fi.field] ?? ""}
                  error={getError(fi.field)}
                  helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                  onChange={(e) => handleChange(e, fi.field, fi.validate, fi.validate2, fi.field2Validation)}
                  inputProps={{
                    min: fi.minVal,
                    max: fi.maxVal,
                    style: { textAlign: 'end' }
                  }}

                />
                {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
              </Grid>
            )
          } else if (fi.type === 'date')
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <MuiPickersUtilsProvider key={groupName + fi.field} utils={DateFnsUtils} locale={(language === "en-GB" || language === "en-US" || language === "en") ? enGB : itIT}>
                <Grid item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                  <KeyboardDatePicker
                    fullWidth
                    required={fi.required}
                    label={translate(fi.titleKey)}
                    format={(language === "en-GB" || language === "en-US" || language === "en") ? "MM/dd/yyy" : "dd/MM/yyyy"}
                    value={formData?.[fi.field] ?? null}
                    onChange={(e) => handleDateChange(e, fi.field, fi.validate, fi.validate2, fi.field2Validation)}
                    okLabel={(language === "en-GB" || language === "en-US" || language === "en") ? "OK" : "Inserisci"}
                    clearLabel={(language === "en-GB" || language === "en-US" || language === "en") ? "Clear" : "Pulisci"}
                    cancelLabel={(language === "en-GB" || language === "en-US" || language === "en") ? "Cancel" : "Annulla"}
                    error={getError(fi.field) || (!formData[fi.field] && fi.required && (!fi.readonly && !readOnly))}
                    invalidDateMessage={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                    minDate={fi.field2Validation ? formData[fi.field2Validation] : undefined}
                    minDateMessage={translate('endDateGreaterEqValidation')}
                    clearable
                  />
                  {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                </Grid>
              </MuiPickersUtilsProvider>
            )
          else if (fi.type === 'image')
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <Grid key={groupName + fi.field} item xs={12}>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid key={fi.field} item xs={fi.colsNum ? fi.colsNum : 6}>
                      <input
                        key={'image-' + dateClearFiles[fi.field]}
                        accept="image/*"
                        style={{ display: 'none' }}
                        id={"contained-button-file-" + fi.field}
                        type="file"
                        readOnly={readOnly || fi.readonly}
                        onChange={(e) => handleChangeImage(e, fi.field)}
                      />
                      <Box marginBottom={1} display="flex" alignItems="center">
                        <label htmlFor={"contained-button-file-" + fi.field}>
                          <Button disabled={readOnly} variant="contained" color="primary" component="span">
                            {translate(fi.titleKey)}
                          </Button>
                        </label>
                        {(!readOnly && formData[fi.field]) && (
                          <IconButton color="secondary" size="small" component="span" onClick={() => clearImage(fi.field)}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Box p={2} display="flex" justifyContent="center" alignItems="center" height="20vh" overflow="visible" border={1} borderRadius="borderRadius">
                        {(formData?.[fi.field]) ? (
                          <img
                            src={formData[fi.field].startsWith('data:') ? formData[fi.field] : 'data:image/png;base64,' + formData[fi.field]}
                            alt={fi.field} height="100%" />
                        ) : (
                          <Typography variant="body1">{translate("noLogo")}</Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          else if (fi.lookupField)
            if (readOnly && update && !formData[fi.field]) return <></>
            else
              if (fi.readonly) {
                return <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                  <TextField
                    fullWidth
                    required={fi.required}
                    label={translate(fi.titleKey)}
                    multiline={fi.multiline}
                    value={formData?.[fi.field] && _lookups?.[fi.field] ? _lookups[fi.field][formData[fi.field]] : ""}
                    error={getError(fi.field)}
                    helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                    onChange={(e) => handleChange(e, fi.field, fi.validate, fi.validate2, fi.field2Validation)}
                  />
                  {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                </Grid>
              } else {
                const data: Array<[string, string]> = [];    // Array<[value, key]>
                let valoreCorrente = formData?.[fi.field] && _lookups?.[fi.field] ? _lookups[fi.field][formData[fi.field]] : '';

                if (_lookups?.[fi.field])
                  Object.entries(_lookups[fi.field]).forEach(([key, value]) => {
                    data.push([value, key])
                  });

                data.sort();

                return (
                  <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        options={data}
                        value={[valoreCorrente ? valoreCorrente.trim() : '']}
                        onChange={(_, newValue: string | string[] | null) =>
                          handleSelectChange(fi.field, newValue ? newValue[1] : '', fi.validate)
                        }
                        id={"controllable-states-demo" + fi.field}
                        getOptionLabel={(option) => option[0] ?? ''}
                        fullWidth
                        renderInput={(params) => <TextField
                          {...params}
                          multiline={fi.multiline}
                          label={translate(fi.titleKey) + (fi.required ? ' *' : '')}
                          variant="outlined"
                          helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                        />}
                      />
                    </FormControl>
                    {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                  </Grid>
                );
              }
          else if (fi.autocomplete && !fi.allowMultipleDraggable) {
            if (readOnly && update && !formData[fi.field]) return <></>
            else {
              const getAutoCompleteObject = (field: string, id: string | number): AutocompleteData => {
                const retval: [string, string] | undefined = _lookups
                  && Object.entries(_lookups[field])
                    .find(elem => {
                      const key = typeof elem[0] === 'string' ? parseInt(elem[0]) : elem[0];

                      return key === id;
                    });

                return {
                  id: retval && retval?.[0] ? parseInt(retval[0]) : 0,
                  value: retval?.[1] ?? ''
                };
              }

              const data = (_lookups && convertToAutoCompleteDataFormat(_lookups[fi.field])) ?? [];

              return (
                <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                  <Autocomplete
                    freeSolo
                    options={data}
                    value={formData?.[fi.field] && autoCompleteValue
                      ? autoCompleteValue
                      : formData?.[fi.field] && update
                        ? getAutoCompleteObject(fi.field, formData[fi.field])
                        : null}
                    onChange={(e, value: string | AutocompleteData | null) => handleAutoCompleteChange((value as AutocompleteData), fi.field)}
                    onInputChange={(e, value) => autoCompleteSearchAction && autoCompleteSearchAction(value, fi.minLen)}
                    getOptionSelected={(lookup, val) => {
                      return (lookup as AutocompleteData).id === (val as AutocompleteData).id
                    }}
                    getOptionLabel={(option) => (option as AutocompleteData).value}
                    renderInput={(params) => {
                      return <TextField
                        {...params}
                        multiline={fi.multiline}
                        label={translate(fi.titleKey) + (fi.required ? ' *' : '')}
                        variant="outlined"
                        helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                      />
                    }}
                  />
                  {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                </Grid>
              );
            }
          }
          else if (fi.autocomplete && fi.allowMultipleDraggable) {
            if (readOnly && update && !formData[fi.field]) return <></>
            else {
              const getAutoCompleteObject = (field: string, id: string | number): AutocompleteData => {
                const retval: [string, string] | undefined = _lookups
                  && Object.entries(_lookups[field])
                    .find(elem => {
                      const key = typeof elem[0] === 'string' ? parseInt(elem[0]) : elem[0];

                      return key === id;
                    });

                return {
                  id: retval && retval?.[0] ? parseInt(retval[0]) : 0,
                  value: retval?.[1] ?? ''
                };
              }

              const data = (_lookups && convertToAutoCompleteDataFormat(_lookups[fi.field])) ?? [];

              const updateFieldCallback = (newData: unknown) => {
                setFormData(state => {
                  return {
                    ...state,
                    [fi.field]: newData
                  };
                });
              }

              const fixedItems = !update ? (obj?.[fi.field] ?? []) : []; // TODO: works only in insert

              return (
                <Grid key={groupName + fi.field} item xs={fi.colsNum ? fi.colsNum : 6} className={clsx(classes.textfieldInputRelative)}>
                  <Autocomplete
                    freeSolo
                    options={data.filter(i => {
                      const selectedData = (formData[fi.field] ?? []).concat(fixedItems);

                      // eslint-disable-next-line eqeqeq
                      return !selectedData.some((j: any) => j == i.id)
                    })}
                    clearOnBlur
                    value={formData?.[fi.field] && autoCompleteValue
                      ? autoCompleteValue
                      : formData?.[fi.field] && update
                        ? getAutoCompleteObject(fi.field, formData[fi.field])
                        : null}
                    onChange={(e, value: string | AutocompleteData | null) => handleAutoCompleteChange((value as AutocompleteData), fi.field, fi.allowMultipleDraggable)}
                    onInputChange={(e, value) => autoCompleteSearchAction && autoCompleteSearchAction(value, fi.minLen)}
                    getOptionSelected={(lookup, val) => {
                      return (lookup as AutocompleteData).id === (val as AutocompleteData).id
                    }}
                    getOptionLabel={(option) => (option as AutocompleteData).value}
                    renderInput={(params) => {
                      return <TextField
                        {...params}
                        value=''
                        multiline={fi.multiline}
                        label={translate(fi.titleKey) + (fi.required ? ' *' : '')}
                        variant="outlined"
                        helperText={(fi.keyTradValidation && errors[fi.field]) ? translate(fi.keyTradValidation) : (errorUtilMex && errorUtilMex[fi.field] ? errorUtilMex[fi.field] : helperTextMex && helperTextMex[fi.field] ? helperTextMex[fi.field] : "")}
                      />
                    }}
                  />
                  {fi.hasHelp && <HelpButton className={clsx(classes.textfieldInputAbsolute)} topics={{ [idAppSoftware]: translate(fi.titleKey) }} importance='secondary' />}
                  {
                    formData[fi.field]?.length > 0 &&
                    <Box py={2} border={1} borderColor='#cecece' borderTop={0} borderRadius={'borderRadius'}>
                      <DndProvider backend={HTML5Backend}>
                        {
                          _lookups &&
                          <DndContainer
                            data={formData[fi.field]}
                            updateFieldCallback={updateFieldCallback}
                            lookup={_lookups[fi.field]}
                            fixedItems={fixedItems}
                          />
                        }
                      </DndProvider>
                    </Box>
                  }
                </Grid>
              );
            }
          }
          else if (fi.type === 'file')
            if (readOnly && update && !formData[fi.field]) return <></>
            else return (
              <Grid key={groupName + fi.field} item xs={12}>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid key={fi.field} item xs={fi.colsNum ? fi.colsNum : 6}>
                      <input
                        key={dateClearFiles[fi.field]}
                        accept="application/pdf, image/jpeg, image/png"
                        style={{ display: 'none' }}
                        id={"contained-button-file-" + fi.field}
                        type="file"
                        readOnly={readOnly || fi.readonly}
                        onChange={(e) => handleChangePDF(e, fi.field)}
                      />
                      <Box marginBottom={1} display="flex" alignItems="center">
                        <label htmlFor={"contained-button-file-" + fi.field}>
                          <Button disabled={readOnly} variant="contained" color="primary" component="span">
                            {translate('upload') + ' ' + translate(fi.titleKey)}
                          </Button>
                        </label>
                        {(!readOnly && formData?.[fi.field]) && (
                          <IconButton color="secondary" size="small" component="span" onClick={() => clearFile(fi.field)}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          else return <></>
      })
  }

  // TODO: Manage fields of subGroups without group
  const renderBoxedFieldGroup = (groupName: string, formFields: Fields[], subGroupIndex: number) => {
    const subGroups: string[] = [];

    /**
     * get all subgroup at level subGroupIndex
     */
    formFields.forEach(field => {
      if (field.subGroup?.length && field.subGroup.length > subGroupIndex && field.subGroup && !subGroups.includes(field.subGroup[subGroupIndex])) {
        subGroups.push(field.subGroup[subGroupIndex])
      }
    });

    /**
     * return renderedFields when there is no subGroups in formFields
     */
    return [
      <Grid key={groupName + subGroupIndex} item xs={12}>
        <Box position="relative" border={2} borderColor="#dee2e6" borderRadius={4} px={3} py={4} mt={2}>
          {
            !(groupName in defaultGroups) &&
            <Box className={classes.groupFormTitle}>
              <Typography variant="overline">
                {translate(groupName)}
              </Typography>
            </Box>
          }
          <Grid container spacing={2} className={groupName in defaultGroups ? '' : classes.mt_1}>
            {
              subGroups.length === 0
                ? renderFields(groupName, formFields) // render fields when there is no subGroups in formFields
                : subGroups.map(group => {
                  return renderBoxedFieldGroup(
                    group,
                    formFields.filter(field => field.subGroup?.[subGroupIndex] === group),
                    subGroupIndex + 1
                  )
                })
            }
          </Grid>

          {
            multiplo &&
            <Box display="flex" justifyContent="center" mt={4}>
              <Button size="large" variant="contained" disabled={disabled} onClick={() => {
                setModifiedFieldOrGroup(groupName);
                setCalledCallback(false);
              }}>
                {translate("update")}
              </Button>
            </Box>
          }

        </Box>
      </Grid>
    ]
  }

  /**
   * filter allFields by groups
   */
  const groups = new Map<string, any[]>();
  allFields.filter((fi: Fields) => (
    ((fi.type && ['string', 'date', 'image', 'numeric', 'boolean', 'time', 'file',].includes(fi.type)) || fi.lookupField || fi.autocomplete) &&
    (!excludedFields || !excludedFields.includes(fi.field)) && ['both', 'form', undefined, null].includes(fi.showOn)))

    .forEach(field => {
      if (!field.group) {
        field.group = defaultGroups.default;
      }

      if (groups.has(field.group)) {
        const data = groups.get(field.group) ?? [];
        data.push(field);
        groups.set(field.group, data);
      }
      else {
        groups.set(field.group, [field]);
      }
    });

  /**
   * generate field subgroup form
   */
  let formFields: JSX.Element[] = [];
  if (!groupOrder || groupOrder.length === 0) {
    /* without group order */
    groups.forEach((allFields: Fields[], group: string) => {
      if (group !== defaultGroups.noGroup && (!(group in defaultGroups) || groups.size >= 2)) {
        formFields = formFields.concat(renderBoxedFieldGroup(group, allFields, 0));
      } else { // key === defaultGroups.noGroup && groups.size === 1
        formFields = formFields.concat(renderFields(group, allFields));
      }
    });
  } else {
    /* with group order */
    if (!groupOrder.includes(defaultGroups.default)) groupOrder.push(defaultGroups.default);
    groupOrder.forEach(group => {
      const allFields = groups.get(group);
      if (allFields)
        if (group !== defaultGroups.noGroup && (!(group in defaultGroups) || groups.size > 2)) {
          formFields = formFields.concat(renderBoxedFieldGroup(group, allFields, 0));
        } else { // all allFields belong to noGroup
          formFields = formFields.concat(renderFields(group, allFields));
        }
    });
  }

  return <>
    {
      called ? <LoadingSvg color="primary" width={300} /> : (
        <Grid container justifyContent="center">
          <Grid item xs={12} className={((groupOrder && groupOrder.length > 0) || groups.size > 0) && action ? classes.mt_4 : ""}>
            <form key={"errorPane"} onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid container spacing={(groupOrder && groupOrder.length > 0) || groups.size > 1 ? 6 : 2}>
                {formFields}
                {
                  (showError && error && error.length > 1 && !error.startsWith('#')) &&
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" height="5vh">
                      <Typography variant="h6" color="error">{error}</Typography>
                    </Box>
                  </Grid>
                }
                {
                  !readOnly && !multiplo && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Button className={classes.insertButton} size="large" type="submit" variant="contained" disabled={disabled}>{
                          buttonLabel
                            ? translate(buttonLabel)
                            : update
                              ? translate("save")
                              : translate("insert")
                        }</Button>
                      </Box>
                    </Grid>
                  )
                }
              </Grid>
            </form>
          </Grid>
        </Grid>
      )
    }
    {
      dialogProps &&
      <DialogConfirm
        open={{
          isOpen: isOpen,
          setIsOpen: setIsOpen
        }}
        setResult={setDialogResultOK}
        clearErrorMessage={dialogProps?.clearErrorMessage}
        title={dialogProps.title ?? ''}
        body={error?.startsWith('#')
          ? (error?.substring(1) + ' ' + translate('proceedPrompt'))
          : (dialogProps.body || 'Vuoi continuare?')}
      />
    }
  </>
}
export default GeneralForm;
