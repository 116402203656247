import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';

import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { defaultValue, filterGroup, Group, renderData } from './utils/utils';

interface SchedaSanitariaSectionProps {
  schedaSanitaria: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    dataVisita: ['visitDateParam', 'date'],
    codiceVisitaDescrizione: ['visitCodeParam', 'string'],
    idoneitaDescrizione: ['eligibilityParam', 'string'],
    prescrizione: ['prescriptionParam', 'string'],
    dataControlloSuccessivo: ['nextcheckDateParam', 'date'],
  }
}

const SchedaSanitariaSection = (props: SchedaSanitariaSectionProps) => {
  const { schedaSanitaria } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});

  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, schedaSanitaria))
  }, [schedaSanitaria]);

  return (
    <View wrap={false}>

      {/* No group */}
      {
        Object.values(noGroup).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitleSchedaSanitaria}>{t('personalDataOfHealthCardSubjectsParam')}</Text>
          {
            Object.entries(noGroup).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              return (
                <View
                  key={'no-group-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(key)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }
    </View>
  )
};

export default SchedaSanitariaSection;