import React, { Dispatch, SetStateAction } from 'react';
import './style.css'
import { Box, Divider, TextField, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { insert } from '../../../../store/slices/chatSlice';
import { User } from '../../../../models/User';
import { fetchByToken } from '../../../../store/slices/utentiPsSlice';
import { fetchAllValidByAppSoftware as fetchAllValidChatByAppSoftware } from '../../../../store/slices/chatSlice';
import { Chat } from '../../../../models/Chat';

const SearchResultBox = Box;

interface SearchNewUserProps {
  setSearchNewUser: Dispatch<SetStateAction<boolean>>,
  setAddedChat: Dispatch<SetStateAction<Chat | undefined>>,
  currentUser?: number
}

const SearchNewUser = (props: SearchNewUserProps) => {
  const { setSearchNewUser, setAddedChat, currentUser } = props;
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const appSoftware = useAppSelector(state => state.applicazioneSoftware.appSoftware);
  const searchedUsersList = useAppSelector(state => state.utentiPs.validUtenti);
  const receivers = useAppSelector(state => state.chat.receivers);

  const closeSearch = React.useCallback(() => {
    setSearchNewUser(false);
  }, [setSearchNewUser]);

  const createChat = React.useCallback((receiver: User) => {
    if (currentUser && appSoftware?.idApplicazioneSoftware && receiver.idUtente) {
      dispatch(insert({
        idApplicazioneSoftware: appSoftware.idApplicazioneSoftware,
        idUtente1: currentUser,
        idUtente2: receiver.idUtente,
        version: 0
      }))
        .then((newChat) => {
          dispatch(fetchAllValidChatByAppSoftware({ idApplicazioneSoftware: appSoftware.idApplicazioneSoftware }))
            // finally will called after the slices
            .finally(() => {
              setAddedChat(newChat.payload as Chat);
            });
          closeSearch();
        });
    }
  }, [closeSearch, currentUser, dispatch, appSoftware?.idApplicazioneSoftware, setAddedChat]);

  const updateToken = React.useCallback((token: string) => {
    if (token.length === 3) {
      dispatch(fetchByToken(token));
    }
  }, [dispatch]);

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' height={25}>
        <IconButton onClick={closeSearch}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Box display='flex'>
        <TextField
          size='small'
          fullWidth
          label={t('searchPlaceholder')}
          variant="outlined"
          onChange={e => updateToken(e.target.value)}
        />
      </Box>
      <Box>
        <SearchResultBox height={300} overflow='auto'>
          {
            searchedUsersList
              .filter(user => ![...receivers, currentUser].includes(user.idUtente))
              .map((user, index) => {
                return (
                  <Box
                    key={'ricerca-utente-' + index}
                    className='user-item'
                    onClick={() => createChat(user)}
                  >
                    <Box paddingX={2} paddingY={1}>
                      <Typography>{user.nome + ' ' + user.cognome}</Typography>
                      <Typography style={{ fontSize: 11, fontStyle: 'italic' }}>{user.nomeUtente}</Typography>
                    </Box>
                  </Box>
                );
              })
          }
        </SearchResultBox>
      </Box>
    </Box>
  )
}

export default SearchNewUser;
