import React from "react";
import { Text, View } from "react-pdf-renderer-v2";
import { AnagraficaSoggettiFormazione } from "../../../../../../models/AnagraficaSoggettiFormazione";
import { getDateDDMMYYYY } from "../../../../../../utils/utilfunctions";
import styles from '../styles/shared';

interface TableRowProps {
  formazione: AnagraficaSoggettiFormazione[]
}

const TableRow = ({ formazione }: TableRowProps) => {
  const defaultValue = '-';

  return (
    <>
      {
        formazione?.map((item, index) => (
          <View style={styles.tableRow} key={'table-row-' + index}>
            <Text style={styles.tableColDescription}>{item.formazioneDescrizione}</Text>
            <Text style={styles.tableColDate}>{item.dataTitolo ? getDateDDMMYYYY(new Date(item.dataTitolo)) : defaultValue}</Text>
          </View>
        ))
      }
    </>
  );
};

export default TableRow;
