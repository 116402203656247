import { validateDate, validateDateXGreaterThanY, validateDateXSmallerThanY, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiDittaKeys = keyof AnagraficaSoggettiDitta;

export const elementIdProp: AnagraficaSoggettiDittaKeys[] = ["idDitta", "idAnagraficaSoggetto", "dataAssegnazione"];
export const elementRenderProp: AnagraficaSoggettiDittaKeys[] = [];

export const allFields: Fields[] = [

    {
        field: "idDitta", required: true, show: true, titleKey: "companyTitle", editable: "onAdd", lookupField: true,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    {
        field: "dataAssegnazione", type: "date", required: true, editable: "onAdd", sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
        field2Validation: "dataFineAssegnazione",
        validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAssignStartDateValidation",
        show: true, titleKey: "assignDateParam"
    },

    {
        field: "dataFineAssegnazione", type: "date", required: false, sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, false), keyTradValidation: "notValidDateValidation",
        field2Validation: "dataInizioAssenza",
        validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "greaterEqualThanAssignEndDateeValidation",
        show: true, titleKey: "assignEndDateParam"
    }
]
export interface AnagraficaSoggettiDitta {
    idDitta: number,
    idAnagraficaSoggetto: number,
    dataAssegnazione: string,
    dataFineAssegnazione?: string,
    version: number
}