import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Click = 'yes' | 'no' | null;
interface DialogConfirm {
  open: boolean,
  title: string,
  text: string,
  click: Click,
  showButtons: boolean
}

interface DialogsState {
  dialogConfirm: DialogConfirm
}

const initialState: DialogsState = {
  dialogConfirm: {
    open: false,
    title: "",
    text: "",
    click: null,
    showButtons: true
  }
}

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openDialogConfirm: (state, { payload }: PayloadAction<{ title: string, text: string, showButtons?: boolean }>) => {
      state.dialogConfirm.open = true;
      state.dialogConfirm.title = payload.title;
      state.dialogConfirm.text = payload.text;
      state.dialogConfirm.showButtons = payload.showButtons ?? true;
    },
    yesClickDialogConfirm: state => {
      state.dialogConfirm.click = "yes";
    },
    noClickDialogConfirm: state => {
      state.dialogConfirm.click = "no";
    },
    resetYesClickDialogConfirm: state => {
      state.dialogConfirm.click = null;
    },
    closeDialogConfirm: state => {

      state.dialogConfirm.open = false;
      state.dialogConfirm.title = "";
      state.dialogConfirm.text = "";
      state.dialogConfirm.showButtons = false;
    }
  }
})

export const {
  openDialogConfirm, closeDialogConfirm, noClickDialogConfirm,
  yesClickDialogConfirm, resetYesClickDialogConfirm } = dialogsSlice.actions

export default dialogsSlice.reducer