import { Column } from "@material-table/core";
import { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";

import { PDFExtraData } from "../../../models/Utils";
import { DittaAnagraficaSoggetti, allFields, DittaAnagraficaSoggettiKeys } from "../../../models/DitteAnagraficaSoggetti";
import { fetchById, reset as resetDitteAnagraficaSoggetti } from '../../../store/slices/ditteAnagraficaSoggettiSlice';
import { lookup as fetchAllStrutture, reset as resetStrutture } from '../../../store/slices/struttureSlice';
import { Fields } from "../../../models/Fields";

interface AnagraficaSoggettoWProps {
  idDitta: number,
}

const DitteAnagraficaSoggettiW = ({ idDitta }: AnagraficaSoggettoWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const strLookup = useAppSelector(state => state.strutture.lookup);
  const dittaLookup = useAppSelector(state => state.ditte.lookup);

  const idDittaConst = "idDitta";
  const idStruttureConst = "idStruttura";

  const fixedProps = useMemo(() => {
    return {
      [idDittaConst]: idDitta,
    }
  }, [idDitta]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idDitta]);

  const title = t("registryTitle");

  useEffect(() => {
    dispatch(fetchAllStrutture());
    isFixedPropsChanged && dispatch(fetchById({ idDitta: fixedProps[idDittaConst] }))
  }, [dispatch, fixedProps, isFixedPropsChanged]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<DittaAnagraficaSoggetti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<DittaAnagraficaSoggetti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as DittaAnagraficaSoggettiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        switch (f.field) {
          case idStruttureConst:
            obj.lookup = strLookup;
            break;
        }

        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, strLookup, t]);

  const errorBE = useAppSelector(state => state.ditteAnagraficaSoggetti.error);
  const validDitteAnagraficaSoggetto = useAppSelector(state => state.ditteAnagraficaSoggetti.validDitteAnagraficaSoggetti);
  const statusValidDitteAnagraficaSoggetto = useAppSelector(state => state.ditteAnagraficaSoggetti.statusValidDitteAnagraficaSoggetti);

  const [data, setData] = useState<Array<DittaAnagraficaSoggetti>>([]);
  useEffect(() => {
    setData(validDitteAnagraficaSoggetto);
  }, [validDitteAnagraficaSoggetto]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetDitteAnagraficaSoggetti);
      dispatch(resetStrutture);
    };
  }, [dispatch]);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();
  useEffect(() => {
    const headTitle: string[] = [];
    const headValue: any[] = [];

    /**
     * init title table
     */
    headTitle.push(t("companyTitle"));
    headValue.push(
      dittaLookup[fixedProps[idDittaConst]]
    );

    setExportDataExtra({
      head: {
        title: headTitle,
        value: headValue
      }
    });
  }, [dittaLookup, fixedProps, t]);

  return <ReadOnlyMaterialTable
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    fetchAllValid={fetchById}
    statusValid={statusValidDitteAnagraficaSoggetto}
    errorBE={errorBE}
    logoUri={logoUri}
    fixedProps={fixedProps}
    exportDataExtra={exportDataExtra}
    isExportLandscape={false}
    localizedDatePicker={true}
    extraOptions={{
      maxBodyHeight: 460,
    }}
  />
}
export default DitteAnagraficaSoggettiW;
