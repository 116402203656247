import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAll, getAllByIds, getAllDeletedByIds, getAllValidByIds, getById, restoreById, upd } from '../../services/services.service'
import { elementIdProps, StruttureSedi, StruttureSediKeys } from '../../models/StruttureSedi';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "strutture";
const entity = "strutturesedi";

interface StruttureSediState {
  statusValidStruttureSedi: StatusEnum,
  validStruttureSedi: StruttureSedi[],
  statusDeletedStruttureSedi: StatusEnum,
  deletedStruttureSedi: StruttureSedi[],
  statusSingleStrutturaSede: StatusEnum,
  strutturaSede: StruttureSedi | null,
  lookup: Lookup,
  error: string | null
}

const initialState: StruttureSediState = {
  statusValidStruttureSedi: StatusEnum.Succeeded,
  validStruttureSedi: [],
  statusDeletedStruttureSedi: StatusEnum.Succeeded,
  deletedStruttureSedi: [],
  statusSingleStrutturaSede: StatusEnum.Succeeded,
  strutturaSede: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idStruttura: number, idStrutturaSede: number }) => {
  let response = await getById(microservice, entity, Object.values(obj));
  return response.data as StruttureSedi;
});

export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async (obj: { idStruttura: number }) => {
  let response = await getAllValidByIds(microservice, entity, Object.values(obj), ['fetchAllValid'])
  return response.data as StruttureSedi[];
});

export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async (obj: { idStruttura: number }) => {
  let response = await getAllDeletedByIds(microservice, entity, Object.values(obj), ['fetchAllDeleted'])
  return response.data as StruttureSedi[];
});

export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  let response = await getAll(microservice, entity);
  return response.data as StruttureSedi[];
});

export const fetchByIdStruttura = createAsyncThunk(entity + '/fetchByIdStruttura', async (obj: { idStruttura: number }) => {
  let response = await getAllByIds(microservice, entity, Object.values(obj))
  return response.data as StruttureSedi[];
});

export const insert = createAsyncThunk(entity + '/insert', async (strutturaSede: StruttureSedi) => {
  const response = await create(strutturaSede, microservice, entity);
  return response.data as StruttureSedi;
});

export const update = createAsyncThunk(entity + '/update', async (strutturaSede: StruttureSedi) => {
  const response = await upd(strutturaSede, microservice, entity);
  return response.data as StruttureSedi;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDel', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const struttureSediSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: StruttureSediState) => {
      return initialState;
    },
    resetError: (state: StruttureSediState) => {
      state.error = initialState.error;
      state.statusValidStruttureSedi = initialState.statusValidStruttureSedi;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusSingleStrutturaSede = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusSingleStrutturaSede = StatusEnum.Failed;
      state.strutturaSede = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<StruttureSedi>) => {
      state.statusSingleStrutturaSede = StatusEnum.Succeeded;
      state.strutturaSede = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
      state.validStruttureSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<StruttureSedi[]>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      state.validStruttureSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idStrutturaSede', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedStruttureSedi = StatusEnum.Failed;
      state.deletedStruttureSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<StruttureSedi[]>) => {
      state.statusDeletedStruttureSedi = StatusEnum.Succeeded;
      state.deletedStruttureSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idStrutturaSede', ['descrizione']);
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
      state.validStruttureSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<StruttureSedi[]>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      state.validStruttureSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idStrutturaSede', ['descrizione']);
    })

    // fetchByIdStruttura
    builder.addCase(fetchByIdStruttura.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdStruttura.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
      state.validStruttureSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchByIdStruttura.fulfilled, (state, { payload }: PayloadAction<StruttureSedi[]>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      state.validStruttureSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idStrutturaSede', ['descrizione']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
      state.strutturaSede = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<StruttureSedi>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      state.strutturaSede = payload;
      state.validStruttureSedi = state.validStruttureSedi.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
      state.strutturaSede = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<StruttureSedi>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      state.validStruttureSedi = state.validStruttureSedi.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.strutturaSede = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedStruttureSedi = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedStruttureSedi = StatusEnum.Succeeded;
      state.deletedStruttureSedi = state.deletedStruttureSedi.filter(el => payload.ids.some(idObj => el[idObj.name as StruttureSediKeys] !== idObj.id));
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStruttureSedi = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidStruttureSedi = StatusEnum.Succeeded;
      const deleted = state.validStruttureSedi.find(el => payload.ids.every(idObj => el[idObj.name as StruttureSediKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedStruttureSedi = state.deletedStruttureSedi.concat([deleted]);
        state.validStruttureSedi = state.validStruttureSedi.filter(el => payload.ids.some(idObj => el[idObj.name as StruttureSediKeys] !== idObj.id));
      }
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedStruttureSedi = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedStruttureSedi = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedStruttureSedi = StatusEnum.Succeeded;
      const valid = state.deletedStruttureSedi.find(el => payload.ids.every(idObj => el[idObj.name as StruttureSediKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validStruttureSedi = state.validStruttureSedi.concat([valid]);
        state.deletedStruttureSedi = state.deletedStruttureSedi.filter(el => payload.ids.some(idObj => el[idObj.name as StruttureSediKeys] !== idObj.id));
      }
    })
  }
});

export const { reset, resetError } = struttureSediSlice.actions;
export default struttureSediSlice.reducer;