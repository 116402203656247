import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, restoreById, getAllValid, getAllDeleted, upd } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';
import { elementIdProps, TipiIncarico, TipiIncaricoKeys } from '../../models/TipiIncarico';

const microservice = "tipiincarico";
const entity = "tipoincarico";

interface tipoIncaricoState {

  statusValidtipoIncarico: StatusEnum,
  validtipoIncarico: TipiIncarico[],
  statusDeletedtipoIncarico: StatusEnum,
  deletedtipoIncarico: TipiIncarico[],
  statusAlltipoIncarico: StatusEnum,
  alltipoIncarico: TipiIncarico[],
  tipoIncarico: TipiIncarico | null,
  lookupDescrizione: Lookup,
  lookupDescrizioneBreve: Lookup,
  error: string | null
}

const initialState: tipoIncaricoState = {
  statusValidtipoIncarico: StatusEnum.Succeeded,
  validtipoIncarico: [],
  statusDeletedtipoIncarico: StatusEnum.Succeeded,
  deletedtipoIncarico: [],
  statusAlltipoIncarico: StatusEnum.Succeeded,
  alltipoIncarico: [],
  tipoIncarico: null,
  lookupDescrizione: {},
  lookupDescrizioneBreve: {},
  error: null,

}

// post inserisci/tipidocumenti
export const insert = createAsyncThunk(microservice + '/insert', async (turno: TipiIncarico) => {
  const response = await create(turno, microservice, entity);
  return response.data as TipiIncarico;
});

// put modifica/tipidocumenti
export const update = createAsyncThunk(microservice + '/update', async (turno: TipiIncarico) => {
  const response = await upd(turno, microservice, entity);
  return response.data as TipiIncarico;
});

// put elimina/tipidocumenti/id/
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/tipidocumenti/id/
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/tipidocuementi/id/
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

// get cerca/tipidocumenti/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as TipiIncarico[];
});

// get cerca/tipidocumenti/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as TipiIncarico[];
});

export const tipoIncaricoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: tipoIncaricoState) => {
      return initialState;
    },
    resetError: (state: tipoIncaricoState) => {
      state.error = initialState.error;
      state.statusValidtipoIncarico = initialState.statusValidtipoIncarico;
      state.statusDeletedtipoIncarico = initialState.statusDeletedtipoIncarico;
      state.statusAlltipoIncarico = initialState.statusAlltipoIncarico;
    }
  },

  extraReducers: builder => {
    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoIncarico = StatusEnum.Failed;
      }
      state.tipoIncarico = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<TipiIncarico>) => {
      state.statusValidtipoIncarico = StatusEnum.Succeeded;
      state.tipoIncarico = payload;
      state.validtipoIncarico = state.validtipoIncarico.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoIncarico = StatusEnum.Failed;
      }
      state.tipoIncarico = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<TipiIncarico>) => {
      state.statusValidtipoIncarico = StatusEnum.Succeeded;
      state.validtipoIncarico = state.validtipoIncarico.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.tipoIncarico = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoIncarico = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidtipoIncarico = StatusEnum.Succeeded;
      const deleted = state.validtipoIncarico.find(el => payload.ids.every(idObj => el[idObj.name as TipiIncaricoKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedtipoIncarico = state.deletedtipoIncarico.concat([deleted]);
      }
      state.validtipoIncarico = state.validtipoIncarico.filter(el => payload.ids.some(idObj => el[idObj.name as TipiIncaricoKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoIncarico = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedtipoIncarico = StatusEnum.Succeeded;
      const valid = state.deletedtipoIncarico.find(el => payload.ids.every(idObj => el[idObj.name as TipiIncaricoKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validtipoIncarico = state.validtipoIncarico.concat([valid]);
      }
      state.deletedtipoIncarico = state.deletedtipoIncarico.filter(el => payload.ids.some(idObj => el[idObj.name as TipiIncaricoKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoIncarico = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedtipoIncarico = StatusEnum.Succeeded;
      state.deletedtipoIncarico = state.deletedtipoIncarico.filter(el => payload.ids.some(idObj => el[idObj.name as TipiIncaricoKeys] !== idObj.id));
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoIncarico = StatusEnum.Failed;
      }
      state.validtipoIncarico = [];

    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<TipiIncarico[]>) => {
      state.statusValidtipoIncarico = StatusEnum.Succeeded;
      state.validtipoIncarico = payload ?? [];
      state.lookupDescrizione = createLookup(payload, 'idTipoIncarico', ['descrizione'])
      state.lookupDescrizioneBreve = createLookup(payload, 'idTipoIncarico', ['descrizioneBreve'])
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedtipoIncarico = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoIncarico = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoIncarico = StatusEnum.Failed;
      }
      state.deletedtipoIncarico = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<TipiIncarico[]>) => {
      state.statusDeletedtipoIncarico = StatusEnum.Succeeded;
      state.deletedtipoIncarico = payload ?? [];
      state.lookupDescrizione = createLookup(payload, 'idTipoIncarico', ['descrizione'])
      state.lookupDescrizioneBreve = createLookup(payload, 'idTipoIncarico', ['descrizioneBreve'])
    })

  }

});

export const { reset, resetError } = tipoIncaricoSlice.actions;
export default tipoIncaricoSlice.reducer;