import React from 'react'
import { useTranslation } from 'react-i18next';

interface ImagePreviewProps {
  image: string,
  alt: string,
  mediaType?: string
}

const ImagePreview = (props: ImagePreviewProps) => {
  const { image, alt, mediaType } = props;

  const { t } = useTranslation();

  return (
    <img
      src={(mediaType ? ('data:image/' + mediaType + ';base64,') : '') + image}
      alt={t(alt)}
      height={'100%'}
    />
  )
}

export default ImagePreview