import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { DittaAnagraficaSoggetti } from '../../models/DitteAnagraficaSoggetti';
import { getByIds } from '../../services/services.service';

const microservice = "anagraficasoggetti";
const entity = "ditteanagraficasoggetti";

interface DitteAnagraficaSoggettiState {
  statusValidDitteAnagraficaSoggetti: StatusEnum,
  validDitteAnagraficaSoggetti: DittaAnagraficaSoggetti[],
  ditteAnagraficaSoggetti: DittaAnagraficaSoggetti | null,
  error: string | null
}

const initialState: DitteAnagraficaSoggettiState = {
  statusValidDitteAnagraficaSoggetti: StatusEnum.Succeeded,
  validDitteAnagraficaSoggetti: [],
  ditteAnagraficaSoggetti: null,
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idDitta: number }) => {

  const ids: any[] = Object.values(obj);
  ids.unshift("id");

  const response = await getByIds(microservice, entity, ids, ['/fetchById']);
  return response.data as DittaAnagraficaSoggetti[];
});

export const ditteAnagraficaSoggettiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: DitteAnagraficaSoggettiState) => {
      return initialState;;
    },
    resetError: (state: DitteAnagraficaSoggettiState) => {
      state.error = initialState.error;
      state.statusValidDitteAnagraficaSoggetti = initialState.statusValidDitteAnagraficaSoggetti;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidDitteAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidDitteAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidDitteAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validDitteAnagraficaSoggetti = [];
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Array<DittaAnagraficaSoggetti>>) => {
      state.statusValidDitteAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validDitteAnagraficaSoggetti = payload;
    })
  }
});

export const { reset, resetError } = ditteAnagraficaSoggettiSlice.actions;
export default ditteAnagraficaSoggettiSlice.reducer;