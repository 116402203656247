import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { openSnackBarError } from "../../../store/slices/snackBarSlice";
import EventSource from 'eventsource';
import { ChatMessaggio } from "../../../models/ChatMessaggio";
import { addNewMessaggio } from "../../../store/slices/chatMessaggioSlice";
import { closeConnection as closeChatConnection, disconnectUser, setConnection } from "../../../store/slices/chatSlice";
import { portaleBEUrl } from '../../../utils/utilconst';

interface ChatClientProps {
  header: object,
  hasIdAppSoftwareCookie: boolean
}

const useChatClient = (props: ChatClientProps) => {
  const { header, hasIdAppSoftwareCookie } = props;

  const dispatch = useAppDispatch();

  const token = useAppSelector(state => state.authInfo.token);
  const appSoftware = useAppSelector(state => state.applicazioneSoftware.appSoftware);
  const organization = useAppSelector(state => state.authInfo.organization);

  const source = React.useMemo(() => {
    const eventSourceInitDict = {
      headers: {
        ...header,
        idApplicazioneSoftware: appSoftware?.idApplicazioneSoftware
      }
    };

    return appSoftware?.idApplicazioneSoftware ? new EventSource(portaleBEUrl + "/chat/notifications", eventSourceInitDict) : null;
  }, [appSoftware?.idApplicazioneSoftware, header]);

  /**
   * SSE notification per chat messaggi
   */
  React.useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token && appSoftware?.idApplicazioneSoftware && organization && source) {
      if (typeof (EventSource) !== "undefined") {
        source.onmessage = event => {
          const newMessage: ChatMessaggio = JSON.parse(event.data)?.source;
          dispatch(addNewMessaggio([newMessage]));
        };
        source.onerror = error => {
          console.error({ error });
        }

        // trigger before the page (tab/window) is closed
        onbeforeunload = () => {
          // close the connection on client side
          dispatch(closeChatConnection());
          if (appSoftware?.idApplicazioneSoftware) {
            // trigger an api call to close the connection from server side
            dispatch(disconnectUser(appSoftware?.idApplicazioneSoftware));
          }
        }

        dispatch(setConnection(source));
      } else {
        dispatch(openSnackBarError('Notifica automatica non supportata dal browser.'));
      }
    }

    return () => {
      dispatch(closeChatConnection());
    }
  }, [appSoftware?.idApplicazioneSoftware, dispatch, hasIdAppSoftwareCookie, organization, source, token]);
}

export default useChatClient