import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { ModelliTerminaleCausali, elementIdProps, elementRenderProps, ModelliTerminaleCausaliKeys } from '../../models/ModelliTerminaleCausali'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllDeletedByIds, getAllValid, getAllValidByIds, getById, restoreById, upd } from '../../services/services.service'
import { createLookup, isNotNullAndUndefined } from '../../utils/utilfunctions';

const microservice = "modelliterminale";
const entity = "modelliterminalecausali";

interface ModelliTerminaleCausaliState {
  statusValidCausali: StatusEnum,
  validCausali: ModelliTerminaleCausali[],
  statusDeletedCausali: StatusEnum,
  deletedCausali: ModelliTerminaleCausali[],
  causale: ModelliTerminaleCausali | null,
  lookup: Lookup,
  error: string | null
}

const initialState: ModelliTerminaleCausaliState = {
  statusValidCausali: StatusEnum.Succeeded,
  validCausali: [],
  statusDeletedCausali: StatusEnum.Succeeded,
  deletedCausali: [],
  causale: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as ModelliTerminaleCausali;
});

export const fetchAllValidByIdModelloTerminale = createAsyncThunk(entity + '/fetchAllValidByIdModelloTerminale', async (ids: { idModelloTerminale: number }) => {
  const key = '/fetchAllValidByIdModelloTerminale';
  const _ids = [ids.idModelloTerminale];
  const response = await getAllValidByIds(microservice, entity, _ids, [key]);
  return response.data as ModelliTerminaleCausali[];
});

export const fetchAllDeletedByIdModelloTerminale = createAsyncThunk(entity + '/fetchAllDeletedByIdModelloTerminale', async (ids: { idModelloTerminale: number }) => {
  const key = '/fetchAllDeletedByIdModelloTerminale';
  const _ids = [ids.idModelloTerminale];
  const response = await getAllDeletedByIds(microservice, entity, _ids, [key]);
  return response.data as ModelliTerminaleCausali[];
});

export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as ModelliTerminaleCausali[];
});

export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as ModelliTerminaleCausali[];
});

export const insert = createAsyncThunk(entity + '/insert', async (causale: ModelliTerminaleCausali) => {
  if (!isNotNullAndUndefined(causale.gestionePresenze))
    causale.gestionePresenze = false;

  const response = await create(causale, microservice, entity);
  return response.data as ModelliTerminaleCausali;
});

export const update = createAsyncThunk(entity + '/update', async (causale: ModelliTerminaleCausali) => {
  const response = await upd(causale, microservice, entity);
  return response.data as ModelliTerminaleCausali;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const modelliTerminaleCausaliSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ModelliTerminaleCausaliState) => {
      return initialState;;
    },
    resetError: (state: ModelliTerminaleCausaliState) => {
      state.error = initialState.error;
      state.statusValidCausali = initialState.statusValidCausali;
      state.statusDeletedCausali = initialState.statusDeletedCausali;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
      state.causale = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      state.causale = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
      state.validCausali = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali[]>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      state.validCausali = payload ?? [];
      state.lookup = createLookup(payload, 'idCausale', elementRenderProps);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedCausali = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCausali = StatusEnum.Failed;
      }
      state.deletedCausali = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali[]>) => {
      state.statusDeletedCausali = StatusEnum.Succeeded;
      state.deletedCausali = payload ?? [];
    })

    // fetchAllValidByIdModelloTerminale
    builder.addCase(fetchAllValidByIdModelloTerminale.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdModelloTerminale.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
      state.validCausali = [];
    })
    builder.addCase(fetchAllValidByIdModelloTerminale.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali[]>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      state.validCausali = payload ?? [];
      state.lookup = createLookup(payload, 'idCausale', elementRenderProps);
    })

    // fetchAllDeletedByIdModelloTerminale
    builder.addCase(fetchAllDeletedByIdModelloTerminale.pending, (state) => {
      state.statusDeletedCausali = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByIdModelloTerminale.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCausali = StatusEnum.Failed;
      }
      state.deletedCausali = [];
    })
    builder.addCase(fetchAllDeletedByIdModelloTerminale.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali[]>) => {
      state.statusDeletedCausali = StatusEnum.Succeeded;
      state.deletedCausali = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
      state.causale = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      state.causale = payload;
      state.validCausali = state.validCausali.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
      state.causale = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<ModelliTerminaleCausali>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      state.validCausali = state.validCausali.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.causale = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidCausali = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCausali = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidCausali = StatusEnum.Succeeded;
      const deleted = state.validCausali.find(el => payload.ids.every(idObj => el[idObj.name as ModelliTerminaleCausaliKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedCausali = state.deletedCausali.concat([deleted]);
      }
      state.validCausali = state.validCausali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleCausaliKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedCausali = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCausali = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCausali = StatusEnum.Succeeded;
      const valid = state.deletedCausali.find(el => payload.ids.every(idObj => el[idObj.name as ModelliTerminaleCausaliKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validCausali = state.validCausali.concat([valid]);
      }
      state.deletedCausali = state.deletedCausali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleCausaliKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedCausali = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCausali = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCausali = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCausali = StatusEnum.Succeeded;
      state.deletedCausali = state.deletedCausali.filter(el => payload.ids.some(idObj => el[idObj.name as ModelliTerminaleCausaliKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = modelliTerminaleCausaliSlice.actions;
export default modelliTerminaleCausaliSlice.reducer;