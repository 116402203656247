import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getByIds } from '../../services/services.service'
import { ScadenzaFinePeriodoProva } from '../../models/ScadenzaPeriodoProva';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettischedeprofessionali";

interface ScadenzaPeriodoProvaState {
  statusValidScadenzaPeriodoProva: StatusEnum,
  validScadenzaPeriodoProva: ScadenzaFinePeriodoProva[],
  scadenzaFinePeriodoProva: ScadenzaFinePeriodoProva | null,
  error: string | null
}

const initialState: ScadenzaPeriodoProvaState = {
  statusValidScadenzaPeriodoProva: StatusEnum.Succeeded,
  validScadenzaPeriodoProva: [],
  scadenzaFinePeriodoProva: null,
  error: null
}

export const fetchAll = createAsyncThunk(entity + 'scadenzaperiododiprova/fetchAll', async (obj: {
  dataInizio: string,
  dataFine: string
}) => {
  const response = await getByIds(microservice, entity, ['scadenzaperiodoprova', obj.dataInizio, obj.dataFine], ['scadenzaperiododiprova/fetchAll']);
  return response.data as ScadenzaFinePeriodoProva[];
});

export const scadenzaPeriodoProvaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ScadenzaPeriodoProvaState) => {
      return initialState;;
    },
    resetError: (state: ScadenzaPeriodoProvaState) => {
      state.error = initialState.error;
      state.statusValidScadenzaPeriodoProva = initialState.statusValidScadenzaPeriodoProva;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidScadenzaPeriodoProva = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidScadenzaPeriodoProva = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidScadenzaPeriodoProva = StatusEnum.Failed;
      }
      state.validScadenzaPeriodoProva = [];
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<ScadenzaFinePeriodoProva[]>) => {
      state.statusValidScadenzaPeriodoProva = StatusEnum.Succeeded;
      state.validScadenzaPeriodoProva = payload;
    })
  }
});

export const { reset, resetError } = scadenzaPeriodoProvaSlice.actions;
export default scadenzaPeriodoProvaSlice.reducer;