import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Assenza } from "./Assenze";
import { Fields } from "./Fields";

export type ModelliTerminaleCausaliKeys = keyof ModelliTerminaleCausali;

export const elementIdProps: ModelliTerminaleCausaliKeys[] = ["idModelliTerminaleCausale", "idModelloTerminale"];
export const elementRenderProps: ModelliTerminaleCausaliKeys[] = ["descrizione"];

export interface ModelliTerminaleCausali {
  idModelliTerminaleCausale: number;
  idModelloTerminale: number;
  codice: string;
  descrizione: string;
  gestionePresenze: boolean;
  idTurnoAssenza?: number;
  version: number;

  turnoAssenza?: Assenza;
}

export const allFields: Fields[] = [
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "codice", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 4, false, helperText, true), keyTradValidation: "size1to4ParamValidation",
    show: true, titleKey: "codeParam"
  },
  {
    field: "gestionePresenze", type: "boolean", required: true, show: true, titleKey: "attendanceParam"
  },
  {
    field: "idTurnoAssenza", required: false, lookupField: true,
    show: true, titleKey: "absenceShiftParam"
  },
]