import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAll, getAllByIds, getAllDeletedByIds, getAllValidByIds, getById, restoreById, upd } from '../../services/services.service'
import { elementIdProps, DipartimentiSedi, DipartimentiSediKeys } from '../../models/DipartimentiSedi';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "dipartimenti";
const entity = "dipartimentisedi";

interface DipartimentiSediState {
  statusValidDipartimentiSedi: StatusEnum,
  validDipartimentiSedi: DipartimentiSedi[],
  statusDeletedDipartimentiSedi: StatusEnum,
  deletedDipartimentiSedi: DipartimentiSedi[],
  statusSingleDipartimentoSede: StatusEnum,
  dipartimentoSede: DipartimentiSedi | null,
  lookup: Lookup,
  error: string | null
}

const initialState: DipartimentiSediState = {
  statusValidDipartimentiSedi: StatusEnum.Succeeded,
  validDipartimentiSedi: [],
  statusDeletedDipartimentiSedi: StatusEnum.Succeeded,
  deletedDipartimentiSedi: [],
  statusSingleDipartimentoSede: StatusEnum.Succeeded,
  dipartimentoSede: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idStruttura: number, idDipartimento: number, idDipartimentoSede: number }) => {
  let response = await getById(microservice, entity, Object.values(obj));
  return response.data as DipartimentiSedi;
});

export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async (obj: { idStruttura: number, idDipartimento: number }) => {
  let response = await getAllValidByIds(microservice, entity, Object.values(obj), ['fetchAllValid'])
  return response.data as DipartimentiSedi[];
});

export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async (obj: { idStruttura: number, idDipartimento: number }) => {
  let response = await getAllDeletedByIds(microservice, entity, Object.values(obj), ['fetchAllDeleted'])
  return response.data as DipartimentiSedi[];
});

export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  let response = await getAll(microservice, entity);
  return response.data as DipartimentiSedi[];
});

export const fetchByIdDipartimento = createAsyncThunk(entity + '/fetchByIdDipartimento', async (obj: { idStruttura: number, idDipartimento: number }) => {
  let response = await getAllByIds(microservice, entity, Object.values(obj))
  return response.data as DipartimentiSedi[];
});

export const insert = createAsyncThunk(entity + '/insert', async (unitaOperativaSede: DipartimentiSedi) => {
  const response = await create(unitaOperativaSede, microservice, entity);
  return response.data as DipartimentiSedi;
});

export const update = createAsyncThunk(entity + '/update', async (unitaOperativaSede: DipartimentiSedi) => {
  const response = await upd(unitaOperativaSede, microservice, entity);
  return response.data as DipartimentiSedi;
});

export const logicalDel = createAsyncThunk(entity + '/logicalDel', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const dipartimentiSediSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: DipartimentiSediState) => {
      return initialState;
    },
    resetError: (state: DipartimentiSediState) => {
      state.error = initialState.error;
      state.statusValidDipartimentiSedi = initialState.statusValidDipartimentiSedi;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusSingleDipartimentoSede = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusSingleDipartimentoSede = StatusEnum.Failed;
      state.dipartimentoSede = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi>) => {
      state.statusSingleDipartimentoSede = StatusEnum.Succeeded;
      state.dipartimentoSede = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
      state.validDipartimentiSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi[]>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      state.validDipartimentiSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idDipartimentoSede', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedDipartimentiSedi = StatusEnum.Failed;
      state.deletedDipartimentiSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi[]>) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Succeeded;
      state.deletedDipartimentiSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idDipartimentoSede', ['descrizione']);
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
      state.validDipartimentiSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi[]>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      state.validDipartimentiSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idDipartimentoSede', ['descrizione']);
    })

    // fetchByIdUnitaOperativa
    builder.addCase(fetchByIdDipartimento.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdDipartimento.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
      state.validDipartimentiSedi = [];
      state.lookup = {};
    })
    builder.addCase(fetchByIdDipartimento.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi[]>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      state.validDipartimentiSedi = payload ?? [];
      state.lookup = createLookup(payload, 'idDipartimentoSede', ['descrizione']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
      state.dipartimentoSede = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      state.dipartimentoSede = payload;
      state.validDipartimentiSedi = state.validDipartimentiSedi.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
      state.dipartimentoSede = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<DipartimentiSedi>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      state.validDipartimentiSedi = state.validDipartimentiSedi.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.dipartimentoSede = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedDipartimentiSedi = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Succeeded;
      state.deletedDipartimentiSedi = state.deletedDipartimentiSedi.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentiSediKeys] !== idObj.id));
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiSedi = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidDipartimentiSedi = StatusEnum.Succeeded;
      const deleted = state.validDipartimentiSedi.find(el => payload.ids.every(idObj => el[idObj.name as DipartimentiSediKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedDipartimentiSedi = state.deletedDipartimentiSedi.concat([deleted]);
      }
      state.validDipartimentiSedi = state.validDipartimentiSedi.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentiSediKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDipartimentiSedi = StatusEnum.Succeeded;
      const valid = state.deletedDipartimentiSedi.find(el => payload.ids.every(idObj => el[idObj.name as DipartimentiSediKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validDipartimentiSedi = state.validDipartimentiSedi.concat([valid]);
      }
      state.deletedDipartimentiSedi = state.deletedDipartimentiSedi.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentiSediKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = dipartimentiSediSlice.actions;
export default dipartimentiSediSlice.reducer;