import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UnitaOperativa, elementIdProps, elementRenderProps, allFields, UnitaOperativaKeys } from "../../../models/UnitaOperative";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllValidByIdStrutturaAndIdDipartimento, fetchAllDeletedByIdStrutturaAndIdDipartimento, insertFiltered, logicalDelFiltered, physicalDelFiltered, restoreFiltered, updateFiltered, reset as resetUnitaOperativeFiltered, resetError as resetErrorUnitaOperativeFiltered } from "../../../store/slices/unitaOperativeFilteredSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { notFoundPath, unitaOperativePath } from "../../../utils/utilconst";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentTabsPath, shiftEmployeesNumTabPath, unitaOperativaTabPath, componentInsertPath, unitaoperativaCodiceVisitePath, unitaOpertativaFormazionePath, unitaOperativeSediPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Box, Paper, Typography } from "@material-ui/core";
import { lookup as fetchLookupStrutture, reset as resetStrutture, resetError as resetErrorStrutture } from "../../../store/slices/struttureSlice";
import { lookupFiltered as fetchLookupDipartimenti, cleanLookup as cleanDipartimentiLookup, reset as resetDipartimentiFiltered, resetError as resetErrorDipartimentiFiltered } from "../../../store/slices/dipartimentiFilteredSlice";
import { fetchAllValid as fetchCentriDiCosto, reset as resetCentrodiCosto, resetError as resetErrorCentriDiCosto } from "../../../store/slices/centriDiCostoSlice";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import UnitaOperativeTurniNumeroAddettiW from "./UnitaOperativeTurniNumeroAddettiW";
import { PDFExtraData } from "../../../models/Utils";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";
import { isUrlRoot } from "../../../utils/utilfunctions";
import UnitaOperativaCodiceVisiteW from "./UnitaOperativaCodiceVisiteW";
import UnitaOperativaFormazioneW from "./UnitaOperativaFormazioneW";
import UnitaOperativeSediW from "./UnitaOperativeSediW";
import LoadingSvg from "../svgs/LoadingSvg";

const UnitaOperativeW = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const abilitazione = useAppSelector(state => getAbilitazione(state, unitaOperativePath));
  const title = t('operatingUnitTitle');

  const LoadingAnimation = useMemo(() => {
    return <LoadingSvg color="primary" width={300} />
  }, []);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);

  const [states, setStates] = useState<{ [selectName: string]: number | string | null }>({ [strSelectName]: "", [dipSelectName]: "" });

  const idStrConst = "idStruttura";
  const idDipConst = "idDipartimento";
  const idCostCenterConst = "idCentroDiCosto";
  const idCentroDiCostoCostiComuniConst = "idCentroDiCostoCostiComuni";
  const idCentroDiCostoSecondarioConst = "idCentroDiCostoSecondario";
  const idCentroDiCostoLungaAssenzaConst = "idCentroDiCostoLungaAssenza";

  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupCostCenter = useAppSelector(state => state.centriDiCosto.lookup);

  useEffect(() => {
    dispatch(fetchLookupStrutture());
    dispatch(fetchCentriDiCosto());
    ;
  }, [dispatch]);

  const resetError = useCallback(() => {
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorStrutture());
    dispatch(resetErrorCentriDiCosto());
    dispatch(resetErrorDipartimentiFiltered());
  }, [dispatch]);

  useEffect(() => {
    states[strSelectName] && dispatch(fetchLookupDipartimenti({ idStruttura: states[strSelectName] as number }));
  }, [states, strSelectName, dispatch]);

  const fixedProps = useMemo(() => { return { [idStrConst]: states[strSelectName], [idDipConst]: states[dipSelectName] } }, [states, strSelectName, dipSelectName]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<UnitaOperativa>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<UnitaOperativa> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as UnitaOperativaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.lookupField) {
          switch (f.field) {
            case idCostCenterConst:
            case idCentroDiCostoSecondarioConst:
            case idCentroDiCostoLungaAssenzaConst:
              obj.lookup = lookupCostCenter;
              break;
            case idStrConst:
              obj.lookup = lookupStr;
              break;
            case idDipConst:
              obj.lookup = lookupDip;
              break;
          }
        }
        /*+++*/

        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, lookupCostCenter, lookupDip, lookupStr, t]);

  const errorBE = useAppSelector(state => state.unitaOperativeFiltered.error);

  const validUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.validUnitaOperativeFiltered);
  const deletedUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.deletedUnitaOperativeFiltered);

  const statusValidUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.statusValidUnitaOperativeFiltered);
  const statusDeletedUnitaOperativeFiltered = useAppSelector(state => state.unitaOperativeFiltered.statusDeletedUnitaOperativeFiltered);

  const [data, setData] = useState<Array<UnitaOperativa>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const [obj, setObj] = useState<UnitaOperativa | null>(null);

  useEffect(() => {
    toggleValidDeleted ? setData(validUnitaOperativeFiltered) : setData(deletedUnitaOperativeFiltered);
  }, [validUnitaOperativeFiltered, deletedUnitaOperativeFiltered, toggleValidDeleted]);

  const insertCallback = () => {
    history.push(unitaOperativePath + componentInsertPath);
  }

  const updateDetailCallback = (dip: UnitaOperativa) => {
    setObj(dip);
    history.push(unitaOperativePath + componentTabsPath);
  }

  const formLookups = useMemo(() => {
    return {
      [idStrConst]: lookupStr,
      [idDipConst]: lookupDip,
      [idCostCenterConst]: lookupCostCenter,
      [idCentroDiCostoSecondarioConst]: lookupCostCenter,
      [idCentroDiCostoCostiComuniConst]: lookupCostCenter,
      [idCentroDiCostoLungaAssenzaConst]: lookupCostCenter,
    }
  }, [lookupCostCenter, lookupDip, lookupStr]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        const _idStruttura = fixedProps[elem];
        if (exportDataExtra.head)
          exportDataExtra.head['Struttura'] = _idStruttura ? lookupStr[_idStruttura] : '';
        break;
      case 'idDipartimento':
        const _idDipartimento = fixedProps[elem];
        if (exportDataExtra.head)
          exportDataExtra.head['Dipartimento'] = _idDipartimento ? lookupDip[_idDipartimento] : '';
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setObj(null);
      setToggleValidDeleted(true);
      setStates({});
      setAllFieldsState([]);
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetStrutture());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetCentrodiCosto())
    };
  }, [dispatch]);

  const _dipSelectName = useMemo(() => states[dipSelectName], [dipSelectName, states]);

  return <>
    <Paper elevation={2}>
      <Box p={4}>
        <SimpleIdSelects
          selectsArray={[
            {
              name: strSelectName,
              lookup: lookupStr,
              label: strSelectLabel,
              disabled: isUrlRoot(history.location.pathname, unitaOperativePath + componentTabsPath),
              breakpoints: { xs: 12, sm: 12, md: 6, lg: 4 },
            },
            {
              name: dipSelectName,
              lookup: lookupDip,
              label: dipSelectLabel,
              disabled: isUrlRoot(history.location.pathname, unitaOperativePath + componentTabsPath),
              breakpoints: { xs: 12, sm: 12, md: 6, lg: 3 },
              callback: cleanDipartimentiLookup,
            },
          ]}
          states={states}
          setStates={setStates}
        />
      </Box>
    </Paper>
    <Box marginTop={2}>
      {
        (states[strSelectName] && states[dipSelectName]) ? (
          <Switch>
            <Route path={unitaOperativePath} exact>
              <CrudMaterialTable
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllValidByIdStrutturaAndIdDipartimento}
                fetchAllDeleted={fetchAllDeletedByIdStrutturaAndIdDipartimento}
                logicalDel={logicalDelFiltered}
                physicalDel={physicalDelFiltered}
                restore={restoreFiltered}
                statusValid={statusValidUnitaOperativeFiltered}
                statusDeleted={statusDeletedUnitaOperativeFiltered}
                dataValid={toggleValidDeleted}
                setToggleValidDeleted={setToggleValidDeleted}
                errorBE={errorBE}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                logoUri={logoUri}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                resetErrorCallback={resetError}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
              />
            </Route>
            <Route path={unitaOperativePath + componentInsertPath} exact>
              <InnerComponentViews abilitazione={abilitazione} mainUri={unitaOperativePath} tabsUri={unitaOperativePath + componentInsertPath} tabsView={false}
                buttonTitle={t('operatingUnitTitle')} info1={t("newOperatingUnitTitle")} info2={_dipSelectName ? lookupDip[_dipSelectName] : ''}
                tabs={
                  [
                    {
                      label: t("operatingUnitTabLabel"), tabPath: "",
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={unitaOperativePath}
                        action={insertFiltered}
                        fixedProps={fixedProps}
                        lookups={formLookups}
                        status={statusValidUnitaOperativeFiltered}
                        error={errorBE}
                        update={false}
                        fields={allFields}
                        translate={t}
                      />,
                      componentElse: <Redirect to={unitaOperativePath} />
                    }
                  ]
                } />
            </Route>
            <Route path={unitaOperativePath + componentTabsPath}>
              {
                obj ? (
                  <InnerComponentViews abilitazione={abilitazione} mainUri={unitaOperativePath} tabsUri={unitaOperativePath + componentTabsPath} tabsView={true}
                    buttonTitle={t('operatingUnitTitle')} info1={obj.nome}
                    tabs={
                      [
                        {
                          label: t("operatingUnitTabLabel"), tabPath: unitaOperativaTabPath,
                          abilitazione: Abilitazione.READ_UPDATE,
                          componentIf: <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={unitaOperativePath}
                            action={updateFiltered}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            status={statusValidUnitaOperativeFiltered}
                            error={errorBE}
                            obj={obj}
                            update={true}
                            fields={allFields}
                            translate={t}
                          />,
                          componentElse: <GeneralForm
                            readOnly={true}
                            language={i18n.language}
                            componentPath={unitaOperativePath}
                            action={updateFiltered}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            status={statusValidUnitaOperativeFiltered}
                            error={errorBE}
                            obj={obj}
                            update={true}
                            fields={allFields}
                            translate={t}
                          />
                        },
                        {
                          label: t("shiftservicehoursTitle"), tabPath: shiftEmployeesNumTabPath,
                          abilitazione: Abilitazione.READ,
                          componentIf: obj.idStruttura && obj.idDipartimento && obj.idUnitaOperativa
                            ? <UnitaOperativeTurniNumeroAddettiW
                              idStruttura={obj.idStruttura}
                              idDipartimento={obj.idDipartimento}
                              idUnitaOperativa={obj.idUnitaOperativa}
                            />
                            : LoadingAnimation,
                          componentElse: <Redirect to={unitaOperativePath} />
                        },
                        {
                          label: t("codice visite"), tabPath: unitaoperativaCodiceVisitePath,
                          abilitazione: Abilitazione.READ,
                          componentIf: obj.idStruttura && obj.idDipartimento && obj.idUnitaOperativa
                            ? <UnitaOperativaCodiceVisiteW
                              idStruttura={obj.idStruttura}
                              idDipartimento={obj.idDipartimento}
                              idUnitaOperativa={obj.idUnitaOperativa}
                            />
                            : LoadingAnimation,
                          componentElse: <Redirect to={unitaOperativePath} />
                        },
                        {
                          label: t("formazione"), tabPath: unitaOpertativaFormazionePath,
                          abilitazione: Abilitazione.READ,
                          componentIf: obj.idStruttura && obj.idDipartimento && obj.idUnitaOperativa
                            ? <UnitaOperativaFormazioneW
                              idStruttura={obj.idStruttura}
                              idDipartimento={obj.idDipartimento}
                              idUnitaOperativa={obj.idUnitaOperativa}
                            />
                            : LoadingAnimation,
                          componentElse: <Redirect to={unitaOperativePath} />
                        },
                        {
                          label: t("sedi"),
                          tabPath: unitaOperativeSediPath,
                          abilitazione: Abilitazione.READ,
                          componentIf: obj.idStruttura && obj.idDipartimento && obj.idUnitaOperativa
                            ? <UnitaOperativeSediW
                              idStruttura={obj.idStruttura}
                              idDipartimento={obj.idDipartimento}
                              idUnitaOperativa={obj.idUnitaOperativa}
                            />
                            : LoadingAnimation,
                          componentElse: <Redirect to={unitaOperativePath} />
                        }
                      ]
                    } />
                ) : <Redirect to={unitaOperativePath} />
              }
            </Route>
            <Route path="*"><Redirect to={notFoundPath} /></Route>
          </Switch>
        ) :
          <Switch>
            <Route path={unitaOperativePath} exact>
              <Paper elevation={2}>
                <Box p={4}>
                  <Typography align="center" variant="h5">
                    {!states[strSelectName] ? strSelectLabel : dipSelectLabel}
                  </Typography>
                </Box>
              </Paper>
            </Route>
            <Route>
              <Redirect to={unitaOperativePath} />
            </Route>
          </Switch>
      }
    </Box>
  </>
}
export default UnitaOperativeW;