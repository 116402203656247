import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAllValid } from '../../services/services.service'
import { AbilitazioniUtenti, AbilitazioniUtentiKeys } from '../../models/AbilitazioniUtenti';

const microservice = "utentiabilitazionigestioneturni";
const entity = "utentiabilitazioni";
interface AbilitazioniUtentiState {
  statusValidAbilitazioniUtenti: StatusEnum,
  validAbilitazioniUtenti: AbilitazioniUtenti[],
  abilitazioneUtente: AbilitazioniUtenti | null,
  error: string | null
}

const initialState: AbilitazioniUtentiState = {
  statusValidAbilitazioniUtenti: StatusEnum.Succeeded,
  validAbilitazioniUtenti: [],
  abilitazioneUtente: null,
  error: null
}

export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as AbilitazioniUtenti[];
});

export const insert = createAsyncThunk(microservice + '/insertFiltered', async (abilitazioneUtente: AbilitazioniUtenti) => {
  const response = await create(abilitazioneUtente, microservice, entity);
  return response.data as AbilitazioniUtenti;
});

export const physicalDel = createAsyncThunk(microservice + '/physicalDelFiltered', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const abilitazioniUtentiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AbilitazioniUtentiState) => {
      return initialState;;
    },
    resetError: (state: AbilitazioniUtentiState) => {
      state.error = initialState.error;
      state.statusValidAbilitazioniUtenti = initialState.statusValidAbilitazioniUtenti;
    }

  },
  extraReducers: builder => {
    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAbilitazioniUtenti = StatusEnum.Failed;
      }
      state.validAbilitazioniUtenti = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<AbilitazioniUtenti[]>) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Succeeded;
      state.validAbilitazioniUtenti = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAbilitazioniUtenti = StatusEnum.Failed;
      }
      state.abilitazioneUtente = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AbilitazioniUtenti>) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Succeeded;
      state.abilitazioneUtente = payload;
      state.validAbilitazioniUtenti = state.validAbilitazioniUtenti.concat([payload]);
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAbilitazioniUtenti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAbilitazioniUtenti = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAbilitazioniUtenti = StatusEnum.Succeeded;
      state.validAbilitazioniUtenti = state.validAbilitazioniUtenti.filter(el => payload.ids.some(idObj => el[idObj.name as AbilitazioniUtentiKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = abilitazioniUtentiSlice.actions;
export default abilitazioniUtentiSlice.reducer;