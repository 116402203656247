import { Fields } from "./Fields";

export const elementIdProps = [];
export const elementRenderProps = [];

export const allFields: Fields[] = [
    {
        field: "cognome", type: 'string', titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", type: 'string', titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "qualificaIncaricoDescrizioneBreve", type: 'string', titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "dataAssegnazione", type: 'date', titleKey: "currentAssignDateParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", type: 'string', titleKey: "currentOperationalUnitParam", required: false, show: true,
    },
    {
        field: "dataAssegnazionePrecedente", type: 'date', titleKey: "previousAssignDateParam", required: false, show: true,
    },
    {
        field: "unitaOperativaPrecedente", type: 'string', titleKey: "previousOperationalUnitParam", required: false, show: true,
    },
];
