import { Fields } from "./Fields";

export type DittaAnagraficaSoggettiKeys = keyof DittaAnagraficaSoggetti;

export const elementIdProp: DittaAnagraficaSoggettiKeys[] = [];
export const elementRenderProp: DittaAnagraficaSoggettiKeys[] = [];

export const allFields: Fields[] = [

    {
        field: "codiceFiscale", type: "string", titleKey: "fiscalCodeParam", required: false, show: true,
    },

    {
        field: "cognome", type: "string", titleKey: "lastNameParam", required: false, show: true,
    },

    {
        field: "nome", type: "string", titleKey: "nameParam", required: false, show: true,
    },

    {
        field: "dataAssegnazione", type: "date", titleKey: "assignDateParam", required: false, show: true,
    },

    {
        field: "dataFineAssegnazione", type: "date", titleKey: "assignEndDateParam", required: false, show: true,
    },
    /********************************************************************/
    {
        field: "sesso", type: "string", titleKey: "genderParam", required: false, show: false,
    },

    {
        field: "idStruttura", type: "numeric", titleKey: "structureParam", required: false, show: false,
    },

    {
        field: "nascitaData", type: "date", titleKey: "dateOfBirthParam", required: false, show: false,
    },

    {
        field: "nascitaCitta", type: "string", titleKey: "cityOfBirthParam", required: false, show: false,
    },

    {
        field: "nascitaProvincia", type: "string", titleKey: "districtOfBirthParam", required: false, show: false,
    },

    {
        field: "residenzaIndirizzo", type: "string", titleKey: "residenceAddressParam", required: false, show: false,
    },

    {
        field: "residenzaCitta", type: "string", titleKey: "residenceCityParam", required: false, show: false,
    },

    {
        field: "residenzaProvincia", type: "string", titleKey: "residenceDistrictParam", required: false, show: false,
    },

    {
        field: "residenzaCap", type: "string", titleKey: "residenceCapParam", required: false, show: false,
    },

    {
        field: "domicilioIndirizzo", type: "string", titleKey: "domicileAddressParam", required: false, show: false,
    },

    {
        field: "domicilioCitta", type: "string", titleKey: "domicileCityParam", required: false, show: false,
    },

    {
        field: "domicilioProvincia", type: "string", titleKey: "domicileDistrictParam", required: false, show: false,
    },

    {
        field: "domicilioCap", type: "string", titleKey: "domicileCapParam", required: false, show: false,
    },

    {
        field: "telefonoAbitazione", type: "string", titleKey: "telephoneParam", required: false, show: false,
    },

    {
        field: "telefonoCellulare", type: "string", titleKey: "mobilePhoneParam", required: false, show: false,
    },

    {
        field: "alboIscrizione", type: "string", titleKey: "alboParam", required: false, show: false,
    },

    {
        field: "alboIscrizioneNumero", type: "numeric", titleKey: "alboNumberParam", required: false, show: false,
    },

    {
        field: "alboIscrizioneData", type: "date", titleKey: "alboDateParam", required: false, show: false,
    },

    {
        field: "idTurnoIniziale", type: "string", titleKey: "firstShiftParam", required: false, show: false,
    },


]
export interface DittaAnagraficaSoggetti {
    codiceFiscale: string,
    cognome: string,
    nome: string,
    dataAssegnazione: string,
    dataFineAssegnazione: string,

    idAnagraficaSoggetto: number,
    idStruttura: number,
    nascitaData: string,
    nascitaCitta: string,
    nascitaProvincia: string,
    sesso: string,
    residenzaIndirizzo: string,
    residenzaCitta: string,
    residenzaProvincia: string,
    residenzaCap: string,
    domicilioIndirizzo: string,
    domicilioCitta: string,
    domicilioProvincia: string,
    domicilioCap: string,
    telefonoAbitazione: string,
    telefonoCellulare: string,
    alboIscrizione: string,
    alboIscrizioneNumero: number,
    alboIscrizioneData: string,
    idTurnoIniziale: number,
    idDitta: number,
    version: number,
}