import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { RiepilogoPianificazioneDTO, MappaTurniOre, IdsConfigExtended } from '../../PianificazioniW';
import InsertTurniModal from './InsertTurniModal';
import { fetchAllValidById as fetchTurniAbilitati } from '../../../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { getDateYYYYMMDD } from '../../../../../utils/utilfunctions';

/** CALENDAR */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hCell: {
      height: 120
    },
    disabled: {
      color: "#cecece"
    },
    bold: {
      fontWeight: "bold"
    },
    error: {
      color: '#f00',
    },
    bgError: {
      backgroundColor: "#900",
      color: "#fff"
    },
    bgErrorDiffMonth: {
      backgroundColor: "#b36767",
      color: "#fff",
    },
    paper: {
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ccc",
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
    },
    textLightGray: {
      color: "#ccc",
    },
  }),
);

export interface OrderedListOfTurni {
  idQualifica: number,
  turno: IdsConfigExtended,
  ore: number,
  minuti: number,
  turnoFisso: boolean,
  modificaTurnoFisso: boolean,
}

interface CalendarProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  mappaTurniOre: MappaTurniOre;
  dateToShow: Date[];
  selectedMonth: Date;
  selectedIdQualifica: number;
  riepilogoAnagraficaModifica: RiepilogoPianificazioneDTO,
  fixedProps: {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    mese: number;
    anno: number;
  };
  isApprovato: boolean;
}

const Calendar = ({ mappaTurniOre, dateToShow, selectedMonth, currentSelectedAS, selectedIdQualifica, fixedProps, isApprovato, riepilogoAnagraficaModifica }: CalendarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentSelectedAS.idAnagraficaSoggetto && currentSelectedAS.idStruttura)
      dispatch(fetchTurniAbilitati([currentSelectedAS.idStruttura, currentSelectedAS.idAnagraficaSoggetto, getDateYYYYMMDD(selectedMonth) + 'T00:00:00']));
  }, [currentSelectedAS.idAnagraficaSoggetto, currentSelectedAS.idStruttura, dispatch, selectedMonth]);

  const daysOfTheWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const numberOfWeeks: number = Math.ceil(dateToShow.length / daysOfTheWeek.length);
  const dayOfOne: number = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).getDay();

  const [idQualifica, setIdQualifica] = useState<number>(0);
  useEffect(() => {
    setIdQualifica(selectedIdQualifica)
  }, [selectedIdQualifica]);

  const handleOpen = (dateIndex: number, currentDate: Date) => {
    if (!isApprovato) {
      setSelectedDateIndex(dateIndex);
      setCurrentSelectedDate(currentDate);
      setOpen(true);
    }
  };

  const [preparedData, setPreparedData] = useState<OrderedListOfTurni[][]>([]);
  useEffect(() => {
    setPreparedData(prepareData(currentSelectedAS, mappaTurniOre, dayOfOne));
  }, [currentSelectedAS, dayOfOne, mappaTurniOre]);


  useEffect(() => {
    return () => {
      setPreparedData([]);
      setCurrentSelectedDate(undefined);
    }
  }, []);

  return (
    <>
      <Paper>
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              {/* Header: days of the week & total column */}
              <Box display='flex' justifyContent={'space-Between'} padding={3} >
                <Typography variant="body1" className={classes.bold} >
                  {riepilogoAnagraficaModifica?.nominativo}
                </Typography>
                <Typography variant="body1">
                  {t('qualificationParam')}:
                  <span className={classes.bold}>
                    {riepilogoAnagraficaModifica?.qualificaDescrizione}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {daysOfTheWeek.map((dayOfTheWeek, index) => (
                <Grid key={'dayOfWeek' + index} item xs>
                  <Paper>
                    <Typography variant="h5" align="center">
                      {t(dayOfTheWeek).substring(0, 3)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {
              // calendar table
              new Array(numberOfWeeks)
                .fill(null)
                .map((_, weekNumber) => {
                  // week row
                  return <Grid key={'calendar' + weekNumber} item container xs={12} spacing={1}>
                    {
                      daysOfTheWeek.map((_, indexDayOfTheWeek) => {
                        const dateIndex: number = weekNumber * daysOfTheWeek.length + indexDayOfTheWeek;
                        const currentDate: Date = dateToShow[dateIndex];
                        const currentDateNumber = currentDate.getDate();

                        // Crea la cella di una giornata dei turni
                        return (
                          <Grid key={'dateCell' + weekNumber.toString() + indexDayOfTheWeek} item xs>
                            {
                              currentDate.getMonth() === selectedMonth.getMonth() &&
                              <Box
                                border={1}
                                borderRadius={2}
                                borderColor="gray"
                                className={classes.hCell}
                                onClick={() => handleOpen(dateIndex, currentDate)}
                              >
                                {/* date title number */}
                                <Container className={'titleNumberClassName'}>
                                  <Typography variant="body1" className={classes.bold} align="center">
                                    {currentDateNumber}
                                  </Typography>
                                </Container>

                                <Divider />

                                {/* turni box */}
                                <Box
                                  p={1}
                                // className={clsx({ [classes.disabled]: currentDate.getMonth() !== selectedMonth.getMonth() })}
                                >
                                  <Grid container>
                                    <Grid item xs>
                                      {
                                        preparedData && preparedData[dateIndex] && currentDate.getMonth() === selectedMonth.getMonth() &&
                                        preparedData[dateIndex].filter(elem => (elem.turno.descrizioneBreve !== '' || elem.turno.descrizione !== '') /* && elem.idQualifica === idQualifica */).map((turni, index) => {
                                          return (
                                            <Typography
                                              key={'turnoDescrizione' + weekNumber.toString() + indexDayOfTheWeek.toString() + index}
                                              variant="body2"
                                              className={clsx(turni.idQualifica !== idQualifica ? classes.textLightGray : '')}
                                            >
                                              {turni.turno.descrizioneBreve}
                                              {turni.turno.reperibilita ? '*' : ""}
                                            </Typography>
                                          )
                                        })
                                      }
                                    </Grid>
                                    <Grid item xs={3}>
                                      {
                                        preparedData && preparedData[dateIndex] && currentDate.getMonth() === selectedMonth.getMonth() &&
                                        preparedData[dateIndex].filter(elem => (elem.turno.descrizioneBreve !== '' || elem.turno.descrizione !== '') /* && elem.idQualifica === idQualifica */).map((turni, index) => {
                                          return (
                                            <Typography
                                              key={'turnoOre' + weekNumber.toString() + indexDayOfTheWeek.toString() + index}
                                              variant="body2"
                                              className={clsx(turni.idQualifica !== idQualifica ? classes.textLightGray : '')}
                                            >
                                              {turni.ore + ':' + (turni.minuti < 10 ? '0' + turni.minuti : (turni.minuti ?? '00'))}
                                            </Typography>
                                          )
                                        })
                                      }
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            }
                          </Grid>
                        );
                      })
                    }

                  </Grid>
                })
            }
            <Grid xs={12}>
              <Box display='flex' justifyContent={'space-Between'} padding={3}>

                <Typography variant="body1"  >
                  {t('totalShiftParam')}:
                  <span className={classes.bold}>
                    {(riepilogoAnagraficaModifica?.oreTotale ?? 0) + ':' + (riepilogoAnagraficaModifica.minutiTotale && riepilogoAnagraficaModifica.minutiTotale < 10 ? '0' + riepilogoAnagraficaModifica.minutiTotale : (riepilogoAnagraficaModifica.minutiTotale ?? '00'))}
                  </span>
                </Typography>

                <Typography variant="body1" >
                  {t('totalRestParam')}:
                  <span className={classes.bold}>
                    {riepilogoAnagraficaModifica?.numeroRiposi ?? 0}
                  </span>
                </Typography>
              </Box>
              <Box padding={3}>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {
        currentSelectedDate &&
        <InsertTurniModal
          currentSelectedAS={currentSelectedAS}
          currentSelectedDate={currentSelectedDate}
          preparedData={preparedData}
          selectedDateIndex={selectedDateIndex}
          openState={{
            open: open,
            setOpen: setOpen
          }}
          fixedProps={fixedProps}
        />
      }
    </>
  );
}

export default Calendar;

function prepareData(riepilogoSingoloAS: RiepilogoPianificazioneDTO, mappaTurniOre: MappaTurniOre, offset: number): OrderedListOfTurni[][] {
  const retval: OrderedListOfTurni[][] = riepilogoSingoloAS?.turniObj.map((giorno, indexGiorno) => {
    const temp: OrderedListOfTurni[] = [];

    // se giorno === '', allora salta (ritorna un'array vuota)
    giorno && giorno.forEach((turno, indexTurno) => {
      temp.push({
        idQualifica: riepilogoSingoloAS.qualificheIds[indexGiorno][indexTurno],
        turno: turno,
        ore: mappaTurniOre[turno.descrizioneBreve]?.ore,
        minuti: mappaTurniOre[turno.descrizioneBreve]?.minuti,
        turnoFisso: riepilogoSingoloAS.turnoFisso,
        modificaTurnoFisso: riepilogoSingoloAS.modificaTurnoFisso,
      });
    });

    return temp;
  });

  // riempie i primi offset posizioni con oggetti vuoti
  new Array(offset).fill({ "": undefined }).forEach(_ => {
    retval?.unshift([]);
  });

  return retval;
}