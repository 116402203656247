import { Fields } from "./Fields";
import RemoveIcon from '@material-ui/icons/Remove';

export type StraordinariKeys = keyof Straordinari;

export const elementIdProps: StraordinariKeys[] = [];
export const elementRenderProps: StraordinariKeys[] = [];

export const allFields: Fields[] = [
    {
        field: "cognome", type: 'string', titleKey: "lastNameParam", required: false, show: true,
    },
    {
        field: "nome", type: 'string', titleKey: "nameParam", required: false, show: true,
    },
    {
        field: "qualificaDescrizioneBreve", type: 'string', titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "oreLavorate", type: 'numeric', titleKey: "hoursWorkedParam", required: false, show: true,
    },
    {
        field: "oreDovute", type: 'numeric', titleKey: "hoursDueParam", required: false, show: true,
    },
    {
        field: "oreStraordinari", type: 'numeric', titleKey: "overtimeParam", required: false, show: true,
        render: (rowData) => rowData.oreStraordinari ?? <RemoveIcon />
    },
];

export interface Straordinari {
    idStruttura: number,
    idDipartimento: number,
    idAnagraficaSoggetto: number,
    idOrganizzazione: number,
    cognome: string,
    nome: string,
    qualificaDescrizione: string,
    qualificaDescrizioneBreve: string,
    idQualifica: number,
    oreLavorate: number,
    oreDovute: number,
    oreStraordinari: number,
}