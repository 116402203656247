import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setDefaultOptions } from 'date-fns';
import { it, enGB } from 'date-fns/locale'
import i18n from "../../i18n";
import {
  setStatoPianificazioneTranslation,
  setStatoPianificazioneMap,
  setCombinazioneTurniLookup,
  setTipoPartTimeLookup,
  setVersoTimbratura,
  setTipoContrattoLookup,
  setElencoTurniFissiLookup,
  setSessoLookup
} from "../../store/slices/lookupSlice";

interface ConfigProps {
  hasIdAppSoftwareCookie: boolean
}

const useConfig = (props: ConfigProps) => {
  const { hasIdAppSoftwareCookie } = props;

  const dispatch = useAppDispatch();

  const lingua = useAppSelector(state => state.guida.lingua);

  /**
   * Settare il locale di default per date-fns
  */
  React.useEffect(() => {
    let locale = it;
    if (lingua) {
      switch (lingua) {
        case 'it':
          locale = it;
          break;
        case 'en':
          locale = enGB;
          break;
      }
      setDefaultOptions({ locale });
    }
  }, [lingua]);

  /**
   * Configurazione delle traduzioni
   */
  const translation = useAppSelector(state => state.applicazioneSoftware.translation);
  React.useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (Object.keys(translation).length > 0) {
      /**
       * Aggiornare tutte le translation di tutte le lingue
       */
      Object.keys(translation).forEach(lang => {
        i18n.addResourceBundle(lang, 'translation', translation[lang], true, true)
      })

      if (translation[lingua]['statoPianificazioneTranslation']) {
        const statoPianificazioneTranslation = JSON.parse(translation[lingua]['statoPianificazioneTranslation'])
        dispatch(setStatoPianificazioneTranslation(statoPianificazioneTranslation))
      }

      if (translation[lingua]['statoPianificazioneMap']) {
        const statoPianificazioneMap = JSON.parse(translation[lingua]['statoPianificazioneMap'])
        dispatch(setStatoPianificazioneMap(statoPianificazioneMap))
      }

      if (translation[lingua]['combinazioniTurniLookup']) {
        const combinazioneTurniLookup = JSON.parse(translation[lingua]['combinazioniTurniLookup'])
        dispatch(setCombinazioneTurniLookup(combinazioneTurniLookup))
      }

      if (translation[lingua]['elencoTurniFissiLookup']) {
        const elencoTurniFissiLookup = JSON.parse(translation[lingua]['elencoTurniFissiLookup'])
        dispatch(setElencoTurniFissiLookup(elencoTurniFissiLookup))
      }

      if (translation[lingua]['tipoPartTimeLookup']) {
        const tipoPartTimeLookup = JSON.parse(translation[lingua]['tipoPartTimeLookup'])
        dispatch(setTipoPartTimeLookup(tipoPartTimeLookup))
      }

      if (translation[lingua]['tipoContrattoLookup']) {
        const tipoContrattoLookup = JSON.parse(translation[lingua]['tipoContrattoLookup'])
        dispatch(setTipoContrattoLookup(tipoContrattoLookup))
      }

      if (translation[lingua]['versoTimbratura']) {
        const versoTimbratura = JSON.parse(translation[lingua]['versoTimbratura'])
        dispatch(setVersoTimbratura(versoTimbratura))
      }

      if (translation[lingua]['sessoLookup']) {
        const sessoLookup = JSON.parse(translation[lingua]['sessoLookup'])
        dispatch(setSessoLookup(sessoLookup))
      }
    }
  }, [dispatch, hasIdAppSoftwareCookie, lingua, translation])
}

export default useConfig;