import { Fields } from "./Fields";
import { AnagraficaSoggettiFormazione } from "./AnagraficaSoggettiFormazione";

export type ScadenzaFormazioneKeys = keyof ScadenzaFormazione;

export const elementIdProps: ScadenzaFormazioneKeys[] = ["idAnagraficaSoggetto"];
export const elementRenderProps: ScadenzaFormazioneKeys[] = ["nome", "cognome"];

export const allFields: Fields[] = [
  {
    field: "matricola", type: "string", required: false, removable: false, show: true, titleKey: "numberParam", readonly: true,
  },
  {
    field: "codiceFiscale", type: "string", required: false, removable: false, show: true, titleKey: "fiscalCodeParam", group: "",
  },
  {
    field: "cognome", type: "string", required: false, removable: false, show: true, titleKey: "lastNameParam", group: "",
  },
  {
    field: "nome", type: "string", required: false, removable: false, show: true, titleKey: "nameParam", group: "",
  },
  {
    field: "strutturaNome", type: "string", required: false, removable: false, show: true, titleKey: "structureParam",
  },
  {
    field: "dipartimentoNome", type: "string", required: false, removable: false, show: true, titleKey: "departmentParam",
  },
  {
    field: "unitaOperativaNome", type: "string", required: false, removable: false, show: true, titleKey: "operationalUnitParam",
  },
  {
    field: "qualificaDescrizione", type: "string", required: false, removable: false, show: true, titleKey: "qualificationParam", readonly: true,
  },
  {
    field: "formazioneDescrizione", required: true, show: true, titleKey: "trainingParam",

  },
  {
    field: "dataTitolo", type: "date", required: false, show: true, titleKey: "titleDateParam", sort: 'desc',

  },
  {
    field: "dataScadenza", type: "date", required: false, show: true, titleKey: "expirationDateParam", sort: 'desc',
  },
  {
    field: "giorniScadenza", type: "numeric", required: false, show: true, titleKey: "Giorni Scadenza", sort: 'desc',
  }

]

export interface ScadenzaFormazione extends AnagraficaSoggettiFormazione {

  matricola: string,
  nome: string,
  cognome: string,
  idStruttura: number,
  strutturaNome: string,
  idDipartimento: number,
  dipartimentoNome: string,
  idUnitaOperativa: number,
  unitaOperativaNome: string,
  qualificaDescrizione: string,
  giorniScadenza: number,
  version: number,

}

