import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { Festivita, elementIdProps, FestivitaKeys } from '../../models/Festivita';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "festivita";
const entity = "festivita";

interface FestivitaState {
  statusValidFestivita: StatusEnum,
  validFestivita: Festivita[],
  statusDeletedFestivita: StatusEnum,
  deletedFestivita: Festivita[],
  statusAllFestivita: StatusEnum,
  allFestivita: Festivita[],
  festivita: Festivita | null,
  lookup: Lookup,
  error: string | null
}

const initialState: FestivitaState = {
  statusValidFestivita: StatusEnum.Succeeded,
  validFestivita: [],
  statusDeletedFestivita: StatusEnum.Succeeded,
  deletedFestivita: [],
  statusAllFestivita: StatusEnum.Succeeded,
  allFestivita: [],
  festivita: null,
  lookup: {},
  error: null
}

// get cerca/festivita/id/{mese}/{giorno}
export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Festivita;
});

// get cerca/festivita/all/completa
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as Festivita[];
});

// get cerca/festivita/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Festivita[];
});

// get cerca/festivita/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Festivita[];
});

// post inserisci/festivita
export const insert = createAsyncThunk(microservice + '/insert', async (codiceVisita: Festivita) => {
  const response = await create(codiceVisita, microservice, entity);
  return response.data as Festivita;
});

// put modifica/festivita
export const update = createAsyncThunk(microservice + '/update', async (codiceVisita: Festivita) => {
  const response = await upd(codiceVisita, microservice, entity);
  return response.data as Festivita;
});

// put elimina/festivita/id/{mese}/{giorno}
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/festivita/id/{mese}/{giorno}
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/festivita/id/{mese}/{giorno}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const festivitaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: FestivitaState) => {
      return initialState;;
    },
    resetError: (state: FestivitaState) => {
      state.error = initialState.error;
      state.statusValidFestivita = initialState.statusValidFestivita;
      state.statusDeletedFestivita = initialState.statusDeletedFestivita;
      state.statusAllFestivita = initialState.statusAllFestivita;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFestivita = StatusEnum.Failed;
      }
      state.festivita = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Festivita>) => {
      state.statusValidFestivita = StatusEnum.Succeeded;
      state.festivita = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllFestivita = StatusEnum.Failed;
      }
      state.lookup = {}
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Festivita[]>) => {
      state.statusAllFestivita = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idCodiceVisita', ['descrizione']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFestivita = StatusEnum.Failed;
      }
      state.validFestivita = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Festivita[]>) => {
      state.statusValidFestivita = StatusEnum.Succeeded;
      state.validFestivita = payload ?? [];

      const lookup: Record<string, string> = {};
      payload.forEach(({ mese, giorno, descrizione }) => lookup[giorno + '/' + mese] = descrizione);
      state.lookup = lookup;
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedFestivita = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFestivita = StatusEnum.Failed;
      }
      state.deletedFestivita = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Festivita[]>) => {
      state.statusDeletedFestivita = StatusEnum.Succeeded;
      state.deletedFestivita = payload ?? [];

      const lookup: Record<string, string> = {};
      payload.forEach(({ mese, giorno, descrizione }) => lookup[giorno + '/' + mese] = descrizione);
      state.lookup = lookup;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidFestivita = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFestivita = StatusEnum.Failed;
      }
      state.festivita = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Festivita>) => {
      state.statusValidFestivita = StatusEnum.Succeeded;
      state.festivita = payload;
      state.validFestivita = state.validFestivita.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidFestivita = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFestivita = StatusEnum.Failed;
      }
      state.festivita = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Festivita>) => {
      state.statusValidFestivita = StatusEnum.Succeeded;
      state.validFestivita = state.validFestivita.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.festivita = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidFestivita = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidFestivita = StatusEnum.Succeeded;
      const deleted = state.validFestivita.find(el => payload.ids.every(idObj => el[idObj.name as FestivitaKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedFestivita = state.deletedFestivita.concat([deleted]);
      }
      state.validFestivita = state.validFestivita.filter(el => payload.ids.some(idObj => el[idObj.name as FestivitaKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedFestivita = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedFestivita = StatusEnum.Succeeded;
      const valid = state.deletedFestivita.find(el => payload.ids.every(idObj => el[idObj.name as FestivitaKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validFestivita = state.validFestivita.concat([valid]);
      }
      state.deletedFestivita = state.deletedFestivita.filter(el => payload.ids.some(idObj => el[idObj.name as FestivitaKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedFestivita = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedFestivita = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedFestivita = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedFestivita = StatusEnum.Succeeded;
      state.deletedFestivita = state.deletedFestivita.filter(el => payload.ids.some(idObj => el[idObj.name as FestivitaKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = festivitaSlice.actions;
export default festivitaSlice.reducer;