import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SnackBarState {
  open: boolean,
  message: string
}

interface SnackBarsState {
  snackbarerror: SnackBarState,
  snackbarsuccess: SnackBarState
}

const initialState: SnackBarsState = {
  snackbarerror: {
    open: false,
    message: ""
  },
  snackbarsuccess: {
    open: false,
    message: ""
  }
}

export const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    openSnackBarError: (state, { payload }: PayloadAction<string>) => {
      state.snackbarerror.open = true;
      state.snackbarerror.message = payload;
    },
    closeSnackBarError: state => {
      state.snackbarerror.open = false;
      state.snackbarerror.message = "";
    },
    openSnackBarSuccess: (state, { payload }: PayloadAction<string>) => {
      state.snackbarsuccess.open = true;
      state.snackbarsuccess.message = payload;
    },
    closeSnackBarSuccess: state => {
      state.snackbarsuccess.open = false;
      state.snackbarsuccess.message = "";
    }
  }
})

export const { openSnackBarError, closeSnackBarError,
  openSnackBarSuccess, closeSnackBarSuccess } = snackbarsSlice.actions

export default snackbarsSlice.reducer