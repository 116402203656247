import { Colors, ColorsKeys } from "../utils/utildata";
import { Fields } from "./Fields";
import { UnitaOperativaTurnoNumeroAddetti } from "./UnitaOperativeTurniNumeroAddetti";

export type ConteggioQualificaKeys = keyof ConteggioQualifica;

export const elementIdProps: ConteggioQualificaKeys[] = [];
export const elementRenderProps: ConteggioQualificaKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, lookupField: true,
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, lookupField: true,
  },

  {
    field: "numeroPresenze", titleKey: "attendanceNumberParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "numeroReperibilita", titleKey: "availabilityParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "differenzaNumLegale", titleKey: "legalNumberDiffParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "differenzaNumAddetti", titleKey: "employeeNumberDiffParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "differenzaNumReperibilitaRichieste", titleKey: "requestedAvailabilityNumberDiffParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "colorMonitoring", titleKey: "statusParam", required: false, show: true,
    render: (rowData) => {
      return <div style={{ height: 25, width: 25, borderRadius: 50, borderColor: '#555555', borderStyle: 'solid', borderWidth: 1, backgroundColor: Colors[rowData.colorMonitoring as ColorsKeys] }}></div>
    },
  },
];

export interface ConteggioQualifica extends UnitaOperativaTurnoNumeroAddetti {

  numeroPresenze: number,
  numeroReperibilita: number,
  numeroLegale: number,
  numeroReperibilitaRichieste: number,
  differenzaNumAddetti: number,
  differenzaNumLegale: number,
  differenzaNumReperibilitaRichieste: number,
  colorMonitoring: 'Bianco' | 'Rosso' | 'Verde' | 'Giallo' | 'Arancione',
}