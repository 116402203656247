import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CheckJWTResponse, TokenResponse } from '../../models/AuthModels';
import { OrganizationInfo } from '../../models/Organizzazione';
import { StatusEnum } from '../../models/Utils';
import { checkJWT, getOrganizationByID, refreshToken } from '../../services/authentication.service';
import { cookieOrg, cookiePortal, cookieProfil, localStorageKeyLoggedApp, portaleFEUrl, secondLevelDomain, sessionLogo, sessionOrg, sessionProfil } from '../../utils/utilconst';
import { deleteCookie, getCookie, setCookie } from '../../utils/utilfunctions';
import { RootState } from '../store';

interface AuthInfoState {
  status: string,
  token: string | null,
  organization: string | null,
  organizationName: string | null,
  logoUri: string | null,
  idProfil: string[] | null,
  domain: string | undefined,
  expire: number,
  error: string | null,
}

const _sessionProfil = sessionStorage.getItem(sessionProfil)

const initialState: AuthInfoState = {
  status: StatusEnum.Succeeded,
  token: getCookie(cookiePortal) ? getCookie(cookiePortal) : null,
  organization: sessionStorage.getItem(sessionOrg),
  organizationName: null,
  logoUri: null,
  idProfil: _sessionProfil ? JSON.parse(_sessionProfil) : null,
  domain: secondLevelDomain,
  expire: 60 * 60 * 1000,
  error: null
}

export const fetchRefreshToken = createAsyncThunk('authInfo/refreshToken', async () => {
  const response = await refreshToken();
  const respData = response.data as TokenResponse;
  return respData.token;
})

export const checkJsonWebToken = createAsyncThunk('authInfo/checkJsonWebToken', async (jwt: string) => {
  const response = await checkJWT(jwt);
  const respData = response.data as CheckJWTResponse;
  return { isValid: respData.isValid, token: jwt };
})

export const fetchOrganizzazioneInfo = createAsyncThunk('authInfo/fetchOrganizzazioneInfo', async (obj: { IDOrg: number, token: string }) => {
  const response = await getOrganizationByID({ IDOrg: obj.IDOrg, token: obj.token });
  return response.data as OrganizationInfo;
})

export const authInfoSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    logoutAction: state => {
      deleteCookie(cookiePortal, state.domain);
      window.localStorage.removeItem(localStorageKeyLoggedApp);
      sessionStorage.removeItem(sessionProfil);
      sessionStorage.removeItem(sessionLogo);
      state.status = StatusEnum.Succeeded;
      state.token = null;
      state.error = null;
      state.logoUri = null;
      state.idProfil = null;
    },
    autoLoginAction: (state, { payload }: PayloadAction<string>) => {
      state.status = StatusEnum.Succeeded;
      state.token = payload;
      state.error = null;
    },
    setCookieOrganizationAction: state => {
      state.organization = getCookie(cookieOrg);
    },
    setSessionProfilAction: state => {
      let profil = getCookie(cookieProfil)

      if (profil) window.sessionStorage.setItem(sessionProfil, profil);
      else profil = window.sessionStorage.getItem(sessionProfil)

      state.idProfil = profil ? JSON.parse(profil) : null;
      deleteCookie(cookieProfil, state.domain);
      !profil && (window.location.href = portaleFEUrl);
    },
    setLogoUri: (state, { payload }: PayloadAction<string>) => {
      sessionStorage.setItem(sessionLogo, payload);
      state.logoUri = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrganizzazioneInfo.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchOrganizzazioneInfo.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      sessionStorage.removeItem(sessionLogo);
      state.organizationName = null;
      state.logoUri = null;
    })
    builder.addCase(fetchOrganizzazioneInfo.fulfilled, (state, { payload }: PayloadAction<OrganizationInfo>) => {
      state.status = StatusEnum.Succeeded;
      sessionStorage.setItem(sessionLogo, payload.logoOrganizzazione);
      state.organizationName = payload.nome;
      state.logoUri = payload.logoOrganizzazione;
    })
    builder.addCase(fetchRefreshToken.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchRefreshToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(fetchRefreshToken.fulfilled, (state, { payload }: PayloadAction<string>) => {
      setCookie(cookiePortal, payload, state.domain, state.expire);
      state.status = StatusEnum.Succeeded;
      state.token = payload;
    })
    builder.addCase(checkJsonWebToken.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(checkJsonWebToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(checkJsonWebToken.fulfilled, (state, { payload }: PayloadAction<{ isValid: boolean, token: string }>) => {
      if (payload) {
        state.status = StatusEnum.Succeeded;
        state.token = payload.token;
      } else {
        state.status = StatusEnum.Failed;
        state.token = null;
        deleteCookie(cookiePortal, state.domain);
      }
    })
  }
})

export const { logoutAction, autoLoginAction,
  setCookieOrganizationAction, setSessionProfilAction, setLogoUri } = authInfoSlice.actions

export const isLoadingAuth = (state: RootState) => state.authInfo.status === StatusEnum.Loading
export const isLogged = (state: RootState) => state.authInfo.token !== null

export default authInfoSlice.reducer