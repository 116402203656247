import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, getByIds, restoreById, upd } from '../../services/services.service'
import { Timbrature, elementIdProps, TimbratureAnagraficaSoggetto, TimbratureKeys, } from '../../models/Timbrature';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "unitaoperative";
const entity = "timbrature";

interface TimbratureState {
  statusValidTimbrature: StatusEnum,
  statusDeletedTimbrature: StatusEnum,
  statusValidTimbratureCheck: StatusEnum,
  statusValidTimbratureAnagraficaSoggetto: StatusEnum,
  validTimbrature: Timbrature[],
  deletedTimbrature: Timbrature[],
  validTimbratureCheck: Timbrature[],
  validTimbratureAnagraficaSoggetto: TimbratureAnagraficaSoggetto | null,
  timbrature: Timbrature | null,
  lookupAnagrafiche: Lookup,
  error: string | null
}

const initialState: TimbratureState = {
  statusValidTimbrature: StatusEnum.Succeeded,
  statusDeletedTimbrature: StatusEnum.Succeeded,
  statusValidTimbratureCheck: StatusEnum.Succeeded,
  statusValidTimbratureAnagraficaSoggetto: StatusEnum.Succeeded,
  validTimbrature: [],
  deletedTimbrature: [],
  validTimbratureCheck: [],
  validTimbratureAnagraficaSoggetto: null,
  timbrature: null,
  lookupAnagrafiche: {},
  error: null
}

//@GetMapping("cerca/timbrature/id/{idTimbratura}")
export const fetchById = createAsyncThunk(entity + '/fetchById', async (obj: { idTimbratura: number }) => {
  let ids = [obj.idTimbratura];
  const response = await getById(microservice, entity, ids);
  return response.data as Timbrature;
});

// @GetMapping("cerca/timbrature/{dataInizio}/{dataFine}")
export const fetchBetweenDates = createAsyncThunk(entity + '/fetchBetweenDates', async (obj: { inizioData: string, fineData: string }) => {
  let ids = [obj.inizioData, obj.fineData];
  const response = await getByIds(microservice, entity, ids, ['/fetchBetweenDates']);
  return response.data as Timbrature[];
});

// @GetMapping("cerca/timbrature/{dataInizio}/{dataFine}")
export const fetchBetweenDatesValid = createAsyncThunk(entity + '/fetchBetweenDatesValid', async (obj: { inizioData: string, fineData: string }) => {
  let ids = [obj.inizioData, obj.fineData, false];
  const response = await getByIds(microservice, entity, ids, ['validTimbrature']);
  return response.data as Timbrature[];
});

// @GetMapping("cerca/timbrature/{dataInizio}/{dataFine}")
export const fetchBetweenDatesDeleted = createAsyncThunk(entity + '/fetchBetweenDatesDeleted', async (obj: { inizioData: string, fineData: string }) => {
  let ids = [obj.inizioData, obj.fineData, true];
  const response = await getByIds(microservice, entity, ids, ['/fetchBetweenDatesDeleted']);
  return response.data as Timbrature[];
});

// @GetMapping("cerca/timbrature/id/anagrafica/{idAnagraficaSoggetto}/{data}")
export const findByIdOrganizzazioneAndIdAnagraficaSoggettoData = createAsyncThunk(entity + '/findByIdOrganizzazioneAndIdAnagraficaSoggettoData', async (obj: { idAnagraficaSoggetto: number, data: string }) => {
  let ids = ["anagrafica", obj.idAnagraficaSoggetto, obj.data];
  const response = await getById(microservice, entity, ids);
  return response.data as TimbratureAnagraficaSoggetto;
});

//@GetMapping ("cerca/timbrature/id/anagrafica/{idAnagraficaSoggetto}/{dataInizio}/{dataFine}")
export const findbyidAnagraficaSoggettoTimbratureDipendente = createAsyncThunk(entity + '/findbyidAnagraficaSoggettoTimbratureDipendente', async (obj: { idAnagraficaSoggetto: number, inizioData: string, fineData: string }) => {
  let ids = ["anagrafica", obj.idAnagraficaSoggetto, obj.inizioData, obj.fineData];
  const response = await getById(microservice, entity, ids);
  return response.data as Timbrature[];
});

//@GetMapping ("cerca/timbrature/id/anagrafica/{idAnagraficaSoggetto}/{data}/{dataFine}")
export const findbyidAnagraficaSoggettoTimbratureDipendenteCheck = createAsyncThunk(entity + '/findbyidAnagraficaSoggettoTimbratureDipendenteCheck', async (obj: { idAnagraficaSoggetto: number, inizioData: string, fineData: string }) => {
  let ids = ["anagrafica", obj.idAnagraficaSoggetto, obj.inizioData, obj.fineData];
  const response = await getById(microservice, entity, ids);
  return response.data as Timbrature[];
});

//@GetMapping("cerca/timbrature/allValid")
export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['validTimbrature']);
  return response.data as Timbrature[];
});

// get cerca/articolilegge/all/deleted
export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Timbrature[];
});

// inserisci/timbrature
export const insert = createAsyncThunk(entity + '/insert', async (timbratura: Timbrature) => {
  const response = await create(timbratura, microservice, entity);
  return response.data as Timbrature;
});

// modifica/timbrature
export const update = createAsyncThunk(entity + '/update', async (timbratura: Timbrature) => {
  const response = await upd(timbratura, microservice, entity);
  return response.data as Timbrature;
});

//restore/timbrature/id/
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<TimbratureKeys>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

//logicaDel
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<TimbratureKeys>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// delete/timbrature/id/{idTimbratura}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<TimbratureKeys>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const timbratureSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: TimbratureState) => {
      return {
        ...state,
        lookupAnagrafiche: {}
      }
    },
    reset: (state: TimbratureState) => {
      return initialState;
    },
    resetError: (state: TimbratureState) => {
      return {
        ...state,
        error: initialState.error,
        statusValidTimbrature: initialState.statusValidTimbrature
      }
    },
    resetTimbraturaAnagrificaSoggetto: (state: TimbratureState) => {
      return {
        ...state,
        validTimbratureAnagraficaSoggetto: initialState.validTimbratureAnagraficaSoggetto,
      }
    }
  },
  extraReducers: builder => {

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.timbrature = null;
      state.lookupAnagrafiche = {};
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Timbrature>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.timbrature = payload;
      state.lookupAnagrafiche = createLookup([payload], "idContratto", ["organizzazioneNome", "applicazioneSoftwareDescrizione", "dataInizio", "dataFine"], null, " - ");
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.validTimbrature = [];
      state.lookupAnagrafiche = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.validTimbrature = payload ?? [];
      state.lookupAnagrafiche = createLookup(payload, "idTimbrature", ["organizzazioneNome", "applicazioneSoftwareDescrizione", "dataInizio", "dataFine"], null, " - ");
    })

    // fetchBetweenDates
    builder.addCase(fetchBetweenDates.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(fetchBetweenDates.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.validTimbrature = [];
      state.lookupAnagrafiche = {};
    })
    builder.addCase(fetchBetweenDates.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.validTimbrature = payload ?? [];
      state.lookupAnagrafiche = createLookup(payload, "idAnagraficaSoggetto", ['cognome', 'nome']);
    })

    // fetchBetweenDatesValid
    builder.addCase(fetchBetweenDatesValid.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(fetchBetweenDatesValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.validTimbrature = [];
      state.lookupAnagrafiche = {};
    })
    builder.addCase(fetchBetweenDatesValid.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.validTimbrature = payload ?? [];
      state.lookupAnagrafiche = createLookup(payload, "idAnagraficaSoggetto", ['cognome', 'nome']);
    })

    // fetchBetweenDatesDeleted
    builder.addCase(fetchBetweenDatesDeleted.pending, (state) => {
      state.statusDeletedTimbrature = StatusEnum.Loading;
    })
    builder.addCase(fetchBetweenDatesDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedTimbrature = StatusEnum.Failed;
      }
      state.deletedTimbrature = [];
      state.lookupAnagrafiche = {};
    })
    builder.addCase(fetchBetweenDatesDeleted.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusDeletedTimbrature = StatusEnum.Succeeded;
      state.deletedTimbrature = payload ?? [];
      state.lookupAnagrafiche = createLookup(payload, "idAnagraficaSoggetto", ['cognome', 'nome']);
    })

    // findByIdOrganizzazioneAndIdAnagraficaSoggettoData
    builder.addCase(findByIdOrganizzazioneAndIdAnagraficaSoggettoData.pending, (state) => {
      state.statusValidTimbratureAnagraficaSoggetto = StatusEnum.Loading;
    })
    builder.addCase(findByIdOrganizzazioneAndIdAnagraficaSoggettoData.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbratureAnagraficaSoggetto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbratureAnagraficaSoggetto = StatusEnum.Failed;
      }
      state.validTimbratureAnagraficaSoggetto = null;
    })
    builder.addCase(findByIdOrganizzazioneAndIdAnagraficaSoggettoData.fulfilled, (state, { payload }: PayloadAction<TimbratureAnagraficaSoggetto>) => {
      state.statusValidTimbratureAnagraficaSoggetto = StatusEnum.Succeeded;
      state.validTimbratureAnagraficaSoggetto = payload;
    })

    //findbyidAnagraficaSoggettoTimbratureDipendente
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendente.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendente.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.validTimbrature = [];
    })
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendente.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.validTimbrature = payload ?? [];
    })

    //findbyidAnagraficaSoggettoTimbratureDipendenteCheck
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendenteCheck.pending, (state) => {
      state.statusValidTimbratureCheck = StatusEnum.Loading;
    })
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendenteCheck.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbratureCheck = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbratureCheck = StatusEnum.Failed;
      }
      state.validTimbratureCheck = [];
    })
    builder.addCase(findbyidAnagraficaSoggettoTimbratureDipendenteCheck.fulfilled, (state, { payload }: PayloadAction<Timbrature[]>) => {
      state.statusValidTimbratureCheck = StatusEnum.Succeeded;
      state.validTimbratureCheck = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
      state.statusValidTimbratureCheck = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.statusValidTimbratureCheck = StatusEnum.Failed;
      state.timbrature = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Timbrature>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.statusValidTimbratureCheck = StatusEnum.Succeeded;
      state.timbrature = payload;
      state.validTimbrature = state.validTimbrature.concat([payload]);
      state.validTimbratureCheck = state.validTimbratureCheck.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
      state.timbrature = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Timbrature>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.validTimbrature = state.validTimbrature.map(el => {
        if (elementIdProps.every(prop => el[prop as TimbratureKeys] === payload[prop as TimbratureKeys])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.timbrature = payload;
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedTimbrature = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedTimbrature = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<TimbratureKeys>[] }>) => {
      state.statusDeletedTimbrature = StatusEnum.Succeeded;
      const valid = state.deletedTimbrature.find(el => payload.ids.every(idObj => el[idObj.name as TimbratureKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validTimbrature = state.validTimbrature.concat([valid]);
      }
      state.deletedTimbrature = state.deletedTimbrature.filter(el => payload.ids.some(idObj => el[idObj.name as TimbratureKeys] !== idObj.id));
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<TimbratureKeys>[] }>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      const deleted = state.validTimbrature.find(el => payload.ids.every(idObj => el[idObj.name as TimbratureKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedTimbrature = state.deletedTimbrature.concat([deleted]);
      }
      state.validTimbrature = state.validTimbrature.filter(el => payload.ids.some(idObj => el[idObj.name as TimbratureKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidTimbrature = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidTimbrature = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidTimbrature = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<TimbratureKeys>[] }>) => {
      state.statusValidTimbrature = StatusEnum.Succeeded;
      state.deletedTimbrature = state.deletedTimbrature.filter(el => payload.ids.some(idObj => el[idObj.name as TimbratureKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError, resetTimbraturaAnagrificaSoggetto } = timbratureSlice.actions;
export default timbratureSlice.reducer;