import { validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { UnitaOperativaCodiceVisite } from "./UnitaOperativaCodiceVisite";
import { UnitaOperativaFormazione } from "./UnitaOperativaFormazione";
import { UnitaOperativaTurnoNumeroAddetti } from "./UnitaOperativeTurniNumeroAddetti";

export type UnitaOperativaKeys = keyof UnitaOperativa;
export type UnitaOperativaEstesaKeys = keyof UnitaOperativaEstesa;

export const elementIdProps: UnitaOperativaKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa"];
export const elementRenderProps: UnitaOperativaKeys[] = ["nome"];

export const allFields: Fields[] = [
    {
        field: "nome", type: "string", required: true, show: true, titleKey: "nameParam", colsNum: 6,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 60, false, helperText, true), keyTradValidation: "size3to60ParamValidation",
    },
    {
        field: "descrizione", type: "string", required: false, show: true, titleKey: "descriptionParam", colsNum: 6,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
    },
    {
        field: "idCentroDiCosto", required: false, show: false, titleKey: "costCenterParam", lookupField: true, colsNum: 12,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "centroDiCostoDescrizione", required: false, show: true, titleKey: "costCenterParam", colsNum: 12,
    },
    {
        field: "idCentroDiCostoSecondario", required: false, show: false, titleKey: "secondaryCdcParam", lookupField: true, colsNum: 12,
        // validate: (x: number, helperText:string) => validateRequired(x,helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "centroDiCostoSecondarioDescrizione", required: false, show: true, titleKey: "secondaryCdcParam", colsNum: 12,
        // validate: (x: number, helperText:string) => validateRequired(x,helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "idCentroDiCostoCostiComuni", required: false, show: false, titleKey: "costCenterParamcommunes", lookupField: true, colsNum: 12,
        // validate: (x: number, helperText:string) => validateRequired(x,helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "centroDiCostoCostiComuniDescrizione", required: false, show: true, titleKey: "costCenterParamcommunes", colsNum: 12,
    },
    {
        field: "idCentroDiCostoLungaAssenza", required: false, show: false, titleKey: "longAbsenceCdcParam", lookupField: true, colsNum: 12,
        // validate: (x: number, helperText:string) => validateRequired(x,helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "centroDiCostoLungaAssenzaDescrizione", required: false, show: true, titleKey: "longAbsenceCdcParam", colsNum: 12,
    },
    {
        field: "monitor", type: "boolean", required: false, show: true, titleKey: "monitorParam"
    },
    {
        field: "reperibilita", type: "boolean", required: false, show: true, titleKey: "availabilityParam"
    },
    {
        field: "reperibilitaFestivi", type: "boolean", required: false, show: true, titleKey: "holidayAvailabilityParam"
    },
]

export interface UnitaOperativa {
    idStruttura: number,
    idDipartimento: number,
    idUnitaOperativa: number,
    nome: string,
    descrizione?: string,
    idCentroDiCosto: number,
    centroDiCostoDescrizione: string,
    idCentroDiCostoSecondario: number,
    centroDiCostoSecondarioDescrizione: string,
    idCentroDiCostoLungaAssenza: number,
    centroDiCostoLungaAssenzaDescrizione: string,
    idCentroDiCostoCostiComuni: number,
    centroDiCostoCostiComuniDescrizione?: string,
    monitor: boolean,
    reperibilita: boolean,
    reperibilitaFestivi: boolean,
    version: number
}

export interface UnitaOperativaEstesa {
    idStruttura: number,
    idDipartimento: number,
    idUnitaOperativa: number,
    nome: string,
    descrizione?: string,
    idCentroDiCosto: number,
    monitor: boolean,
    reperibilita: boolean,
    unitaOperativeTurniNumeroAddetti: UnitaOperativaTurnoNumeroAddetti[]
    unitaOperativaCodiceVisite: UnitaOperativaCodiceVisite[]
    unitaOperativaFormazione: UnitaOperativaFormazione[]
    version: number
}

export interface LookupUnitaOperativaElem {
    idStruttura: number,
    idDipartimento: number,
    idUnitaOperativa: number,
    nome: string
}