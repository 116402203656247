import React from 'react'
import { useTranslation } from 'react-i18next';
import { Text } from 'react-pdf-renderer-v2';
import format from 'date-fns/format';

import styles from './styles/footer';

const FooterSection = () => {
  const { t } = useTranslation();
  const today = format(new Date(), 'dd/LL/yyyy HH:mm');

  return (
    <>
      <Text
        fixed
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => t('pageOfLabel').replace(/\{page\}|\{total\}/g, x => {
          switch (x) {
            case '{page}':
              return pageNumber?.toString();
            case '{total}':
              return totalPages?.toString();
          }

          return '';
        })}
      />
      <Text style={styles.dataOra} fixed>{today}</Text>
    </>
  )

}

export default FooterSection