import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { loadMenu/*, sampleFunctionalities*/ } from '../../services/funzionalita.service'
import { ErrorResponse, StatusEnum } from '../../models/Utils'
import Funz from '../../models/Funzione';
import { loginPagePath, loginTitle } from '../../utils/utilconst'

type HomeParamsT = Record<'homeTitle' | 'homeUri' | 'homeGuida', string | boolean | null>;

interface Abilitazioni {
  [funz: string]: number
}
interface FunzionalitaState {
  status: StatusEnum,
  funzionalita: Funz[],
  abilitazioni: Abilitazioni,
  uritotitle: UriToTitle,
  titletouri: TitleToUri,
  uritoguida: UriToGuida,
  hometitle: string | null,
  homeuri: string | null,
  homeguida: boolean | null,
  error: any
}

const initialState: FunzionalitaState = {
  status: StatusEnum.Succeeded,
  funzionalita: [],
  abilitazioni: {},
  uritotitle: {},
  titletouri: {},
  uritoguida: {},
  hometitle: null,
  homeuri: null,
  homeguida: null,
  error: null
}

export interface UriToTitle {
  [uri: string]: string
}
export interface TitleToUri {
  [title: string]: string
}

export interface UriToGuida {
  [uri: string]: boolean
}

const utilRecTitles = (funzionalita: Funz[], uritotitle: UriToTitle, titletouri: TitleToUri,
  abilitazioni: Abilitazioni, uritoguida: UriToGuida, homeParams: HomeParamsT) => {
  funzionalita.forEach(f => {
    if (f.macro) {
      f.funzioni && (utilRecTitles(f.funzioni, uritotitle, titletouri, abilitazioni, uritoguida, homeParams));
    } else if (f.uri) {
      if (f.homepage) {
        homeParams.homeTitle = f.descrizione;
        homeParams.homeUri = f.uri;
        homeParams.homeGuida = f.guida;
      }
      if (f.abilitazione) abilitazioni[f.uri] = f.abilitazione;

      uritotitle[f.uri] = f.descrizione;
      titletouri[f.descrizione] = f.uri;
      uritoguida[f.uri] = f.guida;
    }
  })
}

const getUriAndTitles = (funzionalita: Funz[]) => {
  let uritotitle: UriToTitle = {};
  let titletouri: TitleToUri = {};
  let abilitazioni: Abilitazioni = {};
  let uritoguida: UriToGuida = {};
  let homeParams: HomeParamsT = { homeTitle: null, homeUri: null, homeGuida: null };

  uritotitle[loginPagePath] = loginTitle;
  titletouri[loginTitle] = loginPagePath;
  uritoguida[loginPagePath] = false;


  funzionalita.forEach(f => {
    if (f.macro) {
      f.funzioni && (utilRecTitles(f.funzioni, uritotitle, titletouri, abilitazioni, uritoguida, homeParams));
    } else if (f.uri) {
      if (f.homepage) {
        homeParams.homeTitle = f.descrizione;
        homeParams.homeUri = f.uri;
        homeParams.homeGuida = f.guida;
      }
      if (f.abilitazione) abilitazioni[f.uri] = f.abilitazione;
      uritotitle[f.uri] = f.descrizione;
      titletouri[f.descrizione] = f.uri;
      uritoguida[f.uri] = f.guida;
    }
  })
  if (homeParams.homeUri === null) {
    homeParams.homeTitle = loginTitle;
    homeParams.homeUri = loginPagePath;
    homeParams.homeGuida = false;
  }

  return [uritotitle, titletouri, abilitazioni, uritoguida, homeParams.homeTitle, homeParams.homeUri, homeParams.homeGuida];
}

export const fetchFunzionalita = createAsyncThunk('funzionalita/fetchFunzionalita',
  async ({ idAppSoft, idAppSoftProfil }: { idAppSoft: string, idAppSoftProfil: string }) => {
    const response = await loadMenu(idAppSoft, idAppSoftProfil);
    return { funz: response.data } as { funz: Funz[] };
  }
)

export const funzionalitaSlice = createSlice({
  name: 'funzionalita',
  initialState,
  reducers: {
    clearFunzionalita: state => {
      state.funzionalita = [];
      state.uritotitle = { [loginPagePath]: loginTitle };
      state.titletouri = { [loginTitle]: loginPagePath };
      state.uritoguida = { [loginPagePath]: false };
      state.homeuri = loginPagePath;
      state.hometitle = loginTitle;
      state.homeguida = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchFunzionalita.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchFunzionalita.rejected, (state, action) => {
      if (action.payload) {
        let simpleErrorResp = action.payload as ErrorResponse;
        state.error = simpleErrorResp.messaggio;
      } else state.error = action.error.message ? action.error.message : null;
      state.status = StatusEnum.Failed;
      state.funzionalita = [];
      state.uritotitle = { [loginPagePath]: loginTitle };
      state.titletouri = { [loginTitle]: loginPagePath };
      state.uritoguida = { [loginPagePath]: false };
      state.homeuri = loginPagePath;
      state.hometitle = loginTitle;
      state.homeguida = false;
    })
    builder.addCase(fetchFunzionalita.fulfilled, (state, { payload }: PayloadAction<{ funz: Funz[] }>) => {
      state.status = StatusEnum.Succeeded;
      state.funzionalita = payload.funz;
      let resp = getUriAndTitles(payload.funz);
      state.uritotitle = resp[0] as UriToTitle;
      state.titletouri = resp[1] as TitleToUri;
      state.abilitazioni = resp[2] as Abilitazioni;
      state.uritoguida = resp[3] as UriToGuida;
      state.hometitle = resp[4] as string;
      state.homeuri = resp[5] as string;
      state.homeguida = resp[6] as boolean;
      state.error = null;
    })
  }
})

export const { clearFunzionalita } = funzionalitaSlice.actions

export const getFunzionalita = (state: RootState) => state.funzionalita.funzionalita
export const getTitleToUri = (state: RootState) => state.funzionalita.titletouri
export const getUriToTitle = (state: RootState) => state.funzionalita.uritotitle
export const getUritoGuida = (state: RootState) => state.funzionalita.uritoguida
export const getHomeUri = (state: RootState) => state.funzionalita.homeuri ? state.funzionalita.homeuri : loginPagePath
export const getAbilitazione = (state: RootState, uri: string) => state.funzionalita.abilitazioni[uri];

export default funzionalitaSlice.reducer