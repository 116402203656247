import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-pdf-renderer-v2';
import styles from '../styles/shared';

const TableHeader = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.tableColDescriptionTitle}>{t('title')}</Text>
      <Text style={styles.tableColDateTitle}>{t('achievedOnTitle')}</Text>
    </View>
  )
};

export default TableHeader;