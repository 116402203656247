
import { Fields } from "./Fields";
import { Presenza } from "./Presenze";

export type DipendentiPresenzeKeys = keyof DipendentiPresenze;
export type PresenzeDipendentiKeys = keyof PresenzeDipendenti;

export const elementIdProps: PresenzeDipendentiKeys[] = ['idAnagraficaSoggetto', 'turnoDescrizione'];
export const elementRenderProps: PresenzeDipendentiKeys[] = ['idAnagraficaSoggetto',];

export const allFieldsState: Fields[] = [
    {
        field: 'nominativo', required: true, show: true, autocomplete: true, titleKey: 'nominativeLabel', defaultGroupOrder: 1,
    },
    {
        field: "turnoDescrizione", type: "string", titleKey: "shiftParam", required: true, show: true,
    },
];

export interface PresenzeDipendenti {
    idAnagraficaSoggetto: number,
    idTurno_TurnoAssenza: number,
    nominativo: string,
    turnoDescrizione: string,
    turnoDescrizioneBreve: string,
    turnoAssenzaDescrizione: string,
    turnoAssenzaDescrizioneBreve: string,
    presenzeObj: Presenza[],
    colore: 'Verde' | 'Giallo' | 'Rosso' | 'Arancione';
}

export type DipendentiPresenze = Presenza;



