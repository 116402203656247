import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, upd, } from '../../services/services.service'
import { AnagraficaSoggettiSchedaProfessionale } from '../../models/AnagraficaSoggettiSchedeProfessionali';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettischedeprofessionali";

interface AnagraficaSoggettiSchedaProfessionaleState {
    statusValidAnagraficaSoggettiSchedeProfessionali: StatusEnum,
    validAnagraficaSoggettiSchedeProfessionali: AnagraficaSoggettiSchedaProfessionale[],
    anagraficaSoggettiSchedaProfessionale: AnagraficaSoggettiSchedaProfessionale | null,
    error: string | null
}

const initialState: AnagraficaSoggettiSchedaProfessionaleState = {
    statusValidAnagraficaSoggettiSchedeProfessionali: StatusEnum.Succeeded,
    validAnagraficaSoggettiSchedeProfessionali: [],
    anagraficaSoggettiSchedaProfessionale: null,
    error: null
}

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiSchedaProfessionale: AnagraficaSoggettiSchedaProfessionale) => {
    const response = await create(anagraficaSoggettiSchedaProfessionale, microservice, entity);
    return response.data as AnagraficaSoggettiSchedaProfessionale;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiSchedaProfessionale: AnagraficaSoggettiSchedaProfessionale) => {
    const response = await upd(anagraficaSoggettiSchedaProfessionale, microservice, entity);
    return response.data as AnagraficaSoggettiSchedaProfessionale;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const anagraficaSoggettiSchedaProfessionaleSlice = createSlice({
    name: entity,
    initialState,
    reducers: {
        reset: (state: AnagraficaSoggettiSchedaProfessionaleState) => {
            return initialState;;
        },
        resetError: (state: AnagraficaSoggettiSchedaProfessionaleState) => {
            state.error = initialState.error;
            state.statusValidAnagraficaSoggettiSchedeProfessionali = initialState.statusValidAnagraficaSoggettiSchedeProfessionali;
        }
    },
    extraReducers: builder => {
        // insert
        builder.addCase(insert.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Failed;
            state.anagraficaSoggettiSchedaProfessionale = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiSchedaProfessionale>) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Succeeded;
            state.anagraficaSoggettiSchedaProfessionale = payload;
        })

        // update
        builder.addCase(update.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Failed;
            state.anagraficaSoggettiSchedaProfessionale = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiSchedaProfessionale>) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Succeeded;
            state.anagraficaSoggettiSchedaProfessionale = payload;
        })

        // physicalDelete
        builder.addCase(physicalDel.pending, (state) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusValidAnagraficaSoggettiSchedeProfessionali = StatusEnum.Succeeded;
        })
    }
});

export const { reset, resetError } = anagraficaSoggettiSchedaProfessionaleSlice.actions;
export default anagraficaSoggettiSchedaProfessionaleSlice.reducer;