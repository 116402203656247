
import { Fields } from "./Fields";

export type UnitaOperativaCodiceVisiteKeys = keyof UnitaOperativaCodiceVisite;

export const elementIdProps: UnitaOperativaCodiceVisiteKeys[] = ["idCodiceVisite"];
export const elementRenderProps: UnitaOperativaCodiceVisiteKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idCodiceVisite", required: true, show: true, titleKey: "visitCodeParam", editable: "onAdd", lookupField: true,
  },
]

export interface UnitaOperativaCodiceVisite {
  idCodiceVisite: number,
  version: number
}


