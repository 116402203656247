import { Paper, Box, Typography } from '@material-ui/core'
import { UnitaOperativeSedi } from '../../../../models/UnitaOperativeSedi'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setState } from '../../../../store/slices/temporaryStatesSlice'
import GTModal from '../../modal/GTModal';
import styles from './coordinateListModal.module.css'

const CoordinateListModal = () => {
  const dispatch = useAppDispatch();

  const temporaryStates = useAppSelector(state => state.temporary);

  return (
    <GTModal
      openState={{
        open: !!temporaryStates?.coordinateModalOpen,
      }}
      handleClose={() => {
        dispatch(setState({
          key: 'coordinateModalOpen',
          value: false
        }));
      }}
    >
      <Box display='flex' flexDirection='column' px={2}>
        {
          ('coordinateResultList' in temporaryStates) &&
          temporaryStates.coordinateResultList.map((result: Record<string, any>, index: number) => {
            return (
              <Paper
                key={'coordinate-' + index}
                className={styles.paperContainer}
                onClick={() => {
                  if ('setData' in temporaryStates) {
                    temporaryStates.setData((state: UnitaOperativeSedi) => {
                      return {
                        ...state,
                        latitudine: result['position'].lat,
                        longitudine: result['position'].lon
                      }
                    })
                  }
                  dispatch(setState({
                    key: 'coordinateModalOpen',
                    value: false
                  }))
                }}
              >
                <Typography>{
                  (result['address'].streetName ? (result['address'].streetName + ' ') : '') +
                  (result['address'].streetNumber ? (result['address'].streetNumber + ', ') : '') +
                  (result['address'].postalCode ? (result['address'].postalCode + ', ') : '') +
                  (result['address'].municipality ? (result['address'].municipality + ', ') : '') +
                  (result['address'].countrySecondarySubdivision ? (result['address'].countrySecondarySubdivision + ', ') : '') +
                  (result['address'].countrySubdivisionName ? (result['address'].countrySubdivisionName) : '')
                }</Typography>
              </Paper>
            )
          })
        }
      </Box>
    </GTModal>
  )
}

export default CoordinateListModal