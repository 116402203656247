import {
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Divider,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  List,
  ListItemText,
  ListItem,
  Theme,
  createStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateDDMMYYYY } from '../../../../utils/utilfunctions';
import { MonthsList } from '../../../../utils/utildata';
import { fetchLegenda, resetLegenda } from '../../../../store/slices/anagraficaSoggettiSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Legenda } from '../../../../models/Presenze';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 500,
    },
    paperTableWidth: {
      maxWidth: 250,
    },
    tableDimTurnoOre: {
      minHeight: 145,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    tableDimTurnoPrec: {
      minHeight: 145,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    center: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    motiviCS: {
      overflowY: 'auto',
      overflowX: 'hidden',
      minHeight: 145,
      maxHeight: 160,
    },
  }),
);

interface LegendaWProps {
  idAnagraficaSoggetto?: number,
  presenzaData?: string,
};

const LegendaW = (props: LegendaWProps) => {
  const { idAnagraficaSoggetto, presenzaData } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let i = 0;
  const getKey = () => ++i;

  const presenza = presenzaData ? new Date(presenzaData) : null;
  const month = presenza?.getMonth() ?? null;
  const monthString = month && 0 >= month && 11 <= month ? MonthsList[month] : '';
  const legenda = useAppSelector(state => state.anagraficaSoggetti.legenda);

  const [legendaState, setLegendaState] = useState<Legenda | null>(null);

  useEffect(() => {
    if (idAnagraficaSoggetto && presenzaData) {
      dispatch(fetchLegenda({ idAnagraficaSoggetto, presenzaData }));
    } else {
      setLegendaState(null);
    }
  }, [dispatch, idAnagraficaSoggetto, presenzaData]);

  useEffect(() => {
    setLegendaState(legenda);
  }, [legenda]);

  const [turniTabella, setTurniTabella] = useState<any[]>([]);
  const [turniPrecTabella, setTurniPrecTabella] = useState<any[]>([]);
  useEffect(() => {
    let tempTabella: Record<string, any>[] = [];
    if (legendaState && legendaState.turni) {
      tempTabella = tempTabella.concat(legendaState.turni.map(elem => {
        return [elem.descrizione, elem.ore];
      }))
    }
    if (legendaState && legendaState.turniAssenza) {
      tempTabella = tempTabella.concat(legendaState.turniAssenza.map(elem => {
        return [elem.descrizione, elem.ore];
      }))
    }
    setTurniTabella(tempTabella);

    if (legendaState && legendaState.turniPrecedenti)
      tempTabella = legendaState.turniPrecedenti.map((elem) => {
        return [elem.dataPresenza, elem.descrizione]
      }).sort().reverse();
    setTurniPrecTabella(tempTabella);
  }, [legendaState]);

  // pulizia degli state all'uscita del componente
  useEffect(() => {
    return () => {
      setLegendaState(null);
      setTurniTabella([]);
      setTurniPrecTabella([]);

      dispatch(resetLegenda());
    }
  }, [dispatch]);

  return (
    <Paper elevation={2}>
      <Box p={2}>
        <Box marginBottom={4}>
          <Typography variant='h6' align='center'>
            {t('legendTitle')}
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={1} alignItems='baseline'>
            {/* Mese */}
            <Grid item xs={5}>
              <Typography variant='body2' align='left'>{t('monthTitle')}</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={clsx(classes.bold)}>
                {idAnagraficaSoggetto ? t(monthString) : ''}
              </Typography>
            </Grid>
            {/* Giorni lavorati e ore dovute */}
            <Grid item xs={5}>
              <Typography variant='body2' align='left'>{t('workingDaysTitle')} </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={clsx(classes.bold)}>
                {legendaState ? legendaState.giorni : ''}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant='body2' align='left'>{t('hoursDueTitle')} </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography className={clsx(classes.bold)}>
                {legendaState ? legendaState.ore : ''}
              </Typography>
            </Grid>
          </Grid>
          {/* Ore e turno tabella */}
          <Grid item xs={12}>
            <Box mt={2}>
              <Paper variant='outlined' className={clsx(classes.paperTableWidth, classes.center)}>
                <TableContainer className={clsx(classes.tableDimTurnoOre)}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='right'>{t('shiftTitle')} </TableCell>
                        <TableCell align='left'>{t('totalHourTitle')} </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {turniTabella.map((elem) => {
                        return (
                          <TableRow key={getKey()}>
                            <TableCell align='right'>{elem[0]}</TableCell>
                            <TableCell align='left'>{elem[1]}</TableCell>
                          </TableRow>
                        )
                      })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Grid>
          {/* Motivi CS */}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Box mt={2} pl={1}>
                <Typography variant='body2'>{t('motiveCSTitle')} </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined'>
                <List dense className={clsx(classes.motiviCS)}>
                  {legendaState && legendaState.motivoCS
                    && legendaState.motivoCS.map(elem => {
                      return (
                        <ListItem key={getKey()}>
                          <ListItemText>
                            {elem}
                          </ListItemText>
                        </ListItem>
                      )
                    })
                  }
                </List>
              </Paper>
            </Grid>
          </Grid>
          {/* Turno Precedente tabella */}
          <Grid item xs={12}>
            <Box mt={2} pl={1}>
              <Typography variant='body2'>{t('previousShiftsTitle')} </Typography>
            </Box>
            <Paper variant='outlined'>
              <TableContainer className={clsx(classes.tableDimTurnoPrec, classes.center)}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='right'>{t('shiftTitle')} </TableCell>
                      <TableCell align='left'>{t('dateTitle')} </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      turniPrecTabella.map((elem) => {
                        return (
                          <TableRow key={getKey()}>
                            <TableCell align='right'>{elem[1]}</TableCell>
                            <TableCell align='left'>{getDateDDMMYYYY(new Date(elem[0]))}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LegendaW;
