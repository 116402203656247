import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbilitazioniUtenti, elementIdProps, elementRenderProps, allFields } from "../../../models/AbilitazioniUtenti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  lookupNoAbilitazione as fetchLookupStruttureNoAbilitazione,
  reset as resetStrutture,
  resetError as resetErrorStrutture
} from "../../../store/slices/struttureSlice";
import {
  fetchAllValid as fetchAllAbilitazioniUtenti,
  insert,
  physicalDel,
  reset as resetAbilitazioniUtenti,
  resetError as resetErrorAbilitazioniUtenti
} from '../../../store/slices/abilitazioniUtentiSlice';
import {
  lookupFiltered as fetchLookupDipartimentiFiltered,
  cleanLookup as resetDipartimentiLookup,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from '../../../store/slices/dipartimentiFilteredSlice';
import {
  lookupFiltered as fetchLookupUOFiltered,
  cleanLookup as resetUOLookup,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from '../../../store/slices/unitaOperativeFilteredSlice';
import {
  lookupByName as fetchASLookupByName,
  cleanLookupEsterno,
  reset as resetAnagraficaSoggettiUnitaOperativeSlice,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { notFoundPath, abilitazioneUtentePath } from "../../../utils/utilconst";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentInsertPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";

const AbilitazioniUtentiW = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const resetError = useCallback(() => {
    dispatch(resetErrorStrutture());
    dispatch(resetErrorAbilitazioniUtenti());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorDipartimentiFiltered());
  }, [dispatch])

  const abilitazione = useAppSelector(state => getAbilitazione(state, abilitazioneUtentePath));

  const title = t('enablingTitle');

  useEffect(() => {
    dispatch(fetchAllAbilitazioniUtenti());
    dispatch(fetchLookupStruttureNoAbilitazione());
  }, [dispatch]);

  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUO = useAppSelector(state => state.unitaOperative.lookup);
  const lookupDipFiltered = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupUOFiltered = useAppSelector(state => state.unitaOperativeFiltered.lookup);
  const lookupAnagrafiche = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupEsterno)

  const strutturaConst = 'idStruttura';
  const dipartimentoConst = 'idDipartimento';
  const unitaOperativaConst = 'idUnitaOperativa';
  const asCognomeConst = 'anagraficaSoggettoCognome';
  const asNomeConst = 'anagraficaSoggettoNome';
  const nominativoConst = 'idAnagraficaSoggetto';

  /**
   * Search token and save results to slice. Called by autocomplete in GeneralForm.
   * @param {string} token - string to be searched
   * @param {number} minLen - minimum acceptable length of token
   */
  const searchAction = (token: string, minLen: number = 3) => {
    if (token.length === minLen) {
      dispatch(fetchASLookupByName({ token: token }));
    }
    else if (token.length === 0) {
      dispatch(cleanLookupEsterno());
    }
  }

  const excludedFieldInTable = useMemo(() => [strutturaConst, dipartimentoConst, unitaOperativaConst], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AbilitazioniUtenti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AbilitazioniUtenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? t('all'),
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        switch (f.field) {
          case strutturaConst:
            obj.lookup = lookupStr;
            break;
          case dipartimentoConst:
            obj.lookup = lookupDip;
            break;
          case unitaOperativaConst:
            obj.lookup = lookupUO;
            break;
        }

        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, lookupDip, lookupStr, lookupUO, t]);

  const errorBE = useAppSelector(state => state.abilitazioniUtenti.error);

  const validAbilitazioniUtenti = useAppSelector(state => state.abilitazioniUtenti.validAbilitazioniUtenti);
  const statusValidAbilitazioniUtenti = useAppSelector(state => state.abilitazioniUtenti.statusValidAbilitazioniUtenti);

  const [data, setData] = useState<Array<AbilitazioniUtenti | Object>>([]);

  useEffect(() => {
    const newData = validAbilitazioniUtenti.map(elem => {
      return {
        ...elem,
        [nominativoConst]: elem[asCognomeConst] || elem[asNomeConst]
          ? (elem[asCognomeConst] + ' ' + elem[asNomeConst])
          : null
      }
    });
    setData(newData);
  }, [validAbilitazioniUtenti]);

  const insertCallback = () => {
    history.push(abilitazioneUtentePath + componentInsertPath);
  }

  const formCallback = useCallback((abilitazioniUtente: AbilitazioniUtenti | null, field: string, optionalArgs: OptionalArgs<AbilitazioniUtenti>) => {
    const { setInternalObj } = optionalArgs;
    if (abilitazioniUtente) {
      switch (field) {
        case strutturaConst:
          if (abilitazioniUtente[strutturaConst])
            dispatch(fetchLookupDipartimentiFiltered({ idStruttura: abilitazioniUtente[strutturaConst] }));

          dispatch(resetDipartimentiLookup());
          dispatch(resetUOLookup());
          setInternalObj(state => {
            return {
              ...state,
              [dipartimentoConst]: null,
              [unitaOperativaConst]: null,
            } as AbilitazioniUtenti;
          });
          break;
        case dipartimentoConst:
          if (abilitazioniUtente[dipartimentoConst])
            dispatch(fetchLookupUOFiltered({ idStruttura: abilitazioniUtente[strutturaConst], idDipartimento: abilitazioniUtente[dipartimentoConst] }))

          dispatch(resetUOLookup());
          setInternalObj(state => {
            return {
              ...state,
              [unitaOperativaConst]: null,
            } as AbilitazioniUtenti;
          });
          break;
      }
    }
  },
    [dispatch],
  );

  const formLookups = {
    [strutturaConst]: lookupStr,
    [dipartimentoConst]: lookupDipFiltered,
    [unitaOperativaConst]: lookupUOFiltered,
    [nominativoConst]: lookupAnagrafiche,
  };

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetStrutture());
      dispatch(resetAbilitazioniUtenti());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetAnagraficaSoggettiUnitaOperativeSlice());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path={abilitazioneUtentePath} exact>
        <CrudMaterialTableWithoutLogicDelete
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchAllAbilitazioniUtenti}
          physicalDel={physicalDel}
          statusValid={statusValidAbilitazioniUtenti}
          errorBE={errorBE}
          logoUri={logoUri}
          localizedDatePicker={true}
          insertCallback={insertCallback}
          resetErrorCallback={resetError}
          extraOptions={{
            maxBodyHeight: 460,
          }}
        />
      </Route>
      <Route path={abilitazioneUtentePath + componentInsertPath} exact>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={abilitazioneUtentePath}
          tabsUri={abilitazioneUtentePath + componentInsertPath}
          tabsView={false}
          buttonTitle={t('enablingTitle')}
          info1={t("newEnablingTitle")}
          tabs={
            [
              {
                label: t("enablingTitle"),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={abilitazioneUtentePath}
                  action={insert}
                  status={statusValidAbilitazioniUtenti}
                  error={errorBE}
                  update={false}
                  fields={allFieldsState}
                  translate={t}
                  checksCallback={formCallback}
                  lookups={formLookups}
                  autoCompleteSearchAction={searchAction}
                />,
                componentElse: <Redirect to={abilitazioneUtentePath} />
              }
            ]
          } />
      </Route>
      <Route path="*"><Redirect to={notFoundPath} /></Route>
    </Switch>
  )
}

export default AbilitazioniUtentiW;