import { createSlice } from '@reduxjs/toolkit'

type TemporaryStateSliceState = Record<string, any>;

const initialState: TemporaryStateSliceState = {};

export const temporaryStateSlice = createSlice({
  name: 'temporaryState',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setState: (state: TemporaryStateSliceState, action: { payload: { key: string, value: any } }) => {
      const { payload } = action;
      state[payload.key] = payload.value;
    },
    setMultipleState: (state: TemporaryStateSliceState, action: { payload: { key: string, value: any }[] }) => {
      const { payload } = action;

      const tempState: Record<string, unknown> = {};
      payload.forEach(s => {
        tempState[s.key] = s.value;
      });

      return {
        ...state,
        ...tempState
      }
    },
    removeState: (state: TemporaryStateSliceState, action: { payload: { key: string } }) => {
      const { payload } = action;
      delete state[payload.key];
    }
  },
});

export const { reset, setState, setMultipleState, removeState } = temporaryStateSlice.actions;
export default temporaryStateSlice.reducer;