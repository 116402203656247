import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type TipiDocumentiKeys = keyof TipiDocumenti;

export const elementIdProps: TipiDocumentiKeys[] = ["idTipoDocumento"];
export const elementRenderProps: TipiDocumentiKeys[] = ["descrizione"];

export const allFields: Fields[] = [
  {
    field: "descrizioneBreve", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 10, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionShortParam"
  },
  {
    field: "descrizione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "visibileAnagraficaSoggetto", type: "boolean", required: false, show: true, titleKey: "visibileAnagraficaSoggetto"
  },
  {
    field: "regolaEstrazionePagine", type: "string", required: false, show: true, titleKey: "Regola Estrazione Pagine",
  }
]

export interface TipiDocumenti {
  idTipoDocumento: number,
  descrizioneBreve: string,
  descrizione: string,
  visibileAnagraficaSoggetto: boolean,
  regolaEstrazionePagine: string,
  version: number
}