import { Fields } from "./Fields";

export type ConteggioAssenzaKeys = keyof ConteggioAssenza;

export const elementIdProps: ConteggioAssenzaKeys[] = [];
export const elementRenderProps: ConteggioAssenzaKeys[] = [];

export const allFields: Fields[] = [
    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true,
    },
    {
        field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true,
    },
    {
        field: "motivoCs", titleKey: "Motivo CS", required: false, show: true,
    },
    {
        field: "totaleGiorni", titleKey: "totalDayParam", required: false, show: true,
    },
    {
        field: "oreTurno", titleKey: "totalHourParam", required: false, show: true,
    },
];

export interface ConteggioAssenza {
    oreTurno: number,
    totaleGiorni: number,
    idAnagraficaSoggettoCount: number,
    idUnitaOperativa: number,
    idArticoloLegge: number,
    motivoCs: string,
    idDipartimento: number,
    idQualifica: number,
    qualificaDescrizioneBreve: string,
    idTurnoAssenza: number,
    turnoAssenzaDescrizione: string,
    idStruttura: number,
    idAnagraficaSoggetto: number,
    idOrganizzazione: number,
}

