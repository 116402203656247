import React from 'react';
import { Badge, Box, Divider, Fab, IconButton, TextField, Theme, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Conversation,
  Avatar,
  ConversationList,
  MessageModel,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react'
import './styles.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { ChatMessaggio } from '../../../models/ChatMessaggio';
import { getCookie } from '../../../utils/utilfunctions';
import { User } from '../../../models/User';
import avatarIco from '../../../assets/avatar.png';
import { Chat } from '../../../models/Chat';
import { useChatBox } from './useChatBox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addValidMessaggi, insert, removeMessaggiLetti, updateMessaggiLetti } from '../../../store/slices/chatMessaggioSlice';
import { StatusEnum } from '../../../models/Utils';
import LoadingSvg from '../svgs/LoadingSvg';
import SearchNewUser from './SearchNewUser/SearchNewUser';
import { addChats } from '../../../store/slices/chatSlice';
import { reset as clearSearchedUsers } from '../../../store/slices/utentiPsSlice';
import { format } from 'date-fns';

const ChatListBox = Box;
const MainChatBox = Box;
const MessageListBox = Box;

type Position = 'first' | 'normal' | 'last';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(0),
      right: theme.spacing(0),
      zIndex: 1
    },
  }),
);

const ChatBox = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [chatListVisible, setChatListVisible] = React.useState(true);
  const [filterChatToken, setFilterChatToken] = React.useState<string>('');
  const [searchNewUser, setSearchNewUser] = React.useState<boolean>(false);

  const userCookie = getCookie('utente') ?? '';
  const { idUtente: currentUser } = JSON.parse(userCookie) as User;

  const errorChat = useAppSelector(state => state.chat.error);
  const errorChatMessaggio = useAppSelector(state => state.chatMessaggio.error);

  const validChat = useAppSelector(state => state.chat.validChat);
  const statusValidChat = useAppSelector(state => state.chat.statusValidChat);

  const validChatMessaggio = useAppSelector(state => state.chatMessaggio.validChatMessaggio);
  const validMessaggiNonLetti = useAppSelector(state => state.chatMessaggio.validChatMessaggioNonLetti);

  const orderedValidChat = React.useMemo(() => {
    const retval: Chat[] = [];

    validChat.forEach(elem => {
      if (Object.keys(validMessaggiNonLetti).includes(generateChatKey(elem))) {
        retval.unshift(elem);
      } else {
        retval.push(elem);
      }
    });

    return retval;
  }, [validChat, validMessaggiNonLetti]);

  const [addedChat, setAddedChat] = React.useState<Chat>();
  const [selectedChat, setSelectedChat] = React.useState<Chat>();
  React.useEffect(() => {
    if (!selectedChat || addedChat)
      if (addedChat) {
        const selected = orderedValidChat.find(elem => {
          return elem.idApplicazioneSoftware === addedChat.idApplicazioneSoftware &&
            elem.idUtente1 === addedChat.idUtente1 &&
            elem.idUtente2 === addedChat.idUtente2;
        });
        setSelectedChat(selected);
        setAddedChat(undefined);
      } else {
        setSelectedChat(orderedValidChat.at(0));
      }
  }, [addedChat, orderedValidChat, selectedChat]);

  const { fetchMessaggiDelChat } = useChatBox({
    selectedChat,
    currentUser
  });

  const openChatBox = React.useCallback(() => {
    setVisible(true);
  }, []);

  const closeChatBox = React.useCallback(() => {
    setVisible(false);
  }, []);

  const openChatListBox = React.useCallback(() => {
    setChatListVisible(true);
  }, []);

  const closeChatListBox = React.useCallback(() => {
    setChatListVisible(false);
  }, []);

  const onSend = React.useCallback((innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => {
    const messaggio: Partial<ChatMessaggio> = {
      idApplicazioneSoftware: selectedChat?.idApplicazioneSoftware,
      idUtente1: selectedChat?.idUtente1,
      idUtente2: selectedChat?.idUtente2,
      testo: textContent
    };

    if (selectedChat)
      dispatch(insert(messaggio as ChatMessaggio))
  }, [dispatch, selectedChat]);

  /**
   * Update chat list and message list when validMessaggiNonLetti is updated
   */
  const chatKey = React.useMemo(() => selectedChat ? generateChatKey(selectedChat) : '', [selectedChat]);
  React.useEffect(() => {
    const newChatMessaggi = validMessaggiNonLetti[chatKey];

    if (newChatMessaggi && newChatMessaggi.length > 0 && visible) {
      // Aggiorna messaggi
      dispatch(updateMessaggiLetti({
        chat: {
          idApplicazioneSoftware: newChatMessaggi[0].idApplicazioneSoftware,
          idUtente1: newChatMessaggi[0].idUtente1,
          idUtente2: newChatMessaggi[0].idUtente2,
          version: newChatMessaggi[0].version ?? 0
        },
        listMessaggi: newChatMessaggi.map(i => i.idChatMessaggio)
      })).then(() => {
        dispatch(addValidMessaggi(newChatMessaggi));
        dispatch(removeMessaggiLetti(chatKey));
      });
    }
  }, [chatKey, dispatch, validChat, validMessaggiNonLetti, visible]);

  // Se ci sono dei messaggi non letti in una nuova chat, aggiorna la lista dei chat
  const validChatIncludes = React.useCallback((nuovoChat: Chat) => {
    return validChat.some(chat => {
      return chat.idApplicazioneSoftware === nuovoChat.idApplicazioneSoftware &&
        (
          (chat.idUtente1 === nuovoChat.idUtente1 && chat.idUtente2 === nuovoChat.idUtente2) ||
          (chat.idUtente1 === nuovoChat.idUtente2 && chat.idUtente2 === nuovoChat.idUtente1)
        );
    });
  }, [validChat]);

  React.useEffect(() => {
    const newChats: Chat[] = [];
    Object.keys(validMessaggiNonLetti).forEach(key => {
      const nuovoChat: Chat = {
        idApplicazioneSoftware: validMessaggiNonLetti[key][0].idApplicazioneSoftware,
        idUtente1: validMessaggiNonLetti[key][0].idUtente1,
        idUtente2: validMessaggiNonLetti[key][0].idUtente2,
        appSoftwareDescrizione: validMessaggiNonLetti[key][0].applicazioneSoftwareDescrizione,
        organizzazioneNome: validMessaggiNonLetti[key][0].organizzazioneNome,
        nominativo1: validMessaggiNonLetti[key][0].idUtente1 === validMessaggiNonLetti[key][0].idUtenteSend
          ? validMessaggiNonLetti[key][0].utenteSend
          : validMessaggiNonLetti[key][0].utenteRecv,
        nominativo2: validMessaggiNonLetti[key][0].idUtente2 === validMessaggiNonLetti[key][0].idUtenteSend
          ? validMessaggiNonLetti[key][0].utenteSend
          : validMessaggiNonLetti[key][0].utenteRecv,
        version: validMessaggiNonLetti[key][0].version ?? 0
      };

      if (!validChatIncludes(nuovoChat))
        newChats.push(nuovoChat);
    });

    if (newChats.length > 0)
      dispatch(addChats(newChats));
  }, [dispatch, validChatIncludes, validMessaggiNonLetti]);

  const renderMessages = React.useCallback(() => {
    const retval: JSX.Element[] = [];

    let lastDateInserted = '';

    const isSameYear = (date: Date) => (new Date()).getFullYear() === date.getFullYear();

    validChatMessaggio
      .forEach((message, index, array) => {
        let position: Position = 'normal';

        const isFirst = () => index === 0 || (array[index - 1].idUtenteSend !== message.idUtenteSend);
        const isLast = () => index === array.length - 1 || (array[index + 1].idUtenteSend !== message.idUtenteSend);

        if (isFirst()) {
          position = 'first';
        } else if (isLast()) {
          position = 'last'
        }

        const data: Partial<MessageModel> = {
          position,
          direction: message.idUtenteSend === currentUser ? 'outgoing' : 'incoming',
          type: 'custom'
        };

        if (message.inserimentoDateTime) {
          const currentDateToInsert = format(new Date(message.inserimentoDateTime), 'dd/MM/yyyy');
          if (currentDateToInsert !== lastDateInserted) {
            const messageTimestamp = new Date(message.inserimentoDateTime);

            lastDateInserted = currentDateToInsert;
            retval.push(
              <MessageSeparator key={'chatmessage-sep-' + index}>
                {format(messageTimestamp, isSameYear(messageTimestamp) ? 'EEE, MMM dd' : 'EEE, MMM dd yyyy')}
              </MessageSeparator>
            );
          }
        }

        retval.push(
          <Message
            key={'chatmessage-' + index}
            model={data as MessageModel}
          >
            <Message.CustomContent>
              <span>{message.testo}</span>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <span style={{
                  alignSelf: 'flex-end',
                  marginLeft: 5,
                  fontSize: 10,
                  fontStyle: 'italic',
                  color: data.direction === 'incoming' ? '#8e8e8e' : '#cecece',
                }}
                >
                  {
                    message.inserimentoDateTime ? format(new Date(message.inserimentoDateTime), 'HH:mm') : ''
                  }
                </span>
              </div>
            </Message.CustomContent>
          </Message>
        );
      });

    return retval;
  }, [currentUser, validChatMessaggio]);

  return (
    visible
      ? (
        <>
          {
            chatListVisible &&
            <ChatListBox
              className={'chat-list-container fixed-bottom-chat-list chat-list-container-animate-enter'}
              display='flex'
              flexDirection='column'
            >
              <main className='main-container'>
                {
                  searchNewUser
                    ? <SearchNewUser
                      setSearchNewUser={setSearchNewUser}
                      setAddedChat={setAddedChat}
                      currentUser={currentUser}
                    />
                    : <Box>
                      <TextField
                        size='small'
                        fullWidth
                        label="Cerca"
                        variant="outlined"
                        onChange={e => setFilterChatToken(e.target.value.toLowerCase())}
                      />
                      <Divider style={{ marginTop: 10 }} />
                      {
                        statusValidChat === StatusEnum.Loading
                          ? <LoadingSvg color="primary" width={200} />
                          : <Box height={335} overflow='auto' position='relative'>
                            <Fab
                              color="primary"
                              aria-label="add"
                              size='small'
                              className={classes.fab}
                              onClick={() => {
                                dispatch(clearSearchedUsers());
                                setSearchNewUser(true)
                              }}
                            >
                              <AddIcon />
                            </Fab>
                            <ConversationList
                            >
                              {
                                orderedValidChat.length > 0 && !errorChat
                                  ? orderedValidChat
                                    .filter(elem => {
                                      if (elem.nominativo1 && elem.nominativo2)
                                        if (currentUser === elem.idUtente1) {
                                          return elem.nominativo2.cognome.toLowerCase().includes(filterChatToken) ||
                                            elem.nominativo2.nome.toLowerCase().includes(filterChatToken) ||
                                            elem.nominativo2.nomeUtente.includes(filterChatToken);
                                        } else {
                                          return elem.nominativo1.cognome.toLowerCase().includes(filterChatToken) ||
                                            elem.nominativo1.nome.toLowerCase().includes(filterChatToken) ||
                                            elem.nominativo1.nomeUtente.includes(filterChatToken);
                                        }
                                      else return false;
                                    })
                                    .map((elem, index) => {
                                      const sender = currentUser === elem.idUtente1 ? elem.nominativo2 : elem.nominativo1;
                                      const selected = selectedChat ? (
                                        selectedChat.idApplicazioneSoftware === elem.idApplicazioneSoftware &&
                                        selectedChat.idUtente1 === elem.idUtente1 &&
                                        selectedChat.idUtente2 === elem.idUtente2
                                      ) : false;

                                      const unreadMessages = validMessaggiNonLetti[generateChatKey(elem)]?.length ?? 0;

                                      return (
                                        <Conversation
                                          key={'chatlist-' + index}
                                          active={selected}
                                          name={<Typography style={{ fontWeight: unreadMessages > 0 ? 'bold' : 'normal' }}>{sender?.nome + ' ' + sender?.cognome}</Typography>}
                                          lastSenderName={''}
                                          info={<span style={{ fontSize: 11, fontStyle: 'italic' }}>{sender?.nomeUtente}</span>}
                                          onClick={() => {
                                            setSelectedChat(elem);
                                            const retrieveMessaggiPromise = new Promise((resolve, reject) => {
                                              fetchMessaggiDelChat();
                                              resolve(null);
                                            });

                                            retrieveMessaggiPromise.then(() => { })
                                          }}

                                        >
                                          <Avatar src={avatarIco} />
                                        </Conversation>
                                      )
                                    })
                                  : (errorChat ?? <Typography>Non esistono chat</Typography>)
                              }
                            </ConversationList>
                          </Box>
                      }
                    </Box>
                }
              </main>
            </ChatListBox>
          }
          <MainChatBox className='fixed-bottom chat-container' display='flex' flexDirection='column'>
            <Box display='flex' marginBottom={1}>
              <Box display='flex' flex={1} flexDirection='row' alignItems='center' >
                <IconButton onClick={chatListVisible ? closeChatListBox : openChatListBox}>
                  {
                    chatListVisible
                      ? <ArrowForwardIcon fontSize='small' htmlColor='white' />
                      : <MenuIcon fontSize='small' htmlColor='white' />
                  }
                </IconButton>
                <Typography style={{ color: '#fff' }}>
                  {
                    selectedChat
                      ? (currentUser === selectedChat.idUtente1
                        ? selectedChat.nominativo2?.nome + ' ' + selectedChat.nominativo2?.cognome
                        : selectedChat.nominativo1?.nome + ' ' + selectedChat.nominativo1?.cognome)
                      : ''
                  }
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={closeChatBox}>
                  <RemoveIcon fontSize='small' htmlColor='white' />
                </IconButton>
              </Box>
            </Box>

            {
              /**
               * La chat viene mostrata in questo componente
               */
            }
            <MessageListBox className='main-container'>
              {
                <ChatContainer>
                  <MessageList>
                    {
                      errorChatMessaggio && errorChatMessaggio !== 'canceled'
                        ? errorChatMessaggio
                        : renderMessages()
                    }
                  </MessageList>
                  <MessageInput placeholder="Scrivi..." onSend={onSend} disabled={!selectedChat} />
                </ChatContainer>
              }
            </MessageListBox>
          </MainChatBox>
        </>
      )
      : (
        <Tooltip
          enterDelay={50}
          title={<span className='tooltip'>{t('personnelChat')}</span>}
        >
          {
            <div className='fixed-bottom margin-bottom-10 chat-icon' onClick={openChatBox}>
              <Badge color="error" badgeContent={Object.keys(validMessaggiNonLetti).length} overlap="circular" showZero={false}>
                <CommentIcon fontSize='large' htmlColor='#0e59a8' />
              </Badge>
            </div>
          }
        </Tooltip >
      )
  )

}

export default ChatBox;

function generateChatKey(chat: Chat) {
  return chat.idApplicazioneSoftware + '-' + chat.idUtente1 + '-' + chat.idUtente2;
}