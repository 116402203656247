import React, { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { Fields } from "../../../../../models/Fields";
import { allFields, ConteggioQualifica, ConteggioQualificaKeys } from "../../../../../models/ConteggioQualifica";

import ReadOnlyMaterialTable from "../../../tables/ReadOnly/ReadOnlyMaterialTable";

import { lookupNoAbilitazione as fetchStrutturaLookup } from '../../../../../store/slices/struttureSlice';
import { lookupNoAbilitazione as fetchDipartimentoLookup } from '../../../../../store/slices/dipartimentiSlice';
import { lookupNoAbilitazione as fetchUnitaOperativaLookup } from '../../../../../store/slices/unitaOperativeSlice';
import { fetchAll as fetchTurniLookup } from '../../../../../store/slices/turniSlice';
import { fetchAll as fetchQualificheLookup } from '../../../../../store/slices/qualificheSlice';
import { fetchConteggioPerQualificaCompleto } from "../../../../../store/slices/presenzeSlice";
import { Lookup } from "../../../../../models/Utils";

interface SituazioneUnitaOperativeProps {
  validData: ConteggioQualifica[];
}

const SituazioneUnitaOperativeW = (props: SituazioneUnitaOperativeProps) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const title = t('operatingUnitSituationTitle');

  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperative.lookup);
  const lookupTurniDesc = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualificheDescBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>([
    ...allFields,
    {
      field: "nomeGruppo", titleKey: "operationalUnitParam", required: false, show: true, defaultGroupOrder: 1
    },
  ]);
  const [columns, setColumns] = useState<Array<Column<ConteggioQualifica>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ConteggioQualifica> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ConteggioQualificaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder)
          obj.defaultGroupOrder = f.defaultGroupOrder;

        // lookups
        if (f.lookupField)
          switch (f.field) {
            case 'idStruttura':
              obj.lookup = lookupStrutture;
              break;
            case 'idDipartimento':
              obj.lookup = lookupDipartimenti;
              break;
            case 'idUnitaOperativa':
              obj.lookup = lookupUnitaOperative;
              break;
            case 'idTurno':
              obj.lookup = lookupTurniDesc;
              break;
            case 'idQualifica':
              obj.lookup = lookupQualificheDescBreve;
              break;
          }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, lookupDipartimenti, lookupQualificheDescBreve, lookupStrutture, lookupTurniDesc, lookupUnitaOperative, t]);

  /**
   * fetch lookups
   */
  useEffect(() => {
    dispatch(fetchStrutturaLookup());
    dispatch(fetchDipartimentoLookup());
    dispatch(fetchUnitaOperativaLookup());
    dispatch(fetchTurniLookup());
    dispatch(fetchQualificheLookup());
  }, [dispatch]);


  useEffect(() => {
    dispatch(fetchConteggioPerQualificaCompleto());
  }, [dispatch]);

  const validConteggioQualifica = useAppSelector(state => state.presenze.conteggioQualifica);
  const statusValidConteggioQualifica = useAppSelector(state => state.presenze.statusConteggioQualifica);
  const errorBE = useAppSelector(state => state.presenze.error);

  const [data, setData] = useState<Array<ConteggioQualifica>>([]);

  useEffect(() => {
    setData(state => {
      return validConteggioQualifica
        .map(elem => {
          return {
            ...elem,
            nomeGruppo: lookupStrutture[elem.idStruttura] + ' > ' + (lookupDipartimenti as Lookup)[elem.idDipartimento] + ' > ' + lookupUnitaOperative[elem.idUnitaOperativa],
          }
        });
    });
  }, [lookupDipartimenti, lookupStrutture, lookupUnitaOperative, validConteggioQualifica]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <ReadOnlyMaterialTable
      title={title}
      columns={columns}
      data={data}
      fetchAllValid={fetchConteggioPerQualificaCompleto}
      statusValid={statusValidConteggioQualifica}
      errorBE={errorBE}
      logoUri={logoUri}
      extraOptions={{
        paging: true,
        search: true,
        draggable: false,
        maxBodyHeight: 1000,
        minBodyHeight: 500,
      }}
    />
  )
}
export default SituazioneUnitaOperativeW;