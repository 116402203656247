import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIdProps,
  elementRenderProps,
  AssenzaProgrammata,
  allFields as allFieldsGenerale,
  AssenzaProgrammataKeys,
} from "../../../models/AssenzeProgrammate";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDeletedByUnitaOperativa,
  fetchValidByUnitaOperativa,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetAssenzeProgrammate,
  resetError as resetErrorAssenzeProgrammate
} from "../../../store/slices/assenzeProgrammateSlice";
import {
  fetchAllValid as fetchArticoliLegge,
  reset as resetArticoliLegge,
  resetError as resetErrorArticoliLegge
} from "../../../store/slices/articoliLeggeSlice";
import {
  fetchAllValid as fetchAssenze,
  reset as resetAssenze,
  resetError as resetErrorAssenze
} from "../../../store/slices/assenzeSlice";
import {
  lookup as fetchLookupStrutture,
  reset as resetStruttureSlice,
  resetError as resetStrutture
} from "../../../store/slices/struttureSlice";
import {
  lookupFiltered as fetchLookupDipartimenti,
  cleanLookup as resetDipartimentiLookup,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  lookupFiltered as fetchLookupUnitaOperative,
  cleanLookup as resetUOLookup,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from "../../../store/slices/unitaOperativeFilteredSlice";
import {
  lookupByUnitaOperativa as fetchLookupAnagraficaSoggettiPerUnitaOperativa,
  reset as resetAnagraficaSoggettiUnitaOperative,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { assenzeProgrammatePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Box, FormControl, FormControlLabel, Grid, TextField, Typography, Switch as MuiSwitch, Paper } from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import {
  // Ids,
  // Lookup,
  PDFExtraData
} from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Autocomplete } from "@material-ui/lab";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { isUrlRoot } from "../../../utils/utilfunctions";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import i18n from "../../../i18n";
import { differenceInMinutes, minutesInHour } from "date-fns";
// import { sortAscObjectsBy } from "../../../utils/utilfunctions";

const AssenzeProgrammateW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const abilitazione = useAppSelector((state) => getAbilitazione(state, assenzeProgrammatePath));
  const title = t("plannedAbsencesTitle");
  const history = useHistory();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);

  const resetError = useCallback(() => {
    dispatch(resetErrorAssenzeProgrammate());
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze());
    dispatch(resetStrutture());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
  }, [dispatch]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null
  });

  useEffect(() => {
    setStates({
      [strSelectName]: null,
      [dipSelectName]: null,
      [unitOpSelectName]: null
    });
  }, [dipSelectName, strSelectName, unitOpSelectName]);

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      idDipartimento: states[dipSelectName],
      idUnitaOperativa: states[unitOpSelectName],
    };
  }, [states, strSelectName, dipSelectName, unitOpSelectName]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";

  const dataInizioAssenzaConst = 'dataInizioAssenza';
  const dataFineAssenzaConst = 'dataFineAssenza';

  const oraInizioAssenzaConst = "oraInizioAssenza";
  const oraFineAssenzaConst = "oraFineAssenza";
  const oreAssenzaConst = 'oreAssenza';
  const minutiAssenzaConst = 'minutiAssenza'

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupAssenzaProgrammata);
  const lookupArticoloLegge = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupAssenzeValidiFromAssenzaProgrammata = useAppSelector(state => state.assenzeProgrammate.lookupTurniValidi);
  const lookupAssenzeEliminatiFromAssenzaProgrammata = useAppSelector(state => state.assenzeProgrammate.lookupTurniEliminati);

  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const currentPath = useMemo(() => history.location.pathname, [history.location.pathname]);
  const [escludiGiaApprovate, setEscludiGiaApprovate] = useState(false);
  const handleChange = () => setEscludiGiaApprovate(state => !state);

  const [obj, setObj] = useState<AssenzaProgrammata | null>(null);

  const updateFields = useCallback((field: Fields, isParziale: boolean): Fields => {
    if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
      return {
        ...field,
        showOn: !isParziale ? 'hidden' : undefined,
        required: [oraInizioAssenzaConst, oraFineAssenzaConst].includes(field.field) && isParziale
      }
    }

    if ([dataFineAssenzaConst].includes(field.field)) {
      return {
        ...field,
        showOn: isParziale ? 'hidden' : undefined
      }
    }

    if ([dataInizioAssenzaConst].includes(field.field)) {
      return {
        ...field,
        colsNum: isParziale ? 12 : 6
      }
    }

    return field;
  }, []);

  const insertCallback = () => {
    setObj({ assenzaDaApprovare: false } as AssenzaProgrammata)

    setAllFieldsState(state => {
      return state.map(field => {
        if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
          return {
            ...field,
            showOn: 'hidden'
          }
        }

        return field;
      });
    });

    history.push(assenzeProgrammatePath + componentInsertPath);
  }

  const updateCallback = (assenzaProgrammate: AssenzaProgrammata) => {
    setObj(assenzaProgrammate);

    setAllFieldsState(state => {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(assenzaProgrammate.idTurnoAssenza))?.assenzaParziale ?? false;
      return state.map(field => updateFields(field, isParziale));
    });

    history.push(assenzeProgrammatePath + componentTabsPath);
  }

  const formLookups = useMemo(() => {
    return {
      [idAnagraficaSoggettoConst]: lookupAnagraficaSoggettoPerUnitaOperativa,
      [idTurnoAssenzaConst]: lookupTurnoAssenza,
      [idArticoloLeggeConst]: lookupArticoloLegge
    }
  }, [lookupAnagraficaSoggettoPerUnitaOperativa, lookupArticoloLegge, lookupTurnoAssenza]);

  /**
   * Fetch lookups
   */
  useEffect(() => {
    dispatch(fetchLookupStrutture());
    dispatch(fetchAssenze());
    dispatch(fetchArticoliLegge());
  }, [dispatch]);

  useEffect(() => {
    if (
      states[strSelectName] &&
      states[dipSelectName] &&
      states[unitOpSelectName]
    )
      dispatch(
        fetchLookupAnagraficaSoggettiPerUnitaOperativa({
          idStruttura: states[strSelectName] as number,
          idDipartimento: states[dipSelectName] as number,
          idUnitaOperativa: states[unitOpSelectName] as number,
        })
      );
  }, [states, strSelectName, dipSelectName, unitOpSelectName, dispatch]);

  useEffect(() => {
    states[strSelectName] &&
      dispatch(
        fetchLookupDipartimenti({
          idStruttura: states[strSelectName] as number,
        })
      );
  }, [states, strSelectName, dispatch]);

  useEffect(() => {
    states[dipSelectName] &&
      dispatch(
        fetchLookupUnitaOperative({
          idStruttura: states[strSelectName] as number,
          idDipartimento: states[dipSelectName] as number,
        })
      );
  }, [states, strSelectName, dipSelectName, dispatch]);

  useEffect(() => {
    dispatch(resetDipartimentiLookup());
    dispatch(resetUOLookup());
  }, [dispatch]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const excludedFieldInTable = useMemo((): string[] => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsGenerale);
  const [columns, setColumns] = useState<Array<Column<AssenzaProgrammata>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState
        .filter(f => !excludedFieldInTable.includes(f.field))
        .filter(f => ['both', 'table', undefined, null].includes(f.showOn))
        .map((f) => {
          let obj: Column<AssenzaProgrammata> = {
            title: f.titleKey ? t(f.titleKey) : "",
            field: f.field,
            removable: f.removable ?? !f.required,
            editable: f.editable ? f.editable : "always",
            defaultSort: f.sort,
            emptyValue: f.defaultValue ?? 'N/A',
          };

          if (f.validate2) {
            obj.validate = (rowData) => {
              let resp: boolean | { isValid: boolean, helperText: string } = false;
              let resp2: boolean | { isValid: boolean, helperText: string } = false;

              if (f.validate2) {
                resp2 = f.validate2(
                  rowData[f.field as AssenzaProgrammataKeys],
                  rowData[f.field2Validation as AssenzaProgrammataKeys],
                  f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                );
              }

              if (f.validate) {
                resp = f.validate(
                  rowData[f.field as AssenzaProgrammataKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              if (resp === true) {
                return resp2;
              } else return resp;
            }
          } else if (f.validate) {
            obj.validate = (rowData) => {
              if (f.validate) {
                return f.validate(
                  rowData[f.field as AssenzaProgrammataKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              return false;
            }
          }

          if (!f.show) {
            obj.hidden = true;
            obj.hiddenByColumnsButton = false;
          }
          if (f.type && f.type !== "image" && f.type !== "file") {
            obj.type = f.type;
          }
          /*+++*/
          if (f.lookupField) {
            switch (f.field) {
              case idAnagraficaSoggettoConst:
                obj.lookup = lookupAnagraficaSoggettoPerUnitaOperativa;
                break;
              case idTurnoAssenzaConst:
                obj.lookup = {
                  ...lookupTurnoAssenza,
                  ...(toggleValidDeleted ? lookupAssenzeValidiFromAssenzaProgrammata : lookupAssenzeEliminatiFromAssenzaProgrammata)
                };
                break;
              case idArticoloLeggeConst:
                obj.lookup = lookupArticoloLegge;
                break;
            }
          }
          /*+++*/

          // Custom components
          if (f.field === idAnagraficaSoggettoConst) {
            obj.editComponent = (props) => {
              const soggettiData = Object.entries(lookupAnagraficaSoggettoPerUnitaOperativa ?? {})
                .map(([key, value]) => [value, key]).sort();

              let id = '';

              return <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  options={soggettiData}
                  value={lookupAnagraficaSoggettoPerUnitaOperativa[id] ?? ""}
                  onChange={(e, val) => {
                    id = val ? val[1] : id;
                    props.onChange(id);
                  }}
                  id={"controllable-states" + f.field}
                  getOptionLabel={(option) => option[0] ?? ''}
                  fullWidth
                  renderInput={(params) => <TextField
                    {...params}
                    required={f.required}
                    margin='dense'
                    error={props.error}
                    helperText={f.keyTradValidation ? t(f.keyTradValidation) : undefined}
                  />}
                />
              </FormControl>
            }
          }

          return obj;
        })
    );
  }, [lookupTurnoAssenza, lookupArticoloLegge, lookupAnagraficaSoggettoPerUnitaOperativa, t, allFieldsState, excludedFieldInTable, toggleValidDeleted, lookupAssenzeValidiFromAssenzaProgrammata, lookupAssenzeEliminatiFromAssenzaProgrammata]);

  const errorBE = useAppSelector((state) => state.assenzeProgrammate.error);

  const validAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.validAssenzeProgrammate);
  const deletedAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.deletedAssenzeProgrammate);

  const statusValidAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.statusValidAssenzeProgrammate);
  const statusDeletedAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.statusDeletedAssenzeProgrammate);

  const [data, setData] = useState<Array<AssenzaProgrammata>>([]);

  useEffect(() => {
    toggleValidDeleted
      ? setData(escludiGiaApprovate ? validAssenzeProgrammate.filter(elem => !elem.approvazioneDateTime && !elem.approvazioneNegataData) : validAssenzeProgrammate)
      : setData(deletedAssenzeProgrammate);
  }, [validAssenzeProgrammate, deletedAssenzeProgrammate, toggleValidDeleted, escludiGiaApprovate]);

  const formCallback = useCallback((formData: AssenzaProgrammata | null, field: string, extraArgs: OptionalArgs<AssenzaProgrammata>) => {
    if (formData) {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(formData.idTurnoAssenza))?.assenzaParziale ?? false;

      if (field === idTurnoAssenzaConst) {
        if (!isParziale) {
          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oraInizioAssenzaConst]: undefined,
              [oraFineAssenzaConst]: undefined,
              [oreAssenzaConst]: undefined,
              [minutiAssenzaConst]: undefined,
            }
          });
        }

        extraArgs.setFields(state => {
          return state.map(field => updateFields(field, isParziale));
        });
      }

      // oreAssenza || minutiAssenza
      if ([oraInizioAssenzaConst, oraFineAssenzaConst].includes(field)) {
        if (formData[oraInizioAssenzaConst] && formData[oraFineAssenzaConst]) {
          const startDate = new Date();
          const endDate = new Date();

          const startTime = formData[oraInizioAssenzaConst];
          const endTime = formData[oraFineAssenzaConst];

          startDate.setHours(Number(startTime?.split(':')[0]));
          startDate.setMinutes(Number(startTime?.split(':')[1]));

          endDate.setHours(Number(endTime?.split(':')[0]));
          endDate.setMinutes(Number(endTime?.split(':')[1]));

          const totalMinutes = differenceInMinutes(endDate, startDate);
          const hours = Math.floor(totalMinutes / minutesInHour);
          const minutes = totalMinutes % minutesInHour;

          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oreAssenzaConst]: Number.isNaN(hours) ? undefined : hours,
              [minutiAssenzaConst]: Number.isNaN(minutes) ? undefined : minutes
            };
          })
        }
      }

      if (isParziale && field === dataInizioAssenzaConst) {
        extraArgs.setInternalObj(state => {
          return {
            ...state,
            [dataFineAssenzaConst]: state[dataInizioAssenzaConst]
          }
        })
      }
    }
  }, [updateFields, validAssenze]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        const idStrutturaValue = fixedProps[elem];
        if (exportDataExtra.head && idStrutturaValue)
          exportDataExtra.head['Struttura'] = lookupStr[idStrutturaValue];
        break;
      case 'idDipartimento':
        const idDipartimentoValue = fixedProps[elem];
        if (exportDataExtra.head && idDipartimentoValue)
          exportDataExtra.head['Dipartimento'] = lookupDip[idDipartimentoValue];
        break;
      case 'idUnitaOperativa':
        const idUnitaOperativaValue = fixedProps[elem];
        if (exportDataExtra.head && idUnitaOperativaValue)
          exportDataExtra.head['Unità Operativa'] = lookupUnitOp[idUnitaOperativaValue];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      setStates({});
      dispatch(resetAssenzeProgrammate());
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetStruttureSlice());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    callback: resetDipartimentiLookup,
                  },
                  {
                    name: unitOpSelectName,
                    lookup: lookupUnitOp,
                    label: unitOpSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    callback: resetUOLookup,
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            <Grid item >
              {
                states[strSelectName] &&
                states[dipSelectName] &&
                states[unitOpSelectName] &&
                <FormControl>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        disabled={!isUrlRoot(currentPath, assenzeProgrammatePath)}
                        checked={escludiGiaApprovate}
                        onChange={handleChange}
                        name="Solo Approvazione"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={t("endforapproved")}
                  />
                </FormControl>
              }
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[strSelectName] &&
          states[dipSelectName] &&
          states[unitOpSelectName] ? (
          <Box marginTop={2}>
            <Switch>
              <Route path={assenzeProgrammatePath} exact>
                <CrudMaterialTable
                  abilitazione={abilitazione}
                  title={title}
                  columns={columns}
                  columnsButton={true}
                  data={data}
                  elementIdProps={elementIdProps}
                  elementRenderProps={elementRenderProps}
                  fetchAllValid={fetchValidByUnitaOperativa}
                  fetchAllDeleted={fetchDeletedByUnitaOperativa}
                  insertCallback={insertCallback}
                  updateCallback={updateCallback}
                  logicalDel={logicalDel}
                  physicalDel={physicalDel}
                  restore={restore}
                  dataValid={toggleValidDeleted}
                  setToggleValidDeleted={setToggleValidDeleted}
                  statusValid={statusValidAssenzeProgrammate}
                  statusDeleted={statusDeletedAssenzeProgrammate}
                  errorBE={errorBE}
                  logoUri={logoUri}
                  localizedDatePicker={true}
                  fixedProps={fixedProps}
                  exportDataExtra={exportDataExtra}
                  isExportLandscape={true}
                  resetErrorCallback={resetError}
                  extraOptions={{
                    maxBodyHeight: 460,
                  }}
                />
              </Route>
              <Route path={assenzeProgrammatePath + componentInsertPath} exact>
                <InnerComponentViews
                  abilitazione={abilitazione}
                  mainUri={assenzeProgrammatePath}
                  tabsUri={assenzeProgrammatePath + componentInsertPath}
                  tabsView={false}
                  buttonTitle={t("plannedAbsenceParam")}
                  info1={t("")}
                  tabs={[
                    {
                      label: t(""),
                      tabPath: "",
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: (
                        <GeneralForm
                          readOnly={false}
                          language={i18n.language}
                          componentPath={assenzeProgrammatePath}
                          action={insert}
                          status={statusValidAssenzeProgrammate}
                          error={errorBE}
                          update={false}
                          obj={{}}
                          fields={allFieldsState}
                          fixedProps={fixedProps}
                          lookups={formLookups}
                          translate={t}
                          checksCallback={formCallback}
                        />
                      ),
                      componentElse: <Redirect to={assenzeProgrammatePath} />,
                    },
                  ]}
                />
              </Route>
              <Route path={assenzeProgrammatePath + componentTabsPath}>
                <InnerComponentViews
                  abilitazione={abilitazione}
                  mainUri={assenzeProgrammatePath}
                  tabsUri={assenzeProgrammatePath + componentTabsPath}
                  tabsView={false}
                  buttonTitle={t(title)}
                  info1={''}
                  tabs={[
                    {
                      label: t("plannedAbsenceParam"),
                      tabPath: '',
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: (
                        <GeneralForm
                          readOnly={false}
                          language={i18n.language}
                          componentPath={assenzeProgrammatePath}
                          action={update}
                          status={statusValidAssenzeProgrammate}
                          error={errorBE}
                          update={true}
                          obj={obj}
                          fields={allFieldsState}
                          fixedProps={fixedProps}
                          lookups={formLookups}
                          translate={t}
                          checksCallback={formCallback}
                        />
                      ),
                      componentElse: <Redirect to={assenzeProgrammatePath} />,
                    }
                  ]}
                />
              </Route>
            </Switch>
          </Box>
        ) : (
          <Paper elevation={2}>
            <Box p={4}>
              <Typography align="center" variant="h5">
                {!states[strSelectName]
                  ? strSelectLabel
                  : !states[dipSelectName]
                    ? dipSelectLabel
                    : unitOpSelectLabel}
              </Typography>
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
};
export default AssenzeProgrammateW;
