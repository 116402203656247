import React from 'react';
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { fetchAllValidByAppSoftware as fetchAllValidChatByAppSoftware } from '../../../store/slices/chatSlice';
import { Chat } from '../../../models/Chat';
import { fetchAllValidByAppSoftware as fetchAllValidChatMessaggiByAppSoftware } from '../../../store/slices/chatMessaggioSlice';

interface UseChatBoxProps {
  selectedChat?: Chat,
  currentUser?: number,
}

export const useChatBox = (props: UseChatBoxProps) => {
  const dispatch = useAppDispatch();

  const receiver = React.useMemo(() => {
    if (props.selectedChat)
      return props.selectedChat.idUtente1 === props.currentUser ? props.selectedChat.nominativo2 : props.selectedChat.nominativo1;
    else return null;
  }, [props.currentUser, props.selectedChat]);

  const appSoftware = useAppSelector(state => state.applicazioneSoftware.appSoftware);
  const errorChatMessaggio = useAppSelector(state => state.chatMessaggio.error);

  const fetchMessaggiDelChat = React.useCallback(() => {
    if (props.selectedChat && appSoftware?.idApplicazioneSoftware) {
      dispatch(fetchAllValidChatMessaggiByAppSoftware({
        idApplicazioneSoftware: appSoftware.idApplicazioneSoftware,
        idUtente1: props.selectedChat.idUtente1,
        idUtente2: props.selectedChat.idUtente2
      }));
    }
  }, [dispatch, appSoftware?.idApplicazioneSoftware, props.selectedChat]);

  React.useEffect(() => {
    if (errorChatMessaggio === 'cancelled') {
      fetchMessaggiDelChat();
    }
  }, [errorChatMessaggio, fetchMessaggiDelChat]);

  /**
   * Fetch all valid chat list
   */
  React.useEffect(() => {
    if (appSoftware?.idApplicazioneSoftware)
      dispatch(fetchAllValidChatByAppSoftware({ idApplicazioneSoftware: appSoftware.idApplicazioneSoftware }));
  }, [dispatch, appSoftware?.idApplicazioneSoftware]);

  /**
   * fetch all valid messages
   */
  React.useEffect(() => {
    if (appSoftware?.idApplicazioneSoftware && receiver) {
      fetchMessaggiDelChat();
    }
  }, [fetchMessaggiDelChat, appSoftware?.idApplicazioneSoftware, receiver]);

  return {
    idApplicazioneSoftware: appSoftware?.idApplicazioneSoftware,
    receiver,
    fetchMessaggiDelChat
  }
}