import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, restoreById, getAllValid, getAllDeleted, upd } from '../../services/services.service'
import { elementIdProps, TipiDocumenti, TipiDocumentiKeys, } from '../../models/TipiDocumenti';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "tipodocumento";
const entity = "tipodocumento";

interface tipoDocumentoState {
  statusValidtipoDocumento: StatusEnum,
  validtipoDocumento: TipiDocumenti[],
  statusDeletedtipoDocumento: StatusEnum,
  deletedtipoDocumento: TipiDocumenti[],
  statusAlltipoDocumento: StatusEnum,
  alltipoDocumento: TipiDocumenti[],
  tipoDocumento: TipiDocumenti | null,
  lookupDescrizione: Lookup,
  lookupDescrizioneBreve: Lookup,
  lookup: Lookup,
  error: string | null
}

const initialState: tipoDocumentoState = {
  statusValidtipoDocumento: StatusEnum.Succeeded,
  validtipoDocumento: [],
  statusDeletedtipoDocumento: StatusEnum.Succeeded,
  deletedtipoDocumento: [],
  statusAlltipoDocumento: StatusEnum.Succeeded,
  alltipoDocumento: [],
  tipoDocumento: null,
  lookupDescrizione: {},
  lookupDescrizioneBreve: {},
  lookup: {},
  error: null
}

// post inserisci/tipidocumenti
export const insert = createAsyncThunk(microservice + '/insert', async (turno: TipiDocumenti) => {
  turno.visibileAnagraficaSoggetto = false;
  const response = await create(turno, microservice, entity);
  return response.data as TipiDocumenti;
});

// put modifica/tipidocumenti
export const update = createAsyncThunk(microservice + '/update', async (turno: TipiDocumenti) => {
  const response = await upd(turno, microservice, entity);
  return response.data as TipiDocumenti;
});

// put elimina/tipidocumenti/id/
export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/tipidocumenti/id/
export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/tipidocuementi/id/
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

// get cerca/tipidocumenti/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as TipiDocumenti[];
});

// get cerca/tipidocumenti/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as TipiDocumenti[];
});

export const tipoDocumentoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: tipoDocumentoState) => {
      return initialState;
    },
    resetError: (state: tipoDocumentoState) => {
      state.error = initialState.error;
      state.statusValidtipoDocumento = initialState.statusValidtipoDocumento;
      state.statusDeletedtipoDocumento = initialState.statusDeletedtipoDocumento;
      state.statusAlltipoDocumento = initialState.statusAlltipoDocumento;
    }
  },

  extraReducers: builder => {
    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoDocumento = StatusEnum.Failed;
      }
      state.tipoDocumento = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<TipiDocumenti>) => {
      state.statusValidtipoDocumento = StatusEnum.Succeeded;
      state.tipoDocumento = payload;
      state.validtipoDocumento = state.validtipoDocumento.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoDocumento = StatusEnum.Failed;
      }
      state.tipoDocumento = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<TipiDocumenti>) => {
      state.statusValidtipoDocumento = StatusEnum.Succeeded;
      state.validtipoDocumento = state.validtipoDocumento.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.tipoDocumento = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoDocumento = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidtipoDocumento = StatusEnum.Succeeded;
      const deleted = state.validtipoDocumento.find(el => payload.ids.every(idObj => el[idObj.name as TipiDocumentiKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedtipoDocumento = state.deletedtipoDocumento.concat([deleted]);
      }
      state.validtipoDocumento = state.validtipoDocumento.filter(el => payload.ids.some(idObj => el[idObj.name as TipiDocumentiKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoDocumento = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedtipoDocumento = StatusEnum.Succeeded;
      const valid = state.deletedtipoDocumento.find(el => payload.ids.every(idObj => el[idObj.name as TipiDocumentiKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validtipoDocumento = state.validtipoDocumento.concat([valid]);
      }
      state.deletedtipoDocumento = state.deletedtipoDocumento.filter(el => payload.ids.some(idObj => el[idObj.name as TipiDocumentiKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoDocumento = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedtipoDocumento = StatusEnum.Succeeded;
      state.deletedtipoDocumento = state.deletedtipoDocumento.filter(el => payload.ids.some(idObj => el[idObj.name as TipiDocumentiKeys] !== idObj.id));
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidtipoDocumento = StatusEnum.Failed;
      }
      state.validtipoDocumento = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<TipiDocumenti[]>) => {
      state.statusValidtipoDocumento = StatusEnum.Succeeded;
      state.validtipoDocumento = payload ?? [];
      state.lookup = createLookup(payload, 'idTipoDocumento', ['descrizione']);
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedtipoDocumento = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedtipoDocumento = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedtipoDocumento = StatusEnum.Failed;
      }
      state.deletedtipoDocumento = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<TipiDocumenti[]>) => {
      state.statusDeletedtipoDocumento = StatusEnum.Succeeded;
      state.deletedtipoDocumento = payload ?? [];
      state.lookup = createLookup(payload, 'idTipoDocumento', ['descrizione']);
    })

  }

});

export const { reset, resetError } = tipoDocumentoSlice.actions;
export default tipoDocumentoSlice.reducer;