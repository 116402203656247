import { Button, createStyles, Dialog, Grid, IconButton, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import { red } from "@material-ui/core/colors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import CloseIcon from '@material-ui/icons/Close';
import { closeDialogConfirm, noClickDialogConfirm, yesClickDialogConfirm } from "../../../store/slices/dialogsSlice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const redSecondaryTheme = createTheme({
    palette: {
        secondary: red,
    },
});

const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 24
        },
        closeButton: {
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme.palette.grey[500],
        }
    })
);

export default function DialogConfirm() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { open, title, text, showButtons } = useAppSelector(state => state.dialogs.dialogConfirm);

    const dispatch = useAppDispatch();

    const backButtonYesClick = async () => {
        dispatch(yesClickDialogConfirm());
        handleClose();
    };

    const backButtonNoClick = async () => {
        dispatch(noClickDialogConfirm());
        handleClose();
    };

    const handleClose = useCallback(() => {
        dispatch(closeDialogConfirm());
    }, [dispatch]);

    return (
        <ThemeProvider theme={redSecondaryTheme}>
            <Dialog onClose={backButtonNoClick} aria-labelledby="goback-dialog-title" open={open}>
                <div className={classes.padding}>
                    <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={backButtonNoClick}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" id="goback-dialog-title">{title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="secondary" variant="h6" id="goback-dialog-title">
                                {text}
                            </Typography>
                        </Grid>
                        {
                            showButtons && <>
                                <Grid item xs={6}>
                                    <Button fullWidth variant="contained" onClick={backButtonNoClick}>{t('no')}</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth variant="contained" color="secondary" onClick={backButtonYesClick}>{t('yes')}</Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
            </Dialog>
        </ThemeProvider>
    );
}

