import { Column } from "@material-table/core";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiSchedaSanitaria
} from "../../../store/slices/anagraficaSoggettiSchedaSanitariaSlice";
import {
  resetError as resetErrorCodiciVisite
} from '../../../store/slices/codiciVisiteSlice';
import {
  resetError as resetErrorIdoneita
} from '../../../store/slices/idoneitaSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiSchedaSanitaria, allFields, AnagraficaSoggettiSchedaSanitariaKeys } from '../../../models/AnagraficaSoggettiSchedaSanitaria';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Route, Switch, } from "react-router-dom";


interface AnagraficaSoggettiSchedaSanitariaWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiSchedaSanitaria: AnagraficaSoggettiSchedaSanitaria[],
  anagraficaSoggettiSchedaSanitariaEliminati: AnagraficaSoggettiSchedaSanitaria[]
}

const AnagraficaSoggettiSchedaSanitariaW = ({ idAnagraficaSoggetto, anagraficaSoggettiSchedaSanitaria, anagraficaSoggettiSchedaSanitariaEliminati }: AnagraficaSoggettiSchedaSanitariaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();


  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiSchedaSanitaria());
    dispatch(resetErrorCodiciVisite());
    dispatch(resetErrorIdoneita());
  }, [dispatch]);

  const idoneitaLookup = useAppSelector(state => state.idoneita.lookup);
  const codiceVisitaLookup = useAppSelector(state => state.codiciVisite.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto"
  const idIdoneita = "idIdoneita";
  const idCodiceVisita = "idCodiceVisita";




  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('healthCardTitle');

  useEffect(() => {

  })

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiSchedaSanitaria>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiSchedaSanitaria> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as AnagraficaSoggettiSchedaSanitariaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '')
            }
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idIdoneita:
            obj.lookup = idoneitaLookup;
            break;
          case idCodiceVisita:
            obj.lookup = codiceVisitaLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [idoneitaLookup, codiceVisitaLookup, t, allFieldsState, excludedFieldInTable]);



  const statusValidAnagraficaSoggettiSchedaSanitaria = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.statusValidAnagraficaSoggettiSchedeSanitarie);
  const statusDeletedAnagraficaSoggettiSchedaSanitaria = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.statusDeletedAnagraficaSoggettiSchedeSanitarie);

  const [data, setData] = useState<Array<AnagraficaSoggettiSchedaSanitaria>>([]);



  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiSchedaSanitaria) : setData(anagraficaSoggettiSchedaSanitariaEliminati);
  }, [
    toggleValidDeleted,
    anagraficaSoggettiSchedaSanitaria,
    anagraficaSoggettiSchedaSanitariaEliminati,
  ]);

  useInitValid(
    statusValidAnagraficaSoggettiSchedaSanitaria,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );
  useInitDeleted(
    statusDeletedAnagraficaSoggettiSchedaSanitaria,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );



  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idAnagraficaSoggetto':
        if (exportDataExtra.head)
          exportDataExtra.head['Anagrafica Soggetto'] = lookupAnagraficaSoggetto[fixedProps[elem]];
        break;
    }
  });


  return (

    <Switch>
      <Route path='*'>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchExtendedByIdAndDate}
          restore={restore}
          logicalDel={logicalDel}
          update={update}
          insert={insert}
          physicalDel={physicalDel}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusValidAnagraficaSoggettiSchedaSanitaria}
          statusDeleted={statusDeletedAnagraficaSoggettiSchedaSanitaria}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          isExportLandscape={true}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            maxBodyHeight: 460,
          }}
        />

      </Route>
    </Switch>

  )
}
export default AnagraficaSoggettiSchedaSanitariaW;